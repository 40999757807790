import { useObserver } from 'mobx-react';
import HeaderSidebar from '@commonComponents/HeaderSidebar/HeaderSidebar';
import Content from '@commonComponents/Content/Content';
import Box1040 from '@commonComponents/Box1040/Box1040';
import Step1 from '@src/pages/Corp/AwsCredit/Step1';

function AwsCredit() {
  return useObserver(() => (
    <>
      <HeaderSidebar companyName="" awsCreditActiveYn={true} />
      <Content>
        <Box1040>
          <Step1 guest={true} />
        </Box1040>
      </Content>
    </>
  ));
}
export default AwsCredit;
