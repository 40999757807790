import { runInAction, observable, toJS } from 'mobx';
import portalModel from '@model/portalModel';
import { _alert, _confirm } from '@model/dialogModel';
import formDataToJson from '@common/module/submit';
import CompanyNameSearch from './CompanyNameSearch/CompanyNameSearch';
import ShareholderListPopup from './ShareholderListPopup/ShareholderListPopup';
import AoAPopup from './AoAPopup/AoAPopup';
import RegisterPopup from './RegisterPopup/RegisterPopup';

class CompanyRegistrationVm {
  constructor() {
    this.state = observable({
      action: false,
      companyNameSearch: false,
      company: {
        companyType: '',
        companyName: '',
        address: '',
        office: '',
        registerNum: '',
      },
    });
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = new FormData(e.target);
      //파일업로드 validation
      const regExp = /\[\d+\]/g;
      let _key = [];
      for (let [key, value] of param) {
        _key.push(key.replace(regExp, ''));
      }

      if (!_key.includes('stockholderFiles')) {
        await _alert('주주명부를 업로드하세요.');
        return;
      }
      if (!_key.includes('aoaFiles')) {
        await _alert('정관을 업로드하세요.');
        return;
      }
      if (!_key.includes('businessRegistrationFile')) {
        await _alert('사업자등록증을 업로드하세요.');
        return;
      }

      runInAction(() => {
        portalModel.content = (
          <RegisterPopup
            param={param}
            companyName={this.state.company.companyName}
            companyAddress={this.state.company.address}
          />
        );
      });
    }
  }
  get companyNameSearch() {
    return this.state.companyNameSearch;
  }
  openCompanyNameSearch() {
    runInAction(() => {
      portalModel.title = '한글 회사이름 입력';
      portalModel.content = <CompanyNameSearch companyData={this.state.company} />;
    });
  }
  shareholderListPopup() {
    runInAction(() => {
      portalModel.content = <ShareholderListPopup />;
      portalModel.closeBtnDarkYn = true;
    });
  }
  aoaPopup() {
    runInAction(() => {
      portalModel.content = <AoAPopup />;
      portalModel.closeBtnDarkYn = true;
    });
  }
}
export default CompanyRegistrationVm;
