import { useObserver } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { Route, Routes } from 'react-router-dom';

import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import st from './Auth.module.scss';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import ExpiredInvitedMembership from './ExpiredInvitedMembership/ExpiredInvitedMembership';
import FindId from './FindId/FindId';
import FindPassword from './FindPassword/FindPassword';
import InvitedJoinMembership from './Invited/Invited';
import JoinMembership from './JoinMembership/JoinMembership';
import Login from './Login/Login';
import LoginMobile from './Login/LoginMobile';
import RequestEmailCheck from './RequestEmailCheck/RequestEmailCheck';
import SetupNewPassword from './SetupNewPassword/SetupNewPassword';

function Auth() {
  return useObserver(() => (
    <Routes>
      {/* 비밀번호 찾기 */}
      <Route path="/findPassword" element={<DesktopWrap component={<FindPassword />} />} />
      {/* 이메일 확인 요청*/}
      <Route path="/requestemailCheck" element={<DesktopWrap component={<RequestEmailCheck />} />} />
      {/* 새로운 비밀번호 설정*/}
      <Route path="/setupNewPassword" element={<DesktopWrap component={<SetupNewPassword />} />} />
      {/* 회원가입 추가정보*/}
      <Route path="/additionalInformation" element={<DesktopWrap scroll component={<AdditionalInformation />} />} />
      {/* 이메일변경*/}
      <Route path="/changeEmail" element={<DesktopWrap component={<ChangeEmail />} />} />
      {/* 로그인 */}
      <Route path="/login" element={isMobile ? <LoginMobile /> : <DesktopWrap component={<Login />} />} />
      {/* 회원가입 */}
      <Route path="/joinMembership" element={<DesktopWrap component={<JoinMembership />} />} />
      {/* 관리자 초대 회원가입 */}
      <Route path="/invitedMembership" element={<DesktopWrap scroll component={<InvitedJoinMembership />} />} />
      {/* 관리자 초대 가입링크 만료 */}
      <Route path="/expiredInvitedMembership" element={<DesktopWrap component={<ExpiredInvitedMembership />} />} />
      {/* 아이디 찾기*/}
      <Route path="/findId" element={<DesktopWrap component={<FindId />} />} />
    </Routes>
  ));
}

function DesktopWrap(props) {
  return useObserver(() => (
    <div className={st.wrap}>
      <div className={st.Auth}>
        <div className={st.AuthBox}>
          <div
            className={st.authContent}
            style={{
              alignItems: props.scroll ? 'flex-start' : 'center',
              justifyContent: props.scroll ? 'flex-start' : 'center',
              paddingRight: props.scroll ? '3px' : '48px',
            }}
          >
            {props.component}
          </div>
          <div className={st.etcContent}>
            <img src="/images/배너_로그인_컨텐츠.png" style={{ width: '420px' }} />
          </div>
        </div>
      </div>
    </div>
  ));
}

export default Auth;
