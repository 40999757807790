import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {
  Agenda,
  IA폼_없음,
} from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA폼_없음>;
}

function C폼_없음(props: Props) {
  return useObserver(() => (
    <div className={st.agenda}>
      <div className={st.title}>
        <div className={st.iconTitle}>안건</div>
        <div className={st.number}>{props.agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>{props.agenda.info.title}</span>
        </div>
        {props.agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
    </div>
  ));
}
export default C폼_없음;
