import { useObserver } from 'mobx-react';
import ShareholderMeetingsWaiverConsentAndNewStockIssueVm from './ShareholderMeetingsWaiverConsentAndNewStockIssueVm';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import Information from './Information/Information';
import Security from './Security/Security';
import SecurityMobile from './Security/SecurityMobile';
import { isMobile } from 'react-device-detect';

let vm: ShareholderMeetingsWaiverConsentAndNewStockIssueVm;

function ShareholderMeetingsWaiverConsentAndNewStockIssue(props: { token: string }) {
  useComponentWillMount(() => {
    vm = new ShareholderMeetingsWaiverConsentAndNewStockIssueVm();
  });

  return useObserver(() => (
    <>
      {vm.state.pageStatus === 'Security' && (
        <>
          {isMobile ? (
            <SecurityMobile token={props.token} changePage={() => vm.changeInformationPage()} />
          ) : (
            <Security token={props.token} changePage={() => vm.changeInformationPage()} />
          )}
        </>
      )}
      {vm.state.pageStatus === 'Information' && (
        <Information token={props.token} changePage={() => vm.changeSecurityPage()} />
      )}
    </>
  ));
}
export default ShareholderMeetingsWaiverConsentAndNewStockIssue;
