import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';

class EmptyShareholdersVm {
  get companyName() {
    return corpModel.company.companyName;
  }
  get demoCorpId() {
    let demoId = '';
    corpModel.companies?.forEach((item, idx) => {
      if (item.isDemoYn) {
        demoId = item.companyId;
      }
    });
    return demoId;
  }
  preview() {
    if (portalModel.content) {
      portalModel.content = null;
    }
  }
}
export default new EmptyShareholdersVm();
