import { observable, makeObservable } from 'mobx';
import type {
  ChangeRegistrationAgencyModelInterface,
  JointCertificate,
  DigitalCertificate,
} from './ChangeRegistrationAgencyModel.interface';

class ChangeRegistrationAgencyModel implements ChangeRegistrationAgencyModelInterface {
  constructor() {
    makeObservable(this, {
      jointCertificate: observable,
      digitalCertificate: observable,
      requestRequirement: observable,
      personInCharge: observable,
      telephoneCounselingYn: observable,
      informationProvideYn: observable,
    });
  }
  jointCertificate: JointCertificate | null = null;
  digitalCertificate: DigitalCertificate = {
    company: {
      useType: null,
    },
    companyShareholderList: null,
  };
  requestRequirement: string = '';
  personInCharge: {
    name: string;
    phoneNum: string;
    email: string;
  } = {
    name: '',
    phoneNum: '',
    email: '',
  };
  telephoneCounselingYn: boolean = false;
  informationProvideYn: boolean = false;
}

export default new ChangeRegistrationAgencyModel();
