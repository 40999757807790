import { post, get } from '@common/module/httpRequest';
import { runInAction, toJS } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import homeModel from '@model/Corp/Stock/Home/homeModel';

class homeService {
  async homeData() {
    const res = await get(`/stockholder/home/company/${corpModel.id}`);
    const data = res.data;
    homeModel.setStock(data);
  }
  async stockholderDate(date) {
    const res = await get(`/stockholder/home/company/${corpModel.id}/${date}`);
    const data = res.data;
    homeModel.setShareholders(data);
  }
}

export default new homeService();
