import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './DashboardVm';
import st from './Dashboard.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import CompanyFiles from './CompnayFiles/CompnayFiles';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import Capital from './Capital/Capital';
import Stock from './Stock/Stock';
import Executives from './Executives/Executives';
import Aoa from './Aoa/Aoa';
import Decision from './Decision/Decision';
import TaxSchedule from './TaxSchedule/TaxSchedule';
import Address from './Address/Address';
import CompanyName from './CompanyName/CompanyName';
import Notice from './Notice/Notice';
import Purposes from './Purposes/Purposes';
import Detail from './Detail/Detail';

function Dashboard() {
  useEffect(() => {
    vm.load();
  }, []);
  return useObserver(() =>
    vm.dashboardData ? (
      <>
        <PageTitle>
          <span className={st.pageTitle}>한 눈에 보는 우리회사</span>
        </PageTitle>
        <div className={st.contentBox}>
          <div className={st.row}>
            <CompanyFiles />
            <CompanyInfo />
          </div>
          <div className={st.row}>
            <Capital />
            <Stock />
          </div>
          <div className={st.row}>
            <Executives />
            <Aoa />
          </div>
          <div className={st.row}>
            <Decision />
            <TaxSchedule />
          </div>
          <div className={st.row}>
            <Address />
            <CompanyName />
            <Notice />
          </div>
          <div className={st.row}>
            <Purposes />
            <Detail />
          </div>
        </div>
      </>
    ) : (
      <></>
    ),
  );
}
export default Dashboard;
