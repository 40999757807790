import FinalModel from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel';
import FinalService from '@service/Corp/Agenda/Step/FinalCheck/Final/FinalService';
import corpModel from '@model/Corp/corpModel';

class SelfVm {
  get documents() {
    return FinalModel.documents;
  }
  fileDown(url: string) {
    FinalService.fileDown(url);
  }
  get completed() {
    return FinalModel.completed;
  }
  get corpId() {
    return corpModel.id;
  }
}
export default new SelfVm();
