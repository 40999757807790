import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import st from './Header.module.scss';
import vm from './HeaderVm';
import { Route, withRouter, Link } from 'react-router-dom';
import clsx from 'clsx';
import DialogBtn from '@standby/common-ui/components/atoms/Button/DialogBtn/DialogBtn';

import ScrollArea from 'react-custom-scrollbars-2';
function Header() {
  useEffect(() => {
    if (vm.notifications.filter((notification) => notification.notificationType === 'INVITE').length !== 0) {
      vm.state.openNotify = true;
    }
  }, [vm.notifications.filter((notification) => notification.notificationType === 'INVITE').length]);
  return useObserver(() => (
    <>
      <header id={st.header}>
        <Link to="/">
          <img src="/images/logo.svg?1" alt="스탠바이 서비스 로고" title="스탠바이 서비스 로고" />
        </Link>
        <div>
          <button
            className={clsx(st.buttonIcon, st.notify, vm.newNotifyYn ? st.new : null)}
            onClick={() => vm.openNotification()}
          >
            알림
          </button>
          <button className={clsx(st.buttonIcon, st.mypage)} onClick={() => vm.openUserSetting()}>
            {vm.name}님
          </button>
        </div>
      </header>
      <div className={clsx(st.notifyWrap, vm.state.openNotify ? st.open : null)}>
        <div className={st.notifyRelative}>
          <div className={st.background} onClick={() => vm.openNotification()}></div>
          <div className={st.notifyBox}>
            <div className={st.title}>알림</div>
            {vm.notifications.length === 0 && <div className={st.empty}>새로운 알림이 없습니다.</div>}
            {vm.notifications.length !== 0 && (
              <div className={st.list} style={{ height: 'calc(100% - 57px)' }}>
                <ScrollArea
                  className={st.scrollArea}
                  renderThumbVertical={(props) => (
                    <div {...props} style={{ width: '3px', backgroundColor: '#ADAFB1' }} />
                  )}
                >
                  <div className={st.inside}>
                    {vm.notifications
                      .filter((notification) => notification.notificationType === 'INVITE')
                      .map((notification, index) => (
                        <div className={st.confirm} key={index}>
                          <div className={st.detail}>{notification.content}</div>
                          <div className={st.time}>{notification.createdAt}</div>
                          <div className={st.btnArea}>
                            <DialogBtn
                              color="confirm"
                              onClick={() =>
                                vm.InviteTypeAccept(notification.callbackUrl, true, notification.notificationId)
                              }
                            >
                              수락
                            </DialogBtn>
                            <DialogBtn
                              onClick={() =>
                                vm.InviteTypeAccept(notification.callbackUrl, false, notification.notificationId)
                              }
                            >
                              거절
                            </DialogBtn>
                          </div>
                        </div>
                      ))}
                    {vm.notifications.filter((notification) => notification.notificationType === 'GENERAL').length !==
                      0 && (
                      <div className={st.readList}>
                        {vm.notifications
                          .filter((notification) => notification.notificationType === 'GENERAL')
                          .map((notification, index) => (
                            <div className={clsx(st.readType, notification.readAt ? st.read : null)} key={index}>
                              <div
                                className={st.detail}
                                dangerouslySetInnerHTML={{ __html: notification.content.replace(/\n/g, '<br />') }}
                              />
                              <div className={st.time}>{notification.createdAt}</div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </ScrollArea>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ));
}
export default Header;
