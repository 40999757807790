import { useObserver } from 'mobx-react';
import st from './ChangeRegistrationAgency.module.scss';
import clsx from 'clsx';
import vm from './ChangeRegistrationAgencyVm';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import { useEffect } from 'react';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import josa, { josaType } from '@common/module/josa';
import regExp from '@common/module/regExp';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface ChangeRegistrationAgencyProps {
  agendaProgressId: string;
}

function ChangeRegistrationAgency(props: ChangeRegistrationAgencyProps) {
  useEffect(() => {
    vm.load(props.agendaProgressId);
  }, []);

  return useObserver(() => (
    <form className={st.modalContent} onSubmit={(e) => vm.submit(e, props.agendaProgressId)}>
      <div className={st.questionBox}>
        <div className={st.questionBoxTitle}>등기절차</div>
        <div className={st.qusetion}>
          <img src="/images/registraion_agency_procedure.svg" className={st.procedureImg} alt="등기절차" />
          <div className={st.progressTypeExplain}>
            <div className={st.explain}>법률사무소가 실시간 상담제공</div>
            <div className={st.explain}>상담 후 등기대행 여부를 결정할 수 있어요.</div>
          </div>
        </div>
      </div>
      {vm.jointCertificate && (
        <div className={st.questionBox}>
          <div className={st.questionBoxTitle}>공동인증서</div>
          <div className={st.qusetion}>
            <div className={st.questionTitle} style={{ marginBottom: '10px' }}>
              아래 분들이 모두 공동인증서 사용이 가능한가요?
            </div>
            <QuestionTooltip title="공동인증서를 이용한 전자등기 안내">
              <div className={st.tooltipContent}>
                <div className={st.tooltip}>
                  <div className={st.title}>
                    1. 은행용 공동 인증서가 있으면, 인감증명서 준비 없이 전자등기가 가능해요.
                  </div>
                  <div className={st.content}>
                    범용 인증서 사용도 가능해요. 만약 서류등기로 진행한다면, 주주, 임원, 법인의 인감증명서 원본(발급
                    3개월내), 주민등록초본, 인감도장 등이 필요해요. 시간도 3일 정도가 더 걸려요.
                  </div>
                </div>
                <div className={st.tooltip}>
                  <div className={st.title}>2. 법인주주의 경우 공동인증서가 아닌 전자증명서로 서명해야 해요.</div>
                  <div className={st.content}>
                    대법원 규칙상 법인주주는 공동인증서가 아니라, 등기소에서 발급하는 온라인 등기 전용 '전자증명서'로
                    전자서명이 가능해요. 전자 증명서가 없다면, 등기대행업체에서 대리발급이 가능해요. (법인인감도장을
                    찍은 서류 실물 필요)
                  </div>
                </div>
                <div className={st.tooltip}>
                  <div className={st.title}>3. 펀드/투자조합이나 외국인은 전자서명이 불가능해요.</div>
                  <div className={st.content}>
                    대법원에서는 펀드/투자조합, 외국인의 전자서명은 아직 허용하지 않고 있어요. 향후에는 허용할 수도
                    있다고 해요.
                  </div>
                </div>
                <div className={st.tooltip}>
                  <div className={st.title}>
                    4. 공동인증서만 사용 가능하고, 금융인증서X 간편인증서X 사용은 불가능해요.
                  </div>
                  <div className={st.content}>
                    대법원에서 운영하는 인터넷등기소는 공동인증서만 허용하고 있어요. 향후에는 금융인증서도 사용이
                    가능하다고 해요.
                  </div>
                </div>
                <div className={st.tooltip}>
                  <div className={st.title}>
                    5. 일부 사람은 공동인증서로, 일부 사람은 인감증명서 준비하는 방식은 불가능해요.
                  </div>
                  <div className={st.content}>
                    대법원에서는 등기를 할 때 방식을 통일하도록 정하고 있어요. 그래서 일부 사람은 공동인증서로
                    전자서명을 하고, 일부 사람은 인감증명서를 준비하는 방식은 불가능해요.
                  </div>
                </div>
                <div className={st.tooltip}>
                  <div className={st.title}>
                    6. 주주, 임원들이 각자 집에서 대법원 사이트에서 공동인증서 비밀번호를 넣어주시면 되어요.
                  </div>
                  <div className={st.content}>
                    각자 집에서 공동인증서 비밀번호만 넣으면 되고, 공동인증서를 등기대행 업체에 보내거나, 한 장소에 모일
                    필요가 없어요.
                  </div>
                </div>
                <div className={st.tooltip}>
                  <div className={st.title}>7. 윈도우PC로만 공동인증서 사용이 가능해요.</div>
                  <div className={st.content}>맥 X, 모바일 X</div>
                </div>
              </div>
            </QuestionTooltip>
            <div className={st.radioBox}>
              <RadioLabel
                className={st.radioLabel}
                name="jointCertificateUseYn"
                value="true"
                checked={String(vm.jointCertificate?.useYn) === 'true'}
                onChange={(e) => vm.setJointCertificateUseYn(e)}
              >
                사용 가능합니다. <span className={st.radioTag}>(전자등기로 진행)</span>
                <ul className={st.shareholderList}>
                  {vm.jointCertificate?.shareholderNameList?.map((shareholder, idx) => (
                    <li key={idx}>{shareholder}</li>
                  ))}
                </ul>
              </RadioLabel>
              <RadioLabel
                className={st.radioLabel}
                name="jointCertificateUseYn"
                value="false"
                checked={String(vm.jointCertificate?.useYn) === 'false'}
                onChange={(e) => vm.setJointCertificateUseYn(e)}
              >
                사용 불가능합니다. <span className={st.radioTag}>(서류등기로 진행)</span>
              </RadioLabel>
            </div>
            <div>
              <Error
                name="jointCertificateUseYn"
                value={vm.jointCertificate?.useYn !== null ? String(vm.jointCertificate?.useYn) : ''}
                errorCase={{
                  required: '공동인증서 사용 가능여부를 선택하세요.',
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={st.questionBox}>
        <div className={st.questionBoxTitle}>전자증명서</div>
        <div className={st.qusetion}>
          <div className={st.certificateBox} style={{ marginBottom: '36px' }}>
            <div className={st.title}>전자증명서 이미지</div>
            <div className={st.exampleBox}>
              <div className={st.example}>
                <div className={st.exampleTitle}>
                  <span>전자증명서 (구)</span>
                </div>
                <img src="/images/certificate.svg" alt="전자증명서(구)" />
              </div>
              <div className={st.example}>
                <div className={st.exampleTitle}>
                  <span>전자증명서 (신)</span>
                </div>
                <img src="/images/certificate_new.svg" alt="전자증명서(신)" />
              </div>
            </div>
          </div>
          <QuestionTooltip title="전자증명서란?" style={{ width: '460px' }}>
            <div className={clsx(st.tooltipContent, st.certificate)}>
              <div className={st.tooltip}>
                <div className={st.title}>전자증명서는 전자등기 전용 인증서에요.</div>
                <div className={st.content}>
                  등기소에서 발급이 가능하고, 등기소 양식에 법인인감도장을 찍어서 발급 받을 수 있어요. (발급 공과금
                  3,000원)
                </div>
              </div>
              <div className={st.tooltip}>
                <div className={st.title}>법인은 전자등기를 할 때 꼭 전자증명서가 있어야 해요.</div>
                <div className={st.content}>
                  전자등기시, 개인의 경우 공동인증서로 전자서명을 하지만, 법인의 경우 전자증명서로만 전자서명을 할 수
                  있어요. (법인의 공동인증서로 전자서명 불가능)
                </div>
              </div>
              <div className={st.tooltip}>
                <div className={st.title}>전자증명서는 방문발급만 가능해요.</div>
                <div className={st.content}>
                  전자증명서는 온라인 발급이 불가능하고, 등기소에 꼭 방문을 해야해요. 등기대행을 맡기면, 법률사무소에서
                  대신 방문해서 발급받아 드려요.
                </div>
              </div>
            </div>
          </QuestionTooltip>
        </div>
      </div>
      {vm.jointCertificate?.useYn && vm.companyShareholderList && (
        <div className={st.questionBox}>
          <div className={st.questionBoxTitle}>법인주주</div>
          <div className={st.qusetion}>
            {vm.companyShareholderList?.map((companyShareholder, idx) => {
              return (
                <div key={idx} className={st.companyShareholder}>
                  <div className={st.questionTitle}>
                    법인주주인 <span className={st.bold}>{josa(companyShareholder.name, josaType.은는)}</span> 위 사진과
                    같은 <span className={st.bold}>전자증명서</span>를 가지고 있나요?
                  </div>
                  <input
                    type="hidden"
                    name={`digitalCertificate.companyShareholderList[${idx}].id`}
                    value={companyShareholder.id}
                  />
                  <div className={st.radioBox}>
                    <RadioLabel
                      className={st.radioLabel}
                      name={`digitalCertificate.companyShareholderList[${idx}].useType`}
                      value="네"
                      checked={companyShareholder.useType === '네'}
                      onChange={(e) => vm.setCompanyUseType(e, idx)}
                    >
                      네
                    </RadioLabel>
                    <RadioLabel
                      className={st.radioLabel}
                      name={`digitalCertificate.companyShareholderList[${idx}].useType`}
                      value="아니오_신규발급_신청_가능해요"
                      checked={companyShareholder.useType === '아니오_신규발급_신청_가능해요'}
                      onChange={(e) => vm.setCompanyUseType(e, idx)}
                    >
                      아니오. 신규발급 신청 가능해요.
                      <div className={st.radioExplain}>
                        신청서에 {companyShareholder.name}의 법인인감도장을 날인하면, 법률사무소에서 발급을
                        <br />
                        받아드려요.
                      </div>
                    </RadioLabel>
                    <RadioLabel
                      className={st.radioLabel}
                      name={`digitalCertificate.companyShareholderList[${idx}].useType`}
                      value="아니오_신규발급_신청_불가능해요"
                      checked={companyShareholder.useType === '아니오_신규발급_신청_불가능해요'}
                      onChange={(e) => vm.setCompanyUseType(e, idx)}
                    >
                      아니오. 신규발급 신청 불가능해요.
                    </RadioLabel>
                    <RadioLabel
                      className={st.radioLabel}
                      name={`digitalCertificate.companyShareholderList[${idx}].useType`}
                      value="잘_모르겠어요"
                      checked={companyShareholder.useType === '잘_모르겠어요'}
                      onChange={(e) => vm.setCompanyUseType(e, idx)}
                    >
                      잘 모르겠어요.
                    </RadioLabel>
                  </div>
                  <div>
                    <Error
                      name={`digitalCertificate.companyShareholderList[${idx}].useType`}
                      value={companyShareholder.useType || ''}
                      errorCase={{
                        required: '전자증명서 소지여부를 선택하세요.',
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={st.questionBox}>
        <div className={st.questionBoxTitle}>{vm.companyName}</div>
        <div className={st.qusetion}>
          <div className={st.questionTitle}>
            <span className={st.bold}>{josa(vm.companyName, josaType.은는)}</span> 위 사진과 같은{' '}
            <span className={st.bold}>전자증명서</span>를 가지고 있나요?
          </div>
          {/* @todo 전자증명서 발급일자 개발되면 이 부분 표시
                    <div className={st.certificateBox}>
                        <div className={st.title}>{프.companyName} 전자증명서 발급이력</div>
                        <div className={st.finalIssue}>2023-04-05 최종발급</div>
                        <div className={st.finalIssueState}>사용가능 (분실시 3,000원 공과금 발생)</div>
                    </div> */}
          <div className={st.radioBox}>
            <RadioLabel
              className={st.radioLabel}
              name="digitalCertificate.company.useType"
              value="네"
              checked={vm.digitalCertificateUseType === '네'}
              onChange={(e) => vm.setDigitalCertificateUseType(e)}
            >
              네
            </RadioLabel>
            <RadioLabel
              className={st.radioLabel}
              name="digitalCertificate.company.useType"
              value="아니오"
              checked={vm.digitalCertificateUseType === '아니오'}
              onChange={(e) => vm.setDigitalCertificateUseType(e)}
            >
              아니오
            </RadioLabel>
            <RadioLabel
              className={st.radioLabel}
              name="digitalCertificate.company.useType"
              value="잘_모르겠어요"
              checked={vm.digitalCertificateUseType === '잘_모르겠어요'}
              onChange={(e) => vm.setDigitalCertificateUseType(e)}
            >
              잘 모르겠어요
            </RadioLabel>
          </div>
          <div>
            <Error
              name="digitalCertificate.company.useType"
              value={vm.digitalCertificateUseType || ''}
              errorCase={{
                required: '전자증명서 소지여부를 선택하세요.',
              }}
            />
          </div>
        </div>
      </div>
      <div className={st.questionBox}>
        <div className={st.questionBoxTitle}>요청사항</div>
        <div className={st.qusetion}>
          <div className={st.questionTitle}>등기대행 요청사항</div>
          <button
            type="button"
            className={clsx(st.requestRequirementBtn, vm.state.requestRequirementYn ? st.open : '')}
            onClick={() => vm.openRequirement()}
          >
            <span>입력하기</span>
          </button>
          {vm.state.requestRequirementYn && (
            <TextArea
              name="requestRequirement"
              value={vm.requestRequirement}
              onChange={(e) => vm.setRequestRequirement(e)}
              placeholder="요청사항 입력"
              style={{ width: '460px', height: '178px', resize: 'none' }}
            />
          )}
        </div>
      </div>
      <div className={st.questionBox}>
        <div className={st.questionBoxTitle}>담당자</div>
        <div className={st.qusetion}>
          <div className={st.questionTitle}>
            등기대행 담당자
            <div className={st.titleSubInfo}>* 등기대행 연락을 받을 담당자 정보를 입력하세요.</div>
          </div>
          <div className={st.personInChargeBox}>
            <div className={st.personInCharge}>
              <div className={st.title}>성함</div>
              <InputText
                className={st.inputText}
                name={`personInCharge.name`}
                placeholder="홍길동"
                fullSize
                value={vm.personInCharge.name || ''}
                onChange={(e) => vm.setPersonInCharge(e, 'name')}
                errorText={
                  <Error
                    name={`personInCharge.name`}
                    value={vm.personInCharge.name}
                    errorCase={{
                      required: '이름을 입력하세요.',
                    }}
                  />
                }
              />
            </div>
            <div className={st.personInCharge}>
              <div className={st.title}>전화번호</div>
              <InputText
                className={st.inputText}
                name={`personInCharge.phoneNum`}
                placeholder="010-1234-1234"
                fullSize
                value={vm.personInCharge.phoneNum || ''}
                onChange={(e) => vm.setPersonInCharge(e, 'phoneNum')}
                errorText={
                  <Error
                    name={`personInCharge.phoneNum`}
                    value={vm.personInCharge.phoneNum}
                    errorCase={{
                      required: '전화번호를 입력하세요.',
                      pattern: {
                        value: regExp.phoneNumber().test(vm.personInCharge.phoneNum),
                        message: '올바른 전화번호를 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
            </div>
            <div className={st.personInCharge}>
              <div className={st.title}>이메일</div>
              <InputText
                className={st.inputText}
                name={`personInCharge.email`}
                placeholder="email@standby.kr"
                fullSize
                value={vm.personInCharge.email || ''}
                onChange={(e) => vm.setPersonInCharge(e, 'email')}
                errorText={
                  <Error
                    name={`personInCharge.email`}
                    value={vm.personInCharge.email}
                    errorCase={{
                      required: '이메일을 입력하세요.',
                      pattern: {
                        value: regExp.email().test(vm.personInCharge.email),
                        message: '올바른 이메일 형식으로 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className={st.termsBox}>
            <Check
              name="informationProvideYn"
              value={String(vm.informationProvideYn)}
              checked={vm.informationProvideYn === true}
              onChange={(e) => vm.setInformationProvideYn(e)}
              className={st.checkbox}
            >
              등기대행 진행을 위한{' '}
              <button type="button" className={st.termsBtn} onClick={() => vm.openTermsWindow()}>
                제3자 정보제공에 동의
              </button>{' '}
              (필수)<span className={st.required}>*</span>
            </Check>
            <Check
              name="telephoneCounselingYn"
              value={String(vm.telephoneCounselingYn)}
              checked={vm.telephoneCounselingYn === true}
              onChange={(e) => vm.setTelephoneCounselingYn(e)}
              className={st.checkbox}
            >
              견적받은 후 전화상담 요청(선택)
            </Check>
          </div>
          <Error
            name="informationProvideYn"
            value={vm.informationProvideYn ? String(vm.informationProvideYn) : ''}
            errorCase={{
              required: '필수 약관에 동의해주세요.',
            }}
          />
        </div>
      </div>
      <div className={st.btnArea}>
        <SquareBtn className={st.submitBtn} type="submit">
          신청 완료하기
        </SquareBtn>
      </div>
    </form>
  ));
}
export default ChangeRegistrationAgency;
