import { useObserver } from 'mobx-react';
import type { I등기연결 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './AlreadyPrograssedRegistrationAgency.module.scss';
import clsx from 'clsx';
import vm from './AlreadyPrograssedRegistrationAgencyVm';

interface Props {
  index: number;
  info: I등기연결;
  agendaProgressId: string;
}

function AlreadyPrograssedRegistrationAgency(props: Props) {
  return useObserver(() => (
    <>
      <div className={bst.row}>
        <div className={clsx(bst.title, bst.section4)}>{props.index + 1}. 등기연결</div>
        <div className={bst.content}>
          <div className={bst.contentTitleBox}>통지/동의를 마치면, 데이터가 자동전송돼요</div>
          <div className={st.statusBox}>
            <div className={st.registrationStepBox}>
              <img src={`/images/alreadyPrograsseRegistrationStep_${vm.status(props.info.steps)}.svg`} />
              {props.info.steps && (
                <>
                  {Object.entries(props.info.steps).map(([key, value]) => (
                    <div className={st.date} data-step={key} key={key}>
                      {value}
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className={st.changeRegistration}>
              <div>
                <span className={st.explain}>주주들에게 동의를 받은 후, 법률사무소 나머지 절차가 진행돼요.</span>
              </div>
              <button type="button" onClick={() => vm.changeSelf(props.agendaProgressId)}>
                셀프등기로 전환하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={bst.row}>
        <div className={clsx(bst.title, bst.upDate)}>자동 업데이트</div>
        <div className={bst.content}>
          <div className={st.upDateBox}>
            <div className={st.upDateTitle}>
              등기대행이 완료되면, <span className={st.important}>자동 업데이트</span> 되어요!
            </div>
            <div className={st.upDateImgBox}>
              <img src="/images/upDate.svg" />
              <div className={st.registrationComplete}>등기대행 완료</div>
              <div className={st.autoUpDate}>
                신규 등기부, 정관, 주주명부
                <br />
                <span className={st.important}>자동 업로드</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ));
}
export default AlreadyPrograssedRegistrationAgency;
