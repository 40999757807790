import { Typography } from '@standby/dike_ui/components/atoms';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import st from './ExpiredInvitedMembership.module.scss';
import ExpiredInvitedMembershipVm from './ExpiredInvitedMembershipVm';

function ExpiredInvitedMembership() {
  const location = useLocation();
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    ExpiredInvitedMembershipVm.load(token);
  }, []);

  return useObserver(() => (
    <div className={st.expiredInvitedMembership}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.title}>
        <Typography fontType="Title-24M">담당자 초대 기간 만료</Typography>
      </div>
      <div className={st.content}>
        <Typography fontType="Body-15R">
          담당자 초대기간이 만료되었어요. (보안상조치)
          <br />
          담당자 등록이 필요하다면, 초대한 분에게 다시 초대 발송을 요청해주세요.
        </Typography>
        <div className={st.dateBox}>
          <div className={st.dateInfo}>
            <Typography fontType="Title-12B" className={st.dateTitle}>
              초대일자
            </Typography>
            <Typography fontType="Body-14M">{ExpiredInvitedMembershipVm.invitedDate}</Typography>
          </div>
          <div className={st.dateInfo}>
            <Typography fontType="Title-12B" className={st.dateTitle}>
              만료일자
            </Typography>
            <Typography fontType="Body-14M">{ExpiredInvitedMembershipVm.expiredDate}</Typography>
          </div>
        </div>
      </div>
    </div>
  ));
}

export default ExpiredInvitedMembership;
