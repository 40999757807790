import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import shareholderListService from '@service/Corp/Stock/ShareholderList/shareholderListService';
import shareholderListModel from '@model/Corp/Stock/ShareholderList/shareholderListModel';
import { pageRouter } from '@model/pageModel';
import { _alert, _confirm } from '@model/dialogModel';
import regExp from '@common/module/regExp';

class SendVm {
  constructor() {
    this.state = observable({
      recipient: {
        emails: [
          {
            name: '',
            email: '',
          },
        ],
        shareholderIds: [],
      },
      loadingYn: false,
    });
  }
  async submit(e, sendParams) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = sendParams;
      let formParam = formDataToJson(e.target).data;

      param.recipient = Object.assign({}, this.state.recipient);

      if (formParam?.shareholderUpdateInfoList) {
        formParam.shareholderUpdateInfoList = formParam.shareholderUpdateInfoList.filter((elem) => elem !== undefined);
        if (param.shareholderUpdateInfoList.length !== 0) {
          param.shareholderUpdateInfoList.forEach((shareholder, idx) => {
            formParam.shareholderUpdateInfoList.forEach((formShareholder, idx2) => {
              if (Number(shareholder.id) === Number(formShareholder.id)) {
                param.shareholderUpdateInfoList[idx].email = formShareholder.email;
              }
            });
          });
        } else {
          param.shareholderUpdateInfoList = formParam.shareholderUpdateInfoList;
        }
      }
      param.shareholderUpdateInfoList = param.shareholderUpdateInfoList.filter((elem) => elem !== undefined);

      if (formParam.recipient.emails.every((obj) => Object.values(obj).every((value) => value === ''))) {
        formParam.recipient.emails = [];
      }

      param.recipient.emails = formParam.recipient.emails;

      if (param.recipient.emails.length === 0 && param.recipient.shareholderIds.length === 0) {
        return _alert('이메일을 발송할 사람을 선택하세요.');
      }
      if (param.recipient.emails.length !== 0) {
        for (let i = 0; i < param.recipient.emails.length; i++) {
          if (param.recipient.emails[i].name && !param.recipient.emails[i].email)
            return _alert('주주 외의 사람의 이메일을 입력해 주세요.');
          else if (!param.recipient.emails[i].name && param.recipient.emails[i].email)
            return _alert('주주 외의 사람의 이름을 입력해 주세요.');
        }
      }

      try {
        this.state.loadingYn = true;
        await shareholderListService.send(param);
        this.state.loadingYn = false;
        await _alert('주주명부를 발송했어요.');
        pageRouter.replace(`/corp/stock/home?corp=${corpModel.id}`);
      } catch (error) {
        this.state.loadingYn = false;
      }
    }
  }
  get shareholders() {
    return shareholderListModel.shareholders;
  }
  get allChecked() {
    let allChecked = true;
    this.shareholders.forEach((shareholder) => {
      if (!this.state.recipient.shareholderIds.includes(shareholder.id)) {
        allChecked = false;
      }
    });
    return allChecked;
  }
  setAllChecked(e) {
    if (e.target.checked) {
      this.shareholders.forEach((shareholder) => {
        if (!this.state.recipient.shareholderIds.includes(shareholder.id)) {
          this.state.recipient.shareholderIds.push(shareholder.id);
        }
      });
    } else {
      this.state.recipient.shareholderIds = [];
    }
  }
  checked(idx) {
    const id = shareholderListModel.shareholders[idx].id;
    if (this.state.recipient.shareholderIds.includes(id)) return true;
    else return false;
  }
  setChecked(e, idx) {
    const id = shareholderListModel.shareholders[idx].id;

    if (e.target.checked) {
      if (this.state.recipient.shareholderIds.includes(id) === false) {
        this.state.recipient.shareholderIds.push(id);
      }
    } else {
      if (this.state.recipient.shareholderIds.length !== 0) {
        this.state.recipient.shareholderIds = this.state.recipient.shareholderIds.filter((elem) => elem !== id);
      }
    }
  }
  get stockTotal() {
    let total = 0;
    for (let i = 0; i < shareholderListModel.shareholders.length; i++) {
      for (let j = 0; j < shareholderListModel.shareholders[i].stocks.length; j++) {
        total = total + shareholderListModel.shareholders[i].stocks[j].number;
      }
    }
    return total;
  }
  displayEmailInput() {
    let cnt = 0;
    if (this.state.recipient.shareholderIds && this.state.recipient.shareholderIds.length !== 0) {
      shareholderListModel.shareholders.forEach((shareholder) => {
        this.state.recipient.shareholderIds.forEach((shareholderId) => {
          if (Number(shareholder.id) === Number(shareholderId)) {
            if (shareholder.existsEmailYn === false) {
              cnt++;
            }
          }
        });
      });
    }
    if (cnt > 0) {
      return true;
    } else {
      return false;
    }
  }
  setEmail(e, idx) {
    runInAction(() => {
      shareholderListModel.shareholders[idx].email = regExp.deleteSpace(e.target.value);
    });
  }
  addEmailInfo() {
    this.state.recipient.emails.push({
      name: '',
      email: '',
    });
  }
  deleteEmailInfo(idx) {
    this.state.recipient.emails.splice(idx, 1);
  }
  setNewName(e, idx) {
    this.state.recipient.emails[idx].name = e.target.value;
  }
  setNewEmail(e, idx) {
    this.state.recipient.emails[idx].email = regExp.deleteSpace(e.target.value);
  }
}

export default SendVm;
