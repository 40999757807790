import { makeObservable, observable } from 'mobx';
import type { AoaDetailModelInterface, ArticlesOfAssociations } from './AoaDetailModel.interface';

class AoaDetailModel implements AoaDetailModelInterface {
  constructor() {
    makeObservable(this, {
      articlesOfAssociations: observable,
    });
  }
  articlesOfAssociations: ArticlesOfAssociations[] = [];
}
export default new AoaDetailModel();
