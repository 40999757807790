import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import type { SelectedAgenda, CommonAgendaDetail } from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class CommonAgendaVm {
  selectedAgenda(index: number) {
    return agendaStepModel.selectedAgendas[index] as SelectedAgenda<CommonAgendaDetail>;
  }
  is자유입력(index: number) {
    return this.selectedAgenda(index).agendaType.includes('자유입력');
  }
  title(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    this.selectedAgenda(index).agendaDetail.title = e.target.value;
  }
  content(e: React.ChangeEvent<HTMLTextAreaElement>, index: number) {
    this.selectedAgenda(index).agendaDetail.content = e.target.value;
  }
  isFileRequired(agendaType: string) {
    if (
      agendaType === '임원_보수_지급규정_승인' ||
      agendaType === '임원_상여금_지급규정_승인' ||
      agendaType === '임원_퇴직금_지급규정_승인'
    ) {
      return true;
    }
    return false;
  }
  download() {
    window.location.href = `${process.env.REACT_APP_S3URL}/agenda/%5B%E1%84%89%E1%85%B3%E1%84%90%E1%85%A2%E1%86%AB%E1%84%87%E1%85%A1%E1%84%8B%E1%85%B5%5D+%E1%84%87%E1%85%A9%E1%84%89%E1%85%AE%2C+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A7%E1%84%80%E1%85%B3%E1%86%B7%2C+%E1%84%90%E1%85%AC%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%80%E1%85%B3%E1%86%B7+%E1%84%80%E1%85%B2%E1%84%8C%E1%85%A5%E1%86%BC+3%E1%84%8C%E1%85%A9%E1%86%BC+%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF.zip`;
  }
  sampleDownload(title: string) {
    if (title === '임원 퇴직금 규정 승인') {
      window.location.href = `${process.env.REACT_APP_S3URL}/agenda/20240303_%E1%84%8B%E1%85%B5%E1%86%B7%E1%84%8B%E1%85%AF%E1%86%AB+%E1%84%90%E1%85%AC%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%80%E1%85%B3%E1%86%B7+%E1%84%8C%E1%85%B5%E1%84%80%E1%85%B3%E1%86%B8%E1%84%80%E1%85%B2%E1%84%8C%E1%85%A5%E1%86%BC_%E1%84%91%E1%85%AD%E1%84%8C%E1%85%AE%E1%86%AB.docx`;
    } else if (title === '임원 상여금 규정 승인') {
      window.location.href = `${process.env.REACT_APP_S3URL}/agenda/20240303_%E1%84%8B%E1%85%B5%E1%86%B7%E1%84%8B%E1%85%AF%E1%86%AB+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A7%E1%84%80%E1%85%B3%E1%86%B7+%E1%84%8C%E1%85%B5%E1%84%80%E1%85%B3%E1%86%B8%E1%84%80%E1%85%B2%E1%84%8C%E1%85%A5%E1%86%BC_%E1%84%91%E1%85%AD%E1%84%8C%E1%85%AE%E1%86%AB.docx`;
    } else if (title === '임원 보수 규정 승인') {
      window.location.href = `${process.env.REACT_APP_S3URL}/agenda/20240303_%E1%84%8B%E1%85%B5%E1%86%B7%E1%84%8B%E1%85%AF%E1%86%AB+%E1%84%87%E1%85%A9%E1%84%89%E1%85%AE+%E1%84%8C%E1%85%B5%E1%84%80%E1%85%B3%E1%86%B8%E1%84%80%E1%85%B2%E1%84%8C%E1%85%A5%E1%86%BC_%E1%84%91%E1%85%AD%E1%84%8C%E1%85%AE%E1%86%AB.docx`;
    }
  }
  shouldGetFile(agendaType: string) {
    if (agendaType === '임원_무보수_결의') {
      return false;
    }
    return true;
  }
}
export default new CommonAgendaVm();
