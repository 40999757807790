import { observable } from 'mobx';

const stockholderModel = observable({
  shareholder: {
    id: '',
    shareholderType: '',
    koreaName: '',
    englishName: '',
    countryCode: '',
    phoneNumber: '',
    email: '',
    address: '',
    memo: '',
    shareholderDto: {},
    properNumber: '',
    ein: '',
    cssn: '',
    kssn: '',
    birth: '',
    representatives: [],
    nationalityType: '',
    nationalityOthers: '',
    englishAddress: '',
    residenceRegistrationYn: null,
  },
  stock: {
    stocks: [],
    classStocks: [],
  },
  onlineMeetingNotice: {
    status: '',
    file: {
      name: '',
      downloadUrl: '',
    },
  },
  setShareholder(data) {
    this.shareholder.id = data?.shareholder.id || '';
    this.shareholder.shareholderType = data?.shareholder.shareholderType || '';
    this.shareholder.koreaName = data?.shareholder.koreaName || '';
    this.shareholder.englishName = data?.shareholder.englishName || '';
    this.shareholder.countryCode = data?.shareholder.countryCode || '';
    this.shareholder.phoneNumber = data?.shareholder.phoneNumber || '';
    this.shareholder.email = data?.shareholder.email || '';
    this.shareholder.address = data?.shareholder.address || '';
    this.shareholder.memo = data?.shareholder.memo || '';
    this.shareholder.shareholderDto = data?.shareholder.shareholderDto || {};
    this.shareholder.properNumber = data?.shareholder.properNumber || '';
    this.shareholder.ein = data?.shareholder.ein || '';
    this.shareholder.cssn = data?.shareholder.cssn || '';
    this.shareholder.kssn = data?.shareholder.kssn || '';
    this.shareholder.birth = data?.shareholder.birth || '';
    this.shareholder.representatives = data?.shareholder.representatives || [];
    this.shareholder.nationalityType = data?.shareholder.nationalityType || '';
    this.shareholder.nationalityOthers = data?.shareholder.nationalityOthers || '';
    this.shareholder.englishAddress = data?.shareholder.englishAddress || '';
    this.shareholder.residenceRegistrationYn = data?.shareholder.residenceRegistrationYn ?? null;
    this.stock.stocks = data?.stock.stocks || [];
    this.stock.classStocks = data?.stock.classStocks || [];
    this.onlineMeetingNotice.status = data?.onlineMeetingNotice.status || '';
    this.onlineMeetingNotice.file.name = data?.onlineMeetingNotice?.file?.name || '';
    this.onlineMeetingNotice.file.downloadUrl = data?.onlineMeetingNotice?.file?.downloadUrl || '';
  },
});

export default stockholderModel;
