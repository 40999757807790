import { observable } from 'mobx';
import st from '@pages/Corp/Agenda/Step/FinalCheck/Final/Final.module.scss';
import FinalService from '@service/Corp/Agenda/Step/FinalCheck/Final/FinalService';

interface State {
  moreViewYn: boolean;
}

class C주주총회Vm {
  state: State = observable({
    moreViewYn: false,
  });
  status(_status: string | null) {
    switch (_status) {
      case '발송전':
        return {
          _className: st.secon200,
          text: '발송 전',
        };
      case '발송중':
        return {
          _className: st.secon200,
          text: '발송 중',
        };
      case '발송완료':
        return {
          _className: st.secon200,
          text: '발송완료\n(대기중)',
        };
      case '동의완료':
        return {
          _className: st.sub300,
          text: '동의완료',
        };
      case '발송실패':
        return {
          _className: st.warning200,
          text: '발송실패',
        };
      case '발송실패_알림톡':
        return {
          _className: st.warning200,
          text: '발송실패\n(알림톡)',
        };
      case '발송실패_메일':
        return {
          _className: st.warning200,
          text: '발송실패\n(메일)',
        };
    }
  }
  moreView() {
    this.state.moreViewYn = !this.state.moreViewYn;
  }
  download(downloadUrl: string) {
    FinalService.fileDown(downloadUrl);
  }
  allDownload(agendaProgressId: string, type: string, opener: 'final' | 'agendaDashboard') {
    FinalService.fileAllDownload(agendaProgressId, type, opener);
  }
}
export default new C주주총회Vm();
