import { get, patch, post } from '@common/module/httpRequest';
import corpModel from '@model/Corp/corpModel';
import InternetAssociateModel from '@src/pages/Corp/InternetAssociate/model/InternetAssociateModel';
import { runInAction } from 'mobx';

import type {
  CalcRq,
  CalcRs,
  LoadStep2Rs,
  LoadStep3Rs,
  LoadStepRs,
  LoadTermRs,
  MemoRq,
  Step2SubmitRq,
  UserRegistrationTerms,
} from './InternetAssociateService.interface';

class InternetAssociateService {
  async loadStep() {
    const res = await get<LoadStepRs>(`/company/${corpModel.id}/internetAssociate/step`);

    return res.data.step;
  }
  async consult() {
    await post(`/company/${corpModel.id}/internetAssociate/consult`);
  }
  async getTermInfo(): Promise<UserRegistrationTerms[]> {
    const res = await get<LoadTermRs>(`/term/INTERNET_ASSOCIATE`);

    return res.data.userRegistrationTerms.map((term) => {
      return {
        id: term.id,
        title: term?.title || '',
        content: term?.content || '',
        required: term?.required ?? false,
      };
    });
  }
  async getStep2Data(corpId: number): Promise<LoadStep2Rs> {
    const res = await get<LoadStep2Rs>(`/company/${corpId}/internetAssociate/step2`);

    return res.data;
  }
  async postOrderSummaryCalc(param: CalcRq): Promise<CalcRs> {
    const res = await post<CalcRs>(`/company/${corpModel.id}/internetAssociate/calc`, param);

    return {
      baseFee: res.data.baseFee || 0,
      bundleDiscount: res.data.bundleDiscount || 0,
      cashback: res.data.cashback || 0,
    };
  }
  async step2Submit(param: Step2SubmitRq) {
    await post(`/company/${corpModel.id}/internetAssociate`, param);
  }
  async loadStep3() {
    const res = await get<LoadStep3Rs>(`/company/${corpModel.id}/internetAssociate/step3`);

    runInAction(() => {
      InternetAssociateModel.step3.status = res.data?.applicationDetails?.status || '';
      InternetAssociateModel.step3.date = res.data?.applicationDetails?.date || '';
      InternetAssociateModel.step3.products = res.data?.completionDetails?.products
        ? res.data?.completionDetails?.products.map((product) => {
            return {
              id: product?.id || '',
              type: product?.type || '',
              name: product?.name || '',
              option: product?.option || '',
              contractYears: product?.contractYears || '',
              contractPeriod: product?.contractPeriod || '',
              circuit: product?.circuit || 0,
              serialNumber: product?.serialNumber || '',
              startDate: product?.startDate || '',
              memo: product?.memo || '',
            };
          })
        : [];
    });
  }
  async memo(param: MemoRq, id: string) {
    await patch(`/company/${corpModel.id}/internetAssociate/${id}/memo`, param);
    window.location.reload();
  }
}

export default new InternetAssociateService();
