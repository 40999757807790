import { get } from '@common/module/httpRequest';
import corpModel from '@model/Corp/corpModel';
import { pageRouter } from '@model/pageModel';

class RedirectPageService {
  async redirect(id) {
    const res = await get(`/company/${id}/redirect`);
    pageRouter.replace(res.data.redirectUrl);
  }
}
export default new RedirectPageService();
