import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import type { SelectedAgenda, I재무재표_승인 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class ApprovaOfFinancialStatementsVm {
  selectedTerm(e: React.ChangeEvent<HTMLSelectElement>, index: number) {
    this.selectedAgenda(index).agendaDetail.selectedTerm = e.target.value;
  }
  selectedAgenda(index: number) {
    return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I재무재표_승인>;
  }
}
export default new ApprovaOfFinancialStatementsVm();
