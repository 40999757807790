import BoardOfDirectorsModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsModel';
import { runInAction } from 'mobx';
import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import BoardOfDirectorsService from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsService';

class Step1Vm {
  async submit(e: React.FormEvent<HTMLFormElement>, companyId: string, agendaProgressId: string) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        chairmanId: string;
        attendMeetingExecutiveMembers: string | string[];
        minutesId: string;
      };
      if (this.attendMeetingPossibleYn === false) {
        return _alert('출석한 이사가 부족해요.', '이사 과반수(=50% 초과)가 출석해야 해요.');
      }
      if (Array.isArray(param.attendMeetingExecutiveMembers) === false) {
        param.attendMeetingExecutiveMembers = [param.attendMeetingExecutiveMembers as string];
      }
      await BoardOfDirectorsService.saveStep1(param, companyId, agendaProgressId);
    }
  }
  get step1() {
    return BoardOfDirectorsModel.step1;
  }
  selectableChairmen(e: React.ChangeEvent<HTMLInputElement>) {
    const id = e.target.value;

    runInAction(() => {
      this.step1.chairmanId = id;
      //참석의장을 춠석임원에 체크함
      BoardOfDirectorsModel.step1.executives.forEach((item) => {
        if (item.id === id) {
          item.attendMeetingYn = true;
        }
      });
    });
  }
  get executiveMembersAttendMeetingAll() {
    let isCheckAll = true;
    let agreeCnt = 0;
    BoardOfDirectorsModel.step1.executives.forEach((item) => {
      if (item.attendMeetingYn) {
        agreeCnt++;
      } else if (!item.attendMeetingYn) {
        isCheckAll = false;
      }
    });
    if (agreeCnt === 0) {
      return false;
    }
    return isCheckAll;
  }
  setExecutiveMembersAttendMeetingAll(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    runInAction(() => {
      BoardOfDirectorsModel.step1.executives.forEach((item) => {
        item.attendMeetingYn = value;
      });
      if (value === false) {
        this.step1.chairmanId = '';
      }
    });
  }
  executiveMembersAttendMeeting(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const checked = e.target.checked;
    const id = (this.step1.executives[index].id || '').toString();
    runInAction(() => {
      this.step1.executives[index].attendMeetingYn = checked;
      //임원출석여부를 체크 해제했을때 그사람이 참석 의장이면 의장에서도 체크를 해제함
      if (checked === false && this.step1.chairmanId === id) {
        this.step1.chairmanId = '';
      }
    });
  }
  get attendMeetingPossibleYn() {
    let totalCount = this.step1.executives.length;
    let checkTotal = this.step1.executives.filter((executive) => executive.attendMeetingYn === true).length;
    return totalCount / 2 < checkTotal;
  }
}
export default new Step1Vm();
