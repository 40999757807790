import corpModel from '@model/Corp/corpModel';

import useForm, { FormBundle } from './useForm';
import useFormDetail, { FormDetailBundle } from './useFormDetail';
import useTerm, { TermBundle } from './useTerm';

interface Step2Bundle {
  formDetailBundle: FormDetailBundle;
  companyName: string;
  termBundle: TermBundle;
  formBundle: FormBundle;
}

const useStep2 = (): Step2Bundle => {
  const corpId = corpModel.id;

  if (!corpId) {
    throw new Error(`corpId is ${corpId}`);
  }

  const formDetailBundle = useFormDetail(corpId);
  const termBundle = useTerm();
  const formBundle = useForm({
    isRequiredTermsAgreed: termBundle.isRequiredTermsAgreed,
    step2FormData: formDetailBundle.step2FormData,
  });

  return {
    formDetailBundle,
    companyName: corpModel.company.companyName,
    termBundle,
    formBundle,
  };
};

export type { FormBundle, FormDetailBundle, TermBundle };
export default useStep2;
