import { useEffect } from 'react';

import { useOrderSummaryMutation } from '../../queries';
import type {
  EstimatedOrderSummary,
  Products,
  SelectedProducts,
  SelectedProductsDetail,
} from '../types';
import { convertSelectedProductsToRequestParam } from '../util';


interface OrderSummaryProps {
  products: Products;
  selectedProducts: SelectedProducts;
  selectedProductsDetail: SelectedProductsDetail;
}

export interface OrderSummaryBundle {
  orderSummary: EstimatedOrderSummary;
  selectedInternetName: string;
  selectedTvName: string;
  selectedTelephoneName: string;
}

const useOrderSummary = ({
  products,
  selectedProducts,
  selectedProductsDetail,
}: OrderSummaryProps): OrderSummaryBundle => {
  const { data: orderSummary, mutate: mutateOrderSummary } = useOrderSummaryMutation();

  useEffect(() => {
    const productsParam = convertSelectedProductsToRequestParam(selectedProductsDetail, selectedProducts);

    mutateOrderSummary(productsParam);
  }, [selectedProducts, selectedProductsDetail, mutateOrderSummary]);

  const getSelectedInternetName = () => {
    const id = selectedProductsDetail.INTERNET;
    const selectedName = products.INTERNET.find((internet) => internet.id === id)?.option;

    return selectedProducts.includes('INTERNET') && selectedName ? selectedName : '미신청';
  };

  const getSelectedTvName = () => {
    const id = selectedProductsDetail.TV;
    const selectedName = products.TV.find((tv) => tv.id === id)?.option;

    return selectedProducts.includes('TV') && selectedName ? selectedName : '미신청';
  };

  const getSelectedTelephoneName = () => {
    let name = '미신청';

    if (selectedProducts.includes('TELEPHONE')) {
      name = `인터넷(${selectedProductsDetail.TELEPHONE.quantity}대)`;
    }
    if (selectedProductsDetail.TELEPHONE_NUMBER.includes('TN1')) {
      name += ', 지역번호';
    }
    if (selectedProductsDetail.TELEPHONE_NUMBER.includes('TN2')) {
      name += ', 대표번호';
    }

    return name;
  };

  return {
    orderSummary,
    selectedInternetName: getSelectedInternetName(),
    selectedTvName: getSelectedTvName(),
    selectedTelephoneName: getSelectedTelephoneName(),
  };
};


export default useOrderSummary;
