import AgendaViewModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel';
import AgendaViewService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewService';
import { ShareholderType } from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';

class AgendaViewModalVm {
  load(agendaProgressId: string) {
    AgendaViewService.load(agendaProgressId);
  }
  get shareholdersMeetingType() {
    return AgendaViewModel.shareholdersMeetingType;
  }
  get newStockType() {
    return AgendaViewModel.newStockType;
  }
  get agendas() {
    return AgendaViewModel.agendas;
  }
  get stockholderInfos() {
    return AgendaViewModel.stockholderInfos;
  }
  switchShareholderType(shareholderType: ShareholderType) {
    switch (shareholderType) {
      case '국내_미성년자':
        return '한국인 미성년';
        break;
      case '국내_법인':
        return '한국 법인';
        break;
      case '펀드_투자조합':
        return '한국 펀드, 투자조합';
        break;
      case '해외_성인':
        return '외국인 성인';
        break;
      case '해외_미성년자':
        return '외국인 미성년';
        break;
      case '해외_법인':
        return '외국 법인';
        break;
      case '국내_성인':
        return '한국인 성인';
        break;
      default:
        return '';
        break;
    }
  }
  get shareholdersSum() {
    let sum = 0;
    this.stockholderInfos.addedShareholder.forEach((shareholder) => {
      shareholder.stocks.forEach((stock) => {
        sum += Number(stock.stockCount);
      });
    });
    return sum;
  }
}
export default new AgendaViewModalVm();
