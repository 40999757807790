import { useObserver } from 'mobx-react';
import vm from './NewShareholdersVm';
import clsx from 'clsx';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './NewShareholders.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import { addCommas } from '@common/module/replaceNumber';

interface NewShareholdersProps {
  index: number;
}

function NewShareholders(props: NewShareholdersProps) {
  return useObserver(() => (
    <div className={qst.questionForm} style={{ marginBottom: '36px' }}>
      {vm.shareholderList(props.index) && vm.shareholderList(props.index)?.length !== 0 && (
        <>
          <div className={qst.qusetion}>
            <div className={qst.questionTitle}>기존 주주에게 배정된 신주</div>
            <Table className={st.table}>
              <colgroup>
                <col width="236" />
                <col width="176" />
              </colgroup>
              <thead>
                <tr>
                  <th style={{ paddingLeft: '26px' }}>주주명</th>
                  <th style={{ textAlign: 'left' }}>기존 주식수</th>
                  <th style={{ textAlign: 'left', paddingLeft: '32px' }}>신규 배정 주식수</th>
                </tr>
              </thead>
              <tbody>
                {vm.shareholderList(props.index)?.map((shareholder, idx2) => (
                  <tr key={idx2}>
                    <td>
                      <span className={st.nameArea}>
                        <span className={st.index}>{idx2 + 1}</span>
                        <span className={st.bold}>
                          {shareholder.name} <span className={st.normal}>주주님</span>
                        </span>
                      </span>
                    </td>
                    <td>
                      {shareholder.stocks?.map((stock, idx3) => (
                        <div key={idx3} className={st.stock}>
                          {stock.name} {addCommas(stock.number)}주
                        </div>
                      ))}
                    </td>
                    <td>
                      {shareholder.newStocks?.map((newStock, idx4) => (
                        <div key={idx4} className={st.newStock}>
                          {newStock.name} {addCommas(newStock.number)}주
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className={qst.qusetion}>
            <div className={qst.questionTitle}>주주분들에게 신주가 배정된 이유가 무엇인가요?</div>
            <div style={{ width: '460px' }}>
              <RadioLabel
                className={clsx(qst.radio, st.radio)}
                name="배정Type"
                value="주주"
                checked={vm.newShareholderAssignReason(props.index) === '주주'}
                onChange={(e) => vm.setNewShareholderAssignReason(e, props.index)}
              >
                신주를 배정받을 수 있는 <span className={st.bold}>주주의 권리 사용</span>해서{' '}
                <span className={st.radioTag}>(주주 배정)</span>
              </RadioLabel>
              <RadioLabel
                className={clsx(qst.radio, st.radio)}
                name="배정Type"
                value="제3자"
                checked={vm.newShareholderAssignReason(props.index) === '제3자'}
                onChange={(e) => vm.setNewShareholderAssignReason(e, props.index)}
              >
                <span className={st.bold}>재무구조 개선, 신기술의 도입 등 경영상 목적</span>을 위하여{' '}
                <span className={st.radioTag}>(제3자 배정)</span>
              </RadioLabel>
              <div>
                <Error
                  name="배정Type"
                  value={vm.newShareholderAssignReason(props.index)}
                  errorCase={{
                    required: '이유를 선택하세요.',
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {vm.newShareholderAssignReason(props.index) && (
        <div className={qst.qusetion}>
          <div className={qst.questionTitle}>
            <div style={{ marginBottom: '10px' }}>신주발행 절차를 선택하세요.</div>
            <QuestionTooltip title="신주발행할 때 통지/공고가 필요한 이유">
              <div className={st.tooltipContent}>
                <div className={st.qna}>
                  <div className={st.title}>원칙상 신주는 주주에게 발행해야 해요.</div>
                  <div className={st.content}>
                    <ul>
                      <li>주주가 가진 주식수에 따라 신주를 배정받을 권리가 있어요. (상법 제418조 제 1항)</li>
                    </ul>
                  </div>
                </div>
                <div className={st.qna}>
                  <div className={st.title}>다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있어요.</div>
                  <div className={st.content}>
                    <ul>
                      <li>
                        신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 발행이
                        가능해요. (상법 제418조 제2항)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={st.qna}>
                  <div className={st.title}>
                    이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는 공고해야 해요.
                  </div>
                  <div className={st.content}>
                    <ul>
                      <li>
                        신주발행을 통해 주주의 지분율이 낮아지므로, 최소 14일 전에 회사는 주주에게 신주발행 사실을
                        알려주도록 상법이 정하고 있어요. (상법 제418조 제4항)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={st.qna}>
                  <div className={st.title}>
                    다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 돼요.
                  </div>
                  <div className={st.content}>
                    <ul>
                      <li>
                        통지/공고는 주주의 권리를 보호하기 위한 것이니, 주주들이 도으이하면 절차를 생략할 수 있어요.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </QuestionTooltip>
          </div>
          <div style={{ width: '460px' }}>
            {vm.newShareholderAssignReason(props.index) === '제3자' && (
              <>
                <RadioLabel
                  className={clsx(qst.radio, st.radio)}
                  name="발행절차Type"
                  value="생략"
                  checked={vm.processStatus(props.index) === '생략'}
                  onChange={(e) => vm.setProcessStatus(e, props.index)}
                >
                  <span className={st.bold}>주주의 동의</span>를 받아, <span className={st.bold}>바로</span> 신주발행을{' '}
                  <span className={st.bold}>진행</span>해요.
                  <div className={clsx(qst.radioExplain, st.radioExplain)}>[온라인 동의받기] 기능 제공</div>
                  <span className={st.tag}>추천</span>
                </RadioLabel>
                <RadioLabel
                  className={clsx(qst.radio, st.radio)}
                  name="발행절차Type"
                  value="기존주주"
                  checked={vm.processStatus(props.index) === '기존주주'}
                  onChange={(e) => vm.setProcessStatus(e, props.index)}
                >
                  주주에게 <span className={st.bold}>신주발행통지</span>를 하고, <span className={st.bold}>14일</span>을{' '}
                  <span className={st.bold}>대기</span>해요.
                  <div className={clsx(qst.radioExplain, st.radioExplain)}>[온라인 신주발행통지] 기능 제공</div>
                </RadioLabel>
                {vm.announceType(props.index) === '신문사' && (
                  <RadioLabel
                    className={clsx(qst.radio, st.radio)}
                    name="발행절차Type"
                    value="신문사"
                    checked={vm.processStatus(props.index) === '신문사'}
                    onChange={(e) => vm.setProcessStatus(e, props.index)}
                  >
                    신문사에 <span className={st.bold}>신주발행 공고</span>를 올리고,{' '}
                    <span className={st.bold}>14일</span>을 <span className={st.bold}>대기</span>해요.
                    <div className={clsx(qst.radioExplain, st.radioExplain)}>[온라인 신주발행통지] 기능 제공</div>
                  </RadioLabel>
                )}
                {vm.announceType(props.index) === '홈페이지' && (
                  <RadioLabel
                    className={clsx(qst.radio, st.radio)}
                    name="발행절차Type"
                    value="홈페이지"
                    checked={vm.processStatus(props.index) === '홈페이지'}
                    onChange={(e) => vm.setProcessStatus(e, props.index)}
                  >
                    홈페이지에 <span className={st.bold}>신주발행 공고</span>를 올리고,{' '}
                    <span className={st.bold}>14일</span>을 <span className={st.bold}>대기</span>해요.
                    <div className={clsx(qst.radioExplain, st.radioExplain)}>[공고문 양식 다운로드] 기능 제공</div>
                  </RadioLabel>
                )}
              </>
            )}
            {vm.newShareholderAssignReason(props.index) === '주주' && (
              <>
                <RadioLabel
                  className={clsx(qst.radio, st.radio)}
                  name="발행절차Type"
                  value="생략"
                  checked={vm.processStatus(props.index) === '생략'}
                  onChange={(e) => vm.setProcessStatus(e, props.index)}
                >
                  <span className={st.bold}>주주의 동의</span>를 받아, <span className={st.bold}>바로</span> 신주발행을{' '}
                  <span className={st.bold}>진행</span>해요.
                  <div className={clsx(qst.radioExplain, st.radioExplain)}>[온라인 동의받기] 기능 제공</div>
                  <span className={st.tag}>추천</span>
                </RadioLabel>
                {vm.announceType(props.index) === '신문사' && (
                  <RadioLabel
                    className={clsx(qst.radio, st.radio)}
                    name="발행절차Type"
                    value="신문사"
                    checked={vm.processStatus(props.index) === '신문사'}
                    onChange={(e) => vm.setProcessStatus(e, props.index)}
                  >
                    신문사에 <span className={st.bold}>신주발행 공고</span>를 올리고,{' '}
                    <span className={st.bold}>14일</span>을 <span className={st.bold}>대기</span>헤요.
                    <div className={clsx(qst.radioExplain, st.radioExplain)}>[공고문 양식 다운로드] 기능 제공</div>
                  </RadioLabel>
                )}
                {vm.announceType(props.index) === '홈페이지' && (
                  <RadioLabel
                    className={clsx(qst.radio, st.radio)}
                    name="발행절차Type"
                    value="홈페이지"
                    checked={vm.processStatus(props.index) === '홈페이지'}
                    onChange={(e) => vm.setProcessStatus(e, props.index)}
                  >
                    홈페이지에 <span className={st.bold}>신주발행 공고</span>를 올리고,{' '}
                    <span className={st.bold}>14일</span>을 <span className={st.bold}>대기</span>해요.
                    <div className={clsx(qst.radioExplain, st.radioExplain)}>[공고문 양식 다운로드] 기능 제공</div>
                  </RadioLabel>
                )}
              </>
            )}
            <div>
              <Error
                name="발행절차Type"
                value={vm.processStatus(props.index)}
                errorCase={{
                  required: '절차를 선택하세요.',
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  ));
}
export default NewShareholders;
