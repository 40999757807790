import regExp from '@common/module/regExp';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import { useObserver } from 'mobx-react';

import type { FormDetailBundle } from '../../useStep2';
import st from './style.module.scss';

type Props = FormDetailBundle & { companyName: string };

function FormDetail(props: Props) {
  let count = 1;

  return useObserver(() => (
    <>
      <div className={st.question}>
        <div className={st.title}>
          {count++}. 현재 {props.companyName}(은)는 <span className={st.bold}>AWS를 이용 중</span>인가요?
        </div>
        <div className={st.info}>AWS를 이용 중이더라도 지원금을 제공해요!</div>
        <div className={st.answer}>
          <RadioLabel
            name="useAWSYn"
            value={'true'}
            checked={props.step2FormData.useAWSYn === true}
            onChange={(e) => props.setStep2FormData(e, 'useAWSYn')}
          >
            네
          </RadioLabel>
          <RadioLabel
            name="useAWSYn"
            value={'false'}
            checked={props.step2FormData.useAWSYn === false}
            onChange={(e) => props.setStep2FormData(e, 'useAWSYn')}
          >
            아니오
          </RadioLabel>
          <div>
            <Error
              name="useAWSYn"
              value={props.step2FormData.useAWSYn === null ? undefined : String(props.step2FormData.useAWSYn)}
              errorCase={{
                required: `AWS 이용여부를 선택해 주세요.`,
              }}
            />
          </div>
        </div>
      </div>
      {props.step2FormData.useAWSYn === true && (
        <div className={st.question}>
          <div className={st.title}>
            {count++}. {props.companyName}(은)는 <span className={st.bold}>메가존 클라우드의 AWS 파트너십 고객</span>
            인가요?
          </div>
          <div className={st.info}>클라우드 지원금 270만 원은 메가존의 신규고객에게만 제공돼요.</div>
          <div className={st.answer}>
            <RadioLabel
              name="MGCUseYn"
              value={'true'}
              checked={props.step2FormData.MGCUseYn === true}
              onChange={(e) => props.setStep2FormData(e, 'MGCUseYn')}
            >
              네
            </RadioLabel>
            <RadioLabel
              name="MGCUseYn"
              value={'false'}
              checked={props.step2FormData.MGCUseYn === false}
              onChange={(e) => props.setStep2FormData(e, 'MGCUseYn')}
            >
              아니오
            </RadioLabel>
            <div>
              <Error
                name="MGCUseYn"
                value={props.step2FormData.MGCUseYn === null ? undefined : String(props.step2FormData.MGCUseYn)}
                errorCase={{
                  required: `메가존 클라우드의 AWS 파트너십 고객여부를 선택해 주세요.`,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {props.step2FormData.useAWSYn === false && (
        <div className={st.question}>
          <div className={st.title}>
            {count++}. 현재 <span className={st.bold}>타 클라우드 서비스</span>를 이용 중인가요?
          </div>
          <div className={st.answer} style={{ marginTop: '16px' }}>
            <RadioLabel
              name="cloudServiceUseType"
              value="아니오"
              checked={props.step2FormData.cloudServiceUseType === '아니오'}
              onChange={(e) => props.setStep2FormData(e, 'cloudServiceUseType')}
            >
              아니오
            </RadioLabel>
            <RadioLabel
              name="cloudServiceUseType"
              value="타_클라우드를_쓰고_있어요"
              checked={props.step2FormData.cloudServiceUseType === '타_클라우드를_쓰고_있어요'}
              onChange={(e) => props.setStep2FormData(e, 'cloudServiceUseType')}
            >
              타 클라우드를 쓰고 있어요
            </RadioLabel>
            <RadioLabel
              name="cloudServiceUseType"
              value="서버를_구입하여_직접_운영하고_있어요"
              checked={props.step2FormData.cloudServiceUseType === '서버를_구입하여_직접_운영하고_있어요'}
              onChange={(e) => props.setStep2FormData(e, 'cloudServiceUseType')}
            >
              서버를 구입하여 직접 운영하고 있어요
            </RadioLabel>
            <div>
              <Error
                name="cloudServiceUseType"
                value={props.step2FormData.cloudServiceUseType}
                errorCase={{
                  required: `타 클라우드 서비스 이용여부를 선택해 주세요.`,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {props.step2FormData.useAWSYn === false &&
        props.step2FormData.cloudServiceUseType === '타_클라우드를_쓰고_있어요' && (
          <>
            <div className={st.question}>
              <div className={st.title}>
                {count++}. 사용하는 <span className={st.bold}>클라우드 서비스 이름</span>을 알려주세요.
              </div>
              <div className={st.answer}>
                <InputText
                  style={{ width: '460px' }}
                  placeholder="Azure, Naver Cloud...."
                  name="cloudServiceName"
                  value={props.step2FormData.cloudServiceName}
                  onChange={(e) => props.setStep2FormData(e, 'cloudServiceName')}
                  errorText={
                    <Error
                      name={`cloudServiceName`}
                      value={props.step2FormData.cloudServiceName}
                      errorCase={{
                        required: '클라우드 서비스 이름을 입력해 주세요.',
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.question}>
              <div className={st.title}>
                {count++}. <span className={st.bold}>클라우드 월 비용</span>을 알려주세요.
              </div>
              <div className={st.info}>월비용을 절약하기 위한 컨설팅을 제공해드려요.</div>
              <div className={st.answer}>
                <InputText
                  style={{ width: '460px' }}
                  placeholder="약 월 300만원"
                  name="monthCost"
                  value={props.step2FormData.monthCost}
                  onChange={(e) => props.setStep2FormData(e, 'monthCost')}
                  errorText={
                    <Error
                      name={`monthCost`}
                      value={props.step2FormData.monthCost}
                      errorCase={{
                        required: '클라우드 월 비용을 입력해 주세요.',
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.question}>
              <div className={st.title}>
                {count++}. <span className={st.bold}>클라우드 사용 목적</span>을 알려주세요.
              </div>
              <div className={st.answer}>
                <InputText
                  style={{ width: '460px' }}
                  placeholder="홈페이지 운영, 앱 운영"
                  name="purpose"
                  value={props.step2FormData.purpose}
                  onChange={(e) => props.setStep2FormData(e, 'purpose')}
                  errorText={
                    <Error
                      name={`purpose`}
                      value={props.step2FormData.purpose}
                      errorCase={{
                        required: '클라우드 사용목적을 입력해 주세요',
                      }}
                    />
                  }
                />
              </div>
            </div>
          </>
        )}
      {props.step2FormData.useAWSYn === false &&
        props.step2FormData.cloudServiceUseType === '서버를_구입하여_직접_운영하고_있어요' && (
          <div className={st.question}>
            <div className={st.title}>
              {count++}. <span className={st.bold}>서버 사용 목적</span>을 알려주세요.
            </div>
            <div className={st.answer}>
              <InputText
                style={{ width: '460px' }}
                placeholder="홈페이지 운영, 앱 운영"
                name="purpose"
                value={props.step2FormData.purpose}
                onChange={(e) => props.setStep2FormData(e, 'purpose')}
                errorText={
                  <Error
                    name={`purpose`}
                    value={props.step2FormData.purpose}
                    errorCase={{
                      required: '서버 사용 목적을 입력해 주세요.',
                    }}
                  />
                }
              />
            </div>
          </div>
        )}
      {props.step2FormData.useAWSYn === true && props.step2FormData.MGCUseYn === false && (
        <>
          <div className={st.question}>
            <div className={st.title}>
              {count++}. <span className={st.bold}>AWS 월 비용</span>을 알려주세요.
            </div>
            <div className={st.info}>월비용을 절약하기 위한 컨설팅을 제공해드려요.</div>
            <div className={st.answer}>
              <InputText
                style={{ width: '460px' }}
                placeholder="약 월 300만원"
                name="monthCost"
                value={props.step2FormData.monthCost}
                onChange={(e) => props.setStep2FormData(e, 'monthCost')}
                errorText={
                  <Error
                    name={`monthCost`}
                    value={props.step2FormData.monthCost}
                    errorCase={{
                      required: 'AWS 월 비용을 입력해 주세요.',
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className={st.question}>
            <div className={st.title}>
              {count++}. <span className={st.bold}>AWS 사용 목적</span>을 알려주세요.
            </div>
            <div className={st.answer}>
              <InputText
                style={{ width: '460px' }}
                placeholder="홈페이지 운영, 앱 운영"
                name="purpose"
                value={props.step2FormData.purpose}
                onChange={(e) => props.setStep2FormData(e, 'purpose')}
                errorText={
                  <Error
                    name={`purpose`}
                    value={props.step2FormData.purpose}
                    errorCase={{
                      required: 'AWS 사용목적을 입력해 주세요',
                    }}
                  />
                }
              />
            </div>
          </div>
        </>
      )}
      {!(props.step2FormData.useAWSYn === true && props.step2FormData.MGCUseYn === true) && (
        <>
          <div className={st.question}>
            <div className={st.title}>
              {count++}. <span className={st.bold}>클라우드 지원금</span>을 받을 <span className={st.bold}>연락처</span>
              를 기재하세요.
            </div>
            {/* <div className={st.info}>AWS 담당 개발자가 있다면, 그 연락처를 기재하면 더 편리해요.</div> */}
            <div className={st.answer}>
              <div className={st.inputRow}>
                <InputText
                  explain="이름"
                  style={{ width: '460px' }}
                  placeholder="홍길동"
                  name="name"
                  value={props.step2FormData.name}
                  onChange={(e) => props.setStep2FormData(e, 'name')}
                  errorText={
                    <Error
                      name={`name`}
                      value={props.step2FormData.name}
                      errorCase={{
                        required: '이름을 입력해 주세요',
                      }}
                    />
                  }
                />
              </div>
              <div className={st.inputRow}>
                <InputText
                  explain="이메일"
                  style={{ width: '460px' }}
                  placeholder="example@standby.kr"
                  name="email"
                  value={props.step2FormData.email}
                  onChange={(e) => props.setStep2FormData(e, 'email')}
                  errorText={
                    <Error
                      name={`email`}
                      value={props.step2FormData.email}
                      errorCase={{
                        required: '이메일을 입력해 주세요',
                        pattern: {
                          value: regExp.email().test(props.step2FormData.email),
                          message: '올바른 이메일 형식으로 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
              <div className={st.inputRow}>
                <InputText
                  explain="전화번호"
                  style={{ width: '460px' }}
                  placeholder="01012345678"
                  name="phoneNumber"
                  value={props.step2FormData.phoneNumber}
                  onChange={(e) => props.setStep2FormData(e, 'phoneNumber')}
                  errorText={
                    <Error
                      name={`phoneNumber`}
                      value={props.step2FormData.phoneNumber}
                      errorCase={{
                        required: '전화번호를 입력해 주세요.',
                      }}
                    />
                  }
                />
              </div>
              <div className={st.inputRow}>
                <InputText
                  explain="담당직무"
                  style={{ width: '460px' }}
                  placeholder="대표"
                  name="responsibility"
                  value={props.step2FormData.responsibility}
                  onChange={(e) => props.setStep2FormData(e, 'responsibility')}
                  errorText={
                    <Error
                      name={`responsibility`}
                      value={props.step2FormData.responsibility}
                      errorCase={{
                        required: '담당직무를 입력해 주세요.',
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  ));
}

export default FormDetail;
