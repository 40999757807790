import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import corpModel from '@model/Corp/corpModel';
import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction } from 'mobx';

import AccountRegistrationPopup from './AccountRegistrationPopup/AccountRegistrationPopup';
import DelegationPopup from './DelegationPopup/DelegationPopup';
import ElectronicSignaturePopup from './ElectronicSignaturePopup/ElectronicSignaturePopup';
import ProvideInformationTermsModal from './ProvideInformationTermsModal/ProvideInformationTermsModal';

class ProgressPageVm {
  constructor() {
    this.state = observable({
      agreeYn: false,
      bookkeepingServiceFeeChangeHistoriesView: false,
    });
  }
  async load() {
    const delegationRegistrationOnlyYn = bookKeepingContractModel.delegationRegistrationOnlyYn;
    const delegationRegistrationAgreeYn = bookKeepingContractModel.delegationRegistrationAgreeYn;
    const corpId = String(corpModel.id);
    let openIdList = [];

    if (delegationRegistrationOnlyYn && !delegationRegistrationAgreeYn) {
      if (localStorage.getItem('delegationPopupOpen')) {
        openIdList = localStorage.getItem('delegationPopupOpen').split(',');
        if (!openIdList.includes(corpId)) {
          openIdList.push(corpId);
          runInAction(() => {
            portalModel.title = '이용등록 요청';
            portalModel.content = <DelegationPopup />;
          });
        }

        localStorage.setItem('delegationPopupOpen', openIdList.join());
      } else {
        localStorage.setItem('delegationPopupOpen', corpId);
        runInAction(() => {
          portalModel.title = '이용등록 요청';
          portalModel.content = <DelegationPopup />;
        });
      }
    }
  }
  bookkeepingServiceFeeChangeHistoriesViewToggle() {
    this.state.bookkeepingServiceFeeChangeHistoriesView = !this.state.bookkeepingServiceFeeChangeHistoriesView;
  }
  get bookkeepingSignatureActivationYn() {
    //전자서명 재요청한경우 버튼 무조건 살리는 기능
    return bookKeepingContractModel.bookkeepingSignatureActivationYn;
  }
  get term() {
    return bookKeepingContractModel.term;
  }
  get accountingFirmName() {
    //기장계약 회계법인
    return bookKeepingContractModel.accountingFirmName;
  }
  get bookkeepingServiceStartAt() {
    //기장계약 시작일
    return bookKeepingContractModel.bookkeepingServiceStartAt;
  }
  get freeBookkeepingServiceEndAt() {
    //무료기장 종료일
    return bookKeepingContractModel.freeBookkeepingServiceEndAt;
  }
  get freeBookkeepingServiceYn() {
    //3개월 무료기장혜택 여부
    return bookKeepingContractModel.freeBookkeepingServiceYn;
  }
  get delegationRegistrationOnlyYn() {
    //수임등록만하기 여부
    return bookKeepingContractModel.delegationRegistrationOnlyYn;
  }
  get bookkeepingServiceFee() {
    //월 기장료
    return bookKeepingContractModel.bookkeepingServiceFee;
  }
  get companyName() {
    return corpModel.company.companyName;
  }
  get acctFirmCode() {
    return corpModel.acctFirmCode;
  }
  get bookkeepingServiceFeeChangeHistories() {
    return bookKeepingContractModel.bookkeepingServiceFeeChangeHistories;
  }
  get bookkeepingContractUpdatedAt() {
    return bookKeepingContractModel.bookkeepingContractUpdatedAt;
  }
  get cmsDirectDebitApplicationUpdatedAt() {
    return bookKeepingContractModel.cmsDirectDebitApplicationUpdatedAt;
  }
  get bookkeepingServicePauseStartedAt() {
    return bookKeepingContractModel.bookkeepingServicePauseStartedAt;
  }
  electronicSignature() {
    runInAction(() => {
      portalModel.title = '기장대리 및 회계자문 계약서';
      portalModel.content = <ElectronicSignaturePopup />;
    });
  }
  get bookkeepingContractFile() {
    //기장계약서 파일
    return bookKeepingContractModel.bookkeepingContractFile;
  }
  fileDownload(downloadLink) {
    void bookKeepingContractService.fileDownload(downloadLink);
  }
  get cmsAccountInputDeadline() {
    return bookKeepingContractModel.cmsAccountInputDeadline;
  }
  accountRegistration() {
    runInAction(() => {
      portalModel.title = '기장료 자동이체를 위한 계좌정보 입력';
      portalModel.content = <AccountRegistrationPopup />;
    });
  }
  get delegationRegistrationAgreeYn() {
    return bookKeepingContractModel.delegationRegistrationAgreeYn;
  }
  get cmsDirectDebitApplicationFile() {
    return bookKeepingContractModel.cmsDirectDebitApplicationFile;
  }
  get pageType() {
    return bookKeepingContractModel.pageType;
  }
  setAgreeYn(e) {
    this.state.agreeYn = e.target.checked;
  }
  async register() {
    if (!this.state.agreeYn) {
      return _alert('정보제공 동의를 해주세요.');
    }

    await bookKeepingContractService.agree(bookKeepingContractModel.term.id);
  }
  get cmsAccountSignatureActivationYn() {
    //cms 계약 활성화 여부
    return bookKeepingContractModel.cmsAccountSignatureActivationYn;
  }
  agreeModal(acctFirmCode) {
    let title = '';

    switch (acctFirmCode) {
      case 'JINPYUNG':
        title = '진평회계법인에 대한 정보제공 동의';
        break;

      case 'BARUN':
        title = '세무회계 바른에 대한 정보제공 동의';
        break;
    }

    portalModel.title = title;
    portalModel.content = <ProvideInformationTermsModal content={bookKeepingContractModel.term.content} />;
  }
  get freeMonth() {
    return bookKeepingContractModel.freeMonth;
  }
  get freeBookkeepingServicePreviousEndAt() {
    return bookKeepingContractModel.freeBookkeepingServicePreviousEndAt;
  }
}

export default new ProgressPageVm();
