import './index.css';

import { pageRouter } from '@model/pageModel';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import App from './App';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: 'https://50bc26a46730bb40a05bcf82b3e5ae57@o774769.ingest.sentry.io/4506109255155712',
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [/^https:\/\/api\.standby\.kr/],
      networkRequestHeaders: ['Authorization', 'Accept', 'User-Agent', 'Cache-Control'],
      networkResponseHeaders: ['Content-Type', 'Date'],
    }),
  ],
  environment: process.env.NODE_ENV,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maskAllText: false,
  maskAllInputs: false,
  blockAllMedia: false,
  ignoreErrors: ['AxiosError'],
});

async function enableMocking() {
  // mocking 사용하고 싶을때 변경
  const isMockingEnabled = false;

  if (process.env.NODE_ENV !== 'development' || !isMockingEnabled) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

void enableMocking().then(() =>
  root.render(
    <HistoryRouter history={pageRouter.history}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HistoryRouter>,
  ),
);
