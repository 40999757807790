import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type { Agenda, IACommonAgenda } from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import vm from './CommonAgendaVm';
import clsx from 'clsx';
import Cst from './CommonAgenda.module.scss';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IACommonAgenda>;
  moreViewYn: boolean;
  sameCount: {
    count: number;
    hasSameType: boolean;
  };
}

function CommonAgenda(props: Props) {
  return useObserver(() => (
    <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
      <div className={st.title}>
        <div className={st.number}>{props.agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>
            {vm.title(props.agenda.type)} {props.sameCount.hasSameType === true && `(${props.sameCount.count})`}
          </span>
        </div>
        {props.agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      {props.moreViewYn && (
        <div className={st.info}>
          <ul className={clsx(st.agendaList, Cst.agendaList)}>
            <li>
              <span>
                <span className={Cst.important}>안건제목</span> :{' '}
              </span>{' '}
              <span className={Cst.content}>{props.agenda.info.title}</span>
            </li>
            <li>
              <span>
                <span className={Cst.important}>안건내용</span> :{' '}
              </span>{' '}
              <span
                className={Cst.content}
                dangerouslySetInnerHTML={{ __html: props.agenda.info.content.replace(/\n/g, '<br />') }}
              />
            </li>
            {vm.shouldGetFile(props.agenda.type) === true && (
              <li>
                별지 :
                <span style={{ marginLeft: '4px' }}>
                  {props.agenda.info.downloadUrl ? (
                    <button
                      type="button"
                      className={st.downloadBtn}
                      onClick={() => vm.fileDown(props.agenda.info.downloadUrl)}
                    >
                      {props.agenda.info.downloadTitle}
                    </button>
                  ) : (
                    '없음'
                  )}
                </span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  ));
}
export default CommonAgenda;
