import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import { FileExtensionType } from '@service/Corp/BusinessRegistration/BusinessRegistrationService.interface';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import BesinessRegistrationEmailSignModal from './BesinessRegistrationEmailSignModal/BesinessRegistrationEmailSignModal';

class BesinessRegistrationDownloadSelectModalVm {
  fileTypeDown(type: FileExtensionType) {
    businessRegistrationService.fileTypeDown(type);
  }
  openSignModal() {
    runInAction(() => {
      portalModel.title = '사업자등록증 다운로드';
      portalModel.content = <BesinessRegistrationEmailSignModal />;
    });
  }
}
export default new BesinessRegistrationDownloadSelectModalVm();
