import { useObserver } from 'mobx-react';
import st from './Expired.module.scss';

function Expired() {
  return useObserver(() => (
    <div className={st.expired}>
      <div className={st.expiredBox}>
        <div className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </div>
        <div className={st.content}>
          <div className={st.title}>열람기간이 종료 되었어요.</div>
          <div className={st.explain}>
            정보가 다시 필요하다면,
            <br />
            발송인에게 다시 요청하세요
          </div>
        </div>
      </div>
    </div>
  ));
}

export default Expired;
