import corpModel from '@model/Corp/corpModel';
import corporateTaxModel from '@model/Corp/CorporateTax/corporateTaxModel';
import CorporateTaxService from '@service/Corp/CorporateTax/CorporateTaxService';
import { observable } from 'mobx';

interface State {
  loadYn: boolean;
}

class CorporateTaxVm {
  state: State = observable({
    loadYn: false,
  });
  async load() {
    await CorporateTaxService.loadGuides();
    await CorporateTaxService.loadCorporateTax();
    this.state.loadYn = true;
  }
  get corpId() {
    return corpModel.id;
  }
  get guides() {
    return corporateTaxModel.guides;
  }
  get nowStep() {
    return corporateTaxModel.taxFilingState.steps[corporateTaxModel.taxFilingState.steps.length - 1]?.step;
  }
  get corporateTaxes() {
    return corporateTaxModel.corporateTaxes;
  }
}

export default new CorporateTaxVm();
