import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import CancelRegModal from '../CancelRegModal/CancelRegModal';
import { _confirm } from '@src/model/dialogModel';

class HeaderVm {
  get company() {
    return AgendaDashboardModel.company;
  }
  fileDown(downloadUrl: string) {
    AgendaDashboardService.fileDown(downloadUrl);
  }
  get selectedAgendaProgressId() {
    return AgendaDashboardModel.selectedAgendaProgressId;
  }
  changeSelectedAgendaProgressId(e: React.ChangeEvent<HTMLSelectElement>) {
    AgendaDashboardModel.selectedAgendaProgressId = e.target.value;
    if (e.target.value) {
      AgendaDashboardService.loadDetail(e.target.value);
    } else {
      AgendaDashboardModel.agendaProgress.company.capital = 0;
    }
  }
  get agendaProgressSelectList() {
    return AgendaDashboardModel.agendaProgressSelectList;
  }
  cancelReg() {
    runInAction(() => {
      portalModel.title = '등기대행 취소';
      portalModel.content = <CancelRegModal />;
    });
  }
  get regAgencyCancelInfo() {
    return AgendaDashboardModel.agendaProgress.registrationAgency.regAgencyCancelInfo;
  }
  get registrationAgencyType() {
    return AgendaDashboardModel.registrationAgencyType;
  }
  async changeRegistrationAgency() {
    if (await _confirm('기진행으로 변경하시겠어요?')) {
      AgendaDashboardService.changeRegistrationAgency(this.selectedAgendaProgressId);
    }
  }
}
export default new HeaderVm();
