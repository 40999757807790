import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';

function AoaPopup() {
  return useObserver(() => (
    <>
      <img src="/images/aoa_popup.png" />
    </>
  ));
}
export default AoaPopup;
