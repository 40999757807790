import { Button, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import st from './SetupNewPassword.module.scss';
import SetupNewPasswordVm from './SetupNewPasswordVm';

function SetupNewPassword(props) {
  const location = useLocation();
  const token = queryString.parse(location.search).token;

  return useObserver(() => (
    <form onSubmit={(e) => SetupNewPasswordVm.submit(e, token)} className={st.setupNewPasswordContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-18B">새로운 비밀번호</Typography>
        <Typography fontType="Title-18M">를 설정해 주세요.</Typography>
      </div>
      <TextField
        label="새 비밀번호"
        name="password"
        type="password"
        value={SetupNewPasswordVm.password}
        onChange={SetupNewPasswordVm.setPassword}
        labelBottom={`* 8글자 이상 입력\n* 영문, 숫자, 특수문자 각 1개 이상 사용`}
        error={
          <ErrorValidation
            name="password"
            value={SetupNewPasswordVm.password}
            errorCase={{
              required: '비밀번호를 입력해 주세요.',
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                    SetupNewPasswordVm.password,
                  ),
                message: '올바른 비밀번호 형식으로 입력해 주세요.',
              },
            }}
          />
        }
      />
      <TextField
        name="passwordCheck"
        type="password"
        label="새 비밀번호 확인"
        className={st.newPassword}
        value={SetupNewPasswordVm.passwordCheck}
        onChange={SetupNewPasswordVm.setPasswordCheck}
        error={
          <ErrorValidation
            name="passwordCheck"
            value={SetupNewPasswordVm.passwordCheck}
            errorCase={{
              required: '비밀번호 확인을 입력해 주세요.',
              validate: {
                func: SetupNewPasswordVm.isSame(),
                message: '동일한 비밀번호를 입력해주세요.',
              },
            }}
          />
        }
      />
      <Button type="submit" className={st.mainBtn} color="primary400" size="large">
        비밀번호 재설정하기
      </Button>
    </form>
  ));
}

export default SetupNewPassword;
