import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import st from './BusinessRegistrationModal.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import vm from './BusinessRegistrationModalVm';
import josa from '@common/module/josa';

function LoginModal(props) {
  return useObserver(() => (
    <div className={st.login}>
      <img src="/images/success_login.svg" className={st.loginImg} />
      <div className={st.name}>{vm.name}님 안녕하세요!</div>
      <div className={st.companyInfo}>
        <div>
          {vm.acceptWaitingCompanies.map((company, idx) => (
            <span key={idx}>
              {' '}
              {company}
              {idx !== vm.acceptWaitingCompanies.length - 1 ? ',' : ' '}
            </span>
          ))}
          설립을 축하드려요!
        </div>
        <div>
          스탠바이에서 새로 설립된
          <span className={st.bold}>
            {vm.acceptWaitingCompanies.map((company, idx) => (
              <span key={idx}>
                {' '}
                {company}
                {idx !== vm.acceptWaitingCompanies.length - 1 ? ',' : ''}
              </span>
            ))}
            의 사업자등록
          </span>
          을 진행해드릴까요?
        </div>
      </div>
      <div className={st.btnArea}>
        <SquareBtn color="white" className={st.progressBtn} onClick={() => vm.businessRegistration()}>
          사업자등록 진행
        </SquareBtn>
      </div>
      <div className={st.explain}>
        사업자등록 진행 버튼을 누르면,
        {vm.acceptWaitingCompanies
          .filter((item, index) => index < vm.acceptWaitingCompanies.length - 1)
          .map((company, idx) => (
            <span key={idx}> {company},</span>
          ))}
        &nbsp;{josa(vm.acceptWaitingCompanies[vm.acceptWaitingCompanies.length - 1], '이가')} [내 법인]에 자동으로
        추가되어요.
      </div>
    </div>
  ));
}
export default LoginModal;
