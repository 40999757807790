import regExp from '@common/module/regExp';
import {
  Button,
  CheckBox,
  Error,
  Label,
  SelectField,
  TextButton,
  TextField,
  Typography,
} from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import ScrollArea from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';

import st from './Invited.module.scss';
import vm from './InvitedVm';

function Invited() {
  const location = useLocation();
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    vm.load(token);
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e, token)} className={st.formContent}>
      <ScrollArea
        className={st.scrollArea}
        renderThumbVertical={(props) => <div {...props} style={{ width: '3px', backgroundColor: '#D6D8DA' }} />}
      >
        <div className={st.contentView}>
          <div className={st.logoWrap}>
            <a href="https://standby.kr/" className={st.logo}>
              <img src="/images/logo_standby.svg?1" />
            </a>
          </div>
          <div className={st.mainTitle}>
            <Typography fontType="Title-18M">
              {vm.email}님<br />
              안녕하세요 :) 스탠바이 헬프미에 오신 것을 환영합니다.
            </Typography>
          </div>
          <TextField
            label="담당자로 초대받은 법인"
            name="text"
            value={vm.companyName}
            className={st.inputText}
            disabled
          />
          <TextField
            label="새 비밀번호"
            labelAsteriskMark
            labelBottom={`* 8글자 이상 입력\n* 영문, 숫자, 특수문자 각 1개 이상 사용`}
            name="password"
            type="password"
            className={st.inputText}
            value={vm.password}
            onChange={vm.setPassword}
            error={
              <ErrorValidation
                name="password"
                value={vm.password}
                errorCase={{
                  required: '비밀번호를 입력해 주세요.',
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                        vm.password,
                      ),
                    message: '올바른 비밀번호 형식으로 입력해 주세요.',
                  },
                }}
              />
            }
          />
          <TextField
            label="새 비밀번호 확인"
            labelAsteriskMark
            name="passwordCheck"
            type="password"
            className={st.inputText}
            value={vm.passwordCheck}
            onChange={vm.setPasswordCheck}
            error={
              <ErrorValidation
                name="passwordCheck"
                value={vm.passwordCheck}
                errorCase={{
                  required: '비밀번호 확인을 입력해 주세요.',
                  validate: {
                    func: vm.isSame(),
                    message: '동일한 비밀번호를 입력해주세요.',
                  },
                }}
              />
            }
          />
          <TextField
            label="이름"
            labelAsteriskMark
            labelBottom="* 실명 사용을 추천드려요!"
            name="name"
            className={st.inputText}
            value={vm.name}
            onChange={vm.setName}
            error={
              <ErrorValidation
                name="name"
                value={vm.name}
                errorCase={{
                  required: '이름을 입력해 주세요.',
                  pattern: {
                    value: regExp.onlyKorEng().test(vm.name),
                    message: '한글 또는 영문으로 입력해 주세요.',
                  },
                }}
              />
            }
          />
          <div className={st.phoneNumber}>
            <Label asteriskMark>전화번호</Label>
            <div className={st.phoneNumberBox}>
              <SelectField name="countryCode" value={vm.countryCode} disabled style={{ width: '64px' }}>
                <option value="">-</option>
                <option value="82">+82</option>
              </SelectField>
              <TextField
                name="phoneNumber"
                value={vm.phoneNumber}
                onChange={vm.setPhoneNumber}
                style={{ width: '356px' }}
                error={
                  <ErrorValidation
                    name="phoneNumber"
                    value={vm.phoneNumber}
                    errorCase={{
                      required: '전화번호를 입력해 주세요.',
                      pattern: {
                        value: regExp.phoneNumber().test(vm.phoneNumber),
                        message: '올바른 전화번호를 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className={st.termsBox}>
            <CheckBox checked={vm.agreeAll} onChange={(e) => vm.setAgreeAll(e)} fontSize="15px">
              모두 동의합니다.
            </CheckBox>
            <ul className={st.termsList}>
              {vm.userRegistrationTerms.map((item, idx) => {
                return (
                  <li key={idx}>
                    <input type="hidden" name={`submittedTermRequests[${idx}].id`} value={item.id} />
                    <CheckBox
                      textClassName={st.checkbox}
                      name={`submittedTermRequests[${idx}].agreeYn`}
                      value={vm.agreeYn(idx)}
                      checked={vm.agreeYn(idx) === true}
                      onChange={(e) => vm.setAgreeYn(e, idx)}
                      error={item.required && vm.state.errorCheckYn && vm.checkBoxError}
                      size="small"
                    >
                      <span className={st.notRequired}>{item.required === false && '[선택] '}</span>
                      {item.title}
                    </CheckBox>
                    {item.content && (
                      <TextButton
                        type="button"
                        underline
                        className={st.textBtn}
                        onClick={() =>
                          vm.termsPopup(
                            item.title,
                            <div
                              className={clsx(st.agreeContent, 'terms')}
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></div>,
                          )
                        }
                      >
                        상세보기
                      </TextButton>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          {vm.state.errorCheckYn && vm.checkBoxError && <Error>필수 약관을 모두 동의해주세요.</Error>}
          <Button type="submit" className={st.mainBtn} color="primary400" size="large">
            스탠바이 바로 시작하기
          </Button>
        </div>
      </ScrollArea>
    </form>
  ));
}

export default Invited;
