import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import { useObserver } from 'mobx-react';

import type { TermBundle } from '../../useStep2';
import st from './style.module.scss';

type Props = TermBundle & { submissionRejected: boolean };

function Term(props: Props) {
  return useObserver(() => (
    <div className={st.question}>
      <CheckBox checked={props.agreeAll} onChange={(e) => props.setAgreeAll(e)} fontSize="15px" size="large">
        모두 동의합니다.
      </CheckBox>
      <ul className={st.termsList}>
        {props.termInfoState.termInfo.map((item, idx) => {
          return (
            <li key={idx}>
              <input type="hidden" name={`awsApplicationTerms[${idx}].id`} value={item.id} />
              <CheckBox
                name={`awsApplicationTerms[${idx}].agreeYn`}
                value={String(item.agreeYn)}
                checked={item.agreeYn}
                onChange={(e) => props.setAgreeYn(e, idx)}
                textClassName={st.radioText}
              >
                <span className={st.notRequired}>{item.required === true && '(필수) '}</span>
                {item.title}
              </CheckBox>
              {item.content && (
                <TextBtn
                  className={st.textBtn}
                  onClick={() =>
                    props.openTermPopup(item.title, <div dangerouslySetInnerHTML={{ __html: item.content }}></div>)
                  }
                >
                  상세보기
                </TextBtn>
              )}
            </li>
          );
        })}
      </ul>
      {props.submissionRejected && !props.isRequiredTermsAgreed && <ErrorText>필수 약관에 동의해주세요.</ErrorText>}
    </div>
  ));
}

export default Term;
