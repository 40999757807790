import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import portalModel from '@model/portalModel';
import { observable, runInAction } from 'mobx';
import { _alert } from '@model/dialogModel';
import AgreeStatePopup from '@src/pages/Corp/Popup/AgreeStatePopup/AgreeStatePopup';
import formDataToJson from '@common/module/submit';

import type {
  LoadDocumentParam,
  I기간단축_동의서,
} from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';

interface NewStockAcquirerInfo {
  assignmentType: '제3자' | '주주' | null;
  isExistsAbandonedStock: boolean;
}

interface State {
  options: I기간단축_동의서;
}

class DocumentVm {
  state: State = observable({
    options: {
      fontSize: 'MEDIUM',
      contentDetailYn: false,
    },
  });
  title(_newStockAcquirerInfo: NewStockAcquirerInfo) {
    const documentType = this.documentType(_newStockAcquirerInfo);
    if (documentType === '기간단축_동의서') {
      return '기간 단축 동의서';
    } else if (documentType === '신주식_인수포기_및_기간_단축_동의서') {
      return '신주식 인수포기 및 기간 단축 동의서';
    }
  }
  documentType(_newStockAcquirerInfo: NewStockAcquirerInfo) {
    const newStockAcquirerInfo = _newStockAcquirerInfo;
    if (
      newStockAcquirerInfo.assignmentType === '제3자' ||
      (newStockAcquirerInfo.assignmentType === '주주' && newStockAcquirerInfo.isExistsAbandonedStock === false)
    ) {
      return '기간단축_동의서';
    } else if (newStockAcquirerInfo.assignmentType === '주주' && newStockAcquirerInfo.isExistsAbandonedStock) {
      return '신주식_인수포기_및_기간_단축_동의서';
    } else {
      return null;
    }
  }
  async load(agendaProgressId: string, newStockAcquirerInfo: NewStockAcquirerInfo) {
    const param = {
      documentType: this.documentType(newStockAcquirerInfo),
      options: this.state.options,
    } as LoadDocumentParam;
    await documentService.loadDocument(agendaProgressId, param);
  }
  next(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string, newStockAcquirerInfo: NewStockAcquirerInfo) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param_formData = new FormData(e.target as HTMLFormElement);

      param_formData.append('documentType', this.documentType(newStockAcquirerInfo) as string);

      runInAction(() => {
        portalModel.title = '2. 발송할 주주를 선택하세요.';
        portalModel.content = (
          <AgreeStatePopup
            agendaProgressId={agendaProgressId}
            documentType={this.documentType(newStockAcquirerInfo)}
            statusType={this.documentType(newStockAcquirerInfo)}
            formData={param_formData}
          />
        );
      });
    }
  }
  get previewLink() {
    return documentModel.previewLink;
  }
  setFontSize(
    e: React.ChangeEvent<HTMLInputElement>,
    agendaProgressId: string,
    newStockAcquirerInfo: NewStockAcquirerInfo,
  ) {
    this.state.options.fontSize = e.target.value;
    this.load(agendaProgressId, newStockAcquirerInfo);
  }
  setContentDetailYn(
    e: React.ChangeEvent<HTMLInputElement>,
    agendaProgressId: string,
    newStockAcquirerInfo: NewStockAcquirerInfo,
  ) {
    this.state.options.contentDetailYn = e.target.value === 'true';
    this.load(agendaProgressId, newStockAcquirerInfo);
  }
}

export default DocumentVm;
