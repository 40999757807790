import { get, post } from '@common/module/httpRequest';
import { pageRouter } from '@model/pageModel';
import userModel from '@model/userModel';
import joinMembershipModel from '@model/Auth/joinMembershipModel';
import additionalInformationModel from '@model/Auth/additionalInformationModel';
import Cookies from 'js-cookie';
import presetService from '@service/presetService';
import loginModel from '@model/Auth/loginModel';
import invitedModel from '@model/Auth/invitedModel';
import { runInAction } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';
import expiredInvitedMembershipModel from '@model/Auth/expiredInvitedMembershipModel';
import socketService from '@service/socketService';
import ReactGA from 'react-ga4';
import findIdModel from '@model/Auth/findId/findIdModel';

class authService {
  async login(data, redirectToken) {
    //로그인
    const res = await post(
      `/login?autoLogin=${data?.autoLogin ? 'true' : false}`,
      {
        username: data?.username,
        password: data?.password,
      },
      redirectToken
        ? {
            'Redirect-Authorization': `${redirectToken}`,
          }
        : {},
    );

    this.loginSuccess(res.data);
    loginModel.email = '';
    loginModel.password = '';
  }
  loginSuccess(res, type = null) {
    /*
            rememberMeToken으로 자동로그인 여부를 판단함.
        */
    if (res.rememberMeToken) {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      localStorage.setItem('rememberMeToken', res.rememberMeToken);
      localStorage.setItem('accessToken', res.accessToken);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('rememberMeToken');
      Cookies.set('accessToken', res.accessToken);
      Cookies.set('refreshToken', res.refreshToken);
    }
    if (!userModel.user.name || !userModel.user.email) {
      presetService.load();
    }

    if (res.redirectUrl) {
      window.location.replace(res.redirectUrl);
    } else if (type === 'joinMembership') {
      window.location.replace(`/companyRegistration`);
    } else {
      window.location.replace(`/userDashboard`);
    }
  }
  async loadTerms(type) {
    //회원가입_약관
    const res = await get(`/term/${type}`, {}, {});
    const data = res.data;
    joinMembershipModel.setUserRegistrationTerms(data);
    //sessionStorage에 joinMembership 데이터가 담겨 있는 경우가 있을 수 있어서 회원가입 페이지 로드할 떄, sessionStorage joinMembership 삭제
    this.removeJoinMembershipInfo();
  }
  async joinMembership(data) {
    //회원가입_메일입력, 약관동의
    const res = await post(`/user/email`, data, {});
    const joinMembershipData = JSON.stringify(data);
    sessionStorage.setItem('joinMembership', joinMembershipData);
    window.location.replace(`/auth/requestemailCheck?emailType=joinMembership`);
  }
  async requestJoinMembershipEmail(data) {
    //회원가입_이메일 다시보내기
    const res = await post(`/user/email`, data, {});
  }
  async requestOtherJoinMembershipEmail() {
    //회원가입_다른 이메일 주소로 보내기
    pageRouter.replace(`/auth/joinMembership`);
    //기존 계정 비활성화 하는 api 필요
  }
  async findPassword(data) {
    //비밀번호 찾기
    const res = await post(`/user/email/reset/password`, data, {});
    const findPasswordData = JSON.stringify(data);
    sessionStorage.setItem('findPassword', findPasswordData);
    pageRouter.replace(`/auth/requestemailCheck?emailType=findPassword`);
  }
  async requestFindPasswordEmail(data) {
    //비밀번호 찾기_이메일 다시 보내기
    const res = await post(`/user/email/reset/password`, data, {});
  }
  async requestOtherFindPasswordEmail() {
    //로그인 페이지로 이동
    pageRouter.replace(`/auth/login`);
  }
  async loadEmailData(token) {
    const res = await get(
      `/user/email/authorization`,
      {},
      {
        'Registration-Authorization': `Bearer ${token}`,
      },
    );
    const data = res.data;
    additionalInformationModel.setEmail(data.email);
  }
  async additionalInformation(data, token) {
    //추가정보 입력
    const res = await post(`/user/email/final`, data, {
      'Registration-Authorization': `Bearer ${token}`,
    });
    //마케팅 구글에널리틱스 관련 코드
    ReactGA.gtag('event', 'complete_signup');
    this.loginSuccess(res.data, 'joinMembership');
  }
  async setupNewPassword(data, token) {
    //비밀번호 재설정
    const res = await post(`/user/reset/password`, data, {
      'Reset-Password-Authorization': `Bearer ${token}`,
    });
    await _alert('비밀번호가 잘 변경되었어요!');
    window.location.replace(`/auth/login`);
  }
  async logout() {
    //로그아웃
    //로그아웃api요청
    let headerParam = {};
    if (Cookies.get('refreshToken')) {
      headerParam['Refresh-Authorization'] = `Bearer ${Cookies.get('refreshToken')}`;
    }
    if (localStorage.getItem('rememberMeToken')) {
      headerParam['Remember-Me-Authorization'] = `Bearer ${localStorage.getItem('rememberMeToken')}`;
    }

    await post(`/my-account/logout`, {}, headerParam);
    this.logoutClient();
    window.location.replace(`/auth/login`);
  }
  logoutClient(data) {
    //리프레시토큰에러같은 로그아웃시에는 로그아웃 api는 요청하지않음
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('rememberMeToken');
    socketService.disConnect();
    userModel.removeUser();
  }
  removeJoinMembershipInfo() {
    //sessionStorage에 있는 joinMembership 데이터 삭제
    sessionStorage.removeItem('joinMembership');
  }
  removeFindPasswordInfo() {
    //sessionStorage에 있는 findPassword 데이터 삭제
    sessionStorage.removeItem('findPassword');
  }
  async loadInvitedAuthorizzation(token) {
    //관리자로 초대받은 사람 회원가입 페이지 불러오기
    const res = await get(
      `/user/register/authorization`,
      {},
      {
        'Invite-Registration-Authorization': `Bearer ${token}`,
      },
    );
    const data = res.data;
    invitedModel.setInvitedAuthorizzation(data);
  }
  async invitedRegistration(data, token) {
    //관리자로 초대받은 사람 회원가입 - 동의하고 시작
    const res = await post(`/user/register`, data, {
      'Invite-Registration-Authorization': `Bearer ${token}`,
    });
    await _alert('회원가입이 완료 되었습니다!');
    this.loginSuccess(res.data);
  }
  async expiredInvitedMembership(token) {
    const res = await get(
      `/user/register/expired`,
      {},
      {
        'Invite-Registration-Authorization': `Bearer ${token}`,
      },
    );
    expiredInvitedMembershipModel.setDate(res.data);
  }
  async verificationToken() {
    const res = await post(`/user/find/verification/token`);
    if (res.data.token) {
      sessionStorage.setItem('verificationToken', res.data.token);
    }
  }
  async sendVerificationToken(param) {
    const res = await post(`/user/find/verification/send`, {
      ...param,
      token: sessionStorage.getItem('verificationToken'),
    });
    findIdModel.step = 'verificationPhoneNumber';
  }
  async findId(param) {
    const res = await post(`/user/find/verification`, {
      ...param,
      token: sessionStorage.getItem('verificationToken'),
      name: findIdModel.name,
      phoneNumber: findIdModel.phoneNumber,
    });
    runInAction(() => {
      findIdModel.users = res.data.users;
      findIdModel.step = 'idList';
    });
  }
}

export default new authService();
