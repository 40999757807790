import { observable, makeObservable } from 'mobx';
import type { agendaStepModelInterface, SelectedAgenda } from './agendaStepModel.interface';

class agendaStepModel implements agendaStepModelInterface {
  constructor() {
    makeObservable(this, {
      selectedAgendas: observable,
    });
  }
  selectedAgendas: SelectedAgenda[] = [];
}

export default new agendaStepModel();
