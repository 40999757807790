import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import vm from './CompnayFilesVm';
import st from './CompnayFiles.module.scss';
import clsx from 'clsx';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';

function CompnayFiles() {
  return useObserver(() => (
    <div className={st.companyFile}>
      <div className={st.companyName}>
        <CopyBtn copyText={vm.dashboardData?.companyKoreanName} className={st.copyCompanyName}>
          {vm.dashboardData?.companyKoreanName}
        </CopyBtn>
      </div>
      <ul className={st.fileList}>
        <li>
          <span>
            <span className={clsx(st.title, st.icon1)}>법인등기부등본</span>
            <span className={st.contentText}>{vm.dashboardData?.files.companyCopyFile.createdAt} updated</span>
          </span>
          <button
            type="button"
            className={st.downloadBtn}
            onClick={() => vm.fileDownload(vm.dashboardData?.files.companyCopyFile.url || '')}
          >
            다운로드
          </button>
        </li>
        <li>
          {vm.dashboardData?.files.aoaFile.url ? (
            <>
              <span>
                <span className={clsx(st.title, st.icon2)}>정관</span>
                <span className={st.contentText}>{vm.dashboardData.files.aoaFile.createdAt} updated</span>
              </span>
              <button
                type="button"
                className={st.downloadBtn}
                onClick={() => vm.fileDownload(vm.dashboardData?.files.aoaFile.url || '')}
              >
                다운로드
              </button>
            </>
          ) : (
            <>
              <span className={clsx(st.title, st.icon2)}>정관</span>
              <span className={st.contentText}>정보 입력 중</span>
            </>
          )}
        </li>
        <li>
          {vm.dashboardData?.files.businessRegistrationFile.url ? (
            <>
              <span>
                <span className={clsx(st.title, st.icon3)}>사업자등록증</span>
                <span className={st.contentText}>
                  {vm.dashboardData.files.businessRegistrationFile.createdAt} updated
                </span>
              </span>
              <button
                type="button"
                className={st.downloadBtn}
                onClick={() => vm.downloadBusinessRegistrationFileModal()}
              >
                다운로드
              </button>
            </>
          ) : (
            <>
              <span className={clsx(st.title, st.icon3)}>사업자등록증</span>
              <span className={st.contentText}>정보 입력 중</span>
            </>
          )}
        </li>
        <li>
          {vm.dashboardData?.files.shareholderListFile.createdAt ? (
            <>
              <span>
                <span className={clsx(st.title, st.icon4)}>주주명부</span>
                <span className={st.contentText}>{vm.dashboardData.files.shareholderListFile.createdAt} updated</span>
              </span>
              {vm.authorityYn('SH') ? (
                <Link className={st.downloadBtn} to={vm.dashboardData.files.shareholderListFile.url}>
                  다운로드
                </Link>
              ) : (
                <span className={st.downloadBtn} style={{ cursor: 'pointer' }} onClick={vm.authorityFalse}>
                  다운로드
                </span>
              )}
            </>
          ) : (
            <>
              <span className={clsx(st.title, st.icon4)}>주주명부</span>
              <span className={st.contentText}>정보 입력 중</span>
            </>
          )}
        </li>
      </ul>
    </div>
  ));
}
export default CompnayFiles;
