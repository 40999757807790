import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import vm from './DecisionVm';
import clsx from 'clsx';
import Cst from './Decision.module.scss';

function Decision() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>의결기관</div>
      <div className={clsx(st.content, Cst.content)}>
        <div>
          <div className={st.circleItem}>
            <div className={st.circleTitle}>의결기관</div>
            <div className={clsx(st.circleContent, Cst.circleContent)}>{vm.decisionBody.join(', ')}</div>
          </div>
          {(vm.transmissionStatus.shareholderMeeting.type.includes('미진행') === false ||
            vm.transmissionStatus.newStockAcquire.type.includes('미진행') === false) && (
            <div className={st.circleItem} style={{ marginTop: '8px' }}>
              <div className={st.circleTitle}>통지방법</div>
              <div className={clsx(st.circleContent, Cst.circleContent)}>
                {vm.transmissionStatus.shareholderMeeting.type.includes('미진행') === false && (
                  <>
                    <span className={Cst.itemTitle}>주총</span>
                    <span style={{ marginRight: '12px' }}>
                      {vm.transmissionStatus.shareholderMeeting.title.replace('주총 ', '')}
                    </span>
                  </>
                )}
                {vm.transmissionStatus.newStockAcquire.type.includes('미진행') === false && (
                  <>
                    <span className={Cst.itemTitle}>신주</span>
                    {vm.transmissionStatus.newStockAcquire.title}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ));
}
export default Decision;
