import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';

class AgendaDashboardVm {
  load(rNumber: string, cssn: string) {
    AgendaDashboardService.load(rNumber, cssn);
  }
  get accessRoute() {
    return AgendaDashboardModel.accessRoute;
  }
  get files() {
    return AgendaDashboardModel.files;
  }
  get agendaProgress() {
    return AgendaDashboardModel.agendaProgress;
  }
}
export default new AgendaDashboardVm();
