import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './Box1040.module.scss';
import clsx from 'clsx';

function Box1040(props) {
  return useObserver(() => (
    <div className={clsx(st.cover, props.className)}>
      <div className={clsx(st.box, props.contentClassName)}>{props.children}</div>
    </div>
  ));
}
export default Box1040;
