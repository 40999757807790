import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';
import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';

class RnumberEditModalVm {
  editRnumber(rNumberId: string, deleteFileYn: boolean, rNumber: string) {
    AgendaDashboardService.editRnumber(
      rNumberId,
      deleteFileYn,
      AgendaDashboardModel.company.cssn,
      AgendaDashboardModel.selectedAgendaProgressId,
      rNumber,
    );
    runInAction(() => {
      portalModel.title = '';
      portalModel.content = null;
    });
  }
}
export default new RnumberEditModalVm();
