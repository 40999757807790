import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import * as d3 from 'd3';
import color from '@standby/common-ui/color.scss';
import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class StockVm {
  /**
   * @todo D3.js type에 맞춰 추후 TS로 변경
   */
  createPie = d3
    .pie()
    .value((d) => d)
    .sort(null);
  createArc = d3.arc().innerRadius(34).outerRadius(48);
  colors = [color.sub200, color.secon100, color.main200];
  get data() {
    return this.createPie(DashboardModel.dashboardData.shareholders.map((shareholder) => shareholder.value));
  }
  get dashboardData() {
    return DashboardModel.dashboardData;
  }
  get id() {
    return corpModel.id;
  }
  agendaLinkModal() {
    runInAction(() => {
      portalModel.title = '자본금 증액하기';
      portalModel.content = <AgendaLinkPopup agendaType="신주발행_유상증자" />;
    });
  }
}
export default new StockVm();
