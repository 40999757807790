import MeetingOfShareholdersService from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersService';
import MeetingOfShareholdersModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersModel';

class MeetingOfShareholdersVm {
  load(companyId: string, agendaProgressId: string, minutesId: string) {
    MeetingOfShareholdersService.load(companyId, agendaProgressId, minutesId);
  }
  get nowStep() {
    return MeetingOfShareholdersModel.nowStep;
  }
}
export default new MeetingOfShareholdersVm();
