import { useObserver } from 'mobx-react';
import st from '@pages/Guest/Component/SecurityContent/SecurityContent.module.scss';
import vm from './SecurityVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import SecurityContent from '@src/pages/Guest/Component/SecurityContent/SecurityContent';

interface SecurityCheckProps {
  token: string;
  changePage: () => void;
}

function SecurityCheck(props: SecurityCheckProps) {
  return useObserver(() => (
    <SecurityContent>
      <form className={st.form} onSubmit={(e) => vm.submit(e, props.token, props.changePage)}>
        <div className={st.information}>
          {vm.status === 'NOT_ACCESSIBLE' && (
            <div className={st.notAccessible}>
              <div className={st.documentType}>{vm.documentType}</div>
              <div className={st.explain}>
                {vm.documentType === '사업자등록증 & 통장사본' ? '사업자등록증, 통장사본' : vm.documentType} 열람이
                중단되었어요.
                <br />
                정보가 필요하다면, 발송인에게 다시 요청하세요.
              </div>
            </div>
          )}
          {vm.status === 'NORMAL' && (
            <div className={st.normal}>
              <div className={st.companyName}>{vm.companyName}</div>
              <div className={st.checkDocumentType}>{vm.documentType}</div>
              {vm.shareType === 'EMAIL' && (
                <>
                  <div className={st.explain}>보안확인을 위해 메세지를 받은 이메일 주소를 입력하세요.</div>
                  <div className={st.inputRow}>
                    <div className={st.label}>
                      <label htmlFor="email">메세지를 받은 이메일 주소*</label>
                    </div>
                    <InputText
                      id="email"
                      fullSize
                      value={vm.state.contact}
                      onChange={(e) => vm.changeContact(e)}
                      name="email"
                      errorText={
                        <Error
                          name="email"
                          value={vm.state.contact}
                          errorCase={{
                            required: '이메일 주소를 입력하세요.',
                            pattern: {
                              value: regExp.email().test(vm.state.contact),
                              message: '올바른 이메일 형식으로 입력해 주세요.',
                            },
                          }}
                        />
                      }
                    />
                  </div>
                </>
              )}
              {vm.shareType === 'MOBILE' && (
                <>
                  <div className={st.explain}>보안확인을 위해 메세지를 받은 휴대폰 번호를 입력하세요.</div>
                  <div className={st.inputRow}>
                    <div className={st.label}>
                      <label htmlFor="phoneNumber">메시지를 받은 휴대폰 번호*</label>
                    </div>
                    <InputText
                      id="phoneNumber"
                      fullSize
                      value={vm.state.contact}
                      onChange={(e) => vm.changeContact(e)}
                      name="phoneNumber"
                      errorText={
                        <Error
                          name="phoneNumber"
                          value={vm.state.contact}
                          errorCase={{
                            required: '휴대폰 번호를 입력하세요.',
                            pattern: {
                              value: regExp.phoneNumber().test(vm.state.contact),
                              message: '올바른 휴대폰 번호를 입력해 주세요.',
                            },
                          }}
                        />
                      }
                    />
                  </div>
                </>
              )}
              <div className={st.btnArea}>
                <SquareBtn className={st.nextBtn} type="submit">
                  다음
                </SquareBtn>
              </div>
            </div>
          )}
        </div>
      </form>
    </SecurityContent>
  ));
}
export default SecurityCheck;
