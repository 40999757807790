import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type { Agenda, IA대표이사_주소_변경 } from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA대표이사_주소_변경>;
  moreViewYn: boolean;
}

function C대표이사_주소_변경(props: Props) {
  return useObserver(() => (
    <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
      <div className={st.title}>
        <div className={st.number}>{props.agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>대표자주소변경</span>
        </div>
        {props.agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      {props.moreViewYn && props.agenda.info.representatives.length !== 0 && (
        <div className={st.info}>
          <ul className={st.agendaList}>
            {props.agenda.info.representatives.map((representative, index) => (
              <li key={index}>{representative}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  ));
}
export default C대표이사_주소_변경;
