import type { AxiosResponse, AxiosRequestHeaders, AxiosError } from 'axios';

import axios from 'axios';
import authService from '@service/authService';
import Cookies from 'js-cookie';
import corpModel from '@model/Corp/corpModel';
import { _alert, _confirm } from '@model/dialogModel';

const baseURL = process.env.REACT_APP_API_URL || 'empty';
const httpRequest = axios.create({
  baseURL: baseURL,
  headers: { 'Cache-Control': 'no-cache' },
});

httpRequest.interceptors.request.use(function (config) {
  if (config?.headers) {
    config.headers.pageUrl = window.location.href;
    if (config.url?.includes('s3.ap-northeast-2.amazonaws.com') === false) {
      if (localStorage.getItem('rememberMeToken')) {
        if (localStorage.getItem('accessToken')) {
          config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        }
      } else {
        if (Cookies.get('accessToken')) {
          config.headers.Authorization = `Bearer ${Cookies.get('accessToken')}`;
        }
      }
    }
  }
  return config;
});
let loginAlert = false;
interface ErrorData {
  message?: string;
  subMessage?: string;
  errCode?: string;
  redirectUrl?: string;
}
httpRequest.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (axios.isAxiosError(error)) {
      const errorRes = error.response;
      if (!errorRes) {
        throw new Error(`Expected 'error' and 'error.response' not to be undefined`);
      }
      if (error.request.responseType === 'blob' && errorRes.data instanceof Blob) {
        errorRes.data = JSON.parse(await errorRes.data.text());
      }
      const errorResData = errorRes.data as ErrorData;
      if (loginAlert === false && errorResData.message) {
        await _alert(errorResData.message, errorResData.subMessage || '');
      }
      if (loginAlert === false && errorRes.status === 401) {
        loginAlert = true;
      }
      if (errorRes.status === 401) {
        if (errorResData.errCode === 'WRONG_TOKEN') {
          //토큰없이 회원사용api요청
          authService.logoutClient();
          if (errorResData.redirectUrl) {
            window.location.href = errorResData.redirectUrl;
          }
          return;
        }
        if (errorResData.errCode === 'EXPIRED_TOKEN') {
          //엑세스 토큰만료
          return await resetTokenAndReattemptRequest(error);
        }
      }
      if (errorResData.redirectUrl) {
        window.location.href = errorResData.redirectUrl;
      }
      return Promise.reject(error);
    }
  },
);

let isAlreadyFetchingAccessToken = false;
type SubscribersCallback = (accessToken: string) => Promise<void>;
let subscribers: SubscribersCallback[] = [];
async function resetTokenAndReattemptRequest(error: AxiosError) {
  try {
    const errorResponse = error.response as AxiosResponse;
    const retryOriginalRequest = new Promise((resolve, reject) => {
      addSubscriber(async (accessToken: string) => {
        try {
          if (errorResponse.config.headers) {
            errorResponse.config.headers['Authorization'] = 'Bearer ' + accessToken;
          }
          resolve(httpRequest(errorResponse.config));
        } catch (err) {
          reject(err);
        }
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const { data } = await postRefresh();
      loginAlert = false;
      if (localStorage.getItem('rememberMeToken')) {
        localStorage.setItem('rememberMeToken', data.rememberMeToken);
        localStorage.setItem('accessToken', data.accessToken);
      } else {
        Cookies.set('accessToken', data.accessToken);
        Cookies.set('refreshToken', data.refreshToken);
      }
      isAlreadyFetchingAccessToken = false;

      onAccessTokenFetched(data.accessToken);
    }

    return retryOriginalRequest;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorResData = error.response.data as ErrorData;
      if (errorResData.message) {
        await _alert(errorResData.message, errorResData.subMessage || '');
      }
      if (errorResData.redirectUrl) {
        window.location.href = errorResData.redirectUrl;
      } else {
        window.location.href = `/auth/login`;
      }
      authService.logoutClient();
    }
    return Promise.reject(error);
  }

  function addSubscriber(callback: SubscribersCallback) {
    subscribers.push(callback);
  }

  function onAccessTokenFetched(accessToken: string) {
    subscribers.forEach((callback) => callback(accessToken));
    subscribers = [];
  }

  async function postRefresh() {
    if (localStorage.getItem('rememberMeToken')) {
      return await axios.post(
        `${baseURL}auth/reIssue`,
        {},
        {
          headers: {
            pageUrl: window.location.href,
            'Remember-Me-Authorization': localStorage.getItem('rememberMeToken') || '',
          },
        },
      );
    } else {
      return await axios.post(
        `${baseURL}auth/reIssue`,
        {},
        {
          headers: {
            pageUrl: window.location.href,
            'Refresh-Authorization': Cookies.get('refreshToken') || '',
          },
        },
      );
    }
  }
}
function checkDemoAddress(url: string) {
  const urlSplit = url.split('/');
  const companyIndex = urlSplit.indexOf('company');
  let isFiltered = false;
  if (companyIndex !== -1) {
    const companyId = urlSplit[companyIndex + 1];
    let demoCompanyId;
    corpModel.companies.forEach((company) => {
      if (company?.isDemoYn) {
        demoCompanyId = company?.companyId;
      }
    });
    if (companyId === demoCompanyId) {
      isFiltered = true;
      _alert('해당 기능은 실제 법인에서 사용가능합니다. 법인을 등록하고 사용해보세요!');
    }
  }
  return isFiltered;
}
function get<T = unknown, D = unknown>(
  url: string,
  body?: D,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  return new Promise((resolve, reject) => {
    httpRequest
      .get(url, { params: body, headers: { ...headers } })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function _delete<T = unknown, D = unknown>(
  url: string,
  body?: D,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  if (!checkDemoAddress(url)) {
    return new Promise((resolve, reject) => {
      httpRequest
        .delete(url, { params: body, headers: { ...headers } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise(() => {
      throw new Error('샘플컴퍼니에선 불가능한 요청입니다!');
    });
  }
}
function put<T = unknown, D = unknown>(
  url: string,
  body?: D,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  if (!checkDemoAddress(url)) {
    return new Promise((resolve, reject) => {
      httpRequest
        .put(url, body, { headers: { ...headers } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise(() => {
      throw new Error('샘플컴퍼니에선 불가능한 요청입니다!');
    });
  }
}
function post<T = unknown, D = unknown>(
  url: string,
  body?: D,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  if (!checkDemoAddress(url)) {
    return new Promise((resolve, reject) => {
      httpRequest
        .post(url, body, { headers: { ...headers } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise(() => {
      throw new Error('샘플컴퍼니에선 불가능한 요청입니다!');
    });
  }
}
function patch<T = unknown, D = unknown>(
  url: string,
  body?: D,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  if (!checkDemoAddress(url)) {
    return new Promise((resolve, reject) => {
      httpRequest
        .patch(url, body, { headers: { ...headers } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise(() => {
      throw new Error('샘플컴퍼니에선 불가능한 요청입니다!');
    });
  }
}
function downloadFileGet<D = unknown>(url: string, body?: D, headers?: AxiosRequestHeaders) {
  return new Promise((resolve, reject) => {
    httpRequest
      .get(url, { params: body, headers: { ...headers }, responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        const contentDisposition = decodeURIComponent(res.headers['content-disposition']); // 파일 이름
        let fileName = 'unknown';
        if (contentDisposition) {
          const [fileNameMatch] = contentDisposition.split(';').filter((str) => str.includes('filename'));
          if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
        }
        link.href = url;
        link.setAttribute('download', `${fileName}`);
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function downloadFilePost<D = unknown>(url: string, body?: D, headers?: AxiosRequestHeaders) {
  if (!checkDemoAddress(url)) {
    return new Promise((resolve, reject) => {
      httpRequest
        .post(url, body, { headers: { ...headers }, responseType: 'blob' })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          const contentDisposition = decodeURIComponent(res.headers['content-disposition']); // 파일 이름
          let fileName = 'unknown';
          if (contentDisposition) {
            const [fileNameMatch] = contentDisposition.split(';').filter((str) => str.includes('filename'));
            if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
          }
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          link.style.cssText = 'display:none';
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise(() => {
      throw new Error('샘플컴퍼니에선 불가능한 요청입니다!');
    });
  }
}

export { get, post, _delete, put, patch, downloadFileGet, downloadFilePost };
