import { Copy } from '@standby/dike_ui/assets/icon/line';
import { Button, TextButton, Typography } from '@standby/dike_ui/components/atoms';
import { CopyButton } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';

import st from './IdList.module.scss';
import vm from './IdListVm';

function IdList() {
  return useObserver(() => (
    <div className={st.result}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-18M">고객님의 정보와 일치하는 </Typography>
        <Typography fontType="Title-18B">아이디를 안내</Typography>
        <Typography fontType="Title-18M">드려요.</Typography>
      </div>
      <ul className={st.idList}>
        {vm.users.map((user, index) => (
          <li key={index}>
            <CopyButton
              className={st.userId}
              fontType="Body-14R"
              underline
              copyText={user.id}
              copyButtonIcon={<Copy width="16" height="16" fill={color.primary400} />}
            >
              {user.id}
            </CopyButton>
            <Typography fontType="Detail-12M" className={st.date}>
              {user.registeredAt.year}-{user.registeredAt.month}-{user.registeredAt.day} 가입
            </Typography>
          </li>
        ))}
      </ul>
      <Link to="/auth/login">
        <Button type="button" size="large" color="primary400" variant="outlined" className={st.mainBtn}>
          로그인 하러가기
        </Button>
      </Link>
      <div className={st.likeBtnBox}>
        <Link to="/auth/findPassword">
          <TextButton type="button" underline className={st.likeBtn}>
            비밀번호 찾기
          </TextButton>
        </Link>
      </div>
    </div>
  ));
}

export default IdList;
