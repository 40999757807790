import { useObserver } from 'mobx-react';
import React from 'react';
import vm from './RightsIssueVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './RightsIssue.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import { addCommas } from '@common/module/replaceNumber';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import clsx from 'clsx';
import type { SelectedAgenda, I신주발행_유상증자 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';

interface RightsIssueProps {
  agenda: SelectedAgenda<I신주발행_유상증자>;
  agendaIndex: number;
}
const RightsIssue = React.memo(function (props: RightsIssueProps) {
  return useObserver(() => (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>현재 등기부상 주식수</div>
        <Table className={st.registerTable}>
          <colgroup>
            <col width="243px" />
            <col />
            <col />
            <col width="230px" />
          </colgroup>
          <thead>
            <tr>
              <th>주식종류</th>
              <th style={{ textAlign: 'right' }}>주식수</th>
              <th style={{ textAlign: 'right' }}>액면금</th>
              <th style={{ textAlign: 'right' }}>자본금(=주식수 x 액면금)</th>
            </tr>
          </thead>
          <tbody>
            {props.agenda.agendaDetail.sharesOnRegister.classStocksOnRegister.map((classStocksOnRegister, index) => (
              <tr key={index}>
                <td>
                  {classStocksOnRegister.stockName}
                  {classStocksOnRegister.content && (
                    <button
                      type="button"
                      className={st.showStockBtn}
                      onClick={() =>
                        vm.openStockContentModal(
                          classStocksOnRegister.stockName,
                          classStocksOnRegister.numberOfShares,
                          classStocksOnRegister.content,
                        )
                      }
                    >
                      주식내용 보기
                    </button>
                  )}
                </td>
                <td style={{ textAlign: 'right' }}>{addCommas(classStocksOnRegister.numberOfShares)}주</td>
                <td style={{ textAlign: 'right' }}>{addCommas(classStocksOnRegister.parValue)}원</td>
                <td style={{ textAlign: 'right' }}>{addCommas(classStocksOnRegister.capitalStock)}원</td>
              </tr>
            ))}
            <tr className={st.totalTr}>
              <td colSpan={2} style={{ textAlign: 'right' }}>
                총{' '}
                <span className={st.bold}>
                  {addCommas(props.agenda.agendaDetail.sharesOnRegister.totalNumOfSharesOnRegister)}
                </span>{' '}
                주
              </td>
              <td colSpan={2} style={{ textAlign: 'right' }}>
                총{' '}
                <span className={st.bold}>
                  {addCommas(props.agenda.agendaDetail.sharesOnRegister.totalCapitalStock)}
                </span>{' '}
                원
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>현재 주주명부</div>
        <a href={`/corp/stock/home?corp=${vm.id}`} target="_blank" className={st.stockLink}>
          <span>주주명부 확인</span>
        </a>
      </div>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle} style={{ marginBottom: '10px' }}>
          신주식의 종류, 수를 입력하세요.
        </div>
        <QuestionTooltip title="입력방법 가이드" style={{ width: '180px' }}>
          <div className={st.tooltipContent}>
            <div className={st.exTitle}>입력방법</div>
            <ol className={clsx(st.list, st.bold)}>
              <li>
                <span className={st.number} data-number="1.">
                  주식종류 선택하기
                </span>
                <ul className={st.circleList}>
                  <li>종류를 정한 적이 없다면 보통주 선택!</li>
                </ul>
              </li>
              <li>
                <span className={st.number} data-number="1.">
                  투자금 입력하기
                </span>
                <ul className={st.circleList}>
                  <li>정확한 금액 O 대략적 금액 X</li>
                  <li>가수금 증자의 경우, 증자할 금액만!</li>
                </ul>
              </li>
            </ol>
            <div className={st.exTitle}>자동계산된 발행가액 확인방법</div>
            <ol className={clsx(st.list, st.bold)}>
              <li>
                <span className={st.number} data-number="1.">
                  발행가액 = 투자금 ÷ 주식 수
                </span>
              </li>
              <li>
                <span className={st.number} data-number="2.">
                  발행가액은 소수점이 불가능!
                </span>
              </li>
              <li>
                <span className={st.number} data-number="3.">
                  발행가액이 소수점인 경우 해결방법
                </span>
                <ul className={st.circleList}>
                  <li>투자자에게 추가 입금을 받거나, 소액의 투자금을 반환하여 인수 주식수를 줄이거나 늘리기</li>
                  <li>외국돈 → 원화 환전시 소수점이 된 경우, 반올림하기</li>
                </ul>
                <div className={st.caution}>
                  발행가액이 투자자마다 달라도 무방하지만, 주식 발행 시기가 비슷하거나(6개월~1년 미만), 신주인수인끼리
                  특수 관계가 있다면 증여세가 발생할 수도 있습니다.
                </div>
              </li>
            </ol>
            <div className={st.exTitle}>가수금 증자 안내</div>
            <ol className={st.list}>
              <li>
                <span className={st.number} data-number="1.">
                  가수금 증자는 회사에 빌려준 돈이 있을 때, 그 돈을 주식으로 전환하는 방식이에요.
                </span>
              </li>
              <li>
                <span className={st.number} data-number="2.">
                  주식으로 전환할 돈만 투자금 칸에 입력하세요. (빌려준 돈 전체를 다 적기X)
                </span>
                <div>
                  <a href="https://standby.kr/blog/article/가수금증자의_모든_것/" target="_blank" className={st.aLink}>
                    지켜야 하는 절차규칙 더 알아보기
                  </a>
                </div>
              </li>
            </ol>
            <div className={st.exTitle}>주식종류 선택방법</div>
            <ol className={st.list}>
              <li>
                <span className={st.number} data-number="1.">
                  아무 조건이 없다면 보통주를 선택하세요.
                </span>
              </li>
              <li>
                <span className={st.number} data-number="2.">
                  상환권, 전환권이 붙은 주식이라면, 조건에 맞는 주식을 선택하세요.
                </span>
              </li>
              <li>
                <span className={st.number} data-number="3.">
                  이미 등기된 주식이라면 '등기됨'이라고 표시되어요. 완전히 같은 조건이라면, 해당보기를 선택하세요.
                </span>
              </li>
              <li>
                <span className={st.number} data-number="4.">
                  예전에도 전환상환우선주를 발행했는데, 이자율, 전환가격 등 약간 조건이 다른 경우라면, 직접입력을
                  선택하고, 전환상환우선주 '2종'이라고 이름을 붙이세요. (2종을 이미 발행했다면 3종을 붙이면 됨)
                </span>
              </li>
            </ol>
          </div>
        </QuestionTooltip>
        <Table className={st.table}>
          <colgroup>
            <col width="205px" />
            <col width="130px" />
            <col width="185px" />
            <col width="150px" />
            <col width="130px" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>신주인수인</th>
              <th>신주대금 입금방법</th>
              <th>주식종류</th>
              <th>투자금</th>
              <th>주식수</th>
              <th>발행가액(자동계산)</th>
            </tr>
          </thead>
          {props.agenda.agendaDetail.newSharesAcquirers.map((newSharesAcquirer, index) => (
            <tbody key={index}>
              {newSharesAcquirer.stocks.map((stock, index2) => {
                return (
                  <tr key={index2}>
                    {index2 === 0 && (
                      <td rowSpan={newSharesAcquirer.stocks.length} style={{ paddingLeft: '0' }}>
                        <span className={st.count}>{index + 1}</span>
                        {newSharesAcquirer?.id && (
                          <input
                            type="hidden"
                            value={newSharesAcquirer?.name}
                            name={`agendaDetail.newSharesAcquirers[${index}].name`}
                          />
                        )}
                        {newSharesAcquirer?.newSharesAcquirerId && (
                          <input
                            type="hidden"
                            value={newSharesAcquirer?.newSharesAcquirerId}
                            name={`agendaDetail.newSharesAcquirers[${index}].newSharesAcquirerId`}
                          />
                        )}
                        <input
                          type="hidden"
                          value={(newSharesAcquirer?.executiveYn ?? '').toString()}
                          name={`agendaDetail.newSharesAcquirers[${index}].executiveYn`}
                        />

                        <Select
                          style={{ width: '160px' }}
                          size="small"
                          name={`highType[${index}]`}
                          value={newSharesAcquirer.highType}
                          onChange={(e) => vm.setHighType(e, props.agendaIndex, index)}
                          errorText={
                            <Error
                              name={`highType[${index}]`}
                              value={newSharesAcquirer.highType}
                              errorCase={{
                                required: '신주인수인을 선택하세요.',
                              }}
                            />
                          }
                        >
                          <option value="">-</option>
                          <option value="기존주주">기존 주주</option>
                          <option value="새로운분">새로운 분</option>
                        </Select>
                        {newSharesAcquirer.highType === '기존주주' && (
                          <div style={{ marginTop: '8px', marginLeft: '28px' }}>
                            <Select
                              style={{ width: '160px' }}
                              size="small"
                              explain="주주 선택*"
                              value={newSharesAcquirer.id}
                              onChange={(e) => vm.personSelect(e, props.agendaIndex, index)}
                              name={`agendaDetail.newSharesAcquirers[${index}].id`}
                              errorText={
                                <Error
                                  name={`agendaDetail.newSharesAcquirers[${index}].id`}
                                  value={newSharesAcquirer.id}
                                  errorCase={{
                                    required: '신주인수인을 선택하세요.',
                                  }}
                                />
                              }
                            >
                              <option value="">-</option>
                              {vm
                                .newShareholderSelect(props.agendaIndex, newSharesAcquirer.id)
                                .map((newShareholderSelection, index2) => (
                                  <option key={index2} value={newShareholderSelection.id}>
                                    {newShareholderSelection.name}
                                  </option>
                                ))}
                            </Select>
                          </div>
                        )}
                        {newSharesAcquirer.highType === '새로운분' && (
                          <div style={{ marginTop: '8px', marginLeft: '28px' }}>
                            <InputText
                              style={{ width: '160px' }}
                              placeholder="이름"
                              explain="이름*"
                              size="small"
                              value={newSharesAcquirer.name}
                              onChange={(e) => vm.stockHolderName(e, props.agendaIndex, index)}
                              name={`agendaDetail.newSharesAcquirers[${index}].name`}
                              errorText={
                                <Error
                                  name={`agendaDetail.newSharesAcquirers[${index}].name`}
                                  value={newSharesAcquirer.name}
                                  errorCase={{
                                    required: '이름을 입력하세요.',
                                  }}
                                />
                              }
                            />
                            <div style={{ marginTop: '8px' }}>
                              <Select
                                style={{ width: '160px' }}
                                size="small"
                                explain="개인/법인*"
                                value={newSharesAcquirer.type}
                                onChange={(e) => vm.personSelect(e, props.agendaIndex, index)}
                                name={`agendaDetail.newSharesAcquirers[${index}].type`}
                                errorText={
                                  <Error
                                    name={`agendaDetail.newSharesAcquirers[${index}].type`}
                                    value={newSharesAcquirer.type}
                                    errorCase={{
                                      required: '개인/법인을 선택하세요.',
                                    }}
                                  />
                                }
                              >
                                <option value="">-</option>
                                <option value="국내_성인">한국인 성인</option>
                                <option value="국내_미성년자">한국인 미성년</option>
                                <option value="국내_법인">한국 법인</option>
                                <option value="펀드_투자조합">한국 펀드, 투자조합</option>
                                <option value="해외_성인">외국인 성인</option>
                                <option value="해외_미성년자">외국인 미성년</option>
                                <option value="해외_법인">외국 법인</option>
                              </Select>
                            </div>
                          </div>
                        )}
                        {props.agenda.agendaDetail.newSharesAcquirers.length > 1 && (
                          <MinusBtn
                            size="large"
                            onClick={() => vm.removeSharesAcquirer(props.agendaIndex, index)}
                            className={st.rowDeleteBtn}
                          />
                        )}
                      </td>
                    )}
                    {index2 === 0 && (
                      <td rowSpan={newSharesAcquirer.stocks.length}>
                        <Select
                          value={newSharesAcquirer.resourceType}
                          size="small"
                          name={`agendaDetail.newSharesAcquirers[${index}].resourceType`}
                          onChange={(e) => vm.resourceType(e, props.agendaIndex, index)}
                          errorText={
                            <Error
                              name={`agendaDetail.newSharesAcquirers[${index}].resourceType`}
                              value={newSharesAcquirer.resourceType}
                              style={{ width: '120px' }}
                              errorCase={{
                                required: '방법을 선택하세요.',
                              }}
                            />
                          }
                        >
                          <option value="">-</option>
                          <option value="계좌이체">계좌이체</option>
                          <option value="가수금_증자">가수금증자(=출자전환)</option>
                        </Select>
                      </td>
                    )}
                    <td className={st.stockTd}>
                      {stock?.id && (
                        <input
                          type="hidden"
                          value={stock?.id}
                          name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].id`}
                        />
                      )}
                      <Select
                        style={{ width: '148px' }}
                        size="small"
                        value={stock.stockType}
                        onChange={(e) => vm.stockType(e, props.agendaIndex, index, index2)}
                        name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].stockType`}
                        errorText={
                          <Error
                            name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].stockType`}
                            value={stock.stockType}
                            errorCase={{
                              required: '주식종류를 선택하세요.',
                            }}
                          />
                        }
                      >
                        <option value="">-</option>
                        {props.agenda.agendaDetail.selectableClassStocks.map((selectableClassStock, index3) => (
                          <option key={index3} value={selectableClassStock.name}>
                            {selectableClassStock.name} ({selectableClassStock.type})
                          </option>
                        ))}
                      </Select>
                      <div className={st.btnArea}>
                        <PlusBtn onClick={() => vm.addStock(props.agendaIndex, index, index2)} className={st.plusBtn} />
                        <MinusBtn
                          onClick={() => vm.deleteStock(props.agendaIndex, index, index2)}
                          className={st.minusBtn}
                          disabled={newSharesAcquirer.stocks.length === 1}
                        />
                      </div>
                      {stock.stockType === '직접입력' && (
                        <InputText
                          style={{ width: '148px', marginTop: '8px' }}
                          size="small"
                          value={stock.name}
                          onChange={(e) => vm.stockName(e, props.agendaIndex, index, index2)}
                          name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].name`}
                          errorText={
                            <Error
                              name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].name`}
                              value={stock.name}
                              errorCase={{
                                required: '주식종류를 입력하세요.',
                              }}
                            />
                          }
                        />
                      )}
                    </td>
                    <td>
                      <div className={st.subTextInput}>
                        <InputText
                          style={{ width: '120px', textAlign: 'right' }}
                          size="small"
                          placeholder="0"
                          value={addCommas(stock.investment)}
                          onChange={(e) => vm.investment(e, props.agendaIndex, index, index2)}
                          name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].investment`}
                          errorText={
                            <Error
                              name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].investment`}
                              value={stock.investment.toString()}
                              style={{ width: '130px' }}
                              errorCase={{
                                required: '투자금을 입력하세요.',
                              }}
                            />
                          }
                        />
                        <span className={st.inputSubText}>원</span>
                      </div>
                    </td>
                    <td>
                      <div className={st.subTextInput}>
                        <InputText
                          style={{ width: '103px', textAlign: 'right' }}
                          size="small"
                          placeholder="0"
                          value={addCommas(stock.number)}
                          onChange={(e) => vm.number(e, props.agendaIndex, index, index2)}
                          name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].number`}
                          errorText={
                            <Error
                              name={`agendaDetail.newSharesAcquirers[${index}].stocks[${index2}].number`}
                              value={stock.number.toString()}
                              style={{ width: '130px' }}
                              errorCase={{
                                required: '주식수를 입력하세요.',
                              }}
                            />
                          }
                        />
                        <span className={st.inputSubText}>주</span>
                      </div>
                    </td>
                    <td className={st.calcTd}>
                      <div className={st.text}>
                        {Number(stock.investment) * Number(stock.number) ? (
                          <span
                            className={clsx(
                              st.calculation,
                              vm.calculation(Number(stock.investment), Number(stock.number)).toString().includes('.')
                                ? st.warn
                                : null,
                            )}
                          >
                            {vm.calculation(Number(stock.investment), Number(stock.number))}
                          </span>
                        ) : (
                          <span className={st.before}>0</span>
                        )}
                        <span className={st.subText}>원</span>
                      </div>
                      {addCommas(props.agenda.agendaDetail.sharesOnRegister.classStocksOnRegister[0].parValue) ===
                        vm.calculation(Number(stock.investment), Number(stock.number)) && (
                        <div className={st.absoluteText}>액면가</div>
                      )}
                      {vm.calculation(Number(stock.investment), Number(stock.number)).toString().includes('.') && (
                        <div className={st.calculationWarn}>
                          <ToolTip
                            upAndDown="up"
                            leftOrRight="left"
                            tooltipContentStyle={{ width: '234px' }}
                            className={st.tooltip}
                          >
                            발행가액은 소수점이 불가능해요. 투자자에게 추가 입금을 받거나 소액의 투자금을 반환해서
                            투자금을 조정하세요. 외국돈을 원화 환전시 소수점이 되었다면, 반올림을 해보세요.
                          </ToolTip>
                          소수점 불가능
                          <input type="hidden" name="error" />
                          <Error
                            name={`error`}
                            value={''}
                            style={{ width: 0, height: 0, display: 'inline-block', overflow: 'hidden' }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ))}
        </Table>
        <div className={st.sum}>
          <AddBtn onClick={() => vm.addSharesAcquirer(props.agendaIndex)} className={st.addBtn}>
            추가하기
          </AddBtn>
          <span className={st.sumText} style={{ right: '275px' }}>
            총 <span className={st.sumvalue}>{addCommas(vm.sum(props.agendaIndex).investment)}</span> 원
          </span>
          <span className={st.sumText} style={{ right: '140px' }}>
            총 <span className={st.sumvalue}>{addCommas(vm.sum(props.agendaIndex).stocks)}</span> 주
          </span>
        </div>
      </div>
    </div>
  ));
});
export default RightsIssue;
