import { post, get, downloadFileGet, downloadFilePost } from '@common/module/httpRequest';
import { pageRouter } from '@model/pageModel';
import { runInAction, toJS } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import userModel from '@model/userModel';
import shareholderListModel from '@model/Corp/Stock/ShareholderList/shareholderListModel';

class shareholderListService {
  async loadSelectionInfo(_date) {
    const res = await get(
      `company/${corpModel.id}/shareholder/shareholderList/paper/selectionInfo`,
      {
        date: _date,
      },
      {},
    );
    const data = res.data;
    shareholderListModel.setLoadSelectionInfo(data);
  }
  async preview(param) {
    const res = await post(`company/${corpModel.id}/shareholder/shareholderList/paper/preview`, param);
    const data = res.data;
    shareholderListModel.setPreviewLink(data);
  }
  async pdfDownload(param) {
    await downloadFilePost(`company/${corpModel.id}/shareholder/shareholderList/paper/download/pdf`, param, {});
  }
  async excelDownload(param) {
    await downloadFilePost(`company/${corpModel.id}/shareholder/shareholderList/paper/download/excel`, param, {});
  }
  async send(param) {
    const res = await post(`company/${corpModel.id}/shareholder/shareholderList/paper/send`, param);
  }
}

export default new shareholderListService();
