import awsCreditModel from '@model/Corp/AwsCredit/awsCreditModel';
import awsCreditService from '@service/Corp/AwsCredit/awsCreditService';
import portalModel from '@model/portalModel';
import Step1 from '../Step1';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import UserSetting from '@pages/UserSetting/UserSetting';
import { _alert } from '@src/model/dialogModel';

class Step3Vm {
  get id() {
    return corpModel.id;
  }
  load() {
    awsCreditService.loadStep3();
  }
  get status() {
    return awsCreditModel.step3.status;
  }
  previewModal() {
    runInAction(() => {
      portalModel.content = <Step1 preview={true} />;
    });
  }
  get companyName() {
    return corpModel.company.companyName;
  }
  openUserSetting() {
    runInAction(() => {
      portalModel.content = <UserSetting activeTab="약관동의" />;
      portalModel.title = '나의 계정';
    });
  }
  beforeOpening(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, serviceName: string) {
    e.preventDefault();
    return _alert(`${serviceName} 제휴혜택은 곧 오픈예정이에요!`);
  }
  fileDown(type: string) {
    awsCreditService.fileDown(type);
  }
}
export default new Step3Vm();
