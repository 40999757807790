import { useObserver } from 'mobx-react';
import React from 'react';
import vm from './ParValueDivisionMergerVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './ParValueDivisionMerger.module.scss';
import type { SelectedAgenda, I액면금분할_합병 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import { addCommas } from '@common/module/replaceNumber';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';

interface ParValueDivisionMergerProps {
  agenda: SelectedAgenda<I액면금분할_합병>;
  agendaIndex: number;
}

const ParValueDivisionMerger = React.memo(function (props: ParValueDivisionMergerProps) {
  return useObserver(() => (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div>변경 후 액면금을 입력하세요.</div>
          <div className={st.titleSubInfo}>숫자만 입력 가능해요.</div>
        </div>
        <Table className={st.table}>
          <colgroup>
            <col width="223px" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>등기부상 액면금</th>
              <th>변경 후</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={st.origin}>1주당 {addCommas(props.agenda.agendaDetail.beforeParValue)}원</td>
              <td>
                1주당
                <InputText
                  name="agendaDetail.parValue"
                  textAlign="right"
                  placeholder="0"
                  value={addCommas(props.agenda.agendaDetail.afterParValue)}
                  onChange={(e) => vm.afterParValue(e, props.agendaIndex)}
                  style={{ width: '160px', marginLeft: '8px', marginRight: '6px' }}
                  errorText={
                    <Error
                      name="agendaDetail.parValue"
                      value={addCommas(props.agenda.agendaDetail.afterParValue) || ''}
                      errorCase={{
                        required: '액면금을 입력하세요.',
                        validate: {
                          func: Number(props.agenda.agendaDetail.afterParValue) % 100 === 0,
                          message: '100원 단위로 입력 가능해요.',
                        },
                      }}
                    />
                  }
                />
                원
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  ));
});

export default ParValueDivisionMerger;
