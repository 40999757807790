import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import DocumentCertificationVm from './DocumentCertificationVm';

function DocumentCertification() {
  return useObserver(() => (
    <>
      $고객님 안녕하세요:)
      <br />
      $발송수단을 입력해주세요.
      <form>
        {/* 전화번호 */}
        <div>
          전화번호
          <select name="countryCode" value="82" disabled>
            <option value="">-</option>
            <option value="82">+82</option>
          </select>
          <input type="text" name="phoneNum" style={{ marginLeft: '10px' }} />
        </div>
        {/* 이메일 */}
        <div>
          이메일
          <input type="text" name="email" style={{ marginLeft: '10px' }} />
        </div>
        <button type="submit" style={{ border: '1px solid black' }}>
          서류보기
        </button>
      </form>
    </>
  ));
}

export default DocumentCertification;
