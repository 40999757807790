import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import StockContentPopupVm from './StockContentPopupVm';
import { addCommas } from '@common/module/replaceNumber';
import st from './StockContentPopup.module.scss';

function StockContentPopup(props) {
  return useObserver(() => (
    <div className={st.stockContentPopup}>
      <div className={st.stock}>{addCommas(StockContentPopupVm.stocks[props.idx].number)} 주</div>
      <div className={st.stockExplain}>등기부에 등기된 내용입니다.</div>
      {StockContentPopupVm.stocks[props.idx].content && (
        <div
          className={st.content}
          dangerouslySetInnerHTML={{ __html: StockContentPopupVm.stocks[props.idx].content }}
        ></div>
      )}
    </div>
  ));
}
export default StockContentPopup;
