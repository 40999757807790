import PageTitle from '@commonComponents/PageTitle/PageTitle';
import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import st from './BeforeRegister.module.scss';
import BeforeRegisterVm from './BeforeRegisterVm';

const OtherPage = React.memo(function OtherPage(props) {
  return useObserver(() => (
    <div className={st.otherPage}>
      <PageTitle>
        <span
          className={st.pageTitle}
          style={{ backgroundImage: `url(/images/${BeforeRegisterVm.otherPageData(props.pathType).icon}.svg)` }}
        >
          {BeforeRegisterVm.otherPageData(props.pathType).title}
        </span>
      </PageTitle>
      <div className={st.imgBox}>
        <img src={`/images/${BeforeRegisterVm.otherPageData(props.pathType).image}.png`} />
        {(props.companyRegisterStatus === 'COPY_ISSUING' || props.companyRegisterStatus === 'COPY_PARSING') && (
          <div className={st.explain}>
            {BeforeRegisterVm.otherPageData(props.pathType).explain}
            <div className={st.subExplain}>{BeforeRegisterVm.otherPageData(props.pathType).subExplain}</div>
          </div>
        )}
        {props.companyRegisterStatus === 'IROS_ERROR' && (
          <div className={st.explain}>
            인터넷등기소와 통신오류가 생겼어요.
            <br />
            오류가 해결되는 대로 등기부를 발급받고,
            <br />
            카톡으로 알려드릴게요 :)
            <div className={st.subExplain}>최대 1영업일</div>
          </div>
        )}
        {props.companyRegisterStatus === 'PARSE_ERROR' && (
          <div className={st.explain}>
            등기부 분석 중 오류가 생겼어요.
            <br />
            오류를 해결하는 대로 카톡으로 알려드릴게요 :)
            <div className={st.subExplain}>최대 1영업일</div>
          </div>
        )}
      </div>
    </div>
  ));
});

const RegisterPage = React.memo(function RegisterPage(props) {
  return useObserver(() => (
    <div className={st.registerPage}>
      <div className={st.title}>{BeforeRegisterVm.registerPageData(props.companyRegisterStatus).title}</div>
      <div
        className={st.explain}
        dangerouslySetInnerHTML={{ __html: BeforeRegisterVm.registerPageData(props.companyRegisterStatus).explain }}
      ></div>
      <div className={st.companyInfoBox}>
        <div className={st.companyInfo}>
          <div className={st.companyTitle}>{BeforeRegisterVm.companyName}</div>
          <div className={st.companyAddress}>{BeforeRegisterVm.companyAddress}</div>
        </div>
      </div>
      {props.companyRegisterStatus === 'COPY_ISSUING' && (
        <>
          <div className={st.animationArea} style={{ backgroundColor: props.colorList[props.currentIndex] }}>
            <img src={`/images/${props.imgList[props.currentIndex]}.gif`} className={st.animationImg} />
          </div>
          <div className={st.animationSubTitle}>{props.textList[props.currentIndex]}</div>
        </>
      )}
      {props.companyRegisterStatus === 'COPY_PARSING' && (
        <>
          <div className={st.animationArea} style={{ backgroundColor: props.colorList[props.currentIndex] }}>
            <img src={`/images/${props.imgList[props.currentIndex]}.gif`} className={st.animationImg} />
          </div>
          <div className={st.subTitle}>주주명부, 이제 온라인으로 한 곳에서 관리하세요!</div>
        </>
      )}
      {(props.companyRegisterStatus === 'IROS_ERROR' || props.companyRegisterStatus === 'PARSE_ERROR') && (
        <div className={st.imgArea}>
          <img src="/images/error_img.svg" />
        </div>
      )}
    </div>
  ));
});

function BeforeRegister(props) {
  const colorList = ['#FFF9D8', '#FFE3E3', '#E1F1FF'];
  const imgList = ['copyAnimation1', 'copyAnimation2', 'copyAnimation3'];
  const textList = [
    '주주명부, 이제 온라인으로 한 곳에서 관리하세요!',
    '세금, 주주명부, 법인등기를 원스톱으로 관리하세요!',
    '정관분석, 내 회사의 정관내용을 한 눈에 정리해서 보세요.',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((index) => (index + 1) % 3);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return useObserver(() => (
    <>
      {props.pathType === 'register' ? (
        <RegisterPage
          companyRegisterStatus={BeforeRegisterVm.companyRegisterStatus}
          colorList={colorList}
          imgList={imgList}
          textList={textList}
          currentIndex={currentIndex}
        />
      ) : (
        <OtherPage pathType={props.pathType} companyRegisterStatus={BeforeRegisterVm.companyRegisterStatus} />
      )}
    </>
  ));
}

export default BeforeRegister;
