import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './AdminAddVm';
import st from './AdminAdd.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';

function AdminAdd() {
  useEffect(() => {
    vm.load();
  }, []);
  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>담당자 추가/변경</span>
      </PageTitle>
      <ContentBox
        title="담당자 추가/초대하기"
        className={st.contentBox}
        titleStyle={{ top: '15px', paddingLeft: '20px', fontSize: '18px' }}
      >
        <div className={st.content}>
          <form onSubmit={(e) => vm.submit(e)}>
            <div className={st.row}>
              <div className={st.title}>이름</div>
              <div className={st.formContent}>
                <InputText
                  name="name"
                  className={st.inputText}
                  value={vm.addData.name}
                  onChange={(e) => vm.changeName(e)}
                  errorText={
                    <Error
                      name="name"
                      value={vm.addData.name}
                      errorCase={{
                        required: '이름을 입력해 주세요.',
                        pattern: {
                          value: regExp.onlyKorEng().test(vm.addData.name),
                          message: '한글 또는 영문으로 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>이메일</div>
              <div className={st.formContent}>
                <InputText
                  name="email"
                  className={st.inputText}
                  placeholder="example@logologo.com"
                  value={vm.addData.email}
                  onChange={(e) => vm.changeEmail(e)}
                  errorText={
                    <Error
                      name="email"
                      value={vm.addData.email}
                      errorCase={{
                        required: '이메일을 입력해 주세요',
                        pattern: {
                          value: regExp.email().test(vm.addData.email),
                          message: '올바른 이메일 형식으로 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>
                역할<span className={st.sub}>(선택)</span>
              </div>
              <div className={st.formContent}>
                <InputText
                  name="role"
                  className={st.inputText}
                  value={vm.addData.role}
                  onChange={(e) => vm.changeRole(e)}
                />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>권한</div>
              <div className={st.formContent}>
                <Select
                  value={vm.addData.authorityType}
                  className={st.inputText}
                  onChange={(e) => vm.changeAuthorityType(e)}
                  name="authorityType"
                >
                  <option value="MANAGER">담당자</option>
                  <option value="GENERAL_MANAGER">최고관리자</option>
                </Select>
                <ul
                  className={clsx(
                    st.selectAuthority,
                    vm.addData.authorityType === 'GENERAL_MANAGER' ? st.disabled : null,
                  )}
                >
                  <li>
                    <div className={st.selectAll}>
                      <label
                        className={clsx(st.labelTitle, vm.authorityCheckedAll('law') ? st.active : null)}
                        htmlFor="law"
                      >
                        법무 기능
                      </label>
                      <input
                        type="checkbox"
                        disabled={vm.addData.authorityType === 'GENERAL_MANAGER' ? true : null}
                        checked={vm.authorityCheckedAll('law')}
                        onChange={(e) => vm.authorityCheckedAllChange(e, 'law')}
                        id="law"
                        className={st.radioCheckInput}
                      />
                      <label htmlFor="law" className={st.radioCheckLabel}></label>
                    </div>
                    <div className={st.checkList}>
                      {vm.addData.authorities.law.map((authority, index) => (
                        <CheckBox
                          disabled={vm.addData.authorityType === 'GENERAL_MANAGER' ? true : null}
                          className={st.checkbox}
                          key={index}
                          checked={authority.useYn}
                          onChange={(e) => vm.changeUseYn(e, 'law', index)}
                        >
                          {authority.menuName}
                        </CheckBox>
                      ))}
                    </div>
                  </li>
                  <li>
                    <div className={st.selectAll}>
                      <label
                        className={clsx(st.labelTitle, vm.authorityCheckedAll('acct') ? st.active : null)}
                        htmlFor={`acct`}
                      >
                        회계 기능
                      </label>
                      <input
                        type="checkbox"
                        disabled={vm.addData.authorityType === 'GENERAL_MANAGER' ? true : null}
                        checked={vm.authorityCheckedAll('acct')}
                        onChange={(e) => vm.authorityCheckedAllChange(e, 'acct')}
                        id="acct"
                        className={st.radioCheckInput}
                      />
                      <label htmlFor="acct" className={st.radioCheckLabel}></label>
                    </div>
                    <div className={st.checkList}>
                      {vm.addData.authorities.acct.map((authority, index) => (
                        <CheckBox
                          disabled={vm.addData.authorityType === 'GENERAL_MANAGER' ? true : null}
                          className={st.checkbox}
                          key={index}
                          checked={authority.useYn}
                          onChange={(e) => vm.changeUseYn(e, 'acct', index)}
                        >
                          {authority.menuName}
                        </CheckBox>
                      ))}
                    </div>
                  </li>
                  <li>
                    <div className={st.selectAll}>
                      <label
                        className={clsx(st.labelTitle, vm.authorityCheckedAll('partner') ? st.active : null)}
                        htmlFor={`partner`}
                      >
                        제휴 기능
                      </label>
                      <input
                        type="checkbox"
                        disabled={vm.addData.authorityType === 'GENERAL_MANAGER' ? true : null}
                        checked={vm.authorityCheckedAll('partner')}
                        onChange={(e) => vm.authorityCheckedAllChange(e, 'partner')}
                        id="partner"
                        className={st.radioCheckInput}
                      />
                      <label htmlFor="partner" className={st.radioCheckLabel}></label>
                    </div>
                    <div className={st.checkList}>
                      {vm.addData.authorities.partner.map((authority, index) => (
                        <CheckBox
                          disabled={vm.addData.authorityType === 'GENERAL_MANAGER' ? true : null}
                          className={st.checkbox}
                          key={index}
                          checked={authority.useYn}
                          onChange={(e) => vm.changeUseYn(e, 'partner', index)}
                        >
                          {authority.menuName}
                        </CheckBox>
                      ))}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={st.btnArea}>
              <button type="submit" className={st.submitAll}>
                추가/초대하기
              </button>
            </div>
          </form>
        </div>
      </ContentBox>
    </>
  ));
}
export default AdminAdd;
