import { onlyNumber } from '@common/module/replaceNumber';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import React, { useEffect } from 'react';

import { useAwsCreditStep2Query } from '../../queries';
import type { CloudServiceUseType, Step2FormData } from '../types';

export interface FormDetailBundle {
  step2FormData: Step2FormData;
  setStep2FormData: (e: React.ChangeEvent<HTMLInputElement>, key: keyof Step2FormData) => void;
}

const useFormDetail = (corpId: number): FormDetailBundle => {
  const { data, isSuccess } = useAwsCreditStep2Query(corpId);

  const step2FormData = useLocalObservable<Step2FormData>(() => ({
    useAWSYn: null,
    MGCUseYn: null,
    monthCost: '',
    purpose: '',
    cloudServiceUseType: undefined,
    cloudServiceName: '',
    name: '',
    email: '',
    phoneNumber: '',
    responsibility: '',
  }));

  useEffect(() => {
    if (!isSuccess) return;

    runInAction(() => {
      step2FormData.name = data.name;
      step2FormData.email = data.email;
      step2FormData.phoneNumber = data.phoneNumber;
      step2FormData.responsibility = data.responsibility;
    });
  }, [data, isSuccess]);

  const setStep2FormData: FormDetailBundle['setStep2FormData'] = (e, key) => {
    runInAction(() => {
      switch (key) {
        case 'useAWSYn':
        case 'MGCUseYn':
          step2FormData[key] = e.target.value === 'true';
          break;
        case 'cloudServiceUseType':
          {
            const cloudServiceUseType = e.target.value as CloudServiceUseType;

            step2FormData[key] = cloudServiceUseType;
          }

          break;
        case 'phoneNumber':
          step2FormData[key] = String(onlyNumber(e.target.value));
          break;
        default:
          step2FormData[key] = e.target.value;
          break;
      }
    });
  };

  return {
    step2FormData,
    setStep2FormData,
  };
};

export default useFormDetail;
