import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import CompanyRegistrationVm from './CompanyRegistrationVm';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import clsx from 'clsx';
import st from './CompanyRegistration.module.scss';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import FileUpload from '@commonComponents/FileUpload/FileUpload';

let vm;

function CompanyRegistration() {
  useComponentWillMount(() => {
    vm = new CompanyRegistrationVm();
  });

  useEffect(() => {
    setTimeout(() => {
      vm.state.action = true;
    }, 700);
  }, []);

  return useObserver(() => (
    <>
      <div className={clsx(st.joinBackground, vm.state.action ? st.action : null)}>
        <div className={st.firecracker}>
          <img src="/images/firecracker/1.svg" />
          <img src="/images/firecracker/2.svg" />
          <img src="/images/firecracker/3.svg" />
          <img src="/images/firecracker/4.svg" />
          <img src="/images/firecracker/5.svg" />
          <img src="/images/firecracker/6.svg" />
          <img src="/images/firecracker/7.svg" />
          <img src="/images/firecracker/8.svg" />
        </div>
        <div className={st.square}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className={st.companyRegistraion}>
        <img src="/images/companyRegistration_info.svg" />
        <form onSubmit={(e) => vm.submit(e)} autoComplete="off" className={st.form}>
          <div className={st.row}>
            <div className={st.title}>
              <span className={st.text}>한글 회사 이름을 입력하세요.</span>
            </div>
            <div className={st.content}>
              {vm.state.company.companyName ? (
                <button type="button" className={st.companyInfoBox} onClick={() => vm.openCompanyNameSearch()}>
                  <div className={st.companyInfo}>
                    <div className={st.companyTitle}>{vm.state.company.companyName}</div>
                    <div className={st.companyAddress}>{vm.state.company.address}</div>
                  </div>
                </button>
              ) : (
                <InputText
                  name="company.companyName"
                  value={vm.state.company.companyName}
                  onClick={() => vm.openCompanyNameSearch()}
                  fullSize
                  errorText={
                    <Error
                      name="company.companyName"
                      value={vm.state.company.companyName}
                      errorCase={{
                        required: '한글 회사 이름을 입력해주세요.',
                      }}
                    />
                  }
                />
              )}
              <input type="hidden" name="company.companyName" value={vm.state.company.companyName} />
              <input type="hidden" name="company.companyType" value={vm.state.company.companyType} />
              <input type="hidden" name="company.address" value={vm.state.company.address} />
              <input type="hidden" name="company.office" value={vm.state.company.office} />
              <input type="hidden" name="company.registerNum" value={vm.state.company.registerNum} />
            </div>
          </div>
          <div className={st.row}>
            <div className={st.title}>
              <span className={st.text}>주주명부를 업로드하세요.</span>
              <button type="button" className={st.tooltipBtn} onClick={() => vm.shareholderListPopup()}>
                <span>주주명부 원스톱 관리의 장점</span>
              </button>
            </div>
            <div className={st.content}>
              <FileUpload name="stockholderFiles" color="gray" />
            </div>
          </div>
          <div className={st.row}>
            <div className={st.title}>
              <span className={st.text}>최신정관을 업로드하세요.</span>
              <button type="button" className={st.tooltipBtn} onClick={() => vm.aoaPopup()}>
                <span>정관 원스톱 관리의 장점</span>
              </button>
            </div>
            <div className={st.content}>
              <FileUpload name="aoaFiles" color="gray" />
            </div>
          </div>
          <div className={st.row}>
            <div className={st.title}>
              <span className={st.text}>사업자등록증을 업로드하세요.</span>
            </div>
            <div className={st.content}>
              <FileUpload
                name="businessRegistrationFile"
                multiple={false}
                color="gray"
                accept=".jpg, .jpeg, .png, .pdf"
              />
            </div>
          </div>
          <div className={st.btnArea}>
            <SquareBtn type="submit" className={st.squareBtn}>
              이메일&카톡으로 처리하는 주주총회 시작하기
            </SquareBtn>
            <div className={st.btnExplain}>곧바로 법인 등기부등본 1부를 무료로 발급해드려요!</div>
          </div>
        </form>
      </div>
    </>
  ));
}
export default CompanyRegistration;
