import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import RegisterVm from './RegisterVm';
import st from './Register.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import Guide from '@commonComponents/Guide/Guide';
import GuideList from '@commonComponents/GuideList/GuideList';

function Register() {
  useEffect(() => {
    RegisterVm.load();
  }, []);
  return useObserver(() => (
    <>
      {RegisterVm.companyCopyList.length !== 0 && (
        <>
          <PageTitle>
            <span className={st.pageTitle}>등기부</span>
          </PageTitle>
          <Guide title={<strong>등기부 가이드</strong>} localStorageKey="registerGuide" corpId={RegisterVm.corpId}>
            <GuideList
              items={RegisterVm.guides}
              fullLink="https://standby.kr/blog/tag/%EB%93%B1%EA%B8%B0%EB%B6%80%EB%93%B1%EB%B3%B8/"
            />
          </Guide>
          <ContentBox
            title="법인 등기부등본"
            className={st.contentBox}
            titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          >
            <div className={st.content}>
              <div className={st.companyCopyBox}>
                <img className={st.companyCopyImg} src={RegisterVm.companyCopyList[0].firstPageUrl} />
                <button
                  type="button"
                  onClick={() => RegisterVm.downLoadUrl(RegisterVm.companyCopyList[0].downloadUrl)}
                  className={st.companyCopyDownloadBtn}
                >
                  <span className={st.text}>
                    {RegisterVm.dateFormat(RegisterVm.companyCopyList[0].downloadDateTime)} 자 등기부 다운로드
                  </span>
                </button>
              </div>
              <div className={st.companyCopyDate}>
                {RegisterVm.dateFormat(RegisterVm.companyCopyList[0].downloadDateTime)} 발급
              </div>
            </div>
          </ContentBox>
          <ContentBox
            title="과거 발급한 등기부등본"
            className={st.contentBox}
            titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          >
            <div className={st.content}>
              <ul className={st.companyCopyList}>
                {RegisterVm.companyCopyList.map((companyCopy, index) => (
                  <li key={index}>
                    <div className={st.name}>{RegisterVm.dateFormat(companyCopy.downloadDateTime)} 발급 등기부</div>
                    <FileBtn className={st.downloadBtn} onClick={() => RegisterVm.downLoadUrl(companyCopy.downloadUrl)}>
                      다운로드
                    </FileBtn>
                  </li>
                ))}
              </ul>
            </div>
          </ContentBox>
        </>
      )}
    </>
  ));
}
export default Register;
