import { useObserver } from 'mobx-react';
import st from './SendPreview.module.scss';

function SendPreview() {
  return useObserver(() => (
    <div className={st.sendPreview}>
      <div className={st.popUptitle}>
        사업자등록증과 통장사본을 같이 발송할 때 화면이에요. 하나만 발송하면 한 가지만 표기되어요.
      </div>
      <div className={st.informationBaord}>
        <div className={st.title}>정보제공 화면</div>
        <img src="/images/informationBaord_example.png?1" alt="정보제공화면 예시" className={st.informationBoardImg} />
      </div>
      <div className={st.emailAndAlimTalk}>
        <div className={st.email}>
          <div className={st.title}>이메일 발송화면</div>
          <img src="/images/BR_Bankbook_email_example.svg?1" alt="이메일 발송화면 예시" className={st.img} />
        </div>
        <div className={st.alimTalk}>
          <div className={st.title}>휴대폰 번호 발송화면</div>
          <img src="/images/BR_Bankbook_alimTalk_example.svg?1" alt="알림톡 발송화면 예시" className={st.img} />
        </div>
      </div>
    </div>
  ));
}

export default SendPreview;
