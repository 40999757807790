import CancelRegModel from '@model/AgendaDashboard/CancelReg/CancelRegModel';
import formDataToJson from '@common/module/submit';
import CancelRegService from '@service/AgendaDashboard/CancelReg/CancelRegService';
import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

class CancelRegModalVm {
  get name() {
    return CancelRegModel.name;
  }
  get reason() {
    return CancelRegModel.reason;
  }
  get sendAlimtalkYn() {
    return CancelRegModel.sendAlimtalkYn;
  }
  changeName(e: React.ChangeEvent<HTMLInputElement>) {
    CancelRegModel.name = e.target.value;
  }
  changeReason(e: React.ChangeEvent<HTMLTextAreaElement>) {
    CancelRegModel.reason = e.target.value;
  }
  changeSendAlimtalkYn(e: React.ChangeEvent<HTMLInputElement>) {
    CancelRegModel.sendAlimtalkYn = e.target.checked;
  }
  submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      const param = formDataToJson(e.target).data as { name: string; reason: string; sendAlimtalkYn: boolean | string };
      CancelRegService.cancel(param, AgendaDashboardModel.selectedAgendaProgressId);
    }
  }
}
export default new CancelRegModalVm();
