import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import vm from './JoinBusinessRegistrationVm';
import st from './JoinBusinessRegistration.module.scss';
import clsx from 'clsx';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import FixBtn from '@standby/common-ui/components/atoms/Button/FixBtn/FixBtn';
import { isMobile } from 'react-device-detect';
import Loading from '@commonComponents/Loading/Loading';

function JoinBusinessRegistration() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;
  const viewport = document.querySelector('meta[name=viewport]');
  useEffect(() => {
    viewport.setAttribute('content', 'width=800');
    return () => {
      viewport.setAttribute('content', 'width=1280');
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      vm.state.action = true;
    }, 700);
    vm.load(token);
    vm.loadTerms();
  }, []);
  return useObserver(() => (
    <>
      <div className={clsx(st.joinBackground, vm.state.action ? st.action : null)}>
        <div className={st.firecracker}>
          <img src="/images/firecracker/1.svg" />
          <img src="/images/firecracker/2.svg" />
          <img src="/images/firecracker/3.svg" />
          <img src="/images/firecracker/4.svg" />
          <img src="/images/firecracker/5.svg" />
          <img src="/images/firecracker/6.svg" />
          <img src="/images/firecracker/7.svg" />
          <img src="/images/firecracker/8.svg" />
        </div>
        <div className={st.square}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className={st.join}>
        <img src="/images/joinTitle.svg" className={st.joinTitleImg} />
        <form className={st.joinForm} onSubmit={(e) => vm.submit(e, token)}>
          <div className={st.logo}>
            <img src="/images/logo_standby.svg?1" />
          </div>
          <div className={st.information}>
            <div className={st.inputRow}>
              <div className={st.inputLabel}>이메일</div>
              <div className={clsx(st.helpmeInfo, st.email)}>{vm.email}</div>
            </div>
            <div className={st.inputRow}>
              {vm.state.changeNameState ? (
                <>
                  <label className={st.inputLabel} htmlFor="name">
                    성함
                  </label>
                  <div className={st.inputs}>
                    <InputText
                      name="name"
                      style={{ width: '268px' }}
                      id="name"
                      value={vm.state.name}
                      onChange={(e) => vm.setName(e)}
                      errorText={
                        <Error
                          name="name"
                          value={vm.state.name}
                          errorCase={{
                            required: '성함을 입력하세요.',
                            pattern: {
                              value: regExp.onlyKorEng().test(vm.name),
                              message: '한글 또는 영문으로 입력하세요.',
                            },
                          }}
                        />
                      }
                    />
                    <FixBtn
                      color="white"
                      light
                      onClick={() => vm.changeName()}
                      className={st.fixBtn}
                      disabled={!vm.state.name}
                    >
                      확인
                    </FixBtn>
                  </div>
                </>
              ) : (
                <>
                  <input type="hidden" name="name" value={vm.name} />
                  <div className={st.inputLabel}>성함</div>
                  <div className={st.helpmeInfo}>{vm.name}</div>
                  <TextBtn onClick={() => vm.nameState()}>수정</TextBtn>
                </>
              )}
            </div>
            <div className={st.inputRow}>
              <input type="hidden" name="countryCode" value="82" />
              {vm.state.changePhoneNumberState ? (
                <>
                  <label className={st.inputLabel} htmlFor="phoneNumber">
                    전화번호
                  </label>
                  <div className={st.inputs}>
                    <InputText
                      name="phoneNumber"
                      style={{ width: '268px' }}
                      id="phoneNumber"
                      value={vm.state.phoneNumber}
                      onChange={(e) => vm.setPhoneNumber(e)}
                      errorText={
                        <Error
                          name="phoneNumber"
                          value={vm.state.phoneNumber}
                          errorCase={{
                            required: '전화번호를 입력하세요.',
                            pattern: {
                              value: regExp.phoneNumber().test(vm.state.phoneNumber),
                              message: '올바른 전화번호를 입력하세요.',
                            },
                          }}
                        />
                      }
                    />
                    <FixBtn
                      color="white"
                      light
                      onClick={() => vm.changephoneNumber()}
                      className={st.fixBtn}
                      disabled={!vm.state.phoneNumber}
                    >
                      확인
                    </FixBtn>
                  </div>
                </>
              ) : (
                <>
                  <input type="hidden" name="phoneNumber" value={vm.phoneNumber} />
                  <div className={st.inputLabel}>전화번호</div>
                  <div className={st.helpmeInfo}>{vm.phoneNumber}</div>
                  <TextBtn onClick={() => vm.phoneNumberState()}>수정</TextBtn>
                </>
              )}
            </div>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="password">
                비밀번호
              </label>
              <div className={st.inputs}>
                <div>
                  <InputText
                    name="password"
                    fullSize
                    type="password"
                    id="password"
                    value={vm.state.password}
                    onChange={(e) => vm.changePassword(e)}
                    errorText={
                      <Error
                        name="password"
                        value={vm.state.password}
                        errorCase={{
                          required: '비밀번호를 입력하세요.',
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                                vm.state.password,
                              ),
                            message: '올바른 비밀번호 형식으로 입력하세요',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <ul className={st.passwordEx}>
                  <li>8글자 이상 입력</li>
                  <li>영문, 숫자, 특수문자 각 1개 이상 사용</li>
                </ul>
              </div>
            </div>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="passwordCheck">
                비밀번호 확인
              </label>
              <div className={st.inputs}>
                <InputText
                  name="passwordCheck"
                  fullSize
                  type="password"
                  id="passwordCheck"
                  value={vm.state.passwordCheck}
                  onChange={(e) => vm.changePasswordCheck(e)}
                  errorText={
                    <Error
                      name="passwordCheck"
                      value={vm.state.passwordCheck}
                      errorCase={{
                        required: '비밀번호 확인을 입력하세요',
                        validate: {
                          func: vm.state.password === vm.state.passwordCheck,
                          message: '동일한 비밀번호를 입력하세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className={st.agree}>
            <CheckBox className={st.agreeAll} checked={vm.isAgreeAll} onChange={(e) => vm.agreeAll(e)} fontSize="15px">
              모두 동의합니다.
            </CheckBox>
            <div className={st.agreeInside}>
              <div className={st.agreeAllEx}>
                사업자등록 등을 위한 프로그램(스탠바이 헬프미) 회원가입 동의가 포함되어 있어요. 미동의시 무료 사업자등록
                서비스 이용이 어려워요.
              </div>
              {vm.state.terms.map((term, index) => (
                <div className={st.agreeRow} key={index}>
                  <CheckBox
                    checked={term.checked}
                    onChange={(e) => vm.agreeCheck(e, index)}
                    className={st.agreeCheckbox}
                  >
                    {term.required ? '[필수] ' : '[선택] '}
                    {term.title}
                  </CheckBox>
                  {term.content && (
                    <TextBtn
                      className={st.textBtn}
                      onClick={() =>
                        vm.openAgreeModal(
                          term.title,
                          <div
                            className={clsx(st.agreeContent, 'terms')}
                            dangerouslySetInnerHTML={{ __html: term.content }}
                          ></div>,
                        )
                      }
                    >
                      상세보기
                    </TextBtn>
                  )}
                </div>
              ))}
            </div>
            <div className={st.btnArea}>
              <SquareBtn className={st.joinBtn} disabled={!vm.isSavePossible} type="submit">
                동의하고 무료사업자등록 진행하기
              </SquareBtn>
            </div>
          </div>
        </form>
      </div>
      {vm.state.cancelYn === true && (
        <div className={st.cancelPopup}>
          <div className={st.popup}>
            <div className={st.title}>무료 사업자등록신청이 취소되었어요.</div>
            <div className={st.content}>혹시 다시 이용을 원하신다면, 헬프미에 요청해주세요!</div>
          </div>
        </div>
      )}
      {vm.state.loadingYn && <Loading />}
    </>
  ));
}
export default JoinBusinessRegistration;
