import { useObserver } from 'mobx-react';
import vm from './CapitalVm';
import { addCommas } from '@common/module/replaceNumber';
import st from './Capital.module.scss';

function Capital() {
  return useObserver(() => (
    <div className={st.capital}>
      <div className={st.title}>자본금</div>
      <div className={st.titleLabel}>
        <span>등기부상 자본금</span>
      </div>
      <div className={st.price_important}>
        <span className={st.price}>{addCommas(vm.dashboardData?.capital || '')}</span>
        <span className={st.won}>원</span>
      </div>
      <div className={st.price_sub}>
        {addCommas(vm.dashboardData?.stocksNumber || '')}주 X 액면금 {addCommas(vm.dashboardData?.parValue || '')}원
      </div>
      {window.parseInt(vm.dashboardData?.capital || '') < 1000000000 && (
        <div className={st.info}>
          <span>자본금 10억 원 미만이어서, </span>
          <a
            href="https://standby.kr/blog/article/%EC%9E%90%EB%B3%B8%EA%B8%8810%EC%96%B5%EB%AF%B8%EB%A7%8C%EB%B2%95%EC%9D%B8%ED%98%9C%ED%83%9D/"
            target="_blank"
          >
            절차 간소화 혜택
          </a>
          <span>이 있어요</span>.
        </div>
      )}
    </div>
  ));
}
export default Capital;
