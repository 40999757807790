import { useObserver } from 'mobx-react';
import React from 'react';
import st from '../../Meetings/Meetings.module.scss';
import { I주주총회_개최_대표결정 } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import clsx from 'clsx';

interface C주주총회_개최_대표결정Props {
  meetingSchedule: I주주총회_개최_대표결정;
  meetingScheduleIndex: number;
  opener: 'final' | 'agendaDashboard';
}

const C주주총회_개최_대표결정 = React.memo(function (props: C주주총회_개최_대표결정Props) {
  return useObserver(() => (
    <div className={st.meeting}>
      <div className={st.sideTitle}>
        {props.meetingScheduleIndex + 1}. {props.meetingSchedule.title}
      </div>
      <div className={st.meetingInfo}>
        <div className={st.detailTitle}>{props.meetingSchedule.detailTitle}</div>
        <ul className={st.detailList}>
          <li>
            <div className={clsx(st.title, st.agenda)}>안건</div>
            <div className={st.content}>
              <ul className={st.agendaList}>
                {props.meetingSchedule.agendas.map((agenda, index) => (
                  <li key={index}>
                    {index + 1}. {agenda}
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li>
            <div className={clsx(st.title, st.date)}>일시</div>
            <div className={st.content}>{props.meetingSchedule.decisionAt}</div>
          </li>
        </ul>
      </div>
    </div>
  ));
});

export default C주주총회_개최_대표결정;
