import BesinessRegistrationEmailSignModalModel from '@src/model/Corp/BusinessRegistration/BesinessRegistrationEmailSignModal/BesinessRegistrationEmailSignModalModel';
import { FileExtensionType } from '@src/model/Corp/BusinessRegistration/BesinessRegistrationEmailSignModal/BesinessRegistrationEmailSignModalModel.interface';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import { FileCustomDownloadRq } from '@service/Corp/BusinessRegistration/BusinessRegistrationService.interface';
import formDataToJson from '@common/module/submit';
import loadingModel from '@model/loadingModel';
import { runInAction } from 'mobx';

class BesinessRegistrationEmailSignModalVm {
  load() {
    businessRegistrationService.loadBesinessRegistrationEmailSignModal();
  }
  get email() {
    return BesinessRegistrationEmailSignModalModel.email;
  }
  get fileExtensionType() {
    return BesinessRegistrationEmailSignModalModel.fileExtensionType;
  }
  get content() {
    return BesinessRegistrationEmailSignModalModel.content;
  }
  setEmail(e: React.ChangeEvent<HTMLInputElement>) {
    BesinessRegistrationEmailSignModalModel.email = e.target.value;
  }
  setContent(e: React.ChangeEvent<HTMLTextAreaElement>) {
    BesinessRegistrationEmailSignModalModel.content = e.target.value;
  }
  setFileExtensionType(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value as FileExtensionType;
    BesinessRegistrationEmailSignModalModel.fileExtensionType = value;
  }
  submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      const param = formDataToJson(e.target).data as FileCustomDownloadRq;
      runInAction(async () => {
        try {
          loadingModel.showYn = true;
          await businessRegistrationService.fileCustomDownload(param);
          loadingModel.showYn = false;
        } catch (err) {
          loadingModel.showYn = false;
        }
      });
    }
  }
}
export default new BesinessRegistrationEmailSignModalVm();
