import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import Cst from './Registration.module.scss';
import clsx from 'clsx';
import vm from './RegistrationVm';

function Registration() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>등기방법</div>
      <div className={clsx(st.content, Cst.content)}>
        <div className={Cst.flexBox}>
          <div className={Cst.item}>
            {vm.jointCertificateUseYn !== null && (
              <div className={st.circleItem}>
                <div className={st.circleTitle}>공동인증서</div>
                <div className={st.circleContent}>{vm.jointCertificateUseYn ? '모두 가능' : '불가'}</div>
              </div>
            )}
            <div className={st.circleItem}>
              <div className={st.circleTitle}>신청법인 전자증명서</div>
              {vm.company.date && vm.company.status === '기보유' && (
                <div className={st.circleContent}>
                  <span className={Cst.subText}>[{vm.company.date} 발급]</span> 사용가능
                </div>
              )}
              {vm.company.date &&
                (vm.company.status === '없음_신규신청_가능' || vm.company.status === '없음_신규신청_불가') && (
                  <div className={st.circleContent}>
                    <span className={Cst.subText}>[{vm.company.date} 발급]</span> 분실
                  </div>
                )}
              {vm.company.date && vm.company.status === 'NONE' && (
                <div className={st.circleContent}>
                  <span className={Cst.subText}>[{vm.company.date} 발급]</span> 소지 여부 잘 모름
                </div>
              )}
              {!vm.company.date && vm.company.status === '기보유' && (
                <div className={st.circleContent}>
                  <span className={Cst.subText}>[발급이력 미확인]</span> 기보유
                </div>
              )}
              {!vm.company.date && vm.company.status === '없음_신규신청_가능' && (
                <div className={st.circleContent}>없음 (신규신청 가능)</div>
              )}
              {!vm.company.date && vm.company.status === '없음_신규신청_불가' && (
                <div className={st.circleContent}>없음 (신규신청 불가)</div>
              )}
              {!vm.company.date && vm.company.status === 'NONE' && <div className={st.circleContent}>잘 모름</div>}
            </div>
          </div>
          {vm.shareholders.length !== 0 && (
            <div className={Cst.item}>
              <div className={st.circleItem}>
                <div className={st.circleTitle}>법인주주 전자증명서</div>
                <ul className={Cst.shareholders}>
                  {vm.shareholders.map((shareholder, index) => (
                    <li key={index}>
                      <div className={Cst.number}>{index + 1}</div>
                      <div className={Cst.name}>{shareholder.name}</div>
                      {shareholder.status === '기보유' && <div className={Cst.state}>기보유</div>}
                      {shareholder.status === '없음_신규신청_가능' && (
                        <div className={Cst.state}>없음 (신규신청 가능)</div>
                      )}
                      {shareholder.status === '없음_신규신청_불가' && (
                        <div className={Cst.warn}>없음 (신규신청 불가)</div>
                      )}
                      {shareholder.status === 'NONE' && <div className={Cst.state}>잘 모름</div>}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ));
}
export default Registration;
