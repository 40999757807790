import formDataToJson from '@common/module/submit';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import ReceiptPopup from './ReceiptPopup/ReceiptPopup';
import portalModel from '@model/portalModel';
import ReactGA from 'react-ga4';
import corpModel from '@model/Corp/corpModel';

class FirstPageVm {
  get pageType() {
    return bookKeepingContractModel.pageType;
  }
  get acctFirmCode() {
    return corpModel.acctFirmCode;
  }
  async apply() {
    await bookKeepingContractService.apply();
    //마케팅 구글에널리틱스 관련 코드
    ReactGA.gtag('event', 'request_accounting_book_keeping_contract');
    runInAction(() => {
      portalModel.content = <ReceiptPopup />;
    });
  }
  get freeMonth() {
    return bookKeepingContractModel.freeMonth;
  }
}

export default new FirstPageVm();
