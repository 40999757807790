import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect } from 'react';
import vm from './RepresentativeDecisionVm';
import st from './RepresentativeDecision.module.scss';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface Props {
  type: '서면결의서' | '대표결정서';
}

function RepresentativeDecision(props: Props) {
  let location = useLocation();
  const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;
  const companyId = queryString.parse(location.search).corp as string;

  useEffect(() => {
    vm.load(companyId, agendaProgressId, props.type);
  }, []);

  return useObserver(() =>
    vm.executives.length !== 0 ? (
      <form className={st.modalContent} onSubmit={(e) => vm.submit(e, companyId, agendaProgressId, props.type)}>
        <div className={st.question}>
          <div className={st.title}>서류 아래에 기재할 대표님을 선택하세요.</div>
          <div className={st.content}>
            {vm.executives.map((executive, index) => (
              <RadioLabel
                key={index}
                name="id"
                value={executive.id}
                checked={executive.selectedYn}
                onChange={(e) => vm.selectedYn(e)}
                className={st.radio}
              >
                {executive.name}
              </RadioLabel>
            ))}
            <div>
              <Error
                name="id"
                value={vm.executives.find((executive) => executive.selectedYn)?.id}
                errorCase={{
                  required: '대표님을 선택하세요.',
                }}
              />
            </div>
          </div>
        </div>
        <div className={st.btnArea}>
          <SquareBtn type="submit" className={st.contentSubmitBtn}>
            선택 완료
          </SquareBtn>
        </div>
      </form>
    ) : (
      <div className={st.modalContent}></div>
    ),
  );
}
export default RepresentativeDecision;
