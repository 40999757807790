import guestModel from '@model/Guest/guestModel';
import guestService from '@service/Guest/guestService';
import { observable } from 'mobx';
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';

import type { G사업자등록 } from '@model/Guest/guestModel.interface';
import type { AuthBusinessRegistrationRs } from '@service/Guest/guestService.interface';

interface State {
  contact: string;
}

class SecurityCheckVm {
  state: State = observable({
    contact: '',
  });
  async submit(e: React.FormEvent<HTMLFormElement>, token: string, changePage: () => void) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param: {
        email?: string;
        phoneNumber?: string;
        contact?: string;
      } = formDataToJson(e.target).data;

      if (param.email) {
        param.contact = param.email;
        delete param.email;
      }
      if (param.phoneNumber) {
        param.contact = param.phoneNumber;
        delete param.phoneNumber;
      }
      try {
        await guestService.auth<AuthBusinessRegistrationRs>(token, param);
        changePage();
      } catch (err) {}
    }
  }
  async load(token: string) {
    await guestService.load(token);
  }
  get status() {
    return guestModel.status;
  }
  get companyName() {
    return guestModel.companyName;
  }
  get documentType() {
    const guestInfo = guestModel.guestInfo as G사업자등록;
    const documentType = guestInfo.documentType;
    if (documentType === 'BR') {
      return '사업자등록증';
    } else if (documentType === 'BANKBOOK') {
      return '통장사본';
    } else if (documentType === 'BR__BANKBOOK') {
      return '사업자등록증 & 통장사본';
    }
    return;
  }
  get shareType() {
    const guestInfo = guestModel.guestInfo as G사업자등록;
    return guestInfo.shareType;
  }
  changeContact(e: React.ChangeEvent<HTMLInputElement>) {
    this.state.contact = regExp.deleteSpace(e.target.value);
  }
}

export default new SecurityCheckVm();
