import { observer } from 'mobx-react';

import Form from './components/Form';
import st from './style.module.scss';
import useStep2 from './useStep2';

const Step2 = observer(() => {
  const { formDetailBundle, companyName, termBundle, formBundle } = useStep2();

  return (
    <>
      <div className={st.mainTitle}>
        <span className={st.bold}>클라우드 지원금 270만 원</span> 무상제공을 위한 간단{' '}
        <span className={st.bold}>정보입력</span>
      </div>
      <div className={st.mainContent}>
        <div className={st.registraionRow}>
          <div className={st.registrationTitle}>
            <span className={st.bold}>지원금</span>은<br />
            이렇게 <span className={st.bold}>제공</span>돼요.
          </div>
          <div className={st.registrationContent}>
            <img src="/images/awsCredit_progress.png?1" />
            <div className={st.checkInfo}>
              메가존 클라우드는 AWS 파트너사에요. 메가존 클라우드와 계약을 체결하더라도{' '}
              <span className={st.bold}>추가비용이 없어요.</span>
            </div>
          </div>
        </div>
        <div className={st.registraionRow}>
          <div className={st.registrationTitle}>
            <span className={st.bold}>정보입력</span>
          </div>
          <div className={st.registrationContent}>
            <Form
              formDetailBundle={formDetailBundle}
              termBundle={termBundle}
              companyName={companyName}
              formBundle={formBundle}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default Step2;
