import { addCommas } from '@common/module/replaceNumber';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import clsx from 'clsx';

import type { OrderSummaryBundle } from '../../../../useStep2';
import st from '../../style.module.scss';

type Props = OrderSummaryBundle & { isSubmissionPending: boolean };

function OrderSummary(props: Props) {
  return (
    <div className={st.estimate}>
      <div className={clsx(st.detail, st.box)}>
        <div className={st.selected}>
          <div className={st.title}>선택상품</div>
          <ul className={st.selectedList}>
            <li>
              <div className={st.title}>인터넷</div>
              <div className={st.content}>{props.selectedInternetName}</div>
            </li>
            <li>
              <div className={st.title}>전화</div>
              <div className={st.content}>{props.selectedTelephoneName}</div>
            </li>
            <li>
              <div className={st.title}>TV</div>
              <div className={st.content}>{props.selectedTvName}</div>
            </li>
          </ul>
        </div>
        <div className={st.price}>
          <div className={st.priceRow}>
            <div className={st.title}>
              예상 월 요금 <span className={st.sub}>(vat별도)</span>
            </div>
            <div className={st.price}>
              {addCommas(props.orderSummary.baseFee - props.orderSummary.bundleDiscount)} 원
              <span className={props.orderSummary.baseFee - props.orderSummary.bundleDiscount ? '' : st.moreHide}>
                {' '}
                ~
              </span>
            </div>
          </div>
          <div className={st.detailPrice}>
            기본요금({addCommas(props.orderSummary.baseFee)}원{props.orderSummary.baseFee ? ' ~' : ''}) - 결합할인(
            {addCommas(props.orderSummary.bundleDiscount)}원)
          </div>
          <div className={st.priceRow}>
            <div className={st.title}>예상 캐시백</div>
            <div className={st.price} style={{ marginRight: '14px' }}>
              {addCommas(props.orderSummary.cashback)} 원
            </div>
          </div>
        </div>
        <SquareBtn type="submit" className={st.submit} disabled={props.isSubmissionPending}>
          상담 신청하기
        </SquareBtn>
      </div>
      {props.orderSummary.bundleDiscount !== 0 && <div className={st.cashback}>결합 할인&캐시백을 받을 수 있어요!</div>}
    </div>
  );
}

export default OrderSummary;
