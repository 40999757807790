import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import vm from './AoaDetailModalVm';
import st from './AoaDetailModal.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';

function AoaDetailModal() {
  let location = useLocation();
  const cssn = queryString.parse(location.search).cssn as string;

  useEffect(() => {
    vm.load(cssn);
  }, []);
  return useObserver(() => (
    <div className={st.aoaDetail}>
      <div className={st.aoaDetailBoxTitle}>{vm.companyName} 정관내용</div>
      {vm.articlesOfAssociations.map((articlesOfAssociation, idx) => (
        <Table key={idx} className={st.table}>
          <thead>
            <tr>
              <th colSpan={2}>{articlesOfAssociation.category}</th>
            </tr>
          </thead>
          <tbody>
            {articlesOfAssociation.questions.map((question, idx2) => (
              <tr key={idx2}>
                <td style={{ fontWeight: '500' }}>
                  {idx2 + 1}. {question.title}
                </td>
                <td
                  style={{ textAlign: 'left', lineHeight: '120%' }}
                  dangerouslySetInnerHTML={{ __html: question.content }}
                ></td>
              </tr>
            ))}
          </tbody>
        </Table>
      ))}
    </div>
  ));
}
export default AoaDetailModal;
