import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import { _alert, _confirm } from '@model/dialogModel';
import corpModel from '@model/Corp/corpModel';

class AccountInformationVm {
  get statusType() {
    return businessRegistrationModel.businessRegistrationStatuses[
      businessRegistrationModel.businessRegistrationStatuses.length - 1
    ]?.status;
  }
  get corpId() {
    return corpModel.id;
  }
  downloadRegistraionFile() {
    const fileLink = businessRegistrationModel.businessRegistrationFile;
    businessRegistrationService.fileDownload(fileLink);
  }
  get corpType() {
    return corpModel.company.corpType;
  }
  get text() {
    const corpType = corpModel.company.corpType;
    switch (corpType) {
      case '주식회사':
        return '주주';
      case '유한회사':
      case '유한책임회사':
        return '사원';
      default:
        return '주주';
    }
  }
  async downloadRosterList() {
    await businessRegistrationService.rosterList();
    const rosterLink = businessRegistrationModel.rosterListDownloadLink;
    await businessRegistrationService.fileDownload(rosterLink);
  }
}

export default new AccountInformationVm();
