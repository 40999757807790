import corpModel from '@model/Corp/corpModel';
import formDataToJson from '@src/common/module/submit';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import InternetAssociateService from '../../service/InternetAssociateService';
import type { Step2SubmitRq } from '../../service/InternetAssociateService.interface';
import type { ConsultInfoInput, SelectedProducts, SelectedProductsDetail, TermInfo } from '../types';
import { convertSelectedProductsToRequestParam } from '../util';

export interface FormBundle {
  isSubmissionPending: boolean;
  // 필수 요건 불충분 상태로 상담 신청하기 클릭시 true
  submissionRejected: boolean;
  onSubmitForm(e: React.FormEvent<HTMLFormElement>): Promise<void>;
}

interface FormProps {
  selectedProducts: SelectedProducts;
  selectedProductsDetail: SelectedProductsDetail;
  termInfos: TermInfo[];
  consultInfoInput: ConsultInfoInput;
  isRequiredTermsAgreed: boolean;
}

const useForm = (props: FormProps): FormBundle => {
  const { selectedProducts, selectedProductsDetail, termInfos, consultInfoInput, isRequiredTermsAgreed } = props;

  const [submissionRejected, setSubmissionRejected] = useState<boolean>(false);

  const submitFormMutation = useMutation<unknown, Error, Step2SubmitRq>({
    mutationFn: (param) => InternetAssociateService.step2Submit(param),
    onSuccess() {
      //step3 결과 페이지로 리다이렉팅
      window.location.href = `/corp/internetAssociate/result?corp=${corpModel.id}`;
    },
  });

  // 상담 신청하기
  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formDataToJson(e.target).error || !isRequiredTermsAgreed) {
      setSubmissionRejected(true);
      window.scrollTo(0, document.body.scrollHeight);

      return;
    }

    const products = convertSelectedProductsToRequestParam(selectedProductsDetail, selectedProducts);
    const terms = termInfos.filter((termInfo) => termInfo.agreeYn).map((termInfo) => termInfo.id);

    submitFormMutation.mutate({ products, consultInfo: consultInfoInput, terms });
  };

  return {
    isSubmissionPending: submitFormMutation.isPending,
    submissionRejected,
    onSubmitForm,
  };
};

export default useForm;
