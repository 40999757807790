import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './FirstPageVm';
import st from './FirstPage.module.scss';
import clsx from 'clsx';

function FirstPage() {
  return useObserver(() => (
    <div className={st.firstPage}>
      <div className={st.exampleImgBox}>
        {vm.pageType === 'INIT_HELPME' && vm.acctFirmCode === 'JINPYUNG' && (
          <img src="/images/bookKeeping_helpme.png" alt="수임등록 설명 이미지" />
        )}
        {vm.pageType === 'INIT_OTHER' && vm.acctFirmCode === 'JINPYUNG' && (
          <img src="/images/book_keeping_other.png" alt="수임등록 설명 이미지" />
        )}
        {vm.pageType === 'INIT_HELPME' && vm.acctFirmCode === 'BARUN' && (
          <img src="/images/bookKeeping_helpme_barun.png" alt="수임등록 설명 이미지" />
        )}
        {vm.pageType === 'INIT_OTHER' && vm.acctFirmCode === 'BARUN' && (
          <img src="/images/book_keeping_other_barun.png" alt="수임등록 설명 이미지" />
        )}
        {vm.pageType === 'INIT_HELPME' && (
          <>
            <div className={st.serviceInfoBox}>
              <div className={st.info}>
                <div className={st.serviceBox}>
                  <div className={clsx(st.title, st.calculator)}>법인기장이란?</div>
                  <div className={st.content}>
                    입출금, 매출 등 <span className={st.bold}>모든 내역</span>을<br />
                    <span className={st.bold}>복식부기</span> 방식으로
                    <br />
                    기록하는 것!
                  </div>
                </div>
                <div className={st.serviceBox}>
                  <div className={clsx(st.title, st.document)}>법인기장 필요성?</div>
                  <div className={st.content}>
                    법인은 복식부기{' '}
                    <span className={st.bold}>
                      위반시 <br />
                      세금폭탄
                    </span>{' '}
                    발생
                  </div>
                </div>
                <div className={st.serviceBox}>
                  <div className={clsx(st.title, st.handshake)}>효율적 해결법?</div>
                  <div className={st.content}>
                    성장에 집중하고
                    <br />
                    <span className={st.bold}>관리는 회계 사무소</span>에<br />
                    맡기는 것이 훨씬 <span className={st.bold}>효율적!</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={st.helpmeInfoBox}>
              <div className={st.info}>
                <div className={st.helpmeBox}>
                  <div className={clsx(st.title, st.helpme)}>헬프미 설립 Only</div>
                  <div className={st.content}>
                    헬프미 고객을 위한
                    <br />
                    특별한 혜택!
                  </div>
                </div>
                <div className={st.helpmeBox}>
                  <div className={clsx(st.title, st.emptyCalendar)}>
                    <span className={st.freeMonth}>{vm.freeMonth}</span>
                    {vm.freeMonth}개월 무료
                  </div>
                  <div className={st.content}>
                    {vm.freeMonth}개월동안 무료로
                    <br />
                    이용 가능!
                  </div>
                </div>
                <div className={st.helpmeBox}>
                  <div className={clsx(st.title, st.calendar)}>약정 없이 이용 가능</div>
                  <div className={st.content}>
                    필수이용 약정기간 없는
                    <br />
                    순수한 혜택!
                  </div>
                </div>
              </div>
              <div className={st.explain}>
                * 무료기장서비스는 헬프미 고객을 위한 특별한 혜택이기 때문에,{' '}
                <span className={st.bold}>법인설립일로부터 10일간만</span> 신청이 가능해요. 양해부탁드려요.
              </div>
            </div>
          </>
        )}
        {(vm.pageType === 'INIT_HELPME' || vm.pageType === 'INIT_OTHER') && vm.acctFirmCode === 'JINPYUNG' && (
          <div className={st.jinpyeongInfoBox}>
            <div className={st.advertisementBox}>
              <div className={st.title}>진평회계법인 소개</div>
              <div className={st.advertisement}>
                <img className={st.img} src="/images/진평_example.png" />
                <div className={st.jinpyeong}>
                  <div className={st.jinpyeongTitle}>
                    서울대 경영대 출신의 회계사들이 만든 <span className={st.bold}>스타트업 전문 회계법인</span>
                  </div>
                  <div className={st.jinpyeongInfo}>
                    <span className={st.boldColor}>서울대 경영대</span> 출신 경력 14년 회계사가 직접 관리
                  </div>
                  <div className={st.jinpyeongInfo}>
                    절세를 고려한 <span className={st.boldColor}>최적의 비용처리 및 세액감면/공제 적용</span>
                  </div>
                  <div className={st.jinpyeongInfo}>
                    <span className={st.boldColor}>투자유치, 상장</span>을 대비한 지분구조 및 회계처리
                  </div>
                  <div className={st.jinpyeongLink}>
                    <img src="/images/logo_진평회계법인.svg" className={st.jinpyeongLogoImg} />
                    <a className={st.aLink} href="https://jinpyung.com" target="_blank" style={{ marginTop: '4px' }}>
                      진평회계법인 소개
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={st.serviceBox}>
              <div className={st.title}>서비스 소개</div>
              <div className={st.service}>
                <div className={st.serviceInfo}>
                  <div className={st.serviceTitle}>4대보험 및 급여 관련</div>
                  <ul className={st.serviceList}>
                    <li>사업장 및 근로자 4대보험 가입</li>
                    <li>급여 계산, 급여대장 작성 및 급여명세서 교부</li>
                    <li>퇴직시 퇴직금 계산 및 정산/4대 보험 상실 처리</li>
                  </ul>
                </div>
                <div className={st.serviceInfo}>
                  <div className={st.serviceTitle}>결산 및 재무제표 작성</div>
                  <ul className={st.serviceList}>
                    <li>복식부기에 의한 회계처리</li>
                    <li>분기별/반기별/연도별 결산 후 손익계산서/재무상태표/이익잉여금처분계산서 등 작성</li>
                  </ul>
                </div>
                <div className={st.serviceInfo}>
                  <div className={st.serviceTitle}>원천세/부가세/법인세 신고 및 납부</div>
                  <ul className={st.serviceList}>
                    <li>매월 급여 관련 원천세 신고 및 납부</li>
                    <li>분기별 부가세 신고 및 납부</li>
                    <li>연도별 법인세 신고 및 납부</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <button type="button" className={st.subApplyBtn} onClick={() => vm.apply()}>
        기장 서비스 신청
      </button>
      <button type="button" className={st.mainApplyBtn} onClick={() => vm.apply()}>
        <div className={st.btnContent}>기장 서비스 상담 신청</div>
        <div className={st.explain}>오른쪽 아래 채팅상담으로 바로 문의해도 돼요!</div>
      </button>
    </div>
  ));
}
export default FirstPage;
