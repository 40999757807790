import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import userSettingModel from '@model/userSettingModel';
import authService from '@service/authService';
import { _alert, _confirm } from '@model/dialogModel';
import userModel from '@model/userModel';

class userSettingService {
  async load() {
    const res = await get('/my-account');
    userSettingModel.setUserSetData(res.data);
  }
  async changePassword(param) {
    await post('/my-account/change-password', {
      password: param.password,
      newPassword: param.newPassword,
    });
  }
  async sendChangeEmail() {
    await post('/my-account/change-email');
  }
  async changeEmail(param, token) {
    await post('/my-account/change-email/authorization', param, {
      'Change-Email-Authorization': `Bearer ${token}`,
    });
  }
  async changeName(param) {
    await post('/my-account/change-name', {
      name: param.name,
    });
    userModel.user.name = param.name;
  }
  async changePhoneNumber(param) {
    await post('/my-account/change-phone-number', {
      countryCode: param.countryCode,
      phoneNumber: param.phoneNumber,
    });
  }
  async changeMarketing(marketingAcceptYn) {
    await post('/my-account/change-marketing', {
      marketingTermId: userSettingModel.userSetData.marketingTermId,
      marketingAcceptYn: marketingAcceptYn,
    });
    this.load();
  }
  async changeMarketingDetail() {
    await post('/my-account/change-marketing-detail', {
      marketingTermId: userSettingModel.userSetData.marketingTermId,
      marketingAcceptEmailYn: userSettingModel.userSetData.marketingAcceptEmailYn,
      marketingAcceptSmsYn: userSettingModel.userSetData.marketingAcceptSmsYn,
    });
    this.load();
  }
}
export default new userSettingService();
