import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';

class ShareholderListVm {
  get shareholderListFile() {
    return AgendaDashboardModel.files.shareholderList;
  }
  fileDown(downloadUrl: string) {
    AgendaDashboardService.fileDown(downloadUrl);
  }
  get shareholders() {
    return AgendaDashboardModel.agendaProgress.company.shareholders;
  }
  get shareholdersCount() {
    let count = 0;
    this.shareholders.forEach((shareholder) => {
      count += shareholder.count;
    });
    return count;
  }
  get shareholderListDate() {
    return AgendaDashboardModel.files.shareholderListDate;
  }
}
export default new ShareholderListVm();
