import beforeFinalService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService';
import loadingModel from '@model/loadingModel';

class AppplicationCheck {
  async submit(agendaProgressId: string) {
    try {
      loadingModel.showYn = true;
      await new Promise((resolve) => setTimeout(resolve, 300)); //로딩 애니메이션 딜레이
      await beforeFinalService.submit(agendaProgressId);
      loadingModel.showYn = false;
    } catch (err) {
      loadingModel.showYn = false;
    }
  }
}

export default new AppplicationCheck();
