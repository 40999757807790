import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { useObserver } from 'mobx-react';

import type { FormBundle, FormDetailBundle, TermBundle } from '../../useStep2';
import FormDetail from '../FormDetail';
import Term from '../Term';
import st from './style.module.scss';

interface Props {
  formDetailBundle: FormDetailBundle;
  termBundle: TermBundle;
  companyName: string;
  formBundle: FormBundle;
}

function Form(props: Props) {
  return useObserver(() => (
    <form className={st.form} onSubmit={(e) => props.formBundle.onSubmitForm(e)}>
      <FormDetail {...props.formDetailBundle} companyName={props.companyName} />
      <Term {...props.termBundle} submissionRejected={props.formBundle.formState.submissionRejected} />
      <SquareBtn type="submit" className={st.submitBtn}>
        클라우드 지원금 신청하기{' '}
      </SquareBtn>
    </form>
  ));
}

export default Form;
