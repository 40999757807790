import { useObserver } from 'mobx-react';
import vm from './FileUploadVm';
import st from './FileUpload.module.scss';

function FileUpload() {
  return useObserver(() => (
    <div className={st.fileUpload}>
      <div className={st.title}>
        사업자등록을 이미 마치셨다면, <em>사업자등록증</em>을 <em>업로드</em>하세요!
      </div>
      <div className={st.sub}>사업자등록증을 버튼 하나로 거래처에 보낼 수 있어요.</div>
      <form className={st.file}>
        <label className={st.fileGuide} htmlFor="fileUpload">
          <div className={st.fileTitle}>파일 업로드</div>
          <div className={st.fileExplain}>고객님의 소중한 정보는 스탠바이가 철저하게 관리하고 있어요!</div>
        </label>
        <input
          type="file"
          id="fileUpload"
          style={{ width: 0, height: 0, position: 'absolute', display: 'none' }}
          accept=".jpg, .jpeg, .png, .pdf"
          onChange={(e) => vm.fileUpload(e)}
        />
      </form>
    </div>
  ));
}
export default FileUpload;
