import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './LoginVm';
import st from './LoginMobile.module.scss';
import LoginVm from './LoginVm';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import { Link } from 'react-router-dom';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

function LoginMobile(props) {
  let location = useLocation();
  const redirectToken = queryString.parse(location.search).redirectToken;
  const viewport = document.querySelector('meta[name=viewport]');
  useEffect(() => {
    viewport.setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=no');
    return () => {
      viewport.setAttribute('content', 'width=1280');
    };
  }, []);
  return useObserver(() => (
    <form onSubmit={(e) => LoginVm.submit(e, redirectToken)} className={st.loginContent}>
      <img src="/images/logo_standby_mobile.svg?1" className={st.logo} />
      <div className={st.mainTitle}>
        법인관리 <span className={st.bold}>스탠바이 헬프미</span>
      </div>
      <div className={st.emailBox}>
        <div className={st.title}>이메일</div>
        <InputText
          name="username"
          className={st.inputText}
          placeholder="example@logologo.com"
          value={LoginVm.email}
          onChange={LoginVm.setEmail}
          fullSize
        />
      </div>
      <div className={st.passwordBox}>
        <div className={st.title}>비밀번호</div>
        <InputText
          type="password"
          name="password"
          className={st.inputText}
          placeholder="Password"
          value={LoginVm.password}
          onChange={LoginVm.setPassword}
          fullSize
        />
      </div>
      <Check className={st.checkBox} name="autoLogin" value="true">
        자동 로그인
      </Check>
      <SquareBtn type="submit" className={st.squareBtn}>
        로그인
      </SquareBtn>
      <div className={st.likeBtnBox}>
        <Link to="/auth/findId" className={st.likeBtn}>
          아이디 찾기
        </Link>
        <Link to="/auth/findPassword" className={st.likeBtn}>
          비밀번호 찾기
        </Link>
        <Link to="/auth/joinMembership" className={st.likeBtn}>
          회원가입 하기
        </Link>
      </div>
    </form>
  ));
}
export default LoginMobile;
