import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import Cst from './Capital.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import vm from './CapitalVm';

function Capital() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>자본</div>
      <div className={clsx(st.content, Cst.content)}>
        <div className={Cst.changeItem}>
          <span className={Cst.prefix}>[등기 전]</span>
          <span className={Cst.item}>
            {addCommas(vm.capital)} <span className={st.won}>원</span>{' '}
          </span>
        </div>
        <div className={Cst.changeItem}>
          <span className={Cst.prefix}>[등기 후]</span>
          {vm.additionalCapital !== 0 && (
            <span className={Cst.item}>
              {addCommas(vm.additionalCapital + vm.capital)} <span className={st.won}>원</span>
              <CopyBtn copyText={vm.additionalCapital.toString()} className={Cst.plus}>
                +{addCommas(vm.additionalCapital)}
              </CopyBtn>
            </span>
          )}
          {vm.additionalCapital === 0 && <span className={Cst.item}>변동없음</span>}
          <span className={Cst.parValue}>(등기부상 액면금 {addCommas(vm.parValue)}원)</span>
        </div>
      </div>
    </div>
  ));
}
export default Capital;
