import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import MeetingOfShareholders from './MeetingOfShareholders/MeetingOfShareholders';
import BoardOfDirectors from './BoardOfDirectors/BoardOfDirectors';
import beforeFinalService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService';
import RepresentativeDecision from './RepresentativeDecision/RepresentativeDecision';
import loadingModel from '@model/loadingModel';

class AutoDocumentVm {
  text(title: string) {
    if (title === '주주총회 개최를 정하는 이사회') {
      return '이사';
    } else if (title.includes('주주')) {
      return '주주';
    } else if (title.includes('이사')) {
      return '이사';
    } else if (title.includes('대표')) {
      return '대표';
    }
  }
  btnText(downloadUrl: string, title: string) {
    if (downloadUrl) {
      if (title === '대표결정서' || title === '주주 전원의 서면결의서') {
        return '자동작성 완료 다운로드';
      } else {
        return '자동작성 의사록 다운로드';
      }
    } else {
      if (title === '대표결정서' || title === '주주 전원의 서면결의서') {
        return '도장찍을 대표 선택하러 가기';
      } else {
        return `참석 ${this.text(title)} 선택하러 가기`;
      }
    }
  }
  changeBtnText(title: string) {
    if (title === '대표결정서' || title === '주주 전원의 서면결의서') {
      return '도장찍을 대표 변경하기';
    } else {
      return `참석 ${this.text(title)} 변경하기`;
    }
  }
  openModal(title: string, id: string) {
    if (title === '주주 전원의 서면결의서') {
      runInAction(() => {
        portalModel.title = '서면결의서';
        portalModel.content = <RepresentativeDecision type="서면결의서" />;
      });
    } else if (this.text(title) === '주주') {
      runInAction(() => {
        portalModel.title = '주주총회 의사록';
        portalModel.content = <MeetingOfShareholders minutesId={id} />;
      });
    } else if (this.text(title) === '이사') {
      runInAction(() => {
        portalModel.title = '이사회 의사록';
        portalModel.content = <BoardOfDirectors minutesId={id} />;
      });
    } else if (this.text(title) === '대표') {
      runInAction(() => {
        portalModel.title = '대표결정서';
        portalModel.content = <RepresentativeDecision type="대표결정서" />;
      });
    }
  }
  async fileDownload(downloadUrl: string) {
    runInAction(async () => {
      try {
        loadingModel.showYn = true;
        //@todo httpRequest 속성값으로 수정하기 (로딩여부, 딜레이여부)
        await new Promise((resolve) => setTimeout(resolve, 300)); //서류자동작성 로딩 애니메이션 딜레이
        await beforeFinalService.fileDownload(downloadUrl);
        loadingModel.showYn = false;
      } catch (err) {
        loadingModel.showYn = false;
      }
    });
  }
}

export default new AutoDocumentVm();
