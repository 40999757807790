import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import Document from './Document/Document';

class PostMusterNoticeVm {
  openModal(agendaProgressId: string) {
    runInAction(() => {
      portalModel.title = '자동 작성된 소집통지문을 확인하세요.';
      portalModel.content = <Document agendaProgressId={agendaProgressId} />;
    });
  }
}

export default new PostMusterNoticeVm();
