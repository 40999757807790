import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import vm from './OnlineAcceptancePopupVm';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import { useEffect } from 'react';

interface OnlineAcceptancePopupProps {
  page?: string;
}

function OnlineAcceptancePopup(props: OnlineAcceptancePopupProps) {
  let location = useLocation();
  const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;

  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <>
      {vm.nowStep === '1' && <Step1 />}
      {vm.nowStep === '2' && <Step2 agendaProgressId={agendaProgressId} page={props.page} />}
    </>
  ));
}
export default OnlineAcceptancePopup;
