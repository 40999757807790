function email() {
  //이메일 validation
  return /^(.+)@(.+)$/;
}

function onlyKorEng() {
  //한글, 영문 입력
  return /^[가-힣a-zA-Z ]{0,}$/;
}

function onlyEnglish() {
  //영문
  return /^[a-zA-Z ]{0,}$/;
}

function phoneNumber() {
  //핸드폰 번호
  return /^[0]{0,1}[1][0][-]{0,1}[0-9]{3,4}[-]{0,1}[0-9]{4}$/;
}

function kssn(value: string) {
  //주민등록번호
  let data = value.replace('-', '');
  let 뒷자리에서첫번째숫자 = parseInt(data.charAt(6));
  let 생년월일 = parseInt(data.substr(0, 4));
  if (
    (뒷자리에서첫번째숫자 === 3 || 뒷자리에서첫번째숫자 === 4) &&
    생년월일 >= 2010 &&
    data.replace(/_/gi, '').length === 13
  ) {
    //2020년10월이후 출생자는 아무거나 입력해도 통과한다
    return true;
  }
  let kssn = [];
  let kssn_last = data.charAt(data.length - 1);
  let num = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
  for (let i = 0; i < data.length - 1; i++) {
    kssn[i] = parseInt(data.charAt(i)) * num[i];
  }
  let sum = 0;
  for (let i = 0; i < data.length - 1; i++) {
    sum += kssn[i];
  }
  sum = 11 - (sum % 11);
  if (sum > 9) {
    sum = sum % 10;
  }
  if (sum === Number(kssn_last)) return true;
  else return false;
}

function cssn() {
  //법인등록번호
  return /^\d{6}-\d{7}$/;
}

function ein() {
  //사업자등록번호
  return /^\d{3}-\d{2}-\d{5}$/;
}

function year() {
  //연도
  return /^(19|20)[0-9]{2}$/;
}

function month() {
  //월
  return /^[0]{0,1}[1-9]{1}$|^1[0-2]{1}$/;
}

function day() {
  //일(날짜)
  return /^[0]{0,1}[1-9]{1}$|^[1-2]{1}[0-9]{1}$|^3[0-1]{1}$/;
}

function deleteSpace(x: string) {
  return x.replace(/\s/g, '');
}

export default { email, onlyKorEng, onlyEnglish, phoneNumber, kssn, cssn, ein, year, month, day, deleteSpace };
