import { useObserver } from 'mobx-react';
import vm from './Step2Vm';
import Direct from './Direct/Direct';
import Document from './Document/Document';

interface Step2Props {
  agendaProgressId: string;
  page?: string;
}

function Step2(props: Step2Props) {
  return useObserver(() => (
    <>
      {vm.nowPage === 'Direct' && <Direct agendaProgressId={props.agendaProgressId} page={props.page} />}
      {vm.nowPage === 'Document' && <Document agendaProgressId={props.agendaProgressId} page={props.page} />}
    </>
  ));
}
export default Step2;
