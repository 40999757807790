import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';

function ShareholderListPopup() {
  return useObserver(() => (
    <>
      <img src="/images/shareholderList_popup.png" />
    </>
  ));
}

export default ShareholderListPopup;
