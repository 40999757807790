import { useObserver } from 'mobx-react';
import vm from './FinalApplicationVm';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './FinalApplication.module.scss';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface FinalApplicationProps {
  agendaProgressId: string;
  setDocumentSubmit: () => void;
}

function FinalApplication(props: FinalApplicationProps) {
  return useObserver(() => (
    <div className={bst.row}>
      <div className={clsx(bst.title, bst.upDate)}>종료처리</div>
      <div className={bst.content}>
        <div className={st.content}>
          <div className={st.title}>
            모든 절차를 잘 완료했다면, <span className={st.important}>종료 처리</span>를 해주세요!
          </div>
          <img src="/images/final_application.svg" className={st.img} />
          <div className={st.explain}>종료 처리 후에는 내용변경, 통지발송이 불가능해요.</div>
        </div>
        <div className={bst.btnArea}>
          {vm.registrationAgencyType === 'SELF' && (
            <SquareBtn
              className={bst.btn}
              onClick={() => vm.openModal('SELF', props.agendaProgressId, props.setDocumentSubmit)}
            >
              새로운 등기부 반영하기
            </SquareBtn>
          )}
          {vm.registrationAgencyType === 'NONE' && (
            <SquareBtn
              className={bst.btn}
              onClick={() => vm.openModal('불포함', props.agendaProgressId, props.setDocumentSubmit)}
            >
              종료 처리하기
            </SquareBtn>
          )}
        </div>
      </div>
    </div>
  ));
}
export default FinalApplication;
