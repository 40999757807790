import regExp from '@common/module/regExp';
import { Button, CheckBox, TextButton, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import st from './JoinMembership.module.scss';
import JoinMembershipVm from './JoinMembershipVm';

function JoinMembership() {
  useEffect(() => {
    JoinMembershipVm.loadTerms();
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => JoinMembershipVm.submit(e)} className={st.joinMembershipContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-24M">법인관리 </Typography>
        <Typography fontType="Title-24B">스탠바이</Typography>
      </div>
      <TextField
        label="이메일"
        name="email"
        className={st.emailInput}
        placeholder="example@logologo.com"
        value={JoinMembershipVm.email}
        onChange={JoinMembershipVm.setEmail}
        error={
          <ErrorValidation
            name="email"
            value={JoinMembershipVm.email}
            errorCase={{
              required: '이메일을 입력해 주세요',
              pattern: {
                value: regExp.email().test(JoinMembershipVm.email),
                message: '올바른 이메일 형식으로 입력해 주세요.',
              },
            }}
          />
        }
      />
      <div className={st.termsBox}>
        <CheckBox checked={JoinMembershipVm.agreeAll} onChange={(e) => JoinMembershipVm.setAgreeAll(e)}>
          이용약관에 모두 동의합니다.
        </CheckBox>
        <ul className={st.termsList}>
          {JoinMembershipVm.userRegistrationTerms.map((item, idx) => {
            return (
              <li key={idx}>
                <input type="hidden" name={`submittedTermRequests[${idx}].id`} value={item.id} />
                <CheckBox
                  name={`submittedTermRequests[${idx}].agreeYn`}
                  value={JoinMembershipVm.agreeYn(idx)}
                  checked={JoinMembershipVm.agreeYn(idx)}
                  onChange={(e) => JoinMembershipVm.setAgreeYn(e, idx)}
                  textClassName={st.checkbox}
                  size="small"
                >
                  <span className={st.notRequired}>{item.required === false && '[선택] '}</span>
                  {item.title}
                </CheckBox>
                {item.content && (
                  <TextButton
                    type="button"
                    underline
                    className={st.textBtn}
                    onClick={() =>
                      JoinMembershipVm.termsPopup(
                        item.title,
                        <div
                          className={clsx(st.agreeContent, 'terms')}
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>,
                      )
                    }
                  >
                    상세보기
                  </TextButton>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <Button
        type="submit"
        size="large"
        color="primary400"
        className={st.mainBtn}
        disabled={JoinMembershipVm.state.submitYn || !JoinMembershipVm.isSavePossible}
      >
        동의하고 시작하기
      </Button>
      <div className={st.likeBtnBox}>
        <Link to="/auth/login">
          <TextButton type="button" className={st.likeBtn} underline>
            로그인
          </TextButton>
        </Link>
      </div>
    </form>
  ));
}

export default JoinMembership;
