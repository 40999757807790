import guestModel from '@model/Guest/guestModel';
import guestService from '@service/Guest/guestService';
import { observable, runInAction } from 'mobx';
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';

import type { G주주총회_소집절차_생략동의 } from '@model/Guest/guestModel.interface';
import type { AuthShareholderMeetingWaiverConsentRs } from '@service/Guest/guestService.interface';

interface State {
  contact: string;
}

class SecurityCheckVm {
  state: State = observable({
    contact: '',
  });
  async submit(e: React.FormEvent<HTMLFormElement>, token: string, changePage: () => void) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        email?: string;
        phoneNumber?: string;
        contact: string;
      };

      if (param.email) {
        param.contact = param.email;
        delete param.email;
      }
      if (param.phoneNumber) {
        param.contact = param.phoneNumber;
        delete param.phoneNumber;
      }
      try {
        runInAction(() => {
          guestModel.status = null;
        });
        await guestService.auth<AuthShareholderMeetingWaiverConsentRs>(token, param);
        changePage();
      } catch (err) {}
    }
  }
  async load(token: string) {
    await guestService.load(token);
  }
  get status() {
    return guestModel.status;
  }
  get companyName() {
    return guestModel.companyName;
  }
  get shareType() {
    return this.guestInfo.shareType;
  }
  changeContact(e: React.ChangeEvent<HTMLInputElement>) {
    this.state.contact = regExp.deleteSpace(e.target.value);
  }
  get guestInfo() {
    return guestModel.guestInfo as G주주총회_소집절차_생략동의;
  }
  get shareholderName() {
    return this.guestInfo.shareholderName;
  }
  get decisionAt() {
    return this.guestInfo.decisionAt;
  }
  get regularYn() {
    return this.guestInfo.regularYn;
  }
  checkBtn() {
    window.close();
  }
}

export default new SecurityCheckVm();
