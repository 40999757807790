import awsCreditService from '@service/Corp/AwsCredit/awsCreditService';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function AwsCredit() {
  const location = useLocation();
  const id = queryString.parse(location.search).corp;
  const navigate = useNavigate();

  useEffect(() => {
    const loadStep = async () => {
      const step = await awsCreditService.loadStep();

      if (step === 'STEP1') {
        navigate(`/corp/awsCredit/promotion?corp=${id}`, { replace: true });
      } else if (step === 'STEP2') {
        navigate(`/corp/awsCredit/application?corp=${id}`, { replace: true });
      } else if (step === 'STEP3') {
        navigate(`/corp/awsCredit/result?corp=${id}`, { replace: true });
      }
    };

    if (location.pathname === '/corp/awsCredit') {
      //처음 진입 했을때만 실행함
      void loadStep();
    }
  }, [location.pathname, id, navigate]);

  return useObserver(() => (
    <>
      <Routes>
        <Route path="/promotion" element={<Step1 />} />
        <Route path="/application" element={<Step2 />} />
        <Route path="/result" element={<Step3 />} />
      </Routes>
    </>
  ));
}

export default AwsCredit;
