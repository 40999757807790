import { useObserver } from 'mobx-react';
import vm from './ValidationModalVm';
import st from './ValidationModal.module.scss';

interface ValidationModalProps {
  agendaProgressId: string;
}

function ValidationModal(props: ValidationModalProps) {
  return useObserver(() => (
    <div className={st.modalContent}>
      <div className={st.title}>앗! 아래 절차가 진행되지 않았어요. 그래도 최종 반영을 하나요?</div>
      <div className={st.content}>
        <ul>
          {vm.unValidatedList?.map((info, idx) => {
            return (
              <li key={idx}>
                {vm.displayType(info.type)}
                <span className={st.info}>
                  {info.name}님 외 {info.count}명 - <span className={st.status}> {vm.displayStatus(info.status)}</span>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={st.explain}>
        <span className={st.bold}>최종반영 후</span>에는 <span className={st.underline}>절차진행, 취소가 불가능</span>
        해요.
      </div>
      <div className={st.btnArea}>
        <button type="button" className={st.positiveBtn} onClick={() => vm.submit(props.agendaProgressId)}>
          네
        </button>
        <button type="button" className={st.negativeBtn} onClick={() => vm.modalClose()}>
          아니오
        </button>
      </div>
    </div>
  ));
}
export default ValidationModal;
