import { post, get, patch } from '@common/module/httpRequest';
import { pageRouter } from '@model/pageModel';
import corpModel from '@model/Corp/corpModel';
import onlineMusterModel from '@model/Corp/Stock/OnlineMuster/onlineMusterModel';

class onlineMusterService {
  async loadGuide() {
    const res = await get(`/administrationGuide/HOME`);
    const data = res.data;
    onlineMusterModel.setAdministrationGuides(data);
  }
  async loadShareholdersData() {
    const res = await get(`/stockholder/notice/onlineMuster/company/${corpModel.id}`);
    const data = res.data;
    onlineMusterModel.setShareholders(data);
  }
  async onlineMusterBeforehand(data) {
    const res = await post(`/stockholder/notice/onlineMusterConsent/company/${corpModel.id}`, data);
  }
  async onlineMusterDirect(data) {
    const res = await patch(`/stockholder/notice/onlineMuster/company/${corpModel.id}/status`, data);
  }
}

export default new onlineMusterService();
