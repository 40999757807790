import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import { pageRouter } from '@model/pageModel';
import userSettingService from '@service/userSettingService';
import { observable } from 'mobx';

class ChangeEmailVm {
  constructor() {
    this.state = observable({
      email: '',
    });
  }
  setEmail(e) {
    this.state.email = regExp.deleteSpace(e.target.value);
  }
  async submit(e, token) {
    e.preventDefault();

    if (formDataToJson(e.target).error) {
      return;
    }

    const param = formDataToJson(e.target).data;

    await userSettingService.changeEmail(param, token);
    await _alert('이메일이 변경되었습니다.');
    pageRouter.replace(`/auth/login`);
  }
}

export default new ChangeEmailVm();
