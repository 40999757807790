import authService from '@service/authService';
import { _alert } from '@src/model/dialogModel';
import { observable } from 'mobx';

class ViewModel {
  constructor() {
    this.state = observable({
      isSendingEmail: false,
    });
  }
  //회원가입 이메일 전송 관련(emailType: join)
  get joinMembershipData() {
    const joinMembership = JSON.parse(sessionStorage.getItem('joinMembership'));

    return joinMembership;
  }
  async sendJoinMembershipEmail() {
    try {
      this.state.isSendingEmail = true;
      await authService.requestJoinMembershipEmail(this.joinMembershipData);
      void _alert('이메일을 발송하였습니다.');
    } finally {
      this.state.isSendingEmail = false;
    }
  }
  sendOtherJoinMembershipEmail() {
    void authService.requestOtherJoinMembershipEmail();
  }
  //비밀번호 찾기 이메일 전송 관련(emailType: find)
  get findPasswordData() {
    const findPassword = JSON.parse(sessionStorage.getItem('findPassword'));

    return findPassword;
  }
  async sendFindPasswordEmail() {
    try {
      this.state.isSendingEmail = true;
      await authService.requestFindPasswordEmail(this.findPasswordData);
      void _alert('이메일을 발송하였습니다.');
    } finally {
      this.state.isSendingEmail = false;
    }
  }
  sendOtherFindPasswordEmail() {
    void authService.requestOtherFindPasswordEmail();
  }
}

export default new ViewModel();
