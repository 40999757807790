import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

class DecisionVm {
  get decisionBody() {
    return AgendaDashboardModel.agendaProgress.decisionBody;
  }
  get transmissionStatus() {
    return AgendaDashboardModel.transmissionStatus;
  }
}
export default new DecisionVm();
