import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import vm from './LoginBusinessRegistrationVm';
import st from './LoginBusinessRegistration.module.scss';
import clsx from 'clsx';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';

function LoginBusinessRegistration() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    setTimeout(() => {
      vm.state.action = true;
    }, 700);
    vm.load(token);
  }, []);

  return useObserver(() => (
    <>
      <div className={clsx(st.loginBackground, vm.state.action ? st.action : null)}>
        <div className={st.firecracker}>
          <img src="/images/firecracker/1.svg" />
          <img src="/images/firecracker/2.svg" />
          <img src="/images/firecracker/3.svg" />
          <img src="/images/firecracker/4.svg" />
          <img src="/images/firecracker/5.svg" />
          <img src="/images/firecracker/6.svg" />
          <img src="/images/firecracker/7.svg" />
          <img src="/images/firecracker/8.svg" />
        </div>
        <div className={st.square}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className={st.login}>
        <img src="/images/loginTitle.svg" className={st.loginTitleImg} />
        <form className={st.loginForm} onSubmit={(e) => vm.submit(e, token, vm.name)}>
          <div className={st.information}>
            <div className={st.guidance}>
              {vm.name}님 안녕하세요!
              <br />
              사업자등록 진행을 위해 먼저 로그인을 해주세요.
            </div>
            <div className={st.inputRow}>
              <div className={st.inputLabel}>이메일</div>
              <div className={st.helpmeInfo}>
                <input type="hidden" name="email" value={vm.email} />
                {vm.email}
              </div>
            </div>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="password">
                비밀번호
              </label>
              <div className={st.inputs}>
                <div>
                  <InputText
                    name="password"
                    fullSize
                    type="password"
                    id="password"
                    value={vm.state.password}
                    onChange={(e) => vm.changePassword(e)}
                    errorText={
                      <Error
                        name="password"
                        value={vm.state.password}
                        errorCase={{
                          required: '비밀번호를 입력하세요.',
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={st.btnArea}>
            <SquareBtn className={st.loginBtn} type="submit">
              로그인
            </SquareBtn>
          </div>
          <div className={st.link}>
            <Link to="/auth/findPassword">비밀번호 찾기</Link>
          </div>
        </form>
      </div>
      {vm.state.cancelYn === true && (
        <div className={st.cancelPopup}>
          <div className={st.popup}>
            <div className={st.title}>무료 사업자등록신청이 취소되었어요.</div>
            <div className={st.content}>혹시 다시 이용을 원하신다면, 헬프미에 요청해주세요!</div>
          </div>
        </div>
      )}
    </>
  ));
}
export default LoginBusinessRegistration;
