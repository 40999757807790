import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './DocumentPopup.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function DocumentPopup(props) {
  return useObserver(() => (
    <div className={st.DocumentPopup}>
      <div className={st.documentBox}>
        <div className={st.content}>
          <iframe title={props.iframeTitle} className={st.document} src={props.iframeSrc} />
        </div>
      </div>
      {props.children}
    </div>
  ));
}

export default DocumentPopup;
