import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import { useEffect, useState } from 'react';

import { useInternetAssociateStep2Query } from '../../queries';
import TelephoneTipModal from '../components/TelephoneTipModal';
import type {
  SelectedProductsDetail,
} from '../types';

export interface ProductsDetailBundle {
  selectedProductsDetail: SelectedProductsDetail;
  showTelephoneToastMessage: boolean;
  selectInternetDetail(id: string): void;
  selectTVDetail(id: string): void;
  setTelephoneQuantity(type: 'minus' | 'plus'): void;
  setTelephoneNumber(phoneNumber: string): void;
  checkTelephoneQuantityMinusDisabled(): boolean;
  openTelephoneTipModal(): void;
}

const useProductDetail = ({ corpId }: { corpId: number }): ProductsDetailBundle => {
  const { data, isSuccess } = useInternetAssociateStep2Query({ corpId });

  const [selectedProductsDetail, setSelectedProductsDetail] = useState<SelectedProductsDetail>({
    INTERNET: '',
    TELEPHONE: {
      id: '',
      quantity: 1,
    },
    TELEPHONE_NUMBER: [],
    TV: '',
  });

  const [showTelephoneToastMessage, setShowTelephoneToastMessage] = useState<boolean>(false);

  const showToastMessage = () => {
    setShowTelephoneToastMessage(true);
    setTimeout(() => setShowTelephoneToastMessage(false), 3050);
  };

  useEffect(() => {
    if (!isSuccess) return;

    setSelectedProductsDetail((prev) => {
      const copy = { ...prev };

      copy.TELEPHONE.id = data.products.TELEPHONE[0].id;

      return copy;
    });
  }, [data, isSuccess]);

  const selectInternetDetail = (id: string) => setSelectedProductsDetail((prev) => ({ ...prev, INTERNET: id }));
  const selectTVDetail = (id: string) => setSelectedProductsDetail((prev) => ({ ...prev, TV: id }));

  const setTelephoneQuantity = (type: 'minus' | 'plus') =>
    setSelectedProductsDetail((prev) => ({
      ...prev,
      TELEPHONE: { ...prev.TELEPHONE, quantity: (prev.TELEPHONE.quantity += type === 'plus' ? 1 : -1) },
    }));

  const setTelephoneNumber = (phoneNumber: string) =>
    setSelectedProductsDetail((prev) => {
      const copy = { ...prev };
      const index = copy.TELEPHONE_NUMBER.indexOf(phoneNumber);

      if (index !== -1) {
        copy.TELEPHONE_NUMBER.splice(index, 1);
      } else {
        copy.TELEPHONE_NUMBER.push(phoneNumber);
      }

      if (copy.TELEPHONE_NUMBER.includes('TN2') && copy.TELEPHONE.quantity < 2) {
        showToastMessage();
        copy.TELEPHONE.quantity = 2;
      }

      return copy;
    });

  const checkTelephoneQuantityMinusDisabled = (): boolean => {
    if (selectedProductsDetail.TELEPHONE_NUMBER.includes('TN2')) {
      return selectedProductsDetail.TELEPHONE.quantity < 3;
    }

    return selectedProductsDetail.TELEPHONE.quantity < 2;
  };

  const openTelephoneTipModal = () => {
    runInAction(() => {
      portalModel.title = '전화기 설치 시 꿀팁';
      portalModel.content = <TelephoneTipModal />;
    });
  };

  return {
    selectedProductsDetail,
    showTelephoneToastMessage,
    selectInternetDetail,
    selectTVDetail,
    setTelephoneQuantity,
    setTelephoneNumber,
    checkTelephoneQuantityMinusDisabled,
    openTelephoneTipModal,
  };
};


export default useProductDetail;
