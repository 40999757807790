import { observable } from 'mobx';
import formDataToJson from '@common/module/submit';
import authService from '@service/authService';

class VerificationPhoneNumberVm {
  state: {
    verificationCode: string;
  };
  constructor() {
    this.state = observable({
      verificationCode: '',
    });
  }
  setVerificationCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.state.verificationCode = e.target.value;
  }
  submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      authService.findId(param);
    }
  }
  reload() {
    window.location.reload();
  }
}
export default new VerificationPhoneNumberVm();
