import { useObserver } from 'mobx-react';
import { Route, Routes, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './UserDashboardVm';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import Header from '@commonComponents/Header/Header';
import Content from '@commonComponents/Content/Content';
import st from './UserDashboard.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import clsx from 'clsx';

function CompanyList(props) {
  return useObserver(() => (
    <li className={clsx(props.company.demoYn ? st.demo : null, props.company.liquidationYn ? st.liquidation : null)}>
      <Link to={`/corp/redirect?corp=${props.company.id}`} className={clsx(st.corp, st.corpCard)}>
        <div className={st.top}>
          <div className={st.corpName}>{props.company.koreanName}</div>
          {/* {company.demoYn === false && 
                        <>
                            {vm.viewType(company) === "new" &&
                                <span className={st.tag} data-type="new">NEW</span>
                            }
                            {vm.viewType(company) === "필요" &&
                                <span className={st.tag} data-type="필요">필요</span>
                            }
                            {vm.viewType(company) === "필수" &&
                                <span className={st.tag} data-type="필수">필수</span>
                            }
                            {vm.viewType(company) === "등록중" &&
                                <span className={st.tag} data-type="등록중">등록중</span>
                            }
                        </>
                    } */}
        </div>
        <div className={st.bottom}>
          {props.company.demoYn === true && <div className={st.demoText}>스탠바이 서비스, 한번 둘러보세요!</div>}
          {props.company.liquidationYn === true && (
            <>
              <div className={st.tagContent}>
                <div className={st.tagWrap}>
                  <span className={st.tag} data-type="청산">
                    청산
                  </span>
                </div>
                <div className={st.text}>
                  <div className={st.mainText}>
                    {vm.representative(props.company.executiveMembers).name} 청산인{' '}
                    {vm.representative(props.company.executiveMembers).count > 1
                      ? `+ ${vm.representative(props.company.executiveMembers).count - 1}`
                      : ''}
                  </div>
                </div>
              </div>
              <div className={st.subText}>{vm.minAddress(props.company.address)}</div>
            </>
          )}
          {props.company.demoYn === false && props.company.liquidationYn === false && (
            <>
              {vm.viewType(props.company) === '필요' && (
                <>
                  <div className={st.tagContent}>
                    <div className={st.tagWrap}>
                      <span className={st.tag} data-type="필요">
                        필요
                      </span>
                    </div>
                    <div className={st.text}>
                      <div className={st.mainText}>
                        임원{' '}
                        <span className={st.importantText} data-type="필요">
                          임기연장
                        </span>{' '}
                        필요!
                      </div>
                    </div>
                  </div>
                  <div className={st.subText}>
                    {vm.minDay(props.company.executiveMembers).name}님의 임기가{' '}
                    <span className={st.importantText} data-type="필요">
                      {vm.minDay(props.company.executiveMembers).positionExpiredDays}일
                    </span>{' '}
                    남았어요.
                  </div>
                </>
              )}
              {vm.viewType(props.company) === '필수' && (
                <>
                  <div className={st.tagContent}>
                    <div className={st.tagWrap}>
                      <span className={st.tag} data-type="필수">
                        필수
                      </span>
                    </div>
                    <div className={st.text}>
                      <div className={st.mainText}>
                        임원{' '}
                        <span className={st.importantText} data-type="필수">
                          임기연장
                        </span>{' '}
                        필수!
                      </div>
                    </div>
                  </div>
                  <div className={st.subText}>
                    {props.company.executiveTags.필수 === 1 && (
                      <>{vm.expiration(props.company.executiveMembers).name}님의 임기가 만료되었어요.</>
                    )}
                    {props.company.executiveTags.필수 > 1 && (
                      <>
                        {vm.expiration(props.company.executiveMembers).name}님+{props.company.executiveTags.필수 - 1}
                        명의 임기가 만료되었어요.
                      </>
                    )}
                  </div>
                </>
              )}
              {vm.viewType(props.company) === 'new' && (
                <div className={st.tagContent}>
                  <div className={st.tagWrap}>
                    <span className={st.tag} data-type="new">
                      NEW
                    </span>
                  </div>
                  <div className={st.text}>
                    <div className={st.mainText}>
                      {props.company.newTags.slice(0, 3).join(', ')}
                      {props.company.newTags.length > 3 && (
                        <div className={st.number}>{props.company.newTags.length - 3}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {vm.viewType(props.company) === '일반' && (
                <div className={st.infoContent}>
                  <div className={st.row}>
                    <div className={st.roundTag}>{vm.minAddress(props.company.address)}</div>
                  </div>
                  <div className={st.row}>
                    <div className={st.roundTag}>{vm.representative(props.company.executiveMembers).name} 대표님</div>
                    {vm.representative(props.company.executiveMembers).count > 1 && (
                      <div className={clsx(st.roundTag, st.count)}>
                        +{vm.representative(props.company.executiveMembers).count - 1}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {vm.viewType(props.company) === '등록중' && (
                <>
                  <div className={st.tagContent}>
                    <div className={st.tagWrap}>
                      <span className={st.tag} data-type="등록중">
                        등록중
                      </span>
                    </div>
                    <div className={st.text}>
                      <div className={st.mainText}>등록을 마치고 알림을 드려요!</div>
                    </div>
                  </div>
                  <div className={st.subText}>(최대 1영업일)</div>
                </>
              )}
            </>
          )}
        </div>
      </Link>
    </li>
  ));
}

function UserDashboard() {
  useEffect(() => {
    vm.load();
  }, []);
  return useObserver(() => (
    <>
      <Header />
      <Content>
        <div className={st.corpList}>
          {vm.companiesInfo.length > 3 && (
            <button
              type="button"
              className={clsx(st.moreView, vm.state.moreViewYn ? st.open : null)}
              onClick={() => vm.moreView()}
            >
              {vm.state.moreViewYn ? '접기' : '더보기'}
            </button>
          )}
          <div className={st.content}>
            <PageTitle>
              <span className={st.pageTitle}>등록 법인 목록</span>
            </PageTitle>
            <ul className={st.cardList}>
              <li>
                <Link to={`/companyRegistration`} className={clsx(st.companyRegistrationLink, st.corpCard)}>
                  <span>법인 신규등록</span>
                </Link>
              </li>
              {vm.companiesInfo.length < 3 || vm.state.moreViewYn === true
                ? vm.companiesInfo.map((company, index) => <CompanyList key={index} company={company} />)
                : vm.companiesInfo.slice(0, 3).map((company, index) => <CompanyList key={index} company={company} />)}
            </ul>
          </div>
        </div>
        <div className={st.blogContentList}>
          <div className={st.content}>
            <div className={st.corpTip}>
              <div className={st.contentTitle}>법인 설립 직후 해야할 일</div>
              <div className={st.tipList}>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/article/%EB%B2%95%EC%9D%B8%EA%B3%84%EC%A2%8C%EA%B0%9C%EC%84%A4%ED%95%98%EB%8A%94%EB%B2%95/"
                  style={{ height: '99px' }}
                >
                  <div className={st.title}>법인 계좌 개설하는 방법</div>
                  <div className={st.tip}>이 글만 따라하면 계좌 개설 과정에서 실패할 가능성이 거의 없어요!</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/article/%EC%82%AC%EC%97%85%EC%9E%90_%EA%B3%B5%EB%8F%99%EC%9D%B8%EC%A6%9D%EC%84%9C_%EB%B0%9C%EA%B8%89/"
                  style={{ height: '105px' }}
                >
                  <div className={st.title}>법인계좌 개설 후, 전자세금용 공동인증서 발급 받는 방법</div>
                  <div className={st.tip}>잊지말고 꼭 해주세요! 사업이 훨씬 쉬워져요.</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/article/%EB%B2%95%EC%9D%B8_%EC%9E%90%EB%B3%B8%EA%B8%88%EC%9D%B4%EC%B2%B4/"
                  style={{ height: '79px' }}
                >
                  <div className={st.title}>법인 자본금은 이 때까지 꼭 이체하세요!</div>
                  <div className={st.tip}>법인 자본금 이체 타이밍을 딱 정해드립니다!</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/article/%EC%97%B0%EA%B0%84%EC%84%B8%EB%AC%B4%EC%9D%BC%EC%A0%95/"
                  style={{ height: '79px' }}
                >
                  <div className={st.title}>대표님이 꼭 알아야 할 연간 세무 일정</div>
                  <div className={st.tip}>꼭 알아야하는 연간 세무 일정</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/article/%EA%B3%B5%EA%B3%BC%EA%B8%88_%EB%AA%85%EC%9D%98%EB%B3%80%EA%B2%BD/"
                  style={{ height: '125px' }}
                >
                  <div className={st.title}>
                    각종 공과금을 개인명의에서 사업자 명의로 변경 하는 법 (전기세, 인터넷 등)
                  </div>
                  <div className={st.tip}>
                    이제 대표님이 되셨으니, 개인 명의에서 사업자 명의로 바로 변경하는건 어떠세요?
                  </div>
                </a>
              </div>
            </div>
            <div className={st.guide}>
              {vm.companiesInfo.length !== 0 && (
                <a href={`/corp/alliance?corp=${vm.companiesInfo[0].id}`} target="_blank">
                  <img src="/images/dashboardEtc.png?4" style={{ marginBottom: '20px' }} />
                </a>
              )}
              <div className={st.contentTitle}>기초, 심화 가이드</div>
              <div className={st.guideList}>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/group/%EB%B2%95%EC%9D%B8%20%EB%93%B1%EA%B8%B0%EB%B6%80%EB%93%B1%EB%B3%B8%EC%9D%98%20%EB%AA%A8%EB%93%A0%20%EA%B2%83/"
                >
                  <div className={st.title}>법인 등기부등본의 모든 것</div>
                  <div className={st.subText}>법인등기부등본, 어디까지 알고 있나요?</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/group/%EB%B2%95%EC%9D%B8%EC%A0%95%EA%B4%80%EC%9D%98%20%EB%AA%A8%EB%93%A0%20%EA%B2%83/"
                >
                  <div className={st.title}>법인정관의 모든 것</div>
                  <div className={st.subText}>정관은 회사의 ‘헌법’이라고 할 수 있죠!</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/group/%EC%A3%BC%EC%A3%BC%EB%AA%85%EB%B6%80%EC%9D%98%20%EB%AA%A8%EB%93%A0%20%EA%B2%83/"
                >
                  <div className={st.title}>주주명부의 모든 것</div>
                  <div className={st.subText}>주주명부 관리는 이렇게 하세요!</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/category/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A6%9D/"
                >
                  <div className={st.title}>사업자등록증</div>
                  <div className={st.subText}>사업자등록증에 관한 모든 것</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/category/%EC%A3%BC%EC%A3%BC&%EC%9E%90%EB%B3%B8%EA%B8%88/"
                >
                  <div className={st.title}>주주 & 자본금</div>
                  <div className={st.subText}>주주와 자본금, 어디까지 알고 있나요?</div>
                </a>
                <a
                  target="_blank"
                  href="https://standby.kr/blog/category/%EB%B2%95%EC%9D%B8%EC%84%B8&%EB%B6%80%EA%B0%80%EC%84%B8/"
                >
                  <div className={st.title}>법인세 & 부가세</div>
                  <div className={st.subText}>이제 세금으로 머리 아프지 마세요!</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </>
  ));
}
export default UserDashboard;
