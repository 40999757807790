import { useObserver } from 'mobx-react';
import React from 'react';
import st from '../../Meetings/Meetings.module.scss';
import vm from './C소집통지_온라인Vm';
import { I소집통지_온라인 } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import File from '../File/File';
import clsx from 'clsx';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import { addCommas } from '@common/module/replaceNumber';

interface C소집통지_온라인Props {
  meetingSchedule: I소집통지_온라인;
  meetingScheduleIndex: number;
  opener: 'final' | 'agendaDashboard';
}

const C소집통지_온라인 = React.memo(function (props: C소집통지_온라인Props) {
  return useObserver(() => (
    <div className={st.meeting}>
      <div className={st.sideTitle}>
        {props.meetingScheduleIndex + 1}. {props.meetingSchedule.title}
      </div>
      <div className={st.meetingInfo}>
        <div className={st.detailTitle}>{props.meetingSchedule.detailTitle}</div>
        <ul className={st.detailList}>
          <li>
            <div className={clsx(st.title, st.notificationMethod)}>방식</div>
            <div className={st.content}>온라인 소집통지</div>
          </li>
          <li>
            <div className={clsx(st.title, st.notice)}>통지문</div>
            <div className={st.content}>
              <File
                file={props.meetingSchedule.files.소집통지서}
                id={props.meetingSchedule.id}
                type="소집통지서"
                opener={props.opener}
              />
            </div>
          </li>
          <li>
            <div className={clsx(st.title, st.date)}>일시</div>
            <div className={st.content}>{props.meetingSchedule.decisionAt}</div>
          </li>
        </ul>
        <div className={st.personResultList}>
          <div className={st.title}>주주총회 소집통지 결과</div>
          <div className={st.qnaWrap}>
            <QuestionTooltip title="주주총회 소집통지 안내" style={{ width: '460px' }}>
              <div className={st.tooltipContent}>
                <div className={st.qna}>
                  <div className={st.qnaTitle}>
                    주주총회 열 때마다 10~14일 전에 소집통지를 발송하고, 근거를 보관하세요.
                  </div>
                  <div className={st.qnaContent}>
                    <ul>
                      <li>
                        정관에 따라 소집통지를 보낸 후 10~14일이 지나야 주주총회를 열 수 있어요. 단 통지 당일은
                        일자계산에서 제외되어요.
                      </li>
                      <li>
                        소집통지를 발송한 증거를 잘 보관하세요. 스탠바이를 통해 온라인 발송을 하면, 발송된 근거가 모두
                        자동보관되어요!
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={st.qna}>
                  <div className={st.qnaTitle}>
                    소집통지는 우편 발송이 원칙이고, 주주 동의를 받으면 온라인 발송도 가능해요.
                  </div>
                  <div className={st.qnaContent}>
                    <ul>
                      <li>
                        자본금 10억 원 미만의 회사는 주주가 동의하면, 우편이 아니라 온라인으로도 소집통지를 보낼 수가
                        있어요.
                      </li>
                      <li>
                        다만 주주의 동의를 받지 않고 온라인으로 소집통지를 보내면, 통지의 효과가 없을 수 있으니 주의가
                        필요해요.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </QuestionTooltip>
          </div>
          <Table className={st.personListTable}>
            <colgroup>
              <col width="264" />
              <col width="117" />
              <col width="90" />
              <col width="154" />
              <col width="104" />
            </colgroup>
            <thead>
              <tr>
                <th>주주명</th>
                <th>발송여부</th>
                <th>발송일자</th>
                <th>주식종류</th>
                <th>주식수</th>
                <th>지분율</th>
              </tr>
            </thead>
            {props.meetingSchedule?.shareholders?.map((shareholder, idx) => {
              if (idx < 6 || (idx > 5 && vm.state.moreViewYn)) {
                return (
                  <tbody key={idx}>
                    {shareholder.stocks.map((stock, idx2) => {
                      return (
                        <tr key={idx2}>
                          {idx2 === 0 && (
                            <>
                              <td rowSpan={shareholder.stocks.length}>
                                <span>{idx + 1}</span>
                                {shareholder.name}
                              </td>
                              <td rowSpan={shareholder.stocks.length}>
                                <div className={vm.status(shareholder.status)?._className}>
                                  {vm.status(shareholder.status)?.text}
                                  {shareholder.status === '발송실패' && (
                                    <ToolTip className={st.tooltip}>
                                      일시적 오류가 발생하여 발송에 실패했어요. 이메일, 휴대번호가 정확한지 확인해보고,
                                      재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를
                                      남겨주세요.
                                    </ToolTip>
                                  )}
                                  {shareholder.status === '발송실패_알림톡' && (
                                    <ToolTip className={st.tooltip}>
                                      일시적으로 오류가 발생하여 알림톡 발송에 실패했어요. (이메일은 발송성공)
                                      휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면
                                      오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                    </ToolTip>
                                  )}
                                  {shareholder.status === '발송실패_메일' && (
                                    <ToolTip className={st.tooltip}>
                                      일시적 오류가 발생하여 메일 발송에 실패했어요. (알림톡은 발송성공) 이메일 주소가
                                      정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래
                                      채팅버튼을 눌러 문의를 남겨주세요.
                                    </ToolTip>
                                  )}
                                </div>
                              </td>
                              <td rowSpan={shareholder.stocks.length}>{shareholder.completedDate}</td>
                            </>
                          )}
                          <td className={st.stockName}>{stock.name}</td>
                          <td className={st.stockNumber}>{addCommas(stock.number)} 주</td>
                          <td className={st.stockRate}>{stock.rate} %</td>
                        </tr>
                      );
                    })}
                  </tbody>
                );
              }
            })}
          </Table>
          {props.meetingSchedule?.shareholders?.length > 6 && (
            <button type="button" className={st.moreViewBtn} onClick={() => vm.moreView()}>
              <span className={vm.state.moreViewYn ? st.open : ''}>{vm.state.moreViewYn ? '접기' : '더보기'}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  ));
});

export default C소집통지_온라인;
