import onlineAcceptancePopupModel from '@model/Corp/Popup/OnlineAcceptancePopup/onlineAcceptancePopupModel';
import onlineAcceptancePopupService from '@src/service/Corp/Popup/OnlineAcceptancePopup/onlineAcceptancePopupService';
import portalModel from '@model/portalModel';
import { runInAction, observable } from 'mobx';
import { _alert } from '@model/dialogModel';

enum Status {
  발송전 = '발송전',
  발송중 = '발송중',
  발송완료 = '발송완료\n(대기중)',
  동의완료 = '동의완료',
  발송실패 = '발송실패',
}

interface State {
  shareholders: number[];
  errorYn: boolean;
}

class DirectVm {
  state: State = observable({
    shareholders: [],
    errorYn: false,
  });
  load(agendaProgressId: string, page?: string) {
    runInAction(() => {
      portalModel.title = '동의여부 직접 입력하기';
      if (page === 'stock') {
        onlineAcceptancePopupService.loadStockDirect();
      } else {
        onlineAcceptancePopupService.loadDirect(agendaProgressId);
      }
    });
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string, page?: string) {
    e.preventDefault();
    if (this.state.shareholders && this.state.shareholders.length !== 0) {
      this.state.errorYn = false;
      if (this.state.shareholders && !Array.isArray(this.state.shareholders)) {
        this.state.shareholders = [this.state.shareholders];
      }

      const _param = {
        shareholders: this.state.shareholders,
      };

      if (page === 'stock') {
        await onlineAcceptancePopupService.stockDirectInput(_param);
      } else {
        await onlineAcceptancePopupService.directInput(agendaProgressId, _param);
      }
      await _alert('저장되었어요.');
      runInAction(() => {
        portalModel.title = '';
        portalModel.content = null;
      });
      window.location.reload();
    } else {
      this.state.errorYn = true;
    }
  }
  get shareholders() {
    return onlineAcceptancePopupModel.direct.shareholders;
  }
  status(_status: keyof typeof Status) {
    return Status[_status];
  }
  get agreeAll() {
    const agreeCnt = onlineAcceptancePopupModel.direct.shareholders.filter((elem) => elem.status !== '동의완료').length;
    if (agreeCnt === this.state.shareholders.length) {
      return true;
    } else {
      return false;
    }
  }
  setAgreeAll(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    if (value) {
      onlineAcceptancePopupModel.direct.shareholders.forEach((item) => {
        if (item.status !== '동의완료') {
          this.state.shareholders.push(item.id);
        }
      });
      this.state.errorYn = false;
    } else {
      this.state.shareholders = [];
    }
  }
  agree(id: number) {
    if (this.state.shareholders.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  setAgree(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    if (!this.state.shareholders.includes(value)) {
      this.state.shareholders.push(value);
    } else {
      this.state.shareholders = this.state.shareholders.filter((elem) => elem !== value);
    }
    if (this.state.shareholders && this.state.shareholders.length) {
      this.state.errorYn = false;
    }
  }
}

export default new DirectVm();
