import { downloadFileGet, get, post } from '@common/module/httpRequest';
import awsCreditModel from '@model/Corp/AwsCredit/awsCreditModel';
import corpModel from '@model/Corp/corpModel';
import { runInAction } from 'mobx';

import { LoadStep2Rs, LoadStep3Rs, LoadStepRs, loadTermRs, SaveStep2Rq } from './awsCreditService.interface';

class AwsCreditService {
  async loadTerm() {
    const res = await get<loadTermRs>(`/term/AWS_CREDIT_APPLICATION`);

    return res.data.userRegistrationTerms.map((userRegistrationTerms) => {
      return {
        id: userRegistrationTerms.id,
        title: userRegistrationTerms?.title || '',
        content: userRegistrationTerms?.content || '',
        required: userRegistrationTerms?.required ?? false,
      };
    });
  }
  async loadStep2(corpId: number) {
    const res = await get<LoadStep2Rs>(`/company/${corpId}/aws/credit/step2`);

    return {
      name: res.data.name || '',
      email: res.data.email || '',
      phoneNumber: res.data.phoneNumber || '',
      responsibility: res.data.responsibility || '',
    };
  }
  async loadStep() {
    const res = await get<LoadStepRs>(`/company/${corpModel.id}/aws/credit/step`);

    return res.data.step;
  }
  async saveStep2(param: SaveStep2Rq) {
    await post(`/company/${corpModel.id}/aws/credit`, param);
  }
  async loadStep3() {
    const res = await get<LoadStep3Rs>(`/company/${corpModel.id}/aws/credit/step3`);

    runInAction(() => {
      awsCreditModel.step3.status = res.data.status || null;
    });
  }
  async consult() {
    await post(`/company/${corpModel.id}/aws/credit/consult`);
  }
  fileDown(type: string) {
    void downloadFileGet(`/company/${corpModel.id}/aws/credit/file/${type}`);
  }
}

export default new AwsCreditService();
