import { Return } from '@standby/dike_ui/assets/icon/line';
import { Button, InfoBox, TextButton, TextField, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import st from './RequestEmailCheck.module.scss';
import RequestEmailCheckVm from './RequestEmailCheckVm';

function RequestEmailCheck(props) {
  const location = useLocation();
  const emailType = queryString.parse(location.search).emailType;

  return useObserver(() => (
    <div className={st.RequestEmailCheckContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      {emailType === 'joinMembership' && (
        <>
          <div className={st.mainTitle}>
            <Typography fontType="Title-18B">이메일 인증 링크</Typography>
            <Typography fontType="Title-18M">가 1분 내로 도착할 예정이니</Typography>
            <br />
            <Typography fontType="Title-18B">메일함 확인</Typography>
            <Typography fontType="Title-18M">을 부탁드려요 :)</Typography>
          </div>
          <TextField
            name="email"
            value={RequestEmailCheckVm.joinMembershipData.email}
            disabled
            label="발송한 이메일 주소"
          />
          <InfoBox className={st.infoBox}>혹시 이메일이 오지 않았다면, 스팸함을 확인하세요.</InfoBox>
          <Button
            type="button"
            className={st.retryBtn}
            onClick={() => RequestEmailCheckVm.sendJoinMembershipEmail()}
            disabled={RequestEmailCheckVm.state.isSendingEmail}
            color="primary400"
            variant="outlined"
            size="large"
          >
            비밀번호 재설정하기
            <Return width="16" height="16" fill={color.primary400} />
          </Button>
          <TextButton
            type="button"
            underline
            className={st.textBtn}
            onClick={() => RequestEmailCheckVm.sendOtherJoinMembershipEmail()}
          >
            다른 메일 주소로 보내기
          </TextButton>
        </>
      )}
      {emailType === 'findPassword' && (
        <>
          <div className={st.mainTitle}>
            <Typography fontType="Title-18B">메일함 확인</Typography>
            <Typography fontType="Title-18M">을 부탁드려요:)</Typography>
          </div>
          <TextField
            name="email"
            value={RequestEmailCheckVm.findPasswordData.email}
            disabled
            label="발송한 이메일 주소"
          />
          <InfoBox className={st.infoBox}>
            혹시 이메일이 오지 않았다면, 스팸함을 확인하세요.
            <br /> 참! 가입되지 않은 이메일이라면 메일 발송이 안 되어요.
          </InfoBox>
          <Button
            type="button"
            className={st.retryBtn}
            onClick={() => RequestEmailCheckVm.sendFindPasswordEmail()}
            disabled={RequestEmailCheckVm.state.isSendingEmail}
            color="primary400"
            variant="outlined"
            size="large"
          >
            이메일 다시 보내기
            <Return width="16" height="16" fill={color.primary400} />
          </Button>
          <TextButton
            type="button"
            underline
            className={st.textBtn}
            onClick={() => RequestEmailCheckVm.sendOtherFindPasswordEmail()}
          >
            이전으로 돌아가기
          </TextButton>
        </>
      )}
    </div>
  ));
}

export default RequestEmailCheck;
