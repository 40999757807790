import { observable, runInAction } from 'mobx';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import beforeFinalService from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService';
import portalModel from '@src/model/portalModel';
import Document from './Document/Document';
import beforeFinalModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel';

import type { SectionType } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';

interface State {
  moreViewYn: boolean;
}

class NewStockIssueVm {
  state: State = observable({
    moreViewYn: false,
  });
  status(_status: string | null) {
    switch (_status) {
      case '발송전':
        return {
          _className: bst.secon200,
          text: '발송 전',
        };
      case '발송중':
        return {
          _className: bst.secon200,
          text: '발송 중',
        };
      case '발송완료':
        return {
          _className: bst.secon200,
          text: '발송완료\n(대기중)',
        };
      case '동의완료':
        return {
          _className: bst.sub300,
          text: '동의완료',
        };
      case '발송실패':
        return {
          _className: bst.warning200,
          text: '발송실패',
        };
      case '발송실패_알림톡':
        return {
          _className: bst.warning200,
          text: '발송실패\n(알림톡)',
        };
      case '발송실패_메일':
        return {
          _className: bst.warning200,
          text: '발송실패\n(메일)',
        };
    }
  }
  moreView() {
    this.state.moreViewYn = !this.state.moreViewYn;
  }
  download(downloadUrl: string) {
    beforeFinalService.fileDownload(downloadUrl);
  }
  allDownload(agendaProgressId: string, type: SectionType) {
    beforeFinalService.fileAllDownload(agendaProgressId, type);
  }
  openModal(
    agendaProgressId: string,
    newStockAcquirerInfo: { assignmentType: '제3자' | '주주' | null; isExistsAbandonedStock: boolean },
  ) {
    runInAction(() => {
      portalModel.title = '1. 자동 작성된 서류를 확인하세요.';
      portalModel.content = (
        <Document agendaProgressId={agendaProgressId} newStockAcquirerInfo={newStockAcquirerInfo} />
      );
    });
  }
  get newStockAcquirerInfo() {
    return beforeFinalModel.newStockAcquirerInfo;
  }
}

export default new NewStockIssueVm();
