import { observable } from 'mobx';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';

class FileUploadVm {
  state: {
    loadingYn: boolean;
  };
  constructor() {
    this.state = observable({
      loadingYn: false,
    });
  }
  async fileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      try {
        this.state.loadingYn = true;
        await businessRegistrationService.fileUpload(e.target.files[0], '/upload/direct');
        this.state.loadingYn = false;
        window.location.reload();
      } catch (err) {
        this.state.loadingYn = false;
      }
    }
  }
}
export default new FileUploadVm();
