import { observable, runInAction } from 'mobx';
import beforeFinalModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel';
import portalModel from '@model/portalModel';
import ChangeMeetingSchedule from './ChangeMeetingSchedule/ChangeMeetingSchedule';
import AgendaView from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaViewModal/AgendaViewModal';

interface State {
  moreViewYn: boolean;
}

class MeetingScheduleVm {
  state: State = observable({
    moreViewYn: false,
  });
  get meetingSchedule() {
    return beforeFinalModel.meetingSchedule;
  }
  moreView() {
    this.state.moreViewYn = !this.state.moreViewYn;
  }
  openModal(agendaProgressId: string) {
    runInAction(() => {
      portalModel.title = '변경할 장소, 일시를 입력하세요.';
      portalModel.content = <ChangeMeetingSchedule agendaProgressId={agendaProgressId} />;
    });
  }
  agendaViewModal(agendaProgressId: string) {
    runInAction(() => {
      portalModel.title = '입력내용 확인하기';
      portalModel.content = <AgendaView agendaProgressId={agendaProgressId} />;
    });
  }
  get showAgendaPopUpButton() {
    return beforeFinalModel.showAgendaPopUpButton;
  }
  get representativeAddressChangeIsHide() {
    return beforeFinalModel.representativeAddressChangeIsHide;
  }
}

export default new MeetingScheduleVm();
