import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';
import awsCreditService from '@service/Corp/AwsCredit/awsCreditService';
import { SaveStep2Rq } from '@service/Corp/AwsCredit/awsCreditService.interface';
import formDataToJson from '@src/common/module/submit';
import { useMutation } from '@tanstack/react-query';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import React from 'react';

import ExistingCustomerModal from '../ExistingCustomerModal/ExistingCustomerModal';
import type { Step2FormData } from '../types';

interface FormState {
  submissionRejected: boolean;
}

export interface FormBundle {
  formState: FormState;
  onSubmitForm(e: React.FormEvent<HTMLFormElement>): Promise<void>;
}

interface FormProps {
  isRequiredTermsAgreed: boolean;
  step2FormData: Step2FormData;
}

const useForm = (props: FormProps): FormBundle => {
  const { isRequiredTermsAgreed, step2FormData } = props;

  const formState = useLocalObservable<FormState>(() => ({
    submissionRejected: false,
  }));

  const submitFormMutation = useMutation<unknown, Error, SaveStep2Rq>({
    mutationFn: (param) => awsCreditService.saveStep2(param),
    onSuccess() {
      //step3 결과 페이지로 리다이렉팅
      window.location.href = `/corp/awsCredit/result?corp=${corpModel.id}`;
    },
  });

  const onSubmitForm: FormBundle['onSubmitForm'] = async (e) => {
    e.preventDefault();
    if (step2FormData.useAWSYn === true && step2FormData.MGCUseYn === true) {
      runInAction(() => {
        portalModel.content = <ExistingCustomerModal />;
      });

      return;
    }

    if (formDataToJson(e.target).error || !isRequiredTermsAgreed) {
      formState.submissionRejected = true;

      return;
    }

    const param = formDataToJson(e.target).data as SaveStep2Rq;

    submitFormMutation.mutate(param);
  };

  return {
    formState,
    onSubmitForm,
  };
};

export default useForm;
