import { observable } from 'mobx';

const applicationModel = observable({
  nowUrlCharge: null,
  isBusinessRegistrationUrl: null,
  setNowUrlCharge(charge) {
    this.nowUrlCharge = charge || null;
  },
  setIsBusinessRegistrationUrl(isBusinessRegistrationUrl) {
    this.isBusinessRegistrationUrl = isBusinessRegistrationUrl ?? null;
  },
});

export default applicationModel;
