import { makeObservable, observable } from 'mobx';

import type { InternetAssociateModelInterface, Step3 } from './InternetAssociateModel.interface';

class InternetAssociateModel implements InternetAssociateModelInterface {
  step3: Step3 = {
    status: '',
    date: '',
    products: [],
  };
  constructor() {
    makeObservable(this, {
      step3: observable,
    });
  }
}

export default new InternetAssociateModel();
