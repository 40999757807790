import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import st from './SecurityContentMobile.module.scss';

interface SecurityContentProps {
  children: React.ReactNode;
}

function SecurityContent(props: SecurityContentProps) {
  const viewport = document.querySelector('meta[name=viewport]');
  useEffect(() => {
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=no');
      return () => {
        viewport.setAttribute('content', 'width=1280');
      };
    }
  }, []);

  return useObserver(() => (
    <>
      <div className={st.securityContent}>
        <div className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </div>
        <div className={st.content}>{props.children}</div>
      </div>
    </>
  ));
}
export default SecurityContent;
