import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './BesinessRegistrationEmailSignModalVm';
import st from './BesinessRegistrationEmailSignModal.module.scss';
import clsx from 'clsx';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import Textarea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function BesinessRegistrationEmailSignModal() {
  useEffect(() => {
    vm.load();
  }, []);
  return useObserver(() => (
    <div className={st.modalContent}>
      <div className={st.preview}>
        <img src="/images/besinessRegistrationEmailSign_preview.png" />
      </div>
      <div className={st.content}>
        <div className={st.title}>정보입력</div>
        <div className={st.subTitle}>
          입력 시 자동저장되고 수정할 수 있으며,
          <br />
          파일에 여러 장이 존재할 경우 가장 첫 장만 다운로드 되어요.
        </div>
        <form onSubmit={(e) => vm.submit(e)}>
          <div className={st.inputRows}>
            <div>
              <label className={clsx(st.inputTitle, st.required)} htmlFor="email">
                이메일
              </label>
            </div>
            <InputText
              style={{ width: '452px' }}
              name="email"
              id="email"
              value={vm.email}
              onChange={(e) => vm.setEmail(e)}
              errorText={
                <Error
                  name="email"
                  value={vm.email}
                  errorCase={{
                    required: '이메일을 입력하세요.',
                    pattern: {
                      value: regExp.email().test(vm.email),
                      message: '올바른 이메일 형식으로 입력해 주세요.',
                    },
                  }}
                />
              }
            />
          </div>
          <div className={st.inputRows}>
            <div>
              <label className={clsx(st.inputTitle, st.required)} style={{ marginBottom: 0 }}>
                파일 형태
              </label>
            </div>
            <div className={st.radios}>
              <Radio
                className={st.radio}
                name="fileExtensionType"
                value="JPG"
                checked={vm.fileExtensionType === 'JPG'}
                onChange={(e) => vm.setFileExtensionType(e)}
              >
                jpg
              </Radio>
              <Radio
                className={st.radio}
                name="fileExtensionType"
                value="PDF"
                checked={vm.fileExtensionType === 'PDF'}
                onChange={(e) => vm.setFileExtensionType(e)}
              >
                pdf
              </Radio>
            </div>
          </div>
          <div className={st.inputRows}>
            <div>
              <label className={st.inputTitle} htmlFor="content">
                기타 정보
              </label>
            </div>
            <Textarea
              className={st.textarea}
              maxLength={100}
              style={{ width: '452px' }}
              placeholder="FAX : 02-123-1234&#10;담당자 : 홍길동(010-1234-5678)"
              name="content"
              id="content"
              value={vm.content}
              onChange={(e) => vm.setContent(e)}
            />
          </div>
          <SquareBtn type="submit" className={st.submit}>
            사업자등록증 다운로드
          </SquareBtn>
        </form>
      </div>
    </div>
  ));
}
export default BesinessRegistrationEmailSignModal;
