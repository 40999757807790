import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {
  Agenda,
  IA액면금분할_합병,
} from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';
import { addCommas } from '@src/common/module/replaceNumber';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA액면금분할_합병>;
}

function C액면금분할_합병(props: Props) {
  return useObserver(() => (
    <div className={st.agenda}>
      <div className={st.title}>
        <div className={st.iconTitle}>안건</div>
        <div className={st.number}>{props.agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>액면금 변경</span>
        </div>
        {props.agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      <div className={st.info}>
        <ul className={st.agendaList}>
          <li>
            액면금 : {addCommas(props.agenda.info.beforeParValue)} 원 → {addCommas(props.agenda.info.afterParValue)} 원
          </li>
        </ul>
      </div>
    </div>
  ));
}
export default C액면금분할_합병;
