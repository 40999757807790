import { useObserver } from 'mobx-react';
import vm from './FileVm';
import st from './File.module.scss';
import type { File } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import React from 'react';
import clsx from 'clsx';

interface FileProps {
  file: File;
  id: string;
  type: string;
  opener: 'final' | 'agendaDashboard';
}

function FileComponent(props: FileProps) {
  let location = useLocation();
  const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;
  const corp = queryString.parse(location.search).corp as string;
  const fileInput = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 선택한 파일 정보를 콘솔에 출력
    if (e.target.files) {
      vm.uploadFile(e.target.files, corp, agendaProgressId, props.id, props.type, props.file);
    }
  };

  return useObserver(() =>
    props.file.downloadUrl ? (
      <div className={st.fileWrap}>
        <button type="button" className={st.downloadBtn} onClick={() => vm.fileDown(props.file.downloadUrl)}>
          <span>{props.file.name}</span>
        </button>
        {props.file.modifiable && (
          <button
            className={st.deleteFile}
            onClick={() => vm.deleteFile(corp, agendaProgressId, props.id, props.type, props.file)}
          >
            삭제
          </button>
        )}
      </div>
    ) : (
      <div className={clsx(st.emptyFile, st.fileWrap)}>
        {props.opener === 'agendaDashboard' ? (
          <span className={st.empty}>{props.type.includes('의사록') ? '헬프미에서 업로드 전' : '고객 작성 전'}</span>
        ) : (
          <>
            {props.file.modifiable === false && <span className={st.empty}>미작성</span>}
            {props.file.modifiable === true && (
              <>
                <span className={st.empty}>{props.file.name}을 업로드 하세요.</span>
                <button type="button" className={st.uploadBtn} onClick={() => handleButtonClick()}>
                  <span>클릭하여 업로드</span>
                </button>
              </>
            )}
            <input type="file" ref={fileInput} onChange={handleChange} style={{ display: 'none' }} multiple />
          </>
        )}
      </div>
    ),
  );
}

export default FileComponent;
