import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';
import { _alert, _confirm } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import RnumberRemoveModal from './RnumberRemoveModal/RnumberRemoveModal';
import RnumberEditModal from './RnumberEditModal/RnumberEditModal';

class RnumberVm {
  get rNumbers() {
    return AgendaDashboardModel.rNumbers;
  }
  insertRnumber(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    AgendaDashboardModel.rNumbers[index].value = e.target.value;
  }
  editRnumber(index: number) {
    runInAction(() => {
      AgendaDashboardModel.rNumbers[index].editYn = !AgendaDashboardModel.rNumbers[index].editYn;
      AgendaDashboardModel.rNumbers[index].value = AgendaDashboardModel.rNumbers[index].originalValue;
    });
  }
  cancelRnumber(index: number) {
    AgendaDashboardModel.rNumbers.splice(index, 1);
  }
  addRnumber() {
    AgendaDashboardModel.rNumbers.push({
      id: '',
      value: '',
      editYn: false,
      originalValue: '',
    });
  }
  async rNumberValidation(rNumber: string) {
    const validationResult = await AgendaDashboardService.rNumberValidation(rNumber);
    if (validationResult.message) {
      await _alert(validationResult.message);
      return false;
    } else if (validationResult.isExists === true) {
      if (await _confirm('다른 화면과 연결된 R번호입니다. 정말 연결하나요?')) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  async saveRnumber(rNumber: string) {
    if (await this.rNumberValidation(rNumber)) {
      AgendaDashboardService.saveRnumber(
        rNumber,
        AgendaDashboardModel.company.cssn,
        AgendaDashboardModel.selectedAgendaProgressId,
      );
    }
  }
  async removeRnumber(rNumberId: string) {
    runInAction(() => {
      portalModel.title = '';
      portalModel.content = <RnumberRemoveModal rNumberId={rNumberId} />;
    });
  }
  async editSaveRnumber(rNumberId: string, rNumber: string) {
    runInAction(() => {
      portalModel.title = '';
      portalModel.content = <RnumberEditModal rNumberId={rNumberId} rNumber={rNumber} />;
    });
  }
  get meetingScheduleChangeLockYn() {
    return AgendaDashboardModel.meetingScheduleChangeLockYn;
  }
  lock(message: string) {
    AgendaDashboardService.lock(AgendaDashboardModel.selectedAgendaProgressId, message);
  }
}
export default new RnumberVm();
