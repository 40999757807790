import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import corpModel from '@model/Corp/corpModel';
import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction } from 'mobx';

import ProvideInformationTermsModal from '../ProvideInformationTermsModal/ProvideInformationTermsModal';

class DelegationPopupVm {
  constructor() {
    this.state = observable({
      agreeYn: false,
    });
  }
  setAgreeYn(e) {
    this.state.agreeYn = e.target.checked;
  }
  async register() {
    if (!this.state.agreeYn) {
      return _alert('정보제공 동의를 해주세요.');
    }

    await bookKeepingContractService.agree(bookKeepingContractModel.term.id);
    runInAction(() => {
      portalModel.title = null;
      portalModel.content = null;
    });
  }
  get delegationRegistrationAgreeYn() {
    return bookKeepingContractModel.delegationRegistrationAgreeYn;
  }
  agreeModal(acctFirmCode) {
    let title = '';

    switch (acctFirmCode) {
      case 'JINPYUNG':
        title = '진평회계법인에 대한 정보제공 동의';
        break;

      case 'BARUN':
        title = '세무회계 바른에 대한 정보제공 동의';
        break;
    }

    portalModel.title = title;
    portalModel.content = <ProvideInformationTermsModal content={bookKeepingContractModel.term.content} />;
  }
  get acctFirmCode() {
    return corpModel.acctFirmCode;
  }
}

export default new DelegationPopupVm();
