import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import vm from './ModalEditVm';
import st from './ModalEdit.module.scss';
import queryString from 'query-string';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';
import clsx from 'clsx';

function ModalEdit(props) {
  const [opened, setOpened] = useState(false);

  return useObserver(() => (
    <div className={st.modalEdit}>
      <button
        type="button"
        className={clsx(st.editBtn, props.disabled ? st.disabled : null)}
        onClick={props.disabled ? () => {} : () => setOpened(!opened)}
      />
      {opened && (
        <form className={st.modal} onSubmit={(e) => vm.submit(e, props.userId)}>
          <button type="button" className={st.close} onClick={() => setOpened(!opened)} />
          <input type="text" name="value" required className={st.inputText} defaultValue={props.value} />
          <button type="submit" className={st.submit}>
            저장
          </button>
        </form>
      )}
    </div>
  ));
}
export default ModalEdit;
