import { observable } from 'mobx';

const userSettingModel = observable({
  userSetData: {
    countryCode: '',
    email: '',
    marketingAcceptEmailYn: false,
    marketingAcceptSmsYn: false,
    marketingAcceptYn: false,
    marketingTermContent: '',
    marketingTermId: null,
    marketingYnChangedDate: null,
    name: '',
    phoneNumber: '',
  },
  setUserSetData(data) {
    this.userSetData = {
      countryCode: data?.countryCode || '',
      email: data?.email || '',
      marketingAcceptEmailYn: data?.marketingAcceptEmailYn || false,
      marketingAcceptSmsYn: data?.marketingAcceptSmsYn || false,
      marketingAcceptYn: data?.marketingAcceptYn || false,
      marketingTermContent: data?.marketingTermContent || '',
      marketingTermId: data?.marketingTermId || null,
      marketingYnChangedDate: data?.marketingYnChangedDate || null,
      name: data?.name || '',
      phoneNumber: data?.phoneNumber || '',
    };
  },
});

export default userSettingModel;
