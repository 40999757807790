import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import st from '@pages/Guest/Component/SecurityContent/SecurityContentMobile.module.scss';
import vm from './SecurityVm';
import SecurityContentMobile from '@src/pages/Guest/Component/SecurityContent/SecurityContentMobile';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';

interface SecurityCheckProps {
  token: string;
  changePage: () => void;
}

function SecurityCheck(props: SecurityCheckProps) {
  const viewport = document.querySelector('meta[name=viewport]');
  useEffect(() => {
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=no');
      return () => {
        viewport.setAttribute('content', 'width=1280');
      };
    }
  }, []);

  return useObserver(() => (
    <SecurityContentMobile>
      <form className={st.form} onSubmit={(e) => vm.submit(e, props.token, props.changePage)}>
        <div className={st.information}>
          {vm.status === 'ACCEPTED' && (
            <div className={st.agreed}>
              <div className={st.title}>{vm.shareholderName} 주주님</div>
              <div className={st.explain}>
                소집절차 생략동의가 완료되었습니다.
                <br />
                감사합니다!
              </div>
              <div className={st.info}>
                {vm.companyName}의 {vm.decisionAt} {vm.regularYn ? '정기' : '임시'}주주총회
              </div>
            </div>
          )}
          {vm.status === 'NORMAL' && (
            <div className={st.normal}>
              <div className={st.companyName}>{vm.companyName}</div>
              <div className={st.checkDocumentType}>주주총회 소집절차 생략동의</div>
              {vm.shareType === 'EMAIL' && (
                <>
                  <div className={st.explain}>
                    보안확인을 위해 메세지를 받은
                    <br />
                    이메일 주소를 입력하세요.
                  </div>
                  <div className={st.inputRow}>
                    <div className={st.label}>
                      <label htmlFor="email">메세지를 받은 이메일 주소*</label>
                    </div>
                    <InputText
                      id="email"
                      fullSize
                      value={vm.state.contact}
                      onChange={(e) => vm.changeContact(e)}
                      name="email"
                      errorText={
                        <Error
                          name="email"
                          value={vm.state.contact}
                          errorCase={{
                            required: '이메일 주소를 입력하세요.',
                            pattern: {
                              value: regExp.email().test(vm.state.contact),
                              message: '올바른 이메일 형식으로 입력해 주세요.',
                            },
                          }}
                        />
                      }
                    />
                  </div>
                </>
              )}
              {vm.shareType === 'MOBILE' && (
                <>
                  <div className={st.explain}>
                    보안확인을 위해 메세지를 받은
                    <br />
                    휴대폰 번호를 입력하세요.
                  </div>
                  <div className={st.inputRow}>
                    <div className={st.label}>
                      <label htmlFor="phoneNumber">메시지를 받은 휴대폰 번호*</label>
                    </div>
                    <InputText
                      id="phoneNumber"
                      fullSize
                      value={vm.state.contact}
                      onChange={(e) => vm.changeContact(e)}
                      name="phoneNumber"
                      errorText={
                        <Error
                          name="phoneNumber"
                          value={vm.state.contact}
                          errorCase={{
                            required: '휴대폰 번호를 입력하세요.',
                            pattern: {
                              value: regExp.phoneNumber().test(vm.state.contact),
                              message: '올바른 휴대폰 번호를 입력해 주세요.',
                            },
                          }}
                        />
                      }
                    />
                  </div>
                </>
              )}
              <div className={st.btnArea}>
                <SquareBtn className={st.nextBtn} type="submit">
                  다음
                </SquareBtn>
              </div>
            </div>
          )}
        </div>
      </form>
    </SecurityContentMobile>
  ));
}
export default SecurityCheck;
