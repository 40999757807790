import { useObserver } from 'mobx-react';
import vm from './HeaderVm';
import Cst from './Header.module.scss';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import clsx from 'clsx';

function Header() {
  return useObserver(() => (
    <div className={Cst.header}>
      <div className={Cst.left}>
        <CopyBtn copyText={vm.company.name} className={Cst.copyBtn} messagePosition="bottom">
          {vm.company.name}
        </CopyBtn>
        <CopyBtn copyText={vm.company.cssn} className={clsx(Cst.copyBtn, Cst.cssn)} messagePosition="bottom">
          {vm.company.cssn}
        </CopyBtn>
        <a className={Cst.downloadBtn} href={vm.company.copyOfRegister.downloadUrl}>
          {vm.company.copyOfRegister.name}
        </a>
        {vm.company.pastNames.length !== 0 && (
          <div className={Cst.pastNames}>구상호 : {vm.company.pastNames.join(', ')}</div>
        )}
      </div>
      <div className={Cst.right}>
        {vm.registrationAgencyType === 'REGISTRATION_AGENCY' && (
          <button
            className={clsx(Cst.registrationAgency, Cst.new)}
            type="button"
            onClick={() => vm.changeRegistrationAgency()}
          >
            신규 등기대행건
          </button>
        )}
        {vm.registrationAgencyType === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY' && (
          <div className={Cst.registrationAgency}>기진행건</div>
        )}
        {!vm.regAgencyCancelInfo && vm.selectedAgendaProgressId && (
          <button type="button" className={Cst.cancelRegBtn} onClick={() => vm.cancelReg()}>
            등기대행 취소
          </button>
        )}
        <Select
          size="small"
          value={vm.selectedAgendaProgressId}
          style={{ minWidth: '400px' }}
          onChange={vm.changeSelectedAgendaProgressId}
          name=""
        >
          <option value="">-</option>
          {vm.agendaProgressSelectList.map((agendaProgress, index) => (
            <option key={index} value={agendaProgress.id}>
              {agendaProgress.name}
            </option>
          ))}
        </Select>
      </div>
    </div>
  ));
}
export default Header;
