import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';
import ChangeRegistrationAgency from './ChangeRegistrationAgency/ChangeRegistrationAgency';

class RegistrationSelfVm {
  openModal(agendaProgressId: string) {
    runInAction(() => {
      portalModel.title = '등기대행으로 변경하기';
      portalModel.content = <ChangeRegistrationAgency agendaProgressId={agendaProgressId} />;
    });
  }
}

export default new RegistrationSelfVm();
