import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';

class AgendaPromotionPopupVm {
  linkBtn(corpId: number | null) {
    window.location.href = `/corp/agenda/step/AGENDA/?corp=${corpId}`;
  }
  closeBtn() {
    runInAction(() => {
      portalModel.title = '';
      portalModel.content = null;
    });
  }
}

export default new AgendaPromotionPopupVm();
