import { observable, makeObservable } from 'mobx';
import type { MeetingOfShareholdersModelInterface, Step1, Step2 } from './MeetingOfShareholdersModel.interface';

class MeetingOfShareholdersModel implements MeetingOfShareholdersModelInterface {
  constructor() {
    makeObservable(this, {
      step1: observable,
      step2: observable,
      nowStep: observable,
    });
  }
  step1: Step1 = {
    chairmanId: '',
    selectableChairmen: [],
    executiveMembers: [],
    shareholders: [],
    normalMeetingYn: false,
  };
  step2: Step2 = {
    totalStockAmount: 0,
    agendaList: [],
  };
  nowStep: '1' | '2' | null = null;
}

export default new MeetingOfShareholdersModel();
