import { useObserver } from 'mobx-react';
import st from './AgendaViewModal.module.scss';
import vm from './AgendaViewModalVm';
import { useEffect } from 'react';
import clsx from 'clsx';
import Agendas from './Agendas/Agendas';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import { addCommas } from '@common/module/replaceNumber';

interface Props {
  agendaProgressId: string;
}

function AgendaViewModal(props: Props) {
  useEffect(() => {
    vm.load(props.agendaProgressId);
  }, []);

  return useObserver(() => (
    <div className={st.agendaView}>
      {(vm.shareholdersMeetingType.meetingType ||
        vm.shareholdersMeetingType.musterNoticeType ||
        vm.newStockType.발행절차_Type ||
        vm.newStockType.배정_Type) && (
        <div className={st.item}>
          <div className={st.title}>진행 방식</div>
          <div className={st.content}>
            <ul className={st.methodList}>
              {vm.shareholdersMeetingType.meetingType && (
                <li>
                  <div className={clsx(st.title, st.notificationMethod)}>주주총회 방식</div>
                  <div className={st.content}>{vm.shareholdersMeetingType.meetingType}</div>
                </li>
              )}
              {vm.shareholdersMeetingType.musterNoticeType && (
                <li>
                  <div className={clsx(st.title, st.notificationMethod)}>소집통지 방식</div>
                  <div className={st.content}>{vm.shareholdersMeetingType.musterNoticeType}</div>
                </li>
              )}
              {vm.newStockType.배정_Type && (
                <li>
                  <div className={clsx(st.title, st.notice_newStock)}>신주배정 방식</div>
                  <div className={st.content}>{vm.newStockType.배정_Type}</div>
                </li>
              )}
              {vm.newStockType.발행절차_Type && (
                <li>
                  <div className={clsx(st.title, st.notice_newStock)}>신주발행 절차</div>
                  <div className={st.content}>{vm.newStockType.발행절차_Type}</div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {vm.agendas.length !== 0 && (
        <div className={st.item}>
          <div className={st.title}>안건</div>
          <div className={clsx(st.content, st.agendas)}>
            <Agendas />
          </div>
        </div>
      )}
      {vm.stockholderInfos.criteriaDate && (
        <div className={st.item}>
          <div className={st.title}>{vm.stockholderInfos.criteriaDate} 기준 주주명부</div>
          <div className={clsx(st.content)}>
            <Table className={st.stockholderTable}>
              <colgroup>
                <col />
                <col width={225} />
                <col width={215} />
                <col width={256} />
              </colgroup>
              <thead>
                <tr>
                  <th>주주명</th>
                  <th style={{ textAlign: 'left' }}>유형</th>
                  <th style={{ textAlign: 'left' }}>주식종류</th>
                  <th style={{ textAlign: 'right' }}>주식수</th>
                </tr>
              </thead>
              <tbody>
                {vm.stockholderInfos.addedShareholder.map((shareholder, index) =>
                  shareholder.stocks.map((stock, index2) => (
                    <tr key={`shareholder${index}stock${index2}`}>
                      {index2 === 0 && (
                        <>
                          <td rowSpan={shareholder.stocks.length}>
                            <div className={st.number}>
                              <span>{index + 1}</span> <span>{shareholder.koreaName}</span>
                            </div>
                          </td>
                          <td rowSpan={shareholder.stocks.length}>
                            {vm.switchShareholderType(shareholder.shareholderType)}
                          </td>
                        </>
                      )}
                      <td>{stock.stockType}</td>
                      <td style={{ textAlign: 'right' }}>{addCommas(stock.stockCount)} 주</td>
                    </tr>
                  )),
                )}
                <tr className={st.shareholderSum}>
                  <td colSpan={3}>
                    <span>총</span>
                    <span className={st.count}>{vm.stockholderInfos.addedShareholder.length}</span>명
                  </td>
                  <td>
                    총<span className={st.count}>{addCommas(vm.shareholdersSum)}</span>주
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  ));
}
export default AgendaViewModal;
