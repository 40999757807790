import { runInAction, observable, toJS } from 'mobx';
import authService from '@service/authService';
import findPasswordModel from '@model/Auth/findPasswordModel';
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class viewModel {
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      authService.findPassword(param);
    }
  }
  removeSessionStorage() {
    authService.removeFindPasswordInfo();
  }
  get email() {
    return findPasswordModel.email;
  }
  setEmail(e) {
    runInAction(() => {
      findPasswordModel.email = regExp.deleteSpace(e.target.value);
    });
  }
}

export default new viewModel();
