import { addCommas } from '@common/module/replaceNumber';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import clsx from 'clsx';

import type { Products, SelectedProducts } from '../../../../types';
import { ProductsDetailBundle } from '../../../../useStep2';
import st from '../../style.module.scss';

interface Props extends ProductsDetailBundle {
  products: Products;
  selectedProducts: SelectedProducts;
}

function ProductDetailSelectionContainer(props: Props) {
  const { products, selectedProducts, selectedProductsDetail, selectInternetDetail, selectTVDetail } = props;

  const isProductSelected = selectedProducts.length > 0;

  return (
    <div className={clsx(st.subCategories, st.box, isProductSelected ? null : st.empty)}>
      {isProductSelected && (
        <>
          {(selectedProducts.includes('TELEPHONE') || selectedProducts.includes('INTERNET')) && (
            <div className={st.tip}>
              <em>기업용 인터넷</em>과 <em>인터넷 전화 동시 신청 시, 캐시백을 추가</em>로 받으실 수 있어요!
            </div>
          )}
          <ul className={st.items}>
            {selectedProducts.includes('INTERNET') && (
              <li className={st.item}>
                <div className={clsx(st.itemTitle, st.rightBtn)} data-type="인터넷">
                  <span>기업용 인터넷</span>
                  <a
                    href="https://standby.kr/blog/article/%EA%B8%B0%EC%97%85%EC%9A%A9_%EC%9D%B8%ED%84%B0%EB%84%B7_%EC%84%A0%ED%83%9D%EC%9D%B4%EC%9C%A0/"
                    target="_blank"
                    className={st.aLink}
                    rel="noreferrer"
                  >
                    법인이 KT 기업용 인터넷을 선택해야 하는 이유
                  </a>
                </div>
                <ul className={st.radioGroup}>
                  {products.INTERNET.map((internet, index) => (
                    <Radio
                      name="products.internet"
                      className={clsx(
                        st.radioItem,
                        internet.id === selectedProductsDetail.INTERNET ? st.checked : null,
                      )}
                      key={index}
                      checked={internet.id === selectedProductsDetail.INTERNET}
                      value={internet.id}
                      onChange={(e) => selectInternetDetail(e.target.value)}
                    >
                      {internet.id === 'IT2' && <div className={st.badge}>추천</div>}
                      <div className={st.explanation}>{internet.explanation}</div>
                      <div className={st.option}>{internet.option}</div>
                      <div className={st.cost}>
                        월 {addCommas(internet.cost)}원 {internet.minimumAmountYn ? '~' : ''}
                      </div>
                    </Radio>
                  ))}
                </ul>
              </li>
            )}
            {selectedProducts.includes('TELEPHONE') && (
              <li className={st.item}>
                <div className={clsx(st.itemTitle, st.rightBtn)} data-type="전화">
                  <span>전화</span>
                  <button type="button" className={st.tooltipBtn} onClick={() => props.openTelephoneTipModal()}>
                    <span>전화기 설치 시 꿀팁</span>
                  </button>
                </div>
                {products.TELEPHONE.map((telephone, index) => (
                  <div className={st.quantityWrap} key={index}>
                    <div className={st.itemInfo}>
                      <div className={st.explanation}>{telephone.explanation}</div>
                      <div className={st.option}>{telephone.option}</div>
                      <div className={st.cost}>
                        월 {addCommas(telephone.cost)}원 {telephone.minimumAmountYn ? '~' : ''}
                      </div>
                    </div>
                    <div className={st.quantity}>
                      <MinusBtn
                        size="large"
                        disabled={props.checkTelephoneQuantityMinusDisabled()}
                        onClick={() => props.setTelephoneQuantity('minus')}
                      />
                      <div className={st.number}>
                        {selectedProductsDetail.TELEPHONE.quantity}
                        {props.showTelephoneToastMessage && (
                          <div className={st.toastMessage}>
                            1588 등 대표번호 신청 시, 수신전환을 위해 <em>2대 이상의 전화</em>가 필요해요!
                          </div>
                        )}
                      </div>
                      <PlusBtn size="large" onClick={() => props.setTelephoneQuantity('plus')} />
                    </div>
                  </div>
                ))}
                <ul className={st.checkList}>
                  {products.TELEPHONE_NUMBER.map((telephone_number, index) => (
                    <li key={index}>
                      <CheckBox
                        value={telephone_number.id}
                        name="products.telephoneNumber"
                        checked={selectedProductsDetail.TELEPHONE_NUMBER.includes(telephone_number.id)}
                        onChange={(e) => props.setTelephoneNumber(e.target.value)}
                      >
                        {telephone_number.option}{' '}
                        <span className={st.won}>
                          (월 {addCommas(telephone_number.cost)}원 {telephone_number.minimumAmountYn ? '~' : ''})
                        </span>
                      </CheckBox>
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {selectedProducts.includes('TV') && (
              <li className={st.item}>
                <div className={st.itemTitle} data-type="TV">
                  TV<span className={st.info}>: KT 인터넷을 쓰고 있는 경우, 개통이 가능해요.</span>
                </div>
                <ul className={st.radioGroup}>
                  {products.TV.map((tv, index) => (
                    <Radio
                      name="products.tv"
                      className={clsx(st.radioItem, tv.id === selectedProductsDetail.TV ? st.checked : null)}
                      key={index}
                      checked={tv.id === selectedProductsDetail.TV}
                      value={tv.id}
                      onChange={(e) => selectTVDetail(e.target.value)}
                    >
                      <div className={st.explanation}>{tv.explanation}</div>
                      <div className={st.option}>{tv.option}</div>
                      <div className={st.cost}>
                        월 {addCommas(tv.cost)}원 {tv.minimumAmountYn ? '~' : ''}
                      </div>
                    </Radio>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
}

export default ProductDetailSelectionContainer;
