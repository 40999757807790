import { useObserver } from 'mobx-react';
import vm from './DetailVm';
import st from './Detail.module.scss';
import clsx from 'clsx';

function Detail() {
  return useObserver(() => (
    <div className={st.detailWrap}>
      <div className={st.wrap}>
        <div className={st.title}>
          <span>스톡옵션</span>
        </div>
        <button
          type="button"
          className={st.openModalBtn}
          onClick={vm.detail?.stockOption.existYn ? vm.agendaLinkModalChange : vm.agendaLinkModalNew}
        >
          변경하기
        </button>
        <ul className={st.detailList}>
          <li>
            <div className={st.title}>규정</div>
            <div className={st.content}>
              <span style={{ fontWeight: '400' }}>
                정관 & 등기부에 스톡옵션 규정 {vm.detail?.stockOption.existYn ? '있음' : '없음'}
              </span>
            </div>
          </li>
          {vm.detail?.stockOption.existYn === true && (
            <li>
              <div className={st.title}>최대한도</div>
              {vm.detail.stockOption.content ? (
                <div className={st.content}>
                  <span style={{ fontWeight: '400' }}>{vm.detail.stockOption.content} =</span>{' '}
                  {vm.detail.stockOption.additionalInformation}
                </div>
              ) : (
                <div className={clsx(st.content, st.empty)}>
                  <div>회사 등록을 마친 후, 확인 가능해요! (최대 1영업일)</div>
                </div>
              )}
            </li>
          )}
        </ul>
      </div>
      <div className={st.wrap}>
        <div className={st.title}>
          <span>주식의 양도제한</span>
        </div>
        <button
          type="button"
          className={st.openModalBtn}
          onClick={vm.detail?.stockTransferRestrictions.existYn ? vm.agendaLinkModalDelete : vm.agendaLinkModalCreate}
        >
          변경하기
        </button>
        <ul className={st.detailList}>
          <li>
            <div className={st.title}>규정</div>
            <div className={st.content}>
              <span style={{ fontWeight: '400' }}>
                정관 & 등기부에 주식양도제한 규정 {vm.detail?.stockTransferRestrictions.existYn ? '있음' : '없음'}
              </span>
            </div>
          </li>
          {vm.detail?.stockTransferRestrictions.existYn === true && (
            <li>
              <div className={st.title}>내용</div>
              {vm.detail.stockTransferRestrictions.content ? (
                <div className={st.content}>
                  <span
                    style={{ fontWeight: '400' }}
                    dangerouslySetInnerHTML={{
                      __html: vm.detail.stockTransferRestrictions.content
                        .replace(/\n/g, '<br/>')
                        .replace(/\\n/g, '<br/>'),
                    }}
                  ></span>
                </div>
              ) : (
                <div className={clsx(st.content, st.empty)}>
                  <div>회사 등록을 마친 후, 확인 가능해요! (최대 1영업일)</div>
                </div>
              )}
            </li>
          )}
        </ul>
      </div>
    </div>
  ));
}
export default Detail;
