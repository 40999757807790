import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';
import { runInAction } from 'mobx';

class NewShareholdersVm {
  shareholderList(idx: number) {
    return meetingsStepModel.tabList[idx].shareholderList;
  }
  newShareholderAssignReason(idx: number) {
    return meetingsStepModel.tabList[idx].newShareholderAssignReason;
  }
  setNewShareholderAssignReason(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].newShareholderAssignReason = e.target.value;
    });
  }
  announceType(idx: number) {
    return meetingsStepModel.tabList[idx].announceType;
  }
  processStatus(idx: number) {
    return meetingsStepModel.tabList[idx].processStatus;
  }
  setProcessStatus(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].processStatus = e.target.value;
    });
  }
}

export default new NewShareholdersVm();
