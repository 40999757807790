import { observable, makeObservable } from 'mobx';
import type { BoardOfDirectorsModelInterface, Step1, Step2 } from './BoardOfDirectorsModel.interface';

class BoardOfDirectorsModel implements BoardOfDirectorsModelInterface {
  constructor() {
    makeObservable(this, {
      step1: observable,
      step2: observable,
      nowStep: observable,
    });
  }
  step1: Step1 = {
    chairmanId: '',
    selectableChairmen: [],
    executives: [],
  };
  step2: Step2 = {
    agendaList: [],
  };
  nowStep: '1' | '2' | null = null;
}

export default new BoardOfDirectorsModel();
