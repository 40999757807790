import { useObserver } from 'mobx-react';
import st from '../Final.module.scss';
import vm from './RegistrationAgencyVm';
import clsx from 'clsx';

function RegistrationAgency() {
  return useObserver(() => (
    <div className={clsx(st.registered, st.content)}>
      <div className={st.title}>
        {vm.registrationAgencyType === 'REGISTRATION_AGENCY' && '등기대행'}
        {vm.registrationAgencyType === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY' && '등기연결'}
      </div>
      <div className={st.contentRow}>
        <div className={st.progressSummary}>
          <div className={st.inside}>
            {vm.registrationAgencyType === 'REGISTRATION_AGENCY' && (
              <>
                <img src="/images/progressSummary_REGISTRATION_AGENCY.svg" />
                <div className={st.texts}>
                  <div className={st.item}>
                    <div className={st.text}>
                      등기대행
                      <br />
                      접수완료
                    </div>
                    <div className={st.date}>{vm.stepsCompletedDate.등기대행_접수완료}</div>
                  </div>
                  <div className={st.item}>
                    <div className={st.text}>견적서 발송</div>
                    <div className={st.date}>{vm.stepsCompletedDate.견적서_발송}</div>
                  </div>
                  <div className={st.item}>
                    <div className={st.text}>결제 완료</div>
                    <div className={st.date}>{vm.stepsCompletedDate.결제_완료}</div>
                  </div>
                  <div className={st.item}>
                    <div className={st.text}>등기소 접수</div>
                    <div className={st.date}>{vm.stepsCompletedDate.등기소_접수}</div>
                  </div>
                  <div className={st.item}>
                    <div className={clsx(st.text, st.important)}>등기완료</div>
                    <div className={st.date}>{vm.stepsCompletedDate.등기완료}</div>
                  </div>
                </div>
              </>
            )}
            {vm.registrationAgencyType === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY' && (
              <>
                <img src="/images/progressSummary_ALREADY_PROGRESSED_REGISTRATION_AGENCY.svg" />
                <div className={clsx(st.texts, st.alreadyProgressed)}>
                  <div className={st.item}>
                    <div className={st.subText}>고객</div>
                    <div className={st.text}>통지/동의 시작</div>
                    <div className={st.date}>{vm.stepsCompletedDate.고객_통지_동의}</div>
                  </div>
                  <div className={st.item}>
                    <div className={st.subText}>스탠바이</div>
                    <div className={st.text}>
                      데이터
                      <br />
                      자동전송
                    </div>
                    <div className={st.date}>{vm.stepsCompletedDate.스탠바이_데이터전송}</div>
                  </div>
                  <div className={st.item}>
                    <div className={st.subText}>법률사무소</div>
                    <div className={st.text}>
                      나머지
                      <br />
                      절차 진행
                    </div>
                    <div className={st.date}>{vm.stepsCompletedDate.법률사무소_절차_진행}</div>
                  </div>
                  <div className={st.item}>
                    <div className={st.subText}>법원</div>
                    <div className={st.text}>등기완료</div>
                    <div className={st.date}>{vm.stepsCompletedDate.등기완료}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={st.contentRow}>
        <div className={st.fileContent}>
          <div className={clsx(st.title, st.manualUpdateIcon)}>등기대행 서류</div>
          <div className={st.fileList}>
            <div className={st.file}>
              <div className={clsx(st.fileTitle, st.receiptIcon)}>의사록 등</div>
              <button
                type="button"
                className={st.downloadBtn}
                onClick={() => vm.fileDown(vm.documents.minutesFile.downloadUrl)}
              >
                <span>다운로드</span>
              </button>
            </div>
            <div className={st.file}>
              <div className={clsx(st.fileTitle, st.receiptIcon)}>등기대행 공과금 영수증</div>
              {vm.documents.receiptFile.downloadUrl ? (
                <button
                  type="button"
                  className={st.downloadBtn}
                  onClick={() => vm.fileDown(vm.documents.receiptFile.downloadUrl)}
                >
                  <span>다운로드</span>
                </button>
              ) : (
                <span className={st.empty}>전달 대기중</span>
              )}
            </div>
            <div className={st.file}>
              <div className={st.fileExText}>세금계산서는 홈택스에서 확인하시면 되어요.</div>
            </div>
            {/* <div className={st.file}>
                            <div className={clsx(st.fileTitle, st.receiptIcon)}>등기대행 최종 견적서</div>
                            <button type='button' className={st.downloadBtn} onClick={() => vm.fileDown(vm.documents.estimateFile.downloadUrl)}><span>다운로드</span></button>
                        </div> */}
          </div>
        </div>
        <div className={st.fileContent}>
          <div className={clsx(st.title, st.autoUpdateIcon)}>자동 업데이트 서류</div>
          <div className={st.fileList}>
            <div className={st.file}>
              <div className={clsx(st.fileTitle, st.registerIcon)}>등기부</div>
              {vm.completed ? (
                <a href={`/corp/register?corp=${vm.corpId}`} className={st.link}>
                  <span>{vm.documents.companyCopyFile.issuedDate} 발급</span>
                </a>
              ) : (
                <span className={st.empty}>진행중</span>
              )}
            </div>
            <div className={st.file}>
              <div className={clsx(st.fileTitle, st.aoaIcon)}>정관</div>
              {vm.completed ? (
                <>
                  {vm.documents.aoaFile.issuedDate && (
                    <a href={`/corp/articlesOfAssociation?corp=${vm.corpId}`} className={st.link}>
                      <span>{vm.documents.aoaFile.issuedDate} 변경</span>
                    </a>
                  )}
                  {!vm.documents.aoaFile.issuedDate && <span className={st.empty}>변동 없음</span>}
                </>
              ) : (
                <span className={st.empty}>진행중</span>
              )}
            </div>
            <div className={st.file}>
              <div className={clsx(st.fileTitle, st.shareholderIcon)}>주주명부</div>
              {vm.completed ? (
                <>
                  {vm.documents.shareholderListFile.issuedDate && (
                    <a href={`/corp/stock/home?corp=${vm.corpId}`} className={st.link}>
                      <span>{vm.documents.shareholderListFile.issuedDate} 변경</span>
                    </a>
                  )}
                  {!vm.documents.shareholderListFile.issuedDate && <span className={st.empty}>변동 없음</span>}
                </>
              ) : (
                <span className={st.empty}>진행중</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
}
export default RegistrationAgency;
