import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import EmptyShareholdersVm from './EmptyShareholdersVm';

function EmptyShareholders() {
  return useObserver(() => (
    <div style={{ width: '700px', border: '1px solid black' }}>
      스탠바이에서 {EmptyShareholdersVm.companyName}의 주주명부를 등록하고 있어요. 등록이 끝나는대로 알림을 드릴게요.
      <br />
      <Link
        to={`/corp/stock/home?corp=${EmptyShareholdersVm.demoCorpId}`}
        style={{ border: '1px solid black' }}
        onClick={() => EmptyShareholdersVm.preview()}
      >
        샘플 컴퍼니 미리 살펴보기
      </Link>
    </div>
  ));
}

export default EmptyShareholders;
