import { useObserver } from 'mobx-react';
import BusinessRegistrationVm from './BusinessRegistrationVm';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import Information from './Information/Information';
import Security from './Security/Security';
import SecurityMobile from './Security/SecurityMobile';
import { isMobile } from 'react-device-detect';

let vm: BusinessRegistrationVm;

function BusinessRegistration(props: { token: string }) {
  useComponentWillMount(() => {
    vm = new BusinessRegistrationVm();
  });

  return useObserver(() => (
    <>
      {vm.state.pageType === 'Security' && (
        <>
          {isMobile ? (
            <SecurityMobile token={props.token} changePage={() => vm.changePage()} />
          ) : (
            <Security token={props.token} changePage={() => vm.changePage()} />
          )}
        </>
      )}
      {vm.state.pageType === 'Information' && <Information />}
    </>
  ));
}
export default BusinessRegistration;
