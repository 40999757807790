import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './BoardOfDirectorsVm';
import queryString from 'query-string';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import st from './BoardOfDirectors.module.scss';

interface BoardOfDirectorsProps {
  minutesId: string;
}

function BoardOfDirectors(props: BoardOfDirectorsProps) {
  let location = useLocation();
  const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;
  const companyId = queryString.parse(location.search).corp as string;

  useEffect(() => {
    vm.load(companyId, agendaProgressId, props.minutesId);
  }, []);

  return useObserver(() => {
    if (vm.nowStep === '1') {
      return <Step1 minutesId={props.minutesId} companyId={companyId} agendaProgressId={agendaProgressId} />;
    } else if (vm.nowStep === '2') {
      return <Step2 minutesId={props.minutesId} companyId={companyId} agendaProgressId={agendaProgressId} />;
    } else {
      return <div className={st.modalContent}></div>;
    }
  });
}
export default BoardOfDirectors;
