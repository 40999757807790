import { useObserver } from 'mobx-react';
import vm from './Step1Vm';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import st from '../MeetingOfShareholders.module.scss';
import { addCommas } from '@common/module/replaceNumber';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { Fragment } from 'react';

interface Step1Props {
  minutesId: string;
  companyId: string;
  agendaProgressId: string;
}

function Step1(props: Step1Props) {
  return useObserver(() => (
    <form className={st.modalContent} onSubmit={(e) => vm.submit(e, props.companyId, props.agendaProgressId)}>
      <div className={st.question}>
        <div className={st.title}>
          주주총회 의장을 선택하세요.
          <div className={st.titleSub}>
            <div className={st.ex}>대표님 중 한 분을 의장으로 선택하세요.</div>
            <div className={st.ex} style={{ marginTop: '2px' }}>
              대표님이 아닌 분이 의장이 되려면 추가적인 이사회 결의를 해야 하는 경우가 많아요.
            </div>
          </div>
        </div>
        <div className={st.content}>
          {vm.step1.selectableChairmen.map((selectableChairmen, index) => (
            <RadioLabel
              key={index}
              name="chairmanId"
              value={selectableChairmen.id}
              checked={selectableChairmen.id === vm.step1.chairmanId}
              onChange={(e) => vm.selectableChairmen(e)}
              className={clsx(st.radio, selectableChairmen.position.includes('대표') ? st.recommend : null)}
            >
              {selectableChairmen.name} {selectableChairmen.position}
            </RadioLabel>
          ))}
          <div>
            <Error
              name="chairmanId"
              value={vm.step1.chairmanId}
              errorCase={{
                required: '의장을 선택하세요.',
              }}
            />
          </div>
          <input type="hidden" value={props.minutesId} name="minutesId" />
        </div>
      </div>
      <div className={st.question}>
        <div className={st.title}>임원의 출석 여부를 선택하세요.</div>
        <div className={st.content}>
          <table className={clsx(st.table, st.executiveMembersTable)} style={{ width: '460px' }}>
            <colgroup>
              <col width="77" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <CheckBox
                    checked={vm.executiveMembersAttendMeetingAll}
                    className={st.checkAll}
                    onChange={(e) => vm.setExecutiveMembersAttendMeetingAll(e)}
                  >
                    출석
                  </CheckBox>
                </th>
                <th>이름</th>
              </tr>
            </thead>
            <tbody>
              {vm.step1.executiveMembers.map((executiveMember, index) => (
                <tr key={index}>
                  <td>
                    <CheckBox
                      className={st.noneText}
                      checked={executiveMember.attendMeetingYn}
                      name="attendMeetingExecutiveMembers"
                      value={executiveMember.id}
                      onChange={(e) => vm.executiveMembersAttendMeeting(e, index)}
                    />
                  </td>
                  <td>
                    <span className={st.index}>{index + 1}</span>
                    <span className={st.name}>{executiveMember.name}</span>
                    <span className={st.position}>{executiveMember.position}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={st.question}>
        <div className={st.title}>
          주주의 출석 여부를 선택하세요.
          <div className={st.titleSub}>
            <div className={st.ex}>주주와 임원을 겸직하는 경우 둘다 선택</div>
          </div>
        </div>
        <div className={st.content}>
          <table className={clsx(st.table, st.shareholdersTable)} style={{ width: '100%' }}>
            <colgroup>
              <col width="77" />
              <col />
              <col width="200" />
              <col width="130" />
              <col width="130" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <CheckBox
                    checked={vm.shareholdersAttendMeetingAll}
                    className={st.checkAll}
                    onChange={(e) => vm.setShareholdersAttendMeetingAll(e)}
                  >
                    출석
                  </CheckBox>
                </th>
                <th>주주명</th>
                <th>주식종류</th>
                <th style={{ textAlign: 'right' }}>주식수</th>
                <th style={{ textAlign: 'right' }}>지분율</th>
              </tr>
            </thead>
            <tbody>
              {vm.step1.shareholders.map((shareholder, index) =>
                shareholder.stocks.map((stock, index2) => (
                  <tr key={index2}>
                    {index2 === 0 && (
                      <Fragment key={index + '.' + index2}>
                        <td rowSpan={shareholder.stocks.length}>
                          <CheckBox
                            className={st.noneText}
                            checked={shareholder.attendMeetingYn}
                            name="attendMeetingShareholderMembers"
                            value={shareholder.id}
                            onChange={(e) => vm.shareholdersAttendMeeting(e, index)}
                          />
                        </td>
                        <td rowSpan={shareholder.stocks.length}>
                          <span className={st.index}>{index + 1}</span>
                          <span className={st.name}>{shareholder.name}</span>
                          {shareholder.executiveId && (
                            <span className={st.positionBadge}>{vm.selectPosition(shareholder.executiveId)}</span>
                          )}
                        </td>
                      </Fragment>
                    )}
                    <td className={st.notFirst}>{stock.name}</td>
                    <td style={{ textAlign: 'right' }}>{addCommas(stock.amount)} 주</td>
                    <td style={{ textAlign: 'right' }}>{stock.percentageOwnership}</td>
                  </tr>
                )),
              )}
            </tbody>
          </table>
          <div className={st.tableSum}>
            {vm.attendMeetingPossibleYn ? (
              <div className={clsx(st.attendMeetingTotal, st.possible)}>
                <img src="/images/possible_icon.svg" />
                충분
              </div>
            ) : (
              <div className={st.attendMeetingTotal}>
                <img src="/images/impossible_icon.svg" />
                부족
              </div>
            )}
            <div className={st.totalCount}>
              총 <span style={{ fontWeight: '700' }}>{addCommas(vm.attendMeetingTotal.count)}</span> 주
            </div>
            <div className={st.totalPercent}>{vm.attendMeetingTotal.percent}%</div>
          </div>
          <div className={st.tooltipWrap}>
            <ToolTip upAndDown="down" leftOrRight="right" iconType="question" tooltipContentStyle={{ width: '225px' }}>
              주주를 선택하면, 상법에 맞는지 자동으로 계산해드려요!
            </ToolTip>
            <span className={st.tooltipText}>의사정족수 자동계산</span>
          </div>
        </div>
      </div>
      <div className={st.btnArea}>
        <SquareBtn type="submit" className={st.contentSubmitBtn}>
          선택 완료
        </SquareBtn>
      </div>
    </form>
  ));
}
export default Step1;
