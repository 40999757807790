import { makeObservable, observable } from 'mobx';

import type { DateInfo, DateListModelInterface } from './dateListModel.interface';

class DateListModel implements DateListModelInterface {
  constructor() {
    makeObservable(this, {
      musterNoticeType: observable,
      processStatus: observable,
      dateList: observable,
      meetingScheduleChangeLockYn: observable,
      newStockResourceTypes: observable,
    });
  }
  onlyRepresentativeChangedAddressYn = false;
  hasCapitalOverBillionYn = false;
  musterNoticeType = '';
  processStatus = '';
  dateList: DateInfo[] = [];
  meetingScheduleChangeLockYn?: boolean = false;
  newStockResourceTypes: string[] = [];
}

export default new DateListModel();
