import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './ContentBox.module.scss';
import clsx from 'clsx';

function ContentBox(props) {
  return useObserver(() => (
    <div style={props.style} className={clsx(st.contentBox, props.className)}>
      {props.title && (
        <div className={st.title} style={props.titleStyle}>
          {props.leftSubTitle && <span className={st.leftSubTitle}>{props.leftSubTitle}</span>}
          <span>{props.title}</span>
          {props.subTitle && <span className={st.subTitle}>{props.subTitle}</span>}
          {props.titleComponent && (
            <div className={st.titleComponent} style={props.titleComponentSyle}>
              {props.titleComponent}
            </div>
          )}
          {props.subTitleBadge && <span className={st.subTitleBadge}>{props.subTitleBadge}</span>}
        </div>
      )}
      {props.children}
    </div>
  ));
}
export default ContentBox;
