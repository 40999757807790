import { useObserver } from 'mobx-react';
import st from '../MeetingOfShareholders.module.scss';
import { useEffect } from 'react';
import vm from './Step2Vm';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import { Fragment } from 'react';
import { addCommas } from '@common/module/replaceNumber';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';

interface Step2Props {
  minutesId: string;
  companyId: string;
  agendaProgressId: string;
}

function Step2(props: Step2Props) {
  useEffect(() => {
    vm.load(props.companyId, props.agendaProgressId, props.minutesId);
  }, []);
  return useObserver(() => (
    <form className={st.modalContent} onSubmit={(e) => vm.submit(e, props.companyId, props.agendaProgressId)}>
      <div className={st.question}>
        <div className={st.title}>찬성여부를 선택하세요.</div>
        <div className={st.content}>
          <input type="hidden" value={props.minutesId} name="minutesId" />
          {vm.step2.agendaList.map((agenda, index) => (
            <div className={st.agenda} key={index}>
              <input type="hidden" value={agenda.type} name={`agendaList[${index}].type`} />
              <div className={st.agendaTitle}>
                <span className={st.agendaIndex}>{index + 1}호 안건</span>
                <span className={st.title}>{agenda.title}</span>
              </div>
              {agenda.normalMeetingYn ? (
                <div className={clsx(st.tooltipWrap, st.tableTooltip)}>
                  <ToolTip
                    upAndDown="up"
                    leftOrRight="right"
                    iconType="question"
                    tooltipContentStyle={{ width: '275px' }}
                  >
                    주주총회 일반결의란 상법 제368조 1항에 따라 보통의 경우 요구되는 결의방법입니다. 임원의 선임,
                    재무제표승인, 임원 구체적 보수액 결정 등이 주주총회 일반결의사항입니다.
                  </ToolTip>
                  <span className={st.tooltipText}>주주총회 일반결의</span>
                </div>
              ) : (
                <div className={clsx(st.tooltipWrap, st.tableTooltip)}>
                  <ToolTip
                    upAndDown="up"
                    leftOrRight="right"
                    iconType="question"
                    tooltipContentStyle={{ width: '275px' }}
                  >
                    주주총회 특별결의란 상법 제434조에 따라 특수한 경우 요구되는 결의방법입니다. 정관의 변경, 상호,
                    목적, 공고방법 변경, 회사의 청산 등이 주주총회 특별결의사항입니다.
                  </ToolTip>
                  <span className={st.tooltipText}>주주총회 특별결의</span>
                </div>
              )}
              <div className={st.agendaTableWrap}>
                <table className={clsx(st.table, st.attendanceTable)} style={{ width: '100%' }}>
                  <colgroup>
                    <col width="77" />
                    <col width="77" />
                    <col width="77" />
                    <col />
                    <col width="200" />
                    <col width="130" />
                    <col width="130" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <CheckBox
                          checked={vm.attendanceAll('찬성', index)}
                          className={st.checkAll}
                          onChange={(e) => vm.setAttendancell(e, '찬성', index)}
                        >
                          찬성
                        </CheckBox>
                      </th>
                      <th>
                        <CheckBox
                          checked={vm.attendanceAll('반대', index)}
                          className={st.checkAll}
                          onChange={(e) => vm.setAttendancell(e, '반대', index)}
                        >
                          반대
                        </CheckBox>
                      </th>
                      <th>
                        <CheckBox
                          checked={vm.attendanceAll('기권', index)}
                          className={st.checkAll}
                          onChange={(e) => vm.setAttendancell(e, '기권', index)}
                        >
                          기권
                        </CheckBox>
                      </th>
                      <th>주주명</th>
                      <th>주식종류</th>
                      <th style={{ textAlign: 'right' }}>주식수</th>
                      <th style={{ textAlign: 'right' }}>지분율</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agenda.shareholders.map((shareholder, index2) =>
                      shareholder.stocks.map((stock, index3) => (
                        <tr key={index3}>
                          {index3 === 0 && (
                            <Fragment key={index2 + '.' + index3}>
                              <td rowSpan={shareholder.stocks.length}>
                                <CheckBox
                                  className={st.noneText}
                                  checked={shareholder.status === '찬성'}
                                  name={`agendaList[${index}].shareholders[${index2}].status`}
                                  value="찬성"
                                  onChange={(e) => vm.status(e, index, index2)}
                                />
                              </td>
                              <td rowSpan={shareholder.stocks.length}>
                                <CheckBox
                                  className={st.noneText}
                                  checked={shareholder.status === '반대'}
                                  name={`agendaList[${index}].shareholders[${index2}].status`}
                                  value="반대"
                                  onChange={(e) => vm.status(e, index, index2)}
                                />
                              </td>
                              <td rowSpan={shareholder.stocks.length}>
                                <CheckBox
                                  className={st.noneText}
                                  checked={shareholder.status === '기권'}
                                  name={`agendaList[${index}].shareholders[${index2}].status`}
                                  value="기권"
                                  onChange={(e) => vm.status(e, index, index2)}
                                />
                              </td>
                              <td rowSpan={shareholder.stocks.length}>
                                <span className={st.index}>{index2 + 1}</span>
                                <span className={st.name}>{shareholder.name}</span>
                                {shareholder.position && (
                                  <span className={st.positionBadge}>{shareholder.position}</span>
                                )}
                                <input
                                  type="hidden"
                                  value={shareholder.id}
                                  name={`agendaList[${index}].shareholders[${index2}].id`}
                                />
                              </td>
                            </Fragment>
                          )}
                          <td className={st.notFirst}>{stock.name}</td>
                          <td className={st.notFirst} style={{ textAlign: 'right' }}>
                            {addCommas(stock.amount)} 주
                          </td>
                          <td className={st.notFirst} style={{ textAlign: 'right' }}>
                            {stock.percentageOwnership}
                          </td>
                        </tr>
                      )),
                    )}
                  </tbody>
                </table>
                <div className={st.tableSum}>
                  {vm.attendanceYn(index) ? (
                    <div className={clsx(st.attendMeetingTotal, st.possible)}>
                      <img src="/images/possible_icon.svg" />
                      충분
                    </div>
                  ) : (
                    <div className={st.attendMeetingTotal}>
                      <img src="/images/impossible_icon.svg" />
                      부족
                    </div>
                  )}
                  <div className={st.totalCount}>
                    총 <span style={{ fontWeight: '700' }}>{addCommas(vm.attendanceTotal(index).count)}</span> 주
                  </div>
                  <div className={st.totalPercent}>{vm.attendanceTotal(index).percent}%</div>
                </div>
                <div className={st.tooltipWrap}>
                  <ToolTip
                    upAndDown="down"
                    leftOrRight="right"
                    iconType="question"
                    tooltipContentStyle={{ width: '225px' }}
                  >
                    주주를 선택하면, 상법에 맞는지 자동으로 계산해드려요!
                  </ToolTip>
                  <span className={st.tooltipText}>의결정족수 자동계산</span>
                </div>
                {((agenda.shareholders.some((shareholder) => !shareholder.status) && vm.state.tryVaild === true) ||
                  (index === 0 && vm.step2.agendaList.length > 1)) && (
                  <div className={st.tableBottom}>
                    {agenda.shareholders.some((shareholder) => !shareholder.status) && vm.state.tryVaild === true && (
                      <ErrorText>찬성/반대/기권 중 하나를 선택하세요.</ErrorText>
                    )}
                    {index === 0 && vm.step2.agendaList.length > 1 && (
                      <div className={st.checkAllBtnWrap}>
                        <button type="button" className={st.checkAllBtn} onClick={() => vm.allCheckAgenda()}>
                          <span>아래 안건들에도 동일하게 체크하기</span>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={st.btnArea}>
        <button type="button" className={st.prevBtn} onClick={() => vm.preStep()}>
          이전 페이지
        </button>
        <SquareBtn type="submit" className={st.contentSubmitBtn}>
          선택 완료
        </SquareBtn>
      </div>
    </form>
  ));
}
export default Step2;
