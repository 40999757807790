import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
class TopWarningVm {
  get rNumbers() {
    return AgendaDashboardModel.rNumbers;
  }
  get regAgencyCancelInfo() {
    return AgendaDashboardModel.agendaProgress.registrationAgency.regAgencyCancelInfo;
  }
}
export default new TopWarningVm();
