import { makeObservable, observable } from 'mobx';

import type { awsCreditModelInterface, Step3 } from './awsCreditModel.interface';

class AwsCreditModel implements awsCreditModelInterface {
  constructor() {
    makeObservable(this, {
      step3: observable,
    });
  }
  step3: Step3 = {
    status: null,
  };
}

export default new AwsCreditModel();
