import { useObserver } from 'mobx-react';
import vm from './TaxScheduleVm';
import st from './TaxSchedule.module.scss';
import clsx from 'clsx';

function TaxSchedule() {
  return useObserver(() => (
    <div className={st.wrap}>
      <div className={st.title}>세금일정</div>
      <ul className={st.taxList}>
        <li>
          <div className={st.title}>법인세</div>
          {vm.aoaRegistrationYn ? (
            <div className={st.content}>
              <div>
                {vm.schedule?.법인세}
                <span style={{ fontWeight: '400' }}>까지</span> 연 1회{' '}
                <span style={{ fontWeight: '400' }}>신고·납부</span>
              </div>
            </div>
          ) : (
            <div className={clsx(st.content, st.empty)}>
              <div>정보 입력중</div>
            </div>
          )}
        </li>
        <li>
          <div className={st.title}>부가세</div>
          <div className={st.content}>
            <div>
              1, 4, 7, 10월 25일<span style={{ fontWeight: '400' }}>까지</span> 연 4회{' '}
              <span style={{ fontWeight: '400' }}>신고·납부</span>
            </div>
            <div className={st.subText}>* 매출액이 작다면, 연 2회만 신고 납부할 수 있어요.</div>
          </div>
        </li>
      </ul>
    </div>
  ));
}
export default TaxSchedule;
