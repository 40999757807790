import { observable, runInAction } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';
import awsCreditService from '@service/Corp/AwsCredit/awsCreditService';
import corpModel from '@model/Corp/corpModel';

class Step1Vm {
  state: {
    tab: { title: string; content: string; opened: boolean }[];
  };
  constructor() {
    this.state = observable({
      tab: [
        {
          title: '이전에 다른 AWS 크레딧을 받은 적이 있는데, 이 혜택도 신청할 수 있나요?',
          content:
            '네, 가능해요.<br/>기존 이력과 무관하게 $2,000(약 270만원)크레딧이 전액 지급돼요. 다만, 메가존 클라우드 신규 고객만 신청할 수 있어요.',
          opened: false,
        },
        {
          title: '진행은 어떻게 되나요?',
          content: `1. [클라우드 지원금 신청하기] 버튼을 눌러주세요.<br/>2. 노출되는 간단한 사전질문에 응답해 주세요.<br/>3. 질문지 제출 후, 영업일 기준 2일 이내 메가존 클라우드에서 전화를 드릴 예정이에요.<br/>4. 전화상담 후, 메가존 클라우드와 계약이 체결되면 $2,000 크레딧이 제공되어요.`,
          opened: false,
        },
      ],
    });
  }
  toggleTab(index: number) {
    runInAction(() => {
      this.state.tab[index].opened = !this.state.tab[index].opened;
    });
  }
  async moveStep2(guestYn: boolean = false) {
    if (guestYn) {
      if (
        await _confirm(
          '스탠바이 회원가입 후 신청 가능해요!',
          'AWS x 메가존 $2,000 크레딧은 스탠바이 등록법인에게만 제공되는 혜택이에요. 회원가입 후 크레딧 신청과 함께 다양한 무료 법인관리 기능을 이용해보세요.',
          '신청하기',
          '닫기',
        )
      ) {
        window.location.href = `/auth/joinmembership`;
      }
    } else {
      await awsCreditService.consult();
      window.location.href = `/corp/awsCredit/application?corp=${corpModel.id}`;
    }
  }
}
export default new Step1Vm();
