import { runInAction, observable, toJS } from 'mobx';
import RedirectPageService from '@service/Corp/RedirectPage/RedirectPageService';

class RedirectPageVm {
  load(id) {
    RedirectPageService.redirect(id);
  }
}

export default new RedirectPageVm();
