import regExp from '@common/module/regExp';
import { Button, InfoBox, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import st from './FindPassword.module.scss';
import FindPasswordVm from './FindPasswordVm';

function FindPassword() {
  useEffect(() => {
    FindPasswordVm.removeSessionStorage();
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => FindPasswordVm.submit(e)} className={st.FindPasswordContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-18M">스탠바이에 가입된 </Typography>
        <Typography fontType="Title-18B">이메일을 입력</Typography>
        <Typography fontType="Title-18M">하세요.</Typography>
      </div>
      <TextField
        name="email"
        className={st.inputEmail}
        placeholder="example@logologo.com"
        value={FindPasswordVm.email}
        onChange={FindPasswordVm.setEmail}
        label="이메일"
        error={
          <ErrorValidation
            name="email"
            value={FindPasswordVm.email}
            errorCase={{
              required: '이메일을 입력해 주세요',
              pattern: {
                value: regExp.email().test(FindPasswordVm.email),
                message: '올바른 이메일 형식으로 입력해 주세요.',
              },
            }}
          />
        }
      />
      <InfoBox className={st.infoBox}>입력한 이메일로 비밀번호 재설정 링크를 보내드려요.</InfoBox>
      <Button type="submit" active={FindPasswordVm.email} size="large" color="primary400" className={st.mainBtn}>
        비밀번호 재설정하기
      </Button>
    </form>
  ));
}

export default FindPassword;
