import FinalService from '@service/Corp/Agenda/Step/FinalCheck/Final/FinalService';
import type { File } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import { runInAction } from 'mobx';

class FileVm {
  fileDown(url: string) {
    FinalService.fileDown(url);
  }
  async deleteFile(companyId: string, agendaProgressId: string, id: string, type: string, fileOBj: File) {
    await FinalService.deleteFile(companyId, agendaProgressId, id, type);
    fileOBj.downloadUrl = '';
  }
  async uploadFile(
    file: FileList,
    companyId: string,
    agendaProgressId: string,
    id: string,
    type: string,
    fileOBj: File,
  ) {
    const uploadFileData = await FinalService.uploadFile(file, companyId, agendaProgressId, id, type);
    runInAction(() => {
      fileOBj.downloadUrl = uploadFileData.downloadUrl;
    });
  }
}
export default new FileVm();
