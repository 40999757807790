import { useObserver } from 'mobx-react';
import { Link, Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import SideVm from './SideVm';
import st from './Side.module.scss';
import clsx from 'clsx';
import ScrollArea from 'react-custom-scrollbars-2';

function Css(text) {
  switch (text) {
    case 'NEW':
      return st.new;
    case '등록중':
    case '진행중':
    case '신고 진행중':
    case '결산 진행중':
    case '개통 진행중':
      return st.ongoing;
    case '등록완료':
    case '신고완료':
    case '신고완료(국세)':
    case '신고완료(지방세)':
    case '계약완료':
    case '반영완료':
    case '개통완료':
      return st.complete;
    case '자료제출 대기중':
    case '계좌 입력중':
      return st.waiting;
    case '고지세액 안내':
    case '안내완료':
    case '업로드필요':
      return st.guide;
    case '등기완료':
      return st.registerComplete;
    default:
      return st.waiting;
  }
}

function Side() {
  let location = useLocation();
  const selectedMenu = useRef();

  useEffect(() => {
    selectedMenu.current?.scrollIntoView();
  }, [location.pathname]);

  return useObserver(() => (
    <div id={st.side}>
      <div className={st.companySelectOpen}>
        <button type="button">{SideVm.corp.companyName}</button>
        <div className={st.selectCompany}>
          <Link to="/">
            <div className={st.title}>등록 법인 목록</div>
          </Link>
          <div className={st.list}>
            <ScrollArea
              className={st.companyListScrollArea}
              renderView={({ style, props }) => (
                <div {...props} style={{ ...style, position: 'none', maxHeight: '288px' }} />
              )}
              renderThumbVertical={(props) => <div {...props} style={{ width: '3px', backgroundColor: '#D6D8DA' }} />}
            >
              <ul>
                {SideVm.companies.map((company, index) => (
                  <li key={index} className={company.companyId === SideVm.id ? st.active : ''}>
                    <a href={`/corp/redirect?corp=${company.companyId}`}>
                      {company.isDemoYn && (
                        <span className={clsx(st.demo, company.companyId === SideVm.id ? st.active : '')}>demo</span>
                      )}
                      <span>{company.companyName}</span>
                    </a>
                    {company.status && <span className={st.tag}>등록중</span>}
                  </li>
                ))}
              </ul>
            </ScrollArea>
          </div>
          <Link className={st.bottomLink} to="/companyRegistration">
            <span className={st.addIcon}>내 법인 추가하기</span>
          </Link>
          {/* {SideVm.companies.length !== 1 && 
                        <Link className={st.bottomLink} to="/userDashboard">
                            <span>계정 대시보드</span>
                        </Link>
                    }
                    {SideVm.companies.length === 1 && 
                        <Link className={st.bottomLink} to="/companyRegistration">
                            <span className={st.addIcon}>내 법인 추가하기</span>
                        </Link>
                    } */}
        </div>
      </div>
      <ScrollArea
        className={st.scrollArea}
        renderView={({ style, props }) => <div {...props} style={{ ...style, overflow: 'auto', marginBottom: '0' }} />}
        renderThumbVertical={(props) => <div {...props} style={{ width: '3px', backgroundColor: '#D6D8DA' }} />}
      >
        <nav>
          {!(SideVm.corpType === '유한회사' || SideVm.corpType === '유한책임회사') && (
            <NavBox title="Home" pathName="home" imgLink="/images/sideNavIcon_Home" location={location}>
              <SideLink
                type="DB"
                menuName="한 눈에 보는 우리회사"
                path="/corp/dashboard"
                boxTag={SideVm.menu?.dashboardTagName}
                selectedMenu={selectedMenu}
                location={location}
              />
            </NavBox>
          )}
          <NavBox title="회사정보" pathName="companyInfo" imgLink="/images/sideNavIcon_회사정보" location={location}>
            <SideLink
              type="AOA"
              menuName="정관"
              path="/corp/articlesOfAssociation"
              tag={SideVm.menu?.articlesOfAssociationTagName}
              selectedMenu={selectedMenu}
              location={location}
            />
            <SideLink
              type="CR"
              menuName="등기부"
              path="/corp/register"
              tag={SideVm.menu?.copyFileTagName}
              selectedMenu={selectedMenu}
              location={location}
            />
            {SideVm.useYn && (
              <SideLink
                type="BR"
                menuName="사업자등록"
                path="/corp/accounting/businessRegistration"
                tag={SideVm.tagName}
                selectedMenu={selectedMenu}
                location={location}
              />
            )}
          </NavBox>
          {!(SideVm.corpType === '유한회사' || SideVm.corpType === '유한책임회사') && (
            <NavBox
              title="자동화 서비스"
              pathName="agenda"
              imgLink="/images/sideNavIcon_자동화_서비스"
              location={location}
            >
              <SideLink
                type="AG"
                menuName="주주총회/이사회/등기"
                path="/corp/agenda/progress"
                boxTag={SideVm.menu?.agendaTagName}
                selectedMenu={selectedMenu}
                location={location}
              />
            </NavBox>
          )}
          {!(SideVm.corpType === '유한회사' || SideVm.corpType === '유한책임회사') && (
            <NavBox
              title="주주/주식"
              pathName="shareholder"
              imgLink="/images/sideNavIcon_주주_주식"
              location={location}
            >
              <SideLink
                type="SH"
                menuName="주주명부"
                path="/corp/stock/home"
                tag={SideVm.menu?.stockholderTagName}
                selectedMenu={selectedMenu}
                location={location}
              />
            </NavBox>
          )}
          {/* <div className={st.navBox}>
                        <div className={st.linkTitle} style={{"backgroundImage":'url(/images/sideNavIcon_회계.svg)'}}>회계</div>
                        <div className={st.sideLinkList}>
                            <Link className={clsx(st.sideLink)}>세전순이익</Link>
                            <Link className={clsx(st.sideLink)}>매출</Link>
                            <Link className={clsx(st.sideLink)}>비용</Link>
                        </div>
                    </div> */}
          {/* <div className={st.navBox}>
                        <div className={st.linkTitle} style={{"backgroundImage":'url(/images/sideNavIcon_임직원.svg)'}}>임직원</div>
                        <div className={st.sideLinkList}>
                            <Link className={clsx(st.sideLink)}>임원목록</Link>
                            <Link className={clsx(st.sideLink)}>직원목록</Link>
                            <Link className={clsx(st.sideLink)}>급여대장</Link>
                        </div>
                    </div> */}
          <NavBox title="세금" pathName="tax" imgLink="/images/sideNavIcon_세금" location={location}>
            <SideLink
              type="BC"
              menuName="기장계약"
              path="/corp/accounting/bookKeepingContract"
              tag={SideVm.menu?.bookkeepingContractTagName}
              addTagText=" with 회계 사무소"
              selectedMenu={selectedMenu}
              location={location}
            />
            <SideLink
              type="VAT"
              menuName="부가세"
              path="/corp/accounting/vat"
              tag={SideVm.menu?.valueAddedTaxTagName}
              selectedMenu={selectedMenu}
              location={location}
            />
            <SideLink
              type="CT"
              menuName="법인세"
              path="/corp/accounting/corporateTax"
              tag={
                SideVm.menu?.corporateTaxTagName !== '신고완료(국세)' &&
                SideVm.menu?.corporateTaxTagName !== '신고완료(지방세)'
                  ? SideVm.menu?.corporateTaxTagName
                  : null
              }
              boxTag={
                SideVm.menu?.corporateTaxTagName === '신고완료(국세)' ||
                SideVm.menu?.corporateTaxTagName === '신고완료(지방세)'
                  ? SideVm.menu?.corporateTaxTagName
                  : null
              }
              selectedMenu={selectedMenu}
              location={location}
            />
          </NavBox>
          <NavBox
            title="스탠바이가 보증하는 특별혜택"
            pathName="contract"
            imgLink="/images/sideNavIcon_전문가_서비스"
            location={location}
            letterNarrow
          >
            <SideLink
              type="IT"
              menuName="기업용 인터넷·전화 캐시백"
              path="/corp/internetAssociate"
              boxTag={SideVm.menu?.internetAssociateTagName}
              selectedMenu={selectedMenu}
              location={location}
            />
            <SideLink
              type="AC"
              menuName="클라우드 지원금 270만원"
              path="/corp/awsCredit"
              selectedMenu={selectedMenu}
              location={location}
            />
          </NavBox>
        </nav>
      </ScrollArea>
      <div className={st.blogWrap}>
        <div className={st.blogContent}>
          <ul className={st.guideList}>
            <li>
              <div className={st.guideTitle}>꼭 알아야 할</div>
              <ul className={st.linkList}>
                <li>
                  <a
                    href="https://standby.kr/blog/category/%EB%B2%95%EC%9D%B8%EC%84%A4%EB%A6%BD%20%EC%A7%81%ED%9B%84%20%ED%95%A0%20%EC%9D%BC/"
                    target="_blank"
                  >
                    법인 운영 기본 가이드
                  </a>
                </li>
              </ul>
              <ul className={st.linkList}>
                <li>
                  <a href="https://standbylab.featurebase.app/ko" target="_blank">
                    개선요청/오류신고
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Link className={st.adminSetting} to={`/corp/adminSetting?corp=${SideVm.id}`}>
        담당자 추가/변경
      </Link>
    </div>
  ));
}

function IsAuthorityYn(props) {
  return useObserver(() =>
    SideVm.authorityYn(props.type) ? (
      <>{props.children}</>
    ) : (
      <span className={clsx(st.sideLink, st.authorityFalse)} onClick={() => SideVm.authorityFalseAlert(props.menuName)}>
        {props.menuName}
      </span>
    ),
  );
}

function SideLink(props) {
  let activeYn = false;
  let refPath = props.path;
  if (props.menuName === '주주명부') {
    if (props.location.pathname.includes('/corp/stock')) {
      activeYn = true;
      refPath = '/corp/stock';
    }
  } else if (props.menuName === '주주총회/이사회/등기') {
    if (props.location.pathname.includes('agenda')) {
      activeYn = true;
    }
  } else if (props.menuName === '클라우드 지원금 270만원') {
    if (props.location.pathname.includes('/corp/awsCredit')) {
      activeYn = true;
    }
  } else if (props.menuName === '기업용 인터넷·전화 캐시백') {
    if (props.location.pathname.includes('/corp/internetAssociate')) {
      activeYn = true;
    }
  } else {
    if (props.location.pathname === props.path) {
      activeYn = true;
    }
  }

  return useObserver(() => (
    <IsAuthorityYn type={props.type} menuName={props.menuName}>
      <Link
        className={clsx(st.sideLink, activeYn ? st.active : '')}
        ref={props.location.pathname === refPath ? props.selectedMenu : null}
        to={`${props.path}?corp=${SideVm.id}`}
      >
        {props.menuName}
        {props.tag ? (
          <span className={st.tag}>
            <span className={Css(props.tag)}>{props.tag}</span>
          </span>
        ) : (
          `${props.addTagText || ''}`
        )}
        {props.boxTag && (
          <div>
            <div className={st.boxTag}>
              <span className={Css(props.boxTag)}>{props.boxTag}</span>
            </div>
          </div>
        )}
      </Link>
    </IsAuthorityYn>
  ));
}

function NavBox(props) {
  return useObserver(() => (
    <div className={st.navBox}>
      <div
        className={clsx(
          st.linkTitle,
          SideVm.activeType(props.location.pathname) === props.pathName ? st.active : '',
          props.letterNarrow ? st.narrow : '',
        )}
        style={{
          backgroundImage: `url(${SideVm.activeType(props.location.pathname) === props.pathName ? `${props.imgLink}_active.svg` : `${props.imgLink}.svg`})`,
        }}
      >
        {props.title}
      </div>
      <div className={st.sideLinkList}>{props.children}</div>
    </div>
  ));
}

export default Side;
