import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import vm from './AccountInformationVm';
import bst from '../Basic.module.scss';
import st from './AccountInformation.module.scss';
import clsx from 'clsx';

function AccountInformation() {
  return useObserver(() => (
    <>
      <div className={bst.registraionRow}>
        <div className={bst.registrationTitle}>
          <span className={bst.bold}>사업용 계좌개설 방법</span>
          <div className={bst.registrationSubTitle}>
            축하합니다!
            <br />
            <span className={bst.small}>
              이제 법인용 은행계좌를 만들어 보세요.
              <br />
              어렵지 않아요, 총 3단계입니다.
            </span>
          </div>
        </div>
        <div className={bst.registrationContent}>
          <div className={st.accountEstablishWay}>
            <div className={st.accountStepBox}>
              <div className={st.titleBox}>
                <img src="/images/accountStep1_icon.svg" />
                <div className={st.title}>
                  1단계<span className={st.bold}>사업자 등록 끝내기</span>
                </div>
              </div>
              <div className={st.explainBox}>
                <div className={clsx(st.tip, st.nonTipIcon)}>먼저, 사업자등록이 끝날때까지 기다리세요.</div>
                <div className={st.tipExplain}>
                  - 계좌를 만들려면 사업자등록증이 반드시 필요해요. 사업자등록이 끝날때까지 조금만 기다려주세요.
                </div>
              </div>
            </div>
            <div className={st.accountStepBox}>
              <div className={st.titleBox}>
                <img src="/images/accountStep2_icon.svg" />
                <div className={st.title}>
                  2단계<span className={st.bold}>서류 준비하기</span>
                </div>
              </div>
              <div className={st.explainBox}>
                <div className={st.tip}>
                  <span className={st.tipIcon}>TIP 1</span>꼭 필요한 서류를 준비해주세요.
                </div>
                <div className={st.tipExplain}>- 아래 서류는 필수 서류예요. 하나씩 다운로드 하세요.</div>
                <div className={st.documentInfo}>
                  <div className={st.download}>
                    <div className={st.title}>다운로드할 서류</div>
                    <div className={st.infoList}>
                      <div className={st.downloadDocument}>
                        <div className={st.documentTitle}>등기부등본</div>
                        <Link to={`/corp/register?corp=${vm.corpId}`} className={st.downloadBtn}>
                          다운로드
                        </Link>
                      </div>
                      <div className={st.downloadDocument}>
                        <div className={st.documentTitle}>정관</div>
                        <Link to={`/corp/articlesOfAssociation?corp=${vm.corpId}`} className={st.downloadBtn}>
                          다운로드
                        </Link>
                      </div>
                      <div className={st.downloadDocument}>
                        <div className={st.documentTitle}>{vm.text}명부</div>
                        {(vm.corpType === '유한회사' || vm.corpType === '유한책임회사') && (
                          <button type="button" className={st.downloadBtn} onClick={() => vm.downloadRosterList()}>
                            다운로드
                          </button>
                        )}
                        {vm.corpType === '주식회사' && (
                          <Link to={`/corp/stock/shareholderList?corp=${vm.corpId}`} className={st.downloadBtn}>
                            다운로드
                          </Link>
                        )}
                      </div>
                      <div
                        className={st.downloadDocument}
                        style={{ marginBottom: vm.statusType !== '사업자등록_완료' ? '18px' : '' }}
                      >
                        <div className={st.documentTitle}>사업자등록증</div>
                        <button
                          className={clsx(st.downloadBtn, vm.statusType !== '사업자등록_완료' ? st.disabled : null)}
                          onClick={() => vm.downloadRegistraionFile()}
                        >
                          다운로드
                        </button>
                        {vm.statusType !== '사업자등록_완료' && (
                          <div className={st.registraionAlert}>사업자등록 완료 후 다운로드 가능해요!</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={st.supply}>
                    <div className={st.title}>별도 준비물</div>
                    <div className={st.infoList}>
                      <div className={clsx(st.supplyItem, st.helpme)}>
                        <div className={clsx(st.supplyTitle, st.companyStamp)}>법인도장</div>
                      </div>
                      <div className={st.supplyItem}>
                        <div className={clsx(st.supplyTitle, st.companyCertifi)}>
                          법인 인감증명서
                          <br />
                          (원본 필수)
                        </div>
                      </div>
                      <div className={st.supplyItem}>
                        <div className={st.supplyTitle}>대표님 신분증</div>
                      </div>
                      <div className={st.supplyItem}>
                        <div className={st.supplyTitle}>임대차 계약서</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={st.explainBox}>
                <div className={st.tip}>
                  <span className={st.tipIcon}>TIP 2</span>계좌 비밀번호와 인터넷뱅킹 아이디를 미리 정하세요.
                  <br />
                  비밀번호와 아이디는 개인 계좌와 다른 것으로 정하면 더 좋아요.
                </div>
              </div>
            </div>
            <div className={st.accountStepBox}>
              <div className={st.titleBox}>
                <img src="/images/accountStep3_icon.svg" />
                <div className={st.title}>
                  3단계<span className={st.bold}>은행 방문하기</span>
                </div>
              </div>
              <div className={st.explainBox}>
                <div className={st.tip}>
                  <span className={st.tipIcon}>TIP 1</span>대표님이 평소 거래하시던 은행 지점에 방문하세요!
                </div>
                <div className={st.tipExplain}>
                  - 거래가 없는 지점이라면 대부분 하루 이체 한도가 30만원인 계좌만 개설 가능해요.
                </div>
              </div>
              <div className={st.explainBox}>
                <div className={st.tip}>
                  <span className={st.tipIcon}>TIP 2</span>계좌를 만들 때는 반드시 아래 3가지를 함께 받아오세요.
                </div>
                <div className={st.tipBox}>
                  <div>1. 인터넷뱅킹 사용 신청</div>
                  <div>2. 공동인증서</div>
                  <div>3. OTP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={bst.registraionRow}>
        <div className={bst.registrationTitle}>
          <span className={bst.bold}>계좌개설 FAQ</span>
        </div>
        <div className={bst.registrationContent}>
          <div className={st.faq}>
            <div className={st.qnaBox}>
              <div className={st.question}>은행에 대리인이 방문해도 계좌를 만들 수 있나요?</div>
              <div className={st.answer}>
                네! 다만 <span className={st.bold}>대리인이 방문할때는 추가 준비물이 있어요.</span>
                <div className={st.answerExplain}>
                  1. 대리인 신분증
                  <br />
                  2. 위임장
                  <br />
                  위임장 양식은 은행마다 달라요.{' '}
                  <a
                    className={st.aLink}
                    href="https://drive.google.com/drive/folders/1r855R-laW8oe6BVizjJQmkCKmJG1XlNM"
                    target="_blank"
                  >
                    이 곳
                  </a>
                  에서 다운로드 받으세요.
                </div>
              </div>
            </div>
            <div className={st.qnaBox}>
              <div className={st.question}>공동인증서는 왜 발급받아야 해요?</div>
              <div className={st.answer}>
                <span className={st.bold}>인터넷뱅킹을 이용</span>하려면 공동인증서가 꼭 있어야 해요. 법인은 편리한
                전자인증서(네이버, 카카오 등)를 이용할 수 없어요.
              </div>
            </div>
            <div className={st.qnaBox}>
              <div className={st.question}>
                개설한 계좌의 일일 이체 한도가 30만 원이라 너무 적습니다. 어떻게 늘리죠?
              </div>
              <div className={st.answer}>
                이미 은행에서 이체한도가 30만 원이라고 안내한다면,{' '}
                <span className={st.bold}>
                  대표님이 원래 거래하던 주거래 은행의 지점을 찾아가서 계좌를 새로 만드는게 가장 빠른 해결방법이에요.
                </span>{' '}
                하지만 그렇게 할 수 없다면, 이미 계좌를 개설한 은행의 직원에게 방법을 자세히 물어봐야 해요.
                <div style={{ marginTop: '4px' }}>
                  보통은 1) 회계 사무소와 작성한 기장계약서와 2) 최초로 발행한 세금 계산서를 가져가면 한도를 조금 더
                  빨리 늘려줍니다.
                </div>
              </div>
            </div>
            <div className={st.qnaBox}>
              <div className={st.question}>계좌를 만드는데 왜이렇게 까다롭나요?</div>
              <div className={st.answer}>
                <span className={st.bold}>허위법인을 만들어서 보이스피싱에 이용</span>한 사람들이 있어서 그래요. 새로
                만든 법인의 계좌가 보이스피싱에 이용된다면, 계좌를 만들어 준 은행의 지점이 불이익을 받기 때문에 까다롭게
                장치를 만들어 둔 것이에요.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ));
}
export default AccountInformation;
