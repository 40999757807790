import { useObserver } from 'mobx-react';
import { useState } from 'react';

import st from './Guide.module.scss';
import GuideVm from './GuideVm';

function Guide(props) {
  const localStorageValue = () => {
    let hideIdList = [];

    if (localStorage.getItem(props.localStorageKey)) {
      hideIdList = localStorage.getItem(props.localStorageKey).split(',');
    }
    if (hideIdList.includes(props.corpId)) {
      return false;
    }

    return true;
  };
  const [vm, setVm] = useState(new GuideVm(localStorageValue()));

  return useObserver(() => (
    <div className={st.guide}>
      <div className={st.titleRow}>
        <span className={st.title}>{props.title}</span>
        <input
          type="checkbox"
          checked={vm.state.guideDisplayYn}
          onChange={(e) => vm.guideHideState(e, props.localStorageKey)}
          id={props.localStorageKey}
          className={st.hideInput}
        />
        <label htmlFor={props.localStorageKey} className={st.hideLabel}></label>
      </div>
      {vm.state.guideDisplayYn === true && <div className={st.contentRow}>{props.children}</div>}
    </div>
  ));
}

export default Guide;
