import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import userDashboardModel from '@model/userDashboardModel';
import { pageRouter } from '@model/pageModel';

class userDashboardService {
  async load() {
    const res = await get('/user/dashboard');
    userDashboardModel.setCompaniesInfo(res.data.companies);
    userDashboardModel.setAcceptWaitingCompanies(res.data);
  }
  async businessRegistration() {
    const res = await post(`/user/dashboard/businessRegistration`);
    pageRouter.replace(`/corp/accounting/businessRegistration?corp=${res.data.companyId}`);
  }
}

export default new userDashboardService();
