import { useObserver } from 'mobx-react';
import { useState } from 'react';
import vm from './CompanyInfoVm';
import st from './CompanyInfo.module.scss';
import clsx from 'clsx';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import copyText from '@standby/common-ui/module/copyText';
import ToastMessage from '@standby/common-ui/components/atoms/ToastMessage/ToastMessage';

interface CopyBtnWithToastProps {
  className: string;
  toastMessageClassName: string;
  copyText: string;
  children?: string;
}

function CopyBtnWithToast(props: CopyBtnWithToastProps) {
  const [showYn, setShowYn] = useState<boolean>(false);

  const _onClick = (_copyText: string) => {
    setShowYn(true);
    copyText(_copyText);
    setTimeout(() => setShowYn(false), 2000);
  };

  return useObserver(() => (
    <div className={st.toastMessageBtnBox}>
      {showYn && <ToastMessage className={props.toastMessageClassName}>복사 되었어요!</ToastMessage>}
      <button type="button" className={props.className} onClick={() => _onClick(props.copyText)}>
        {props.children}
      </button>
    </div>
  ));
}

function CompanyInfo() {
  return useObserver(() => (
    <div className={st.companyInfo}>
      <div className={st.number}>
        <div className={clsx(st.businessNumber, st.numberBox)}>
          <div className={st.boxIcon} />
          <div className={st.title}>법인등록번호</div>
          <div className={st.copyWrap}>
            <CopyBtnWithToast
              className={st.copyBtn}
              toastMessageClassName={st.toastMessage}
              copyText={vm.dashboardData?.cssn.split('-')[0] || ''}
            >
              {vm.dashboardData?.cssn.split('-')[0]}
            </CopyBtnWithToast>
            -
            <CopyBtnWithToast
              className={st.copyBtn}
              toastMessageClassName={st.toastMessage}
              copyText={vm.dashboardData?.cssn.split('-')[1] || ''}
            >
              {vm.dashboardData?.cssn.split('-')[1]}
            </CopyBtnWithToast>
            <CopyBtnWithToast
              className={st.copyIcon}
              toastMessageClassName={st.toastMessage}
              copyText={vm.dashboardData?.cssn || ''}
            />
          </div>
        </div>
        <div className={clsx(st.companyNumber, st.numberBox)}>
          <div className={st.boxIcon} />
          <div className={st.title}>사업자등록번호</div>
          <div className={st.copyWrap}>
            {vm.dashboardData?.businessRegistration.businessRegistrationNumber ? (
              <>
                <CopyBtnWithToast
                  className={st.copyBtn}
                  toastMessageClassName={st.toastMessage}
                  copyText={vm.dashboardData?.businessRegistration.businessRegistrationNumber.split('-')[0] || ''}
                >
                  {vm.dashboardData?.businessRegistration.businessRegistrationNumber.split('-')[0]}
                </CopyBtnWithToast>
                -
                <CopyBtnWithToast
                  className={st.copyBtn}
                  toastMessageClassName={st.toastMessage}
                  copyText={vm.dashboardData?.businessRegistration.businessRegistrationNumber.split('-')[1] || ''}
                >
                  {vm.dashboardData?.businessRegistration.businessRegistrationNumber.split('-')[1]}
                </CopyBtnWithToast>
                -
                <CopyBtnWithToast
                  className={st.copyBtn}
                  toastMessageClassName={st.toastMessage}
                  copyText={vm.dashboardData?.businessRegistration.businessRegistrationNumber.split('-')[2] || ''}
                >
                  {vm.dashboardData?.businessRegistration.businessRegistrationNumber.split('-')[2]}
                </CopyBtnWithToast>
                <CopyBtnWithToast
                  className={st.copyIcon}
                  toastMessageClassName={st.toastMessage}
                  copyText={vm.dashboardData?.businessRegistration.businessRegistrationNumber || ''}
                />
              </>
            ) : (
              <div className={st.emptyText}>{vm.dashboardData?.businessRegistration.businessRegistrationStatus}</div>
            )}
          </div>
        </div>
      </div>
      <div className={st.yearInfo}>
        <div className={st.row}>
          <div className={st.item}>
            <div className={clsx(st.title, st.companyIcon, st.text)}>법인설립일</div>
            <CopyBtn
              copyText={vm.dashboardData?.establishedAt}
              className={st.copyText}
              messageClassName={st.copyMessage}
            >
              <span className={st.text}>{vm.dashboardData?.establishedAt}</span>
            </CopyBtn>
          </div>
          <div className={st.item}>
            <div className={clsx(st.title, st.homeIcon, st.text)}>개업 연월일</div>
            {vm.dashboardData?.businessRegistration.businessStartAt ? (
              <CopyBtn
                copyText={vm.dashboardData.businessRegistration.businessStartAt}
                className={st.copyText}
                messageClassName={st.copyMessage}
              >
                <span className={st.text}>{vm.dashboardData.businessRegistration.businessStartAt}</span>
              </CopyBtn>
            ) : (
              <span className={clsx(st.empty, st.text)}>정보 입력 중</span>
            )}
          </div>
        </div>
        <div className={st.row}>
          <div className={st.item}>
            <div className={clsx(st.title, st.bagIcon, st.text)}>사업연도</div>
            {vm.dashboardData?.businessYear ? (
              <span className={st.text}>{vm.dashboardData.businessYear}</span>
            ) : (
              <span className={clsx(st.empty, st.text)}>정보 입력 중</span>
            )}
          </div>
        </div>
      </div>
    </div>
  ));
}
export default CompanyInfo;
