import ChannelService from '@common/module/ChannelService';
import applicationModel from '@model/applicationModel';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import corpModel from '@model/Corp/corpModel';
import { dialogModel } from '@model/dialogModel';
import loadingModel from '@model/loadingModel';
import portalModel from '@model/portalModel';
import userModel from '@model/userModel';
import presetService from '@service/presetService';
import socketService from '@service/socketService';
import Cookies from 'js-cookie';
import { reaction, runInAction } from 'mobx';
import ReactGA from 'react-ga4';

class AppVm {
  async loginedStart() {
    if (!userModel.user.name || !userModel.user.email) {
      await presetService.load();
    }
  }
  socketDisConnect() {
    void socketService.disConnect();
  }
  get user() {
    return userModel.user;
  }
  get isLogined() {
    if ((Cookies.get('accessToken') && Cookies.get('refreshToken')) || localStorage.getItem('rememberMeToken')) {
      return true;
    }

    return false;
  }
  //popup 관련 함수들
  get popupContent() {
    return portalModel.content;
  }
  get popupTitle() {
    return portalModel.title;
  }
  get popupCloseBtn() {
    return portalModel.closeBtn;
  }
  get popupRadius() {
    return portalModel.radius;
  }
  popupClose() {
    runInAction(() => {
      portalModel.content = null;
      portalModel.title = '';
      portalModel.closeBtnDarkYn = false;
      portalModel.closeBtn = true;
      portalModel.radius = false;
    });
  }
  get popupCloseBtnDarkYn() {
    return portalModel.closeBtnDarkYn;
  }
  get portalModel() {
    return portalModel;
  }
  //dialog 관련 함수들
  get dialogType() {
    return dialogModel.type;
  }
  get dialogTitle() {
    return dialogModel.title;
  }
  get dialogContent() {
    return dialogModel.content;
  }
  get dialogBtn() {
    return dialogModel.btn;
  }
  get dialogAlertBtnText() {
    return dialogModel.alertBtnText;
  }
  get dialogConfirmBtnText() {
    return dialogModel.confirmBtnText;
  }
  get dialogCancelBtnText() {
    return dialogModel.cancelBtnText;
  }
  get dialogContentJSX() {
    return dialogModel.contentJSX;
  }
  get dialogIcon() {
    return dialogModel.icon;
  }
  //로딩 관련 함수들
  get loadingShowYn() {
    return loadingModel.showYn;
  }
  switchAccountingChannel(acctFirmCode, channelMemberId) {
    switch (acctFirmCode) {
      case 'JINPYUNG': {
        ChannelService.boot({
          pluginKey: '6ff9325f-da6b-43c8-8b6c-00e56a07ce9a',
          memberId: channelMemberId,
        });
        break;
      }

      case 'BARUN': {
        ChannelService.boot({
          pluginKey: '8a9d96c6-6cab-4507-a977-b074a45e5b58',
          memberId: channelMemberId,
        });
        break;
      }
    }
  }
  bootAccountingChannel(channelMemberId) {
    if (corpModel.acctFirmCode === '') {
      reaction(
        () => corpModel.acctFirmCode,
        (acctFirmCode) => {
          this.switchAccountingChannel(acctFirmCode, channelMemberId);
        },
      );
    } else {
      this.switchAccountingChannel(corpModel.acctFirmCode, channelMemberId);
    }
  }
  bootStandbyChannel(channelMemberId) {
    ChannelService.boot({
      pluginKey: 'deef52fd-f5e3-446f-82f0-fd0136e787fc',
      memberId: channelMemberId,
    });
  }
  bootBusinessRegistrationChannel = (pageType, channelMemberId) => {
    if (pageType === 'INCLUDE_STEP') {
      this.bootAccountingChannel(channelMemberId);
    } else {
      this.bootStandbyChannel(channelMemberId);
    }
  };
  accountingAddressChannel(isBusinessRegistrationUrl, channelMemberId) {
    //사업자등록일때는 스탠바이 채널톡이 떠야하는 경우가 있음
    if (!isBusinessRegistrationUrl) {
      this.bootAccountingChannel(channelMemberId);

      return;
    }
    //처음로드할때 pageType이 없기 때문에 사업자등록 페이지의 경우 reaction으로 페이지타입을 받아옴
    if (!businessRegistrationModel.pageType) {
      reaction(
        () => businessRegistrationModel.pageType,
        (pageType) => {
          this.bootBusinessRegistrationChannel(pageType, channelMemberId);
        },
      );

      return;
    }

    this.bootBusinessRegistrationChannel(businessRegistrationModel.pageType, channelMemberId);
  }
  launchChannelService(channelMemberId, urlPath, openChannel) {
    if (urlPath) {
      const type = urlPath.indexOf('accounting') !== -1 ? 'accounting' : 'standby';
      const isBusinessRegistrationUrl = urlPath.indexOf('businessRegistration') !== -1 && type === 'accounting';
      const viewport = document.querySelector('meta[name=viewport]');

      //현재 페이지가 회계 | 스탠바이로 전환되었거나 회계 내에서 사업자등록 외의 페이지로 전환되었을때 실행함
      if (
        type !== applicationModel.nowUrlCharge ||
        (type === 'accounting' && isBusinessRegistrationUrl !== applicationModel.isBusinessRegistrationUrl)
      ) {
        applicationModel.setNowUrlCharge(type);
        applicationModel.setIsBusinessRegistrationUrl(isBusinessRegistrationUrl);

        window.document.head.insertAdjacentHTML(
          'beforeend',
          `<style>div.ch-mobile-messenger{width:100vW !important; height:100vH !important}</style>`,
        );

        //주소에 accounting이 포함된경우 기본적으로 회계 채널톡을 띄움
        if (applicationModel.nowUrlCharge === 'accounting') {
          this.accountingAddressChannel(isBusinessRegistrationUrl, channelMemberId);
        } else {
          this.bootStandbyChannel(channelMemberId);
        }
        if (openChannel === 'open') {
          ChannelService.showMessenger();
        }

        ChannelService.onHideMessenger(() => {
          viewport.setAttribute('content', 'width=1280, initial-scale=0.1');
        });
        //마케팅 구글에널리틱스 관련 코드
        ChannelService.onShowMessenger(() => {
          ReactGA.gtag('event', 'request_talk');
        });
      }
    }
  }
}

export default new AppVm();
