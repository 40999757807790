import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class DetailVm {
  get detail() {
    return DashboardModel.dashboardData?.aoa.detail;
  }
  agendaLinkModalNew() {
    runInAction(() => {
      portalModel.title = '스톡옵션 추가하기';
      portalModel.content = <AgendaLinkPopup agendaType="스톡옵션_규정_신설" />;
    });
  }
  agendaLinkModalChange() {
    runInAction(() => {
      portalModel.title = '스톡옵션 변경하기';
      portalModel.content = <AgendaLinkPopup agendaType="스톡옵션_규정_변경" />;
    });
  }
  agendaLinkModalCreate() {
    runInAction(() => {
      portalModel.title = '주식의 양도제한 추가하기';
      portalModel.content = <AgendaLinkPopup agendaType="주식양도금지_신설" />;
    });
  }
  agendaLinkModalDelete() {
    runInAction(() => {
      portalModel.title = '주식의 양도제한 폐지하기';
      portalModel.content = <AgendaLinkPopup agendaType="주식양도금지_폐지" />;
    });
  }
}
export default new DetailVm();
