import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';
import AoaDetailModal from './AoaDetailModal/AoaDetailModal';

class AoaVm {
  get aoa() {
    return AgendaDashboardModel.files.aoa;
  }
  fileDown(downloadUrl: string) {
    AgendaDashboardService.fileDown(downloadUrl);
  }
  openModal() {
    runInAction(() => {
      portalModel.title = '정관DB';
      portalModel.content = <AoaDetailModal />;
    });
  }
}
export default new AoaVm();
