import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './viewModel';
import st from './style.module.scss';
import clsx from 'clsx';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';

function Step3() {
  useEffect(() => {
    vm.load();
  }, []);
  return useObserver(() => (
    <>
      <div className={st.mainTitle}>AWS 크레딧</div>
      {vm.status === 'APPLICATION_COMPLETE' && (
        <div className={st.statue}>
          <div className={st.title}>신청이 접수되었어요!</div>
          <div className={st.sub}>2 영업일 내 메가존 클라우드측에서 전화드릴 예정이에요.</div>
          <button className={st.arrowLink} type="button" onClick={() => vm.previewModal()}>
            스탠바이의 특별한 헤택 내용 다시보기
          </button>
        </div>
      )}
      {vm.status === 'PROVIDE_COMPLETE' && (
        <div className={clsx(st.statue, st.provideComplete)}>
          <div className={st.title}>
            AWS x 메가존 클라우드 지원금 270만 원<br />
            <span className={st.bold}>제공 완료</span>
          </div>
          <button className={st.arrowLink} type="button" onClick={() => vm.previewModal()}>
            스탠바이의 특별한 헤택 내용 다시보기
          </button>
        </div>
      )}
      {vm.status === 'PROVIDE_DIFFICULT' && (
        <div className={clsx(st.statue, st.provideDifficult)}>
          <div className={st.title}>안타깝게도 지원금 제공이 어려워요.</div>
          <div className={st.sub}>
            혹시 {vm.companyName} 외에{' '}
            <span className={st.bold}>메가존 클라우드와 파트너십 계약을 맺지 않은 사업체</span>가 있나요?
            <br />
            그렇다면 스탠바이에 법인등록 후 그 계정으로 지원금을 신청하세요!
          </div>
          <div className={st.links}>
            <button className={st.arrowLink} type="button" onClick={() => vm.previewModal()}>
              혜택 내용 다시보기
            </button>
            <a href="/companyRegistration" className={st.arrowLink} target="_blank">
              법인 등록하기
            </a>
          </div>
        </div>
      )}
      <div className={st.mainContent}>
        {vm.status === 'APPLICATION_COMPLETE' && (
          <div className={st.row}>
            <div className={st.listContent}>
              <div className={st.title}>사전 질문지 안내</div>
              <div className={st.sub}>사전 질문지를 미리 보내주시면, 상담에 보다 도움이 되어요.</div>
              <ul className={st.list}>
                <li>① 우측 사전 질문지를 다운로드 받아주세요.</li>
                <li>② 사전 질문지에 해당하는 내용을 응답해 주세요.</li>
                <li>
                  ③ 작성하신 사전 질문지를 <a href="mailto:jwshim@mz.co.kr">jwshim@mz.co.kr</a> 로 보내주세요.
                </li>
              </ul>
            </div>
            <div className={st.downloadQuestion}>
              <img src="/images/mzc_meetTheExpert_thumbnail.svg" />
              <div className={st.title}>메가존 클라우드 사전 질문지</div>
              <FileBtn onClick={() => vm.fileDown('PRE_QUESTIONNAIRE')} className={st.download}>
                다운로드
              </FileBtn>
            </div>
          </div>
        )}
        <div className={st.row} style={{ marginTop: vm.status === 'APPLICATION_COMPLETE' ? '24px' : '48px' }}>
          <div className={st.listContent}>
            <div className={st.title}>혜택 소개하기</div>
            <div className={st.sub}>지인분께 클라우드 지원금 270만 원을 받는 기회를 나눠보세요!</div>
            <ul className={st.list}>
              <li>① 우측 [복사하기] 버튼을 클릭하면, 링크가 복사되어요.</li>
              <li>② 혜택을 알리고 싶은 지인분께 링크를 전달하세요!</li>
            </ul>
          </div>
          <div className={st.inviteLink}>
            <div className={st.title}>클라우드 지원금 270만 원 소개링크</div>
            <CopyBtn
              copyText="https://app.standby.kr/guest/awsCredit"
              className={st.copyArea}
              messageClassName={st.copyMessage}
            >
              <span className={st.copyText}>app.standby.kr/guest/awsCredit</span>
              <div className={st.likeBtn}>복사하기</div>
            </CopyBtn>
          </div>
        </div>

        {(vm.status === 'PROVIDE_COMPLETE' || vm.status === 'PROVIDE_DIFFICULT') && (
          <>
            <div className={st.row}>
              <div className={st.listContent}>
                <div className={st.title}>제휴혜택 더 알아보기</div>
                <div className={st.sub} style={{ marginTop: '4px' }}>
                  오직 스탠바이 법인에게만 제공되는 특별한 제휴혜택!
                </div>
                <ul className={st.advertisementCards}>
                  <li>
                    <a href={`/corp/internetAssociate?corp=${vm.id}`} className={st.card}>
                      <div className={st.image}>
                        <img src="/images/kt_logo.svg" />
                      </div>
                      <div className={st.bottomContent}>
                        <ul className={st.hashtag}>
                          <li>#기업용 인터넷·전화</li>
                          <li>#캐시백 지급</li>
                        </ul>
                        <div className={st.title}>기업용 인터넷·전화</div>
                        <ul className={st.circlrList}>
                          <li>전국유일! 스탠바이 회원 단독 캐시백</li>
                          <li>스탠바이 전용 KT콜센터 운영</li>
                        </ul>
                      </div>
                      <div className={st.guide}>혜택 보러가기</div>
                    </a>
                  </li>
                  <li>
                    <button className={clsx(st.card, st.full)} onClick={() => vm.openUserSetting()}>
                      <div className={st.mailIcon}>
                        <img src="/images/newletterIcon.svg" />
                      </div>
                      <div className={st.guide}>구독하기</div>
                      <div className={st.title}>
                        스탠바이 뉴스레터 구독하고,
                        <br />
                        제휴 혜택 안내받으세요!
                      </div>
                      <div className={st.info}>
                        제휴 혜택 안내 외에도 법인운영에 유용한
                        <br />
                        다양한 정보를 무료로 보내드려요!
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className={st.row} style={{ paddingRight: '40px' }}>
              <ul className={st.outlinkCards}>
                <li>
                  <a href="https://aws.amazon.com/ko/what-is-aws/" target="_blank" className={st.card}>
                    <div className={st.title}>왜 클라우드는 AWS 일까요?</div>
                    <div className={st.content}>
                      Amazon Web Services(AWS)는 전 세계적으로 분포한 데이터 센터에서
                      <br />
                      200개가 넘는 완벽한 기능의 서비스를 제공하는, 세계적으로 가장 포괄적이
                      <br />
                      며, 널리 채택되고 있는 클라우드입니다. 
                    </div>
                    <div className={st.moreViewGuide}>더 알아보기</div>
                  </a>
                </li>
                <li>
                  <a href="https://www.megazone.com/hyper-solutions/hyper-billing/" target="_blank" className={st.card}>
                    <div className={st.title}>월 비용이 궁금하다면?</div>
                    <div className={st.content}>
                      월 비용이 궁금하다면 아래 [바로가기] 버튼을 클릭하세요.
                      <br />
                      메가존 클라우드의 하이퍼빌링 시스템에서 월 비용을 안내드립니다. 
                    </div>
                    <div className={st.moreViewGuide}>바로가기</div>
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
        {vm.status === 'APPLICATION_COMPLETE' && (
          <div className={st.moreView}>
            <div className={st.title}>
              <span className={st.bold}>+</span> 더 알아보기
            </div>
            <div className={st.sub}>신청결과가 나올 때 까지, AWS와 메가존 클라우드에 대해 더 알아보세요! </div>
            <ul className={st.viewList}>
              <li>
                <a href="https://aws.amazon.com/ko/what-is-aws/" target="_blank" className={st.item}>
                  <div className={st.icon}>
                    <img src="/images/internetIcon.svg" />
                  </div>
                  <div className={st.guide}>바로가기</div>
                  <div className={st.sub}>왜 AWS일까?</div>
                  <div className={st.title}>AWS를 통한 클라우드 컴퓨팅</div>
                </a>
              </li>
              <li>
                <button type="button" className={st.item} onClick={() => vm.fileDown('COMPANY_PROFILE')}>
                  <div className={st.icon}>
                    <img src="/images/pdfIcon.svg" />
                  </div>
                  <div className={st.guide}>다운로드</div>
                  <div className={st.sub}>왜 메가존 클라우드일까?</div>
                  <div className={st.title}>메가존 클라우드 소개자료</div>
                </button>
              </li>
              <li>
                <button type="button" className={st.item} onClick={() => vm.fileDown('TEAM_COST_OPTIMIZATION')}>
                  <div className={st.icon}>
                    <img src="/images/pdfIcon.svg" />
                  </div>
                  <div className={st.guide}>다운로드</div>
                  <div className={st.sub}>왜 메가존 클라우드일까?</div>
                  <div className={st.title}>MZC_CSM팀 비용최적화 자료</div>
                </button>
              </li>
              <li>
                <button type="button" className={st.item} onClick={() => vm.fileDown('PARTNER_CATALOG')}>
                  <div className={st.icon}>
                    <img src="/images/pdfIcon.svg" />
                  </div>
                  <div className={st.guide}>다운로드</div>
                  <div className={st.sub}>왜 메가존 클라우드일까?</div>
                  <div className={st.title}>MZC_ISV Partner Catalog</div>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  ));
}
export default Step3;
