import MeetingOfShareholdersService from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersService';
import MeetingOfShareholdersModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersModel';
import type { AgendaType } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersModel.interface';
import { runInAction, observable } from 'mobx';
import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';

class StepVm {
  state: {
    tryVaild: boolean;
  };
  constructor() {
    this.state = observable({
      tryVaild: false,
    });
  }
  async submit(e: React.FormEvent<HTMLFormElement>, companyId: string, agendaProgressId: string) {
    e.preventDefault();
    const specialMeetingYn = this.step2.agendaList.some((agenda) => !agenda.normalMeetingYn);
    let possible = true;
    let allCheck = true as boolean;
    for (let index = 0; index < this.step2.agendaList.length; index++) {
      if (this.attendanceYn(index) === false) {
        possible = false;
      }
    }
    if (this.state.tryVaild === false) {
      this.state.tryVaild = true;
    }

    this.step2.agendaList.forEach((agenda) => {
      //전부 체크하지 않은경우 저장실행안함
      if (agenda.shareholders.some((shareholder) => !shareholder.status)) {
        allCheck = false;
      }
    });
    if (allCheck === false) return;
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        minutesId: string;
        agendaList: {
          type: AgendaType;
          shareholders: {
            id: string;
            status: string;
          }[];
        }[];
      };
      if (possible === false) {
        if (specialMeetingYn) {
          //특별결의
          return _alert(
            '주주총회 특별결의를 하려면, 출석한 주주의 주식 수의 3분의 2 이상의 수와 발행 주식총수의 3분의 1 이상의 수가 찬성해야 해요 (의결권 있는 주식 수 기준)',
          );
        } else {
          //일반결의
          return _alert(
            '주주총회 일반결의를 하려면, 출석한 주주의 주식 수의 과반수와 발행 주식총수의 4분의 1 이상의 수가 찬성해야 해요. (의결권 있는 주식 수 기준)',
          );
        }
      }
      await MeetingOfShareholdersService.saveStep2(param, companyId, agendaProgressId);
    }
  }
  load(companyId: string, agendaProgressId: string, minutesId: string) {
    MeetingOfShareholdersService.step2Load(companyId, agendaProgressId, minutesId);
  }
  get step2() {
    return MeetingOfShareholdersModel.step2;
  }
  attendanceAll(status: string, index: number) {
    let isCheckAll = true;
    MeetingOfShareholdersModel.step2.agendaList[index].shareholders.forEach((item) => {
      if (item.status !== status) {
        isCheckAll = false;
      }
    });
    return isCheckAll;
  }
  setAttendancell(e: React.ChangeEvent<HTMLInputElement>, status: string, index: number) {
    const chekced = e.target.checked;
    runInAction(() => {
      this.step2.agendaList[index].shareholders.forEach((item) => {
        if (chekced) {
          item.status = status;
        } else {
          item.status = '';
        }
      });
    });
  }
  status(e: React.ChangeEvent<HTMLInputElement>, index: number, index2: number) {
    const chekced = e.target.checked;
    const value = e.target.value;
    runInAction(() => {
      if (this.state.tryVaild === false) {
        this.state.tryVaild = true;
      }
      if (chekced) {
        this.step2.agendaList[index].shareholders[index2].status = value;
      } else {
        this.step2.agendaList[index].shareholders[index2].status = '';
      }
    });
  }
  attendanceTotal(index: number) {
    let count = 0;
    let totalAttendanceCount = 0;
    this.step2.agendaList[index].shareholders.forEach((item) => {
      item.stocks.forEach((stock) => {
        if (item.status === '찬성') {
          count += stock.amount;
        }
        totalAttendanceCount += stock.amount;
      });
    });
    return {
      count: count,
      percent: ((count / totalAttendanceCount) * 100).toFixed(2).replace('.00', ''),
      totalAttendanceCount: totalAttendanceCount,
    };
  }
  attendanceYn(index: number) {
    let possibleYn = false;
    let totalAttendanceCount = this.attendanceTotal(index).totalAttendanceCount;
    let checkTotal = this.attendanceTotal(index).count;
    if (this.step2.agendaList[index].normalMeetingYn) {
      // 일반결의일때
      if (totalAttendanceCount / 2 <= checkTotal) {
        //출석한 주주의 과반수
        if (this.step2.totalStockAmount / 4 <= checkTotal) {
          // 발행주식 총수의 4분의 1이상
          return true;
        }
      }
    } else {
      // 특별결의 일때
      if ((totalAttendanceCount / 3) * 2 <= checkTotal) {
        //출석한 주주의 주식수의 3분의 2 이상 찬성
        if (this.step2.totalStockAmount / 3 <= checkTotal) {
          // 발행주식 총수의 3분의 1이상
          return true;
        }
      }
    }
    return possibleYn;
  }
  allCheckAgenda() {
    this.step2.agendaList.forEach((agenda, index) => {
      if (index !== 0) {
        agenda.shareholders.forEach((shareholder, index2) => {
          shareholder.status = this.step2.agendaList[0].shareholders[index2].status;
        });
      }
    });
  }
  preStep() {
    MeetingOfShareholdersModel.nowStep = '1';
  }
}
export default new StepVm();
