import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import stockholderService from '@service/Corp/Stock/Stockholder/stockholderService';
import stockholderModel from '@model/Corp/Stock/Stockholder/stockholderModel';
import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';

class StockholderVm {
  constructor() {
    this.state = observable({
      birth: {
        year: '',
        month: '',
        day: '',
      },
      loadingYn: false,
    });
  }
  submit(e, shareholderId) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;

      if (param.birth) {
        if (this.state.birth.year && this.state.birth.month && this.state.birth.day) {
          param.birth = this.state.birth.year + '-' + this.state.birth.month + '-' + this.state.birth.day;
        } else {
          param.birth = '';
        }
      }
      try {
        this.state.loadingYn = true;
        stockholderService.updateStockholderInfo(param, shareholderId);
        this.state.loadingYn = false;
      } catch (err) {
        this.state.loadingYn = false;
      }
    }
  }
  async load(shareholderId) {
    await stockholderService.loadStockholderInfo(shareholderId);
    if (
      stockholderModel.shareholder.shareholderType === '해외_성인' ||
      stockholderModel.shareholder.shareholderType === '해외_미성년자' ||
      stockholderModel.shareholder.shareholderType === '해외_법인'
    ) {
      let dateList = stockholderModel.shareholder.birth.split('-').map((item) => item.trim());
      this.state.birth.year = dateList[0];
      this.state.birth.month = dateList[1];
      this.state.birth.day = dateList[2];
    }
  }
  get shareholder() {
    return stockholderModel.shareholder;
  }
  setShareholderType(e) {
    runInAction(() => {
      stockholderModel.shareholder.shareholderType = e.target.value;
    });
  }
  setEnglishName(e) {
    runInAction(() => {
      stockholderModel.shareholder.englishName = e.target.value;
    });
  }
  setPhoneNumber(e) {
    runInAction(() => {
      stockholderModel.shareholder.phoneNumber = regExp.deleteSpace(onlyNumber(e.target.value));
    });
  }
  setEmail(e) {
    runInAction(() => {
      stockholderModel.shareholder.email = regExp.deleteSpace(e.target.value);
    });
  }
  setAddress(e) {
    runInAction(() => {
      stockholderModel.shareholder.address = e.target.value;
    });
  }
  get 고유번호Type() {
    const shareholderType = this.shareholder.shareholderType;
    switch (shareholderType) {
      case '국내_성인':
      case '국내_미성년자':
        return '주민등록번호';
      case '국내_법인':
        return '법인등록번호';
      case '펀드_투자조합':
        return '고유번호';
      case '해외_성인':
      case '해외_미성년자':
        return '생년월일';
      case '해외_법인':
        return '설립년월일';
    }
  }
  set고유번호(e, type) {
    runInAction(() => {
      switch (type) {
        case '주민등록번호':
          stockholderModel.shareholder.kssn = e.target.value;
          break;
        case '법인등록번호':
          stockholderModel.shareholder.cssn = e.target.value;
          break;
        case '고유번호':
          stockholderModel.shareholder.properNumber = e.target.value;
          break;
      }
    });
  }
  setBirth(e, type) {
    if (type === 'year') {
      this.state.birth.year = e.target.value;
    } else if (type === 'month') {
      this.state.birth.month = e.target.value;
    } else if (type === 'day') {
      this.state.birth.day = e.target.value;
    }
  }
  birthBlur(e, type) {
    let val = e.target.value;
    if (type === 'month') {
      this.state.birth.month = e.target.value;
    }
    if (type === 'day') {
      this.state.birth.day = e.target.value;
    }
  }
  get birthValidtaion() {
    const year = this.state.birth.year;
    const month = this.state.birth.month;
    const day = this.state.birth.day;
    if (year && !month && !day) {
      return {
        validation: regExp.year().test(year),
        text: '올바를 연도를 입력해주세요.',
      };
    } else if (year && month && !day) {
      return {
        validation: regExp.month().test(month),
        text: '올바른 월을 입력해주세요.',
      };
    } else if (year && month && day) {
      return {
        validation: regExp.day().test(day),
        text: '올바른 일(날짜)을 입력해주세요.',
      };
    } else {
      return {
        validation: true,
        text: '',
      };
    }
  }
  setEin(e) {
    runInAction(() => {
      stockholderModel.shareholder.ein = e.target.value;
    });
  }
  setRepresentativesPosition(e, idx) {
    runInAction(() => {
      stockholderModel.shareholder.representatives[idx].position = e.target.value;
    });
  }
  setRepresentativesName(e, idx) {
    runInAction(() => {
      stockholderModel.shareholder.representatives[idx].name = e.target.value;
    });
  }
  addRepresentatives() {
    runInAction(() => {
      stockholderModel.shareholder.representatives.push({
        id: '',
        position: '',
        name: '',
      });
    });
  }
  deleteRepresentatives(idx) {
    runInAction(() => {
      stockholderModel.shareholder.representatives.splice(idx, 1);
    });
  }
  setMemo(e) {
    runInAction(() => {
      stockholderModel.shareholder.memo = e.target.value;
    });
  }
  setResidenceRegistrationYn(e) {
    runInAction(() => {
      stockholderModel.shareholder.residenceRegistrationYn = e.target.value === 'true';
    });
  }
  setEnglishAddress(e) {
    runInAction(() => {
      stockholderModel.shareholder.englishAddress = e.target.value;
    });
  }
  setRepresentativesEnglishName(e, idx) {
    runInAction(() => {
      stockholderModel.shareholder.representatives[idx].englishName = e.target.value;
    });
  }
  addForeignRepresentatives() {
    runInAction(() => {
      stockholderModel.shareholder.representatives.push({
        englishName: '',
        name: '',
      });
    });
  }
  setNationalityType(e) {
    runInAction(() => {
      stockholderModel.shareholder.nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e) {
    runInAction(() => {
      stockholderModel.shareholder.nationalityOthers = e.target.value;
    });
  }
  onlineMusterNoticeState() {
    const status = stockholderModel.onlineMeetingNotice.status;
    switch (status) {
      case '발송전':
      case '발송중':
      case '동의완료':
      case '발송실패':
        return status;
      case '발송완료':
        return '발송완료 (대기중)';
    }
  }
  get stock() {
    return stockholderModel.stock;
  }
  nowDate() {
    let today = new Date();
    let year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return year + '-' + month + '-' + day;
  }
  get file() {
    return stockholderModel.onlineMeetingNotice.file;
  }
  fileDownload(url) {
    stockholderService.fileDownload(url);
  }
}

export default new StockholderVm();
