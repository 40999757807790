import { createQueryKeys } from '@lukemorales/query-key-factory';
import awsCreditService from '@service/Corp/AwsCredit/awsCreditService';
import { LoadStep2Rs, Term } from '@service/Corp/AwsCredit/awsCreditService.interface';
import { useQuery } from '@tanstack/react-query';

const queryKey = createQueryKeys('awsCredit', {
  getTermInfo: null,
  getStep2: (corpId) => [corpId],
});

const useAwsCreditTermQuery = () => {
  return useQuery<Term[]>({
    ...queryKey.getTermInfo,
    queryFn: () => awsCreditService.loadTerm(),
    staleTime: 1000,
  });
};

const useAwsCreditStep2Query = (corpId: number) => {
  return useQuery<LoadStep2Rs>({
    ...queryKey.getStep2(corpId),
    queryFn: () => awsCreditService.loadStep2(corpId),
    staleTime: 1000,
  });
};

export { useAwsCreditStep2Query, useAwsCreditTermQuery };
