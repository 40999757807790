import { useObserver } from 'mobx-react';
import type { I소집통지_우편 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import vm from './PostMusterNoticeVm';

interface PostMusterNoticeProps {
  index: number;
  info: I소집통지_우편;
  agendaProgressId: string;
}

function PostMusterNotice(props: PostMusterNoticeProps) {
  return useObserver(() => (
    <>
      <div className={bst.row}>
        <div className={clsx(bst.title, bst.section2)}>
          {props.index + 1}. 소집통지 <span className={bst.light}>(우편)</span>
        </div>
        <div className={bst.content}>
          <div className={bst.contentTitleBox}>
            자동작성된 소집통지문을 우편 발송하세요. <span className={bst.light}>({props.info.date} 발송)</span>
          </div>
          <div className={bst.wrap}>
            <div className={bst.wrapTitle}>
              <span className={bst.important}>주주총회 소집통지 방법</span> 상세 안내
            </div>
            <div className={bst.steps}>
              <div className={bst.stepWrap}>
                <div className={bst.step}>
                  <div className={bst.stepTitle}>2주 전 사전통지 필수!</div>
                  <div className={bst.stepContent}>
                    주주총회 열 때마다 2주 전<br />
                    사전통지 필수
                  </div>
                </div>
              </div>
              <div className={bst.stepWrap}>
                <div className={bst.step}>
                  <div className={bst.stepTitle}>우편 발송이 원칙!</div>
                  <div className={bst.stepContent}>
                    자본금 10억 원 이상이라면,
                    <br />
                    우편 발송이 필수
                  </div>
                </div>
              </div>
              <div className={bst.stepWrap}>
                <div className={bst.step}>
                  <div className={bst.stepTitle}>
                    2주 전에 알고 있는 마지막
                    <br />
                    주소로 발송하면 충분!
                  </div>
                  <div className={bst.stepContent}>반송되어도 무방</div>
                </div>
              </div>
            </div>
          </div>
          <div className={bst.btnArea}>
            <SquareBtn className={bst.btn} onClick={() => vm.openModal(props.agendaProgressId)}>
              주주총회 소집통지문 다운로드
            </SquareBtn>
          </div>
        </div>
      </div>
    </>
  ));
}
export default PostMusterNotice;
