import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import DocumentVm from './DocumentVm';
import st from './Document.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import clsx from 'clsx';

let vm: DocumentVm;

interface DocumentProps {
  agendaProgressId: string;
  newStockAcquirerInfo: {
    assignmentType: '제3자' | '주주' | null;
    isExistsAbandonedStock: boolean;
  };
}

function Document(props: DocumentProps) {
  const [moreDocumentYn, setMoreDocumentYn] = useState(true);
  const [scrollActiveYn, setScrollActiveYn] = useState(false);

  const height = document.body.offsetHeight;
  const half = height - 250;

  useComponentWillMount(() => {
    vm = new DocumentVm();
    vm.load(props.agendaProgressId, props.newStockAcquirerInfo);
  });

  useEffect(() => {
    reaction(
      () => vm.previewLink,
      (previewLink: string) => {
        if (previewLink) {
          let previewFrame = document.getElementById('previewFrame') as HTMLIFrameElement;
          if (previewFrame && previewFrame.contentWindow) {
            previewFrame.contentWindow.location.replace(previewLink);

            window.addEventListener('message', function (e) {
              if (typeof e.data === 'string') {
                if (JSON.parse(e.data)?.height) {
                  const iframeHeight = JSON.parse(e.data).height + 200;
                  previewFrame.style.height = iframeHeight + 'px';
                }
              }
            });
          }
        }
      },
    );
  }, []);

  useEffect(() => {
    const scrollableDiv = document.getElementById('scrollableDiv');

    const handleScroll = () => {
      setScrollActiveYn(true);
      const timer = setTimeout(() => {
        setMoreDocumentYn(false);
      }, 900);
      return () => clearTimeout(timer);
    };

    if (moreDocumentYn && scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [moreDocumentYn]);

  // let imgList = ['moreDocument_step1', 'moreDocument_step2', 'moreDocument_step3'];
  // const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         setCurrentIndex((index) => (index+1) % 3);
  //     }, 300);
  //     return () => clearInterval(interval);
  // }, []);

  return useObserver(() => (
    <div className={st.document}>
      <div className={st.title}>주주 동의서</div>
      <div className={st.contentBox}>
        <div className={st.documentBox}>
          {/* <div className={clsx(st.moreDocument, scrollActiveYn && st.scroll)} style={{top: half+'px', backgroundImage:`url(/images/${imgList[currentIndex]}.svg)`}}>화면을 스크롤하면<br/>서류가 더 있어요!</div> */}
          {moreDocumentYn && (
            <div className={clsx(st.moreDocument, scrollActiveYn && st.scroll)} style={{ top: half + 'px' }}></div>
          )}
          <div className={st.content} id="scrollableDiv">
            <div className={st.iframeDiv}>
              <iframe id="previewFrame" title="주주 동의서" className={st.iframeDocument} />
            </div>
          </div>
        </div>
        <form
          className={st.documentOption}
          onSubmit={(e) => vm.next(e, props.agendaProgressId, props.newStockAcquirerInfo)}
        >
          <div className={st.optionSeparate}>
            <div className={st.optionBox}>
              <div className={st.optionTitle}>글자크기</div>
              <div className={st.option}>
                <Radio
                  className={st.radio}
                  name="options.fontSize"
                  value="SMALL"
                  checked={vm.state.options.fontSize === 'SMALL'}
                  onChange={(e) => vm.setFontSize(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  작게
                </Radio>
                <Radio
                  className={st.radio}
                  name="options.fontSize"
                  value="MEDIUM"
                  checked={vm.state.options.fontSize === 'MEDIUM'}
                  onChange={(e) => vm.setFontSize(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  중간
                </Radio>
              </div>
            </div>
            <div className={st.optionBox}>
              <div className={st.optionTitle}>내용</div>
              <div className={st.option}>
                <Radio
                  className={st.radio}
                  name="options.contentDetailYn"
                  value="false"
                  checked={vm.state.options.contentDetailYn === false}
                  onChange={(e) => vm.setContentDetailYn(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  간단하게
                </Radio>
                <Radio
                  className={st.radio}
                  name="options.contentDetailYn"
                  value="true"
                  checked={vm.state.options.contentDetailYn === true}
                  onChange={(e) => vm.setContentDetailYn(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  자세히
                </Radio>
              </div>
              <div className={st.explain}>
                등기시 주식종류 명칭 등이 변경될 수 있다면, [간단하게]를 선택하고, 주주에게 자세히 알려주고 싶다면
                [자세히]를 선택하세요.
              </div>
              <div className={st.btnArea}>
                <SquareBtn className={st.nextBtn} type="submit">
                  다음 페이지
                </SquareBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  ));
}
export default Document;
