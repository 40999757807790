import { observable } from 'mobx';
import st from '@pages/Corp/Agenda/Step/FinalCheck/Final/Final.module.scss';

interface State {
  moreViewYn: boolean;
}

class C신주발행통지Vm {
  state: State = observable({
    moreViewYn: false,
  });
  status(_status: string | null) {
    switch (_status) {
      case '발송전':
        return {
          _className: st.secon200,
          text: '발송 전',
        };
      case '발송중':
        return {
          _className: st.secon200,
          text: '발송 중',
        };
      case '메일발송':
        return {
          _className: st.sub300,
          text: '메일 발송 O',
        };
      case 'SMS발송':
        return {
          _className: st.sub300,
          text: '알림톡 발송 O',
        };
      case '발송완료':
        return {
          _className: st.sub300,
          text: '메일&알림톡\n발송 O',
        };
      case '발송실패':
        return {
          _className: st.warning200,
          text: '발송실패',
        };
      case '발송실패_알림톡':
        return {
          _className: st.warning200,
          text: '발송실패\n(알림톡)',
        };
      case '발송실패_메일':
        return {
          _className: st.warning200,
          text: '발송실패\n(메일)',
        };
    }
  }
  moreView() {
    this.state.moreViewYn = !this.state.moreViewYn;
  }
}
export default new C신주발행통지Vm();
