import { post, get, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import registerModel from '@model/Corp/Register/registerModel';

class registerService {
  async load() {
    const res = await get(`/company/${corpModel.id}/copy/home`);
    registerModel.setCompanyCopyList(res.data);
  }
  async downLoadUrl(url) {
    await downloadFileGet(url);
  }
}
export default new registerService();
