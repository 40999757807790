import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import Cst from './Memo.module.scss';
import clsx from 'clsx';
import vm from './MemoVm';

function Memo() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>
        스탠바이 고객뷰
        <br />
        의견 남기기
      </div>
      <div className={clsx(st.content, Cst.content)}>
        <div className={st.memoContent}>
          {vm.memo ? (
            <div className={Cst.memo} dangerouslySetInnerHTML={{ __html: vm.memo }} />
          ) : (
            <div className={st.comment}>
              스탠바이에 입력된 내용과 실제 등기진행 내용이 다르다면 메모 남겨주세요. (안건변경, 통지방식 변경 등)
            </div>
          )}
          <div className={Cst.btnArea}>
            <button type="button" className={st.likeLinkBtn} onClick={() => vm.openModal(vm.memo)}>
              입력하기
            </button>
          </div>
        </div>
      </div>
    </div>
  ));
}
export default Memo;
