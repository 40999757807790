import regExp from '@common/module/regExp';
import { Button, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import st from './FindId.module.scss';
import vm from './FindIdVm';
import IdList from './IdList/IdList';
import VerificationPhoneNumber from './VerificationPhoneNumber/VerificationPhoneNumber';

function FindId() {
  useEffect(() => {
    vm.verificationToken();
  }, []);

  return useObserver(() => (
    <>
      {vm.step === 'findId' && (
        <form onSubmit={vm.submit} className={st.form}>
          <div className={st.logoWrap}>
            <a href="https://standby.kr/" className={st.logo}>
              <img src="/images/logoStadnby-Login.svg" />
            </a>
          </div>
          <div className={st.mainTitle}>
            <Typography fontType="Title-18M">스탠바이에 가입된 </Typography>
            <Typography fontType="Title-18B">이름</Typography>
            <Typography fontType="Title-18M">과 </Typography>
            <Typography fontType="Title-18B">전화번호</Typography>
            <Typography fontType="Title-18M">를 입력하세요.</Typography>
          </div>
          <div className={st.info}>
            <Typography fontType="Body-14R">
              보안을 위해 가입하신 전화번호로 인증문자를 보내드려요.
              <br />
              본인인증 후 아이디(이메일)을 안내해 드릴게요.
            </Typography>
          </div>
          <TextField
            name="name"
            label="이름"
            className={st.inputText}
            placeholder="홍길동"
            value={vm.name}
            onChange={vm.setName}
            error={
              <ErrorValidation
                name="name"
                value={vm.name}
                errorCase={{
                  required: '이름을 입력해 주세요.',
                  pattern: {
                    value: regExp.onlyKorEng().test(vm.name),
                    message: '한글 또는 영문으로 입력해 주세요.',
                  },
                }}
              />
            }
          />
          <TextField
            name="phoneNumber"
            label="전화번호"
            className={st.inputText}
            placeholder="01012345678"
            value={vm.phoneNumber}
            onChange={vm.setPhoneNumber}
            error={
              <ErrorValidation
                name="phoneNumber"
                value={vm.phoneNumber}
                errorCase={{
                  required: '전화번호를 입력해 주세요.',
                  pattern: {
                    value: regExp.phoneNumber().test(vm.phoneNumber),
                    message: '올바른 전화번호를 입력해 주세요.',
                  },
                }}
              />
            }
          />
          <Button type="submit" size="large" color="primary400" className={st.mainBtn}>
            인증문자 보내기
          </Button>
        </form>
      )}
      {vm.step === 'verificationPhoneNumber' && <VerificationPhoneNumber phoneNumber={vm.phoneNumber} name={vm.name} />}
      {vm.step === 'idList' && <IdList />}
    </>
  ));
}

export default FindId;
