import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

class RegistrationVm {
  get jointCertificateUseYn() {
    return AgendaDashboardModel.agendaProgress.registrationAgency.jointCertificateUseYn;
  }
  get company() {
    return AgendaDashboardModel.agendaProgress.registrationAgency.digitalCertificate.company;
  }
  get shareholders() {
    return AgendaDashboardModel.agendaProgress.registrationAgency.digitalCertificate.shareholders;
  }
}
export default new RegistrationVm();
