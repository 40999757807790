import termsService from '@service/termsService';
import { observable } from 'mobx';

interface State {
  terms: {
    id: number;
    required: boolean;
    title: string;
    content: string;
  }[];
}

class TermsVm {
  state: State = observable({
    terms: [],
  });
  async load(type: string) {
    const data = await termsService.loadTerms(type);
    this.state.terms = data.userRegistrationTerms.map((item) => {
      return {
        id: item.id || -1,
        required: item.required || false,
        title: item.title || '',
        content: item.content || '',
      };
    });
  }
  title(termId: string) {
    const findIdx = this.state.terms.findIndex((item) => Number(termId) === Number(item.id));
    if (findIdx !== -1) {
      return this.state.terms[findIdx].title;
    }
  }
  content(termId: string) {
    const findIdx = this.state.terms.findIndex((item) => Number(termId) === Number(item.id));
    if (findIdx !== -1) {
      return this.state.terms[findIdx].content;
    }
  }
}

export default new TermsVm();
