import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import ExecutivePopupModel from '@model/Corp/Agenda/Step/ExecutiveModal/ExecutiveModalModel';
import portalModel from '@model/portalModel';
import ExecutiveModal from './ExecutiveModal/ExecutiveModal';
import { runInAction } from 'mobx';
import type {
  SelectedAgenda,
  I임원_취임_중임_사임_퇴임,
  Info,
  ChangeType,
  ExecutivesPosition,
  OriginExecutive,
  SimpleNationalityType,
} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class ExecutivesVm {
  selectedAgenda(index: number) {
    return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I임원_취임_중임_사임_퇴임>;
  }
  get modalOpened() {
    return ExecutivePopupModel.modalOpened;
  }
  openModal(openFirst: () => void) {
    runInAction(() => {
      portalModel.title = null;
      portalModel.content = <ExecutiveModal openFirst={openFirst} />;
      portalModel.closeBtn = false;
      portalModel.radius = true;
    });
  }
  get positionList() {
    return [
      {
        value: '단독대표',
        text: '단독 대표',
      },
      {
        value: '각자대표',
        text: '각자 대표(대표 2명 이상)',
      },
      {
        value: '공동대표',
        text: '공동 대표(대표 2명 이상)',
      },
      {
        value: '사내이사',
        text: '사내이사',
      },
      {
        value: '사외이사',
        text: '사외이사',
      },
      {
        value: '기타비상무이사',
        text: '기타비상무이사',
      },
      {
        value: '감사',
        text: '감사',
      },
    ];
  }
  changePositionList(info: Info) {
    return this.positionList.filter((position) => {
      let success = true;
      if (position.value === info.position) {
        success = false;
      }
      if (
        (info.position === '대표이사' || info.position === '대표') &&
        (position.value === '단독대표' || position.value === '각자대표')
      ) {
        success = false;
      }
      return success;
    });
  }
  positionIcon(info: Info[]) {
    const position = info[0].position;
    if (info.length > 2) {
      return '대표이사';
    } else {
      if (position === '사내이사' || position === '사외이사' || position === '감사') {
        return position;
      } else if (position.includes('대표')) {
        return '대표이사';
      } else {
        return '기타';
      }
    }
  }
  changeType(e: React.ChangeEvent<HTMLSelectElement>, agendaIndex: number, index: number, index2: number) {
    let value: ChangeType;
    if (
      e.target.value !== '임기연장' &&
      e.target.value !== '변경없음' &&
      e.target.value !== '사임_퇴임' &&
      e.target.value !== '직급변경'
    ) {
      value = '변경없음';
    } else {
      value = e.target.value;
    }
    this.selectedAgenda(agendaIndex).agendaDetail.originExecutives[index].info[index2].changeType = value;
  }
  newPosition(e: React.ChangeEvent<HTMLSelectElement>, agendaIndex: number, index: number, index2: number) {
    this.selectedAgenda(agendaIndex).agendaDetail.originExecutives[index].info[index2].newPosition = e.target
      .value as ExecutivesPosition;
  }
  separateInputYn(agendaIndex: number, index: number) {
    this.selectedAgenda(agendaIndex).agendaDetail.originExecutives[index].separateInputYn =
      !this.selectedAgenda(agendaIndex).agendaDetail.originExecutives[index].separateInputYn;
  }
  allRegistrationRequiredYn(originExecutives: OriginExecutive[]) {
    let registrationRequiredYn: boolean = false;
    originExecutives.forEach((originExecutive) => {
      originExecutive.info.forEach((info) => {
        if (info.registrationRequiredYn === true) {
          registrationRequiredYn = true;
        }
      });
    });
    return registrationRequiredYn;
  }
  changeAddressExecutives(e: React.ChangeEvent<HTMLInputElement>, agendaIndex: number, index: number) {
    this.selectedAgenda(agendaIndex).agendaDetail.changeAddressExecutives[index].checked = e.target.checked;
  }
  changeNewExecutivesExistsYn(e: React.ChangeEvent<HTMLInputElement>, agendaIndex: number) {
    this.selectedAgenda(agendaIndex).agendaDetail.newExecutivesExistsYn = e.target.value === 'true';
  }
  position(e: React.ChangeEvent<HTMLSelectElement>, index: number, index2: number) {
    this.selectedAgenda(index).agendaDetail.newExecutives[index2].position = e.target.value as ExecutivesPosition;
  }
  simpleNationalityType(e: React.ChangeEvent<HTMLSelectElement>, index: number, index2: number) {
    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].simpleNationalityType = e.target
        .value as SimpleNationalityType;
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityOthers = '';
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityType = '';
    });
  }
  nationalityType(e: React.ChangeEvent<HTMLSelectElement>, index: number, index2: number) {
    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityType = e.target.value;
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityOthers = '';
    });
  }
  nationalityTypes(index: number) {
    return this.selectedAgenda(index).agendaDetail.nationalityTypes;
  }
  name(e: React.ChangeEvent<HTMLInputElement>, index: number, index2: number) {
    this.selectedAgenda(index).agendaDetail.newExecutives[index2].name = e.target.value;
  }
  nationalityOthers(e: React.ChangeEvent<HTMLInputElement>, index: number, index2: number) {
    this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityOthers = e.target.value;
  }
  add(index: number) {
    this.selectedAgenda(index).agendaDetail.newExecutives.push({
      executiveId: '',
      name: '',
      position: null,
      simpleNationalityType: null,
      shareholderId: '',
    });
  }
  delete(index: number, index2: number) {
    this.selectedAgenda(index).agendaDetail.newExecutives.splice(index2, 1);
  }
  shareholderId(e: React.ChangeEvent<HTMLSelectElement>, index: number, index2: number) {
    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].shareholderId = e.target.value;
      this.selectedAgenda(index).agendaDetail.newExecutives[index2].name = '';
      if (e.target.value && e.target.value !== '0') {
        this.selectedAgenda(index).agendaDetail.shareholders.forEach((shareholder) => {
          if (shareholder.id.toString() === e.target.value) {
            this.selectedAgenda(index).agendaDetail.newExecutives[index2].simpleNationalityType =
              shareholder.nationalityType;
          }
        });
      } else {
        this.selectedAgenda(index).agendaDetail.newExecutives[index2].simpleNationalityType = null;
        this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityOthers = '';
        this.selectedAgenda(index).agendaDetail.newExecutives[index2].nationalityType = '';
      }
    });
  }
}
export default new ExecutivesVm();
