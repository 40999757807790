import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import ArticlesOfAssociationChangeFormVm from './ArticlesOfAssociationChangeFormVm';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import FileUpload from '@commonComponents/FileUpload/FileUpload';
import { toJS } from 'mobx';
let vm;
function ArticlesOfAssociationChangeForm() {
  useComponentWillMount(() => {
    vm = new ArticlesOfAssociationChangeFormVm();
  });

  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)} autoComplete="off">
      <div>과거에 변경된 정관 반영 요청하기</div>
      <div>변경된 정관 업로드하기</div>
      {vm.state.aoaFiles.map((item, idx) => {
        return (
          <div key={idx}>
            <FileUpload name={`aoaFiles[${idx}]`} onChange={(e) => vm.aoaFileUpload(e, idx)} />
            <button type="button" onClick={() => vm.aoaFilesAdd()}>
              추가버튼
            </button>
            {idx !== 0 && (
              <button type="button" onClick={() => vm.aoaFilesDelete(idx)}>
                삭제버튼
              </button>
            )}
          </div>
        );
      })}
      <div>어떤 부분이 변경되었는지 간단히 알려주세요.</div>
      <textarea style={{ border: '1px solid black' }} name="memo"></textarea>
      <div>
        <button type="submit">변경된 정관 반영 요청하기</button>
      </div>
      <div>변경된 정관은 영업일 기준 1~2일 내에 스탠바이 시스템에 반영됩니다.</div>
    </form>
  ));
}
export default ArticlesOfAssociationChangeForm;
