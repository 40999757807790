import type {
  ConsultInfoInputBundle,
  FormBundle,
  OrderSummaryBundle,
  ProductsBundle,
  ProductsDetailBundle,
  TermBundle,
} from '../../useStep2';
import ConsultInfoForm from './components/ConsultInfoForm';
import OrderSummary from './components/OrderSummary';
import ProductDetailSelectionContainer from './components/ProductDetailSelectionContainer';
import st from './style.module.scss';

interface Props {
  termBundle: TermBundle;
  productsBundle: ProductsBundle;
  productsDetailBundle: ProductsDetailBundle;
  consultInfoInputBundle: ConsultInfoInputBundle;
  orderSummaryBundle: OrderSummaryBundle;
  formBundle: FormBundle;
}

const Form = (props: Props) => {
  const { termBundle, consultInfoInputBundle, productsBundle, productsDetailBundle, orderSummaryBundle, formBundle } =
    props;

  return (
    <form className={st.detailInfos} onSubmit={(e) => formBundle.onSubmitForm(e)}>
      <div className={st.detail}>
        <ProductDetailSelectionContainer {...productsBundle} {...productsDetailBundle} />
        <ConsultInfoForm
          {...termBundle}
          {...consultInfoInputBundle}
          submissionRejected={formBundle.submissionRejected}
        />
      </div>
      <OrderSummary {...orderSummaryBundle} isSubmissionPending={formBundle.isSubmissionPending} />
    </form>
  );
};

export default Form;
