import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './ReceiptPopupVm';
import st from './ReceiptPopup.module.scss';

function ReceiptPopup() {
  return useObserver(() => (
    <div className={st.receiptPopup}>
      <div className={st.title}>기장서비스 상담이 접수되었어요.</div>
      {vm.acctFirmCode === 'JINPYUNG' && (
        <div className={st.content}>
          진평회계법인에서 02-2088-8750 번호로 곧 전화를 드릴거에요.
          <br />
          빠른 상담을 원한다면, 전화를 주시거나, 오른쪽 아래 채팅상담 버튼을 눌러 문의 주세요.
        </div>
      )}
      {vm.acctFirmCode === 'BARUN' && (
        <div className={st.content}>
          세무회계 바른의 담당자가 010으로 시작하는 번호로 전화드릴 예정이에요!
          <br />
          지금 궁금하신 게 있으시다면, 오른쪽 아래 채팅상담을 통해 빠른 문의가 가능해요.
        </div>
      )}
      <button type="button" className={st.checkBtn} onClick={() => vm.check()}>
        확인
      </button>
    </div>
  ));
}
export default ReceiptPopup;
