import { useObserver } from 'mobx-react';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import vm from './ShareholderListVm';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import st from './ShareholderList.module.scss';
import { addCommas } from '@common/module/replaceNumber';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';

interface ShareholderListProps {
  index: number;
}

function ShareholderList(props: ShareholderListProps) {
  return useObserver(() => (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div>
            {vm.nearestShareholderListDate(props.index)} 기준 주주명부
            <input type="hidden" name="shareholderListDate" value={vm.nearestShareholderListDate(props.index)} />
          </div>
          <div className={qst.explain}>스탠바이에 저장된 가장 근접한 시점의 주주명부에요.</div>
        </div>
        <Table className={st.table}>
          <colgroup>
            <col />
            <col width={225} />
            <col width={215} />
            <col width={256} />
          </colgroup>
          <thead>
            <tr>
              <th>주주명</th>
              <th style={{ textAlign: 'left' }}>유형</th>
              <th style={{ textAlign: 'left' }}>주식종류</th>
              <th style={{ textAlign: 'right' }}>주식수</th>
            </tr>
          </thead>
          <tbody>
            {vm.shareholders(props.index)?.map((shareholder, index) =>
              shareholder.stocks.map((stock, index2) => (
                <tr key={`shareholder${index}stock${index2}`}>
                  {index2 === 0 && (
                    <>
                      <td rowSpan={shareholder.stocks.length}>
                        <div className={st.number}>
                          <span>{index + 1}</span> <span>{shareholder.name}</span>
                        </div>
                      </td>
                      <td rowSpan={shareholder.stocks.length}>
                        {vm.switchShareholderType(shareholder.shareholderType)}
                      </td>
                    </>
                  )}
                  <td>{stock.name}</td>
                  <td style={{ textAlign: 'right' }}>{addCommas(stock.number)} 주</td>
                </tr>
              )),
            )}
            <tr className={st.shareholderSum}>
              <td colSpan={3}>
                <span>총</span>
                <span className={st.count}>{vm.shareholders(props.index)?.length}</span>명
              </td>
              <td>
                총<span className={st.count}>{addCommas(vm.shareholdersSum(props.index))}</span>주
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div>위 주주명부는 {vm.decisionDate(props.index)}자 주주명부와 일치하나요?</div>
          <div className={qst.explain}>{vm.decisionDatePhrases(props.index)}</div>
        </div>
        <div className={st.radioBox}>
          <RadioLabel
            className={qst.radio}
            name="matchedYn"
            value="true"
            checked={vm.matchedYn(props.index) === true}
            onChange={(e) => vm.setMatchedYn(e, props.index)}
          >
            네.
          </RadioLabel>
          <RadioLabel
            className={qst.radio}
            name="matchedYn"
            value="false"
            checked={vm.matchedYn(props.index) === false}
            onChange={(e) => vm.setMatchedYn(e, props.index)}
          >
            아니오.
          </RadioLabel>
        </div>
        <div>
          <Error
            name="matchedYn"
            value={vm.matchedYn(props.index) !== null ? String(vm.matchedYn(props.index)) : ''}
            errorCase={{
              required: '주주명부 일치 여부를 선택하세요.',
            }}
          />
        </div>
      </div>
      {vm.matchedYn(props.index) === false && (
        <div className={qst.qusetion}>
          <div className={qst.questionTitle}>{vm.decisionDate(props.index)}자 주주명부를 입력해 주세요.</div>
          <Table className={st.table}>
            <colgroup>
              <col />
              <col width={220} />
              <col width={215} />
              <col width={215} />
              <col width={25} />
            </colgroup>
            <thead>
              <tr>
                <th>주주명</th>
                <th style={{ textAlign: 'left' }}>유형</th>
                <th style={{ textAlign: 'left' }}>주식종류</th>
                <th style={{ textAlign: 'right', paddingRight: '49px' }} colSpan={2}>
                  주식수
                </th>
              </tr>
            </thead>
            <tbody>
              {vm.changedShareholders(props.index)?.map((changedShareholder, index) =>
                changedShareholder.stocks.map((stock, index2) => (
                  <tr
                    key={`changedShareholder${index}stock${index2}`}
                    style={{
                      position: 'relative',
                      zIndex: 20000 - ((index + 1) * vm.totalStockLength(props.index) + index2),
                    }}
                  >
                    {index2 === 0 && (
                      <>
                        <td
                          rowSpan={changedShareholder.stocks.length}
                          className={changedShareholder.newYn === true ? undefined : st.textTd}
                        >
                          <input
                            type="hidden"
                            name={`changedShareholders[${index}].id`}
                            value={changedShareholder.id}
                          />
                          {changedShareholder.newYn === false && (
                            <>
                              <input
                                type="hidden"
                                name={`changedShareholders[${index}].name`}
                                value={changedShareholder.name}
                              />
                              <div className={st.number}>
                                <span>{index + 1}</span> <span>{changedShareholder.name}</span>
                              </div>
                            </>
                          )}
                          {changedShareholder.newYn === true && (
                            <div className={st.number}>
                              <span className={st.innerText}>{index + 1}</span>
                              <InputText
                                style={{ width: '198px' }}
                                size="small"
                                placeholder="이름"
                                value={changedShareholder.name}
                                onChange={(e) => vm.setName(e, props.index, index)}
                                name={`changedShareholders[${index}].name`}
                                errorText={
                                  <Error
                                    name={`changedShareholders[${index}].name`}
                                    value={changedShareholder.name}
                                    style={{ width: '130px' }}
                                    errorCase={{
                                      required: '이름을 입력하세요.',
                                    }}
                                  />
                                }
                              />
                            </div>
                          )}
                        </td>
                        <td
                          rowSpan={changedShareholder.stocks.length}
                          className={changedShareholder.newYn === true ? undefined : st.textTd}
                        >
                          {changedShareholder.newYn === false && (
                            <>
                              {vm.switchShareholderType(changedShareholder.shareholderType)}{' '}
                              <input
                                type="hidden"
                                name={`changedShareholders[${index}].type`}
                                value={changedShareholder.shareholderType}
                              />
                            </>
                          )}
                          {changedShareholder.newYn === true && (
                            <Select
                              style={{ width: '209px' }}
                              size="small"
                              value={changedShareholder.shareholderType}
                              onChange={(e) => vm.setShareholderType(e, props.index, index)}
                              name={`changedShareholders[${index}].type`}
                              errorText={
                                <Error
                                  name={`changedShareholders[${index}].type`}
                                  value={changedShareholder.shareholderType}
                                  errorCase={{
                                    required: '유형을 선택하세요.',
                                  }}
                                />
                              }
                            >
                              <option value="">-</option>
                              <option value="국내_성인">한국인 성인</option>
                              <option value="국내_미성년자">한국인 미성년</option>
                              <option value="국내_법인">한국 법인</option>
                              <option value="펀드_투자조합">한국 펀드, 투자조합</option>
                              <option value="해외_성인">외국인 성인</option>
                              <option value="해외_미성년자">외국인 미성년</option>
                              <option value="해외_법인">외국 법인</option>
                            </Select>
                          )}
                        </td>
                      </>
                    )}
                    <td className={st.stockTd}>
                      {stock.id && (
                        <input
                          type="hidden"
                          name={`changedShareholders[${index}].stocks[${index2}].id`}
                          value={stock.id}
                        />
                      )}
                      <Select
                        style={{ width: '178px' }}
                        size="small"
                        value={stock.name}
                        onChange={(e) => vm.setStock(e, props.index, index, index2)}
                        name={`changedShareholders[${index}].stocks[${index2}].name`}
                        errorText={
                          <Error
                            name={`changedShareholders[${index}].stocks[${index2}].name`}
                            value={stock.name}
                            errorCase={{
                              required: '주식종류를 선택하세요.',
                            }}
                          />
                        }
                      >
                        <option value="">-</option>
                        {vm.stocks(props.index)?.map((stock, index3) => (
                          <option key={index3} value={stock}>
                            {stock}
                          </option>
                        ))}
                      </Select>
                      <div className={st.btnArea}>
                        <ToolTip
                          upAndDown="down"
                          leftOrRight="left"
                          tooltipContentStyle={{ width: '92px', top: '21px', pointerEvents: 'none' }}
                          style={{ zIndex: 10 }}
                          handle={<PlusBtn onClick={() => vm.addStock(props.index, index, index2)} />}
                        >
                          소유주식 추가
                        </ToolTip>
                        <ToolTip
                          upAndDown="down"
                          leftOrRight="left"
                          tooltipContentStyle={{ width: '92px', top: '21px', pointerEvents: 'none' }}
                          style={{ zIndex: 5 }}
                          handle={
                            <MinusBtn
                              onClick={() => vm.deleteStock(props.index, index, index2)}
                              disabled={changedShareholder.stocks.length === 1}
                            />
                          }
                        >
                          해당 주식 삭제
                        </ToolTip>
                      </div>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <InputText
                        style={{ width: '183px', textAlign: 'right' }}
                        size="small"
                        placeholder="0"
                        value={addCommas(stock.number)}
                        onChange={(e) => vm.setNumber(e, props.index, index, index2)}
                        name={`changedShareholders[${index}].stocks[${index2}].number`}
                        errorText={
                          <Error
                            name={`changedShareholders[${index}].stocks[${index2}].number`}
                            value={stock.number.toString()}
                            style={{ width: '130px' }}
                            errorCase={{
                              required: '주식수를 입력하세요.',
                            }}
                          />
                        }
                      />
                      <span className={st.inputSubText}>주</span>
                    </td>
                    {index2 === 0 && (
                      <td
                        rowSpan={changedShareholder.stocks.length}
                        style={{ textAlign: 'right', verticalAlign: 'middle' }}
                      >
                        <ToolTip
                          upAndDown="down"
                          leftOrRight="left"
                          tooltipContentStyle={{ width: '92px', pointerEvents: 'none' }}
                          handle={
                            <MinusBtn
                              size="large"
                              disabled={vm.changedShareholders(props.index)?.length === 1}
                              onClick={() => vm.changedShareholder(props.index, index)}
                              className={st.rowDeleteBtn}
                            />
                          }
                        >
                          해당 주주 삭제
                        </ToolTip>
                      </td>
                    )}
                  </tr>
                )),
              )}
              <tr className={st.changedShareholderSum}>
                <td colSpan={3}>
                  <AddBtn onClick={() => vm.addChangedShareholder(props.index)}>추가하기</AddBtn>
                </td>
                <td colSpan={2}>
                  총<span className={st.count}>{addCommas(vm.changedShareholdersSum(props.index))}</span>주
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  ));
}
export default ShareholderList;
