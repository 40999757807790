import FinalModel from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel';
import FinalService from '@service/Corp/Agenda/Step/FinalCheck/Final/FinalService';

class FinalVm {
  load(agendaProgressId: string) {
    FinalService.load(agendaProgressId);
  }
  get completed() {
    return FinalModel.completed;
  }
  get agendaPeriod() {
    return FinalModel.agendaPeriod;
  }
  get registrationAgencyType() {
    return FinalModel.registrationAgencyType;
  }
  get meetingSchedule() {
    return FinalModel.meetingSchedule;
  }
  get memo() {
    return FinalModel.memo;
  }
}

export default new FinalVm();
