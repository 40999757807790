import guestModel from '@model/Guest/guestModel';
import type { A사업자등록, Banks } from '@model/Guest/guestModel.interface';

class InformationVm {
  joinStandby() {
    window.open('/auth/joinMembership');
  }
  get authInfo() {
    const authInfo = guestModel.authInfo as A사업자등록;
    return authInfo;
  }
  get documentType() {
    return this.authInfo.documentType;
  }
  get companyName() {
    const company = this.authInfo.company;
    let companyName = '';
    if (company.prefixed) {
      companyName = company.companyType + ' ' + company.companyName;
    } else {
      companyName = company.companyName + ' ' + company.companyType;
    }
    return companyName;
  }
  get userName() {
    return this.authInfo.userName;
  }
  get expireDate() {
    return this.authInfo.expireDate;
  }
  get company() {
    return this.authInfo.company;
  }
  get businessRegistration() {
    return this.authInfo.businessRegistration;
  }
  get separateEmail() {
    const email = this.authInfo.businessRegistration.taxInvoiceIssuanceEmail || '';
    const emailList = email.split('@');
    return {
      pre: emailList[0],
      sur: emailList[1],
    };
  }
  get bankbook() {
    return this.authInfo?.bankbook;
  }
  accountInfoCopyText(bankName: Banks, accountNumber: string) {
    const company = this.authInfo.company;
    let companyName: string = '';

    if (company.prefixed) {
      if (company?.companyType === '주식회사') {
        companyName = `(주)${company.companyName}`;
      } else if (company.companyType === '유한회사' || company.companyType === '유한책임회사') {
        companyName = `(유)${company.companyName}`;
      }
    } else {
      if (company.companyType === '주식회사') {
        companyName = `${company.companyName}(주)`;
      } else if (company.companyType === '유한회사' || company.companyType === '유한책임회사') {
        companyName = `${company.companyName}(유)`;
      }
    }

    let copyString: string = '';
    copyString = bankName + ' ' + accountNumber + ' ' + companyName;
    return copyString;
  }
}

export default new InformationVm();
