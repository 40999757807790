import clsx from 'clsx';

import st from './style.module.scss';

interface TermsModalProps {
  content: JSX.Element;
}

function TermsModal(props: TermsModalProps) {
  return <div className={clsx(st.termsContent, 'terms')}>{props.content}</div>;
}

export default TermsModal;
