import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import HomeVm from './HomeVm';
import { addCommas } from '@common/module/replaceNumber';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import Guide from '@commonComponents/Guide/Guide';
import GuideList from '@commonComponents/GuideList/GuideList';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import ContentBtn from '@standby/common-ui/components/atoms/Button/ContentBtn/ContentBtn';
import ListCard from '@standby/common-ui/components/atoms/ListCard';
import st from './Home.module.scss';
import clsx from 'clsx';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';

function Home() {
  useEffect(() => {
    HomeVm.load();
  }, [HomeVm.corpId]);

  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>주주/주식</span>
      </PageTitle>
      {HomeVm.companyRegisterStatus === 'REGISTERED' && HomeVm.stockholderTagName === '등록중' ? (
        <div className={st.imgBox}>
          <img src="/images/stock_example.png" />
          <div className={st.explain}>
            지금 주주명부를 등록하고 있어요.
            <br />
            등록을 마치고 카톡으로 알림을 드릴게요.
            <div className={st.subExplain}>최대 1영업일</div>
          </div>
        </div>
      ) : (
        <>
          <Guide
            title={<strong>주주/주식 가이드</strong>}
            localStorageKey="stockHolderHomeGuide"
            corpId={HomeVm.corpId}
          >
            <GuideList
              items={HomeVm.stockManagementGuides}
              fullLink="https://standby.kr/blog/category/%EC%A3%BC%EC%A3%BC&%EC%9E%90%EB%B3%B8%EA%B8%88/"
            />
          </Guide>
          <div className={st.row}>
            <div className={st.capital}>
              <div className={st.title}>자본금</div>
              <div className={st.titleLabel}>
                <span>등기부상 자본금</span>
              </div>
              <div className={st.price_important}>
                <span className={st.price}>{addCommas(HomeVm.capital)}</span>
                <span className={st.won}>원</span>
              </div>
              <div className={st.price_sub}>
                {addCommas(HomeVm.stocksNumber)}주 X 액면금 {addCommas(HomeVm.parValue)}원
              </div>
              {window.parseInt(HomeVm.capital) < 1000000000 && (
                <div className={st.info}>
                  <span>자본금 10억 원 미만이어서, </span>
                  <a
                    href="https://standby.kr/blog/article/%EC%9E%90%EB%B3%B8%EA%B8%8810%EC%96%B5%EB%AF%B8%EB%A7%8C%EB%B2%95%EC%9D%B8%ED%98%9C%ED%83%9D/"
                    target="_blank"
                  >
                    절차 간소화 혜택
                  </a>
                  <span>이 있어요</span>.
                </div>
              )}
            </div>
            <ContentBox
              style={{ width: '616px', minHeight: '279px' }}
              title="주식"
              className={st.contentBox}
              titleStyle={{ paddingLeft: '20px', top: '26px' }}
            >
              <div className={st.top}>
                <div className={st.info}>
                  총 {addCommas(HomeVm.stocksNumber)} 주 / {HomeVm.stocks.length} 종류
                </div>
                <div className={st.btnarea}>
                  <ContentBtn color="important" onClick={() => HomeVm.increaseCapital()}>
                    자본금 증액
                  </ContentBtn>
                  <ContentBtn onClick={() => HomeVm.transferStocks()}>주식양도</ContentBtn>
                  {/* <ContentBtn onClick={() => HomeVm.stockHistory()}>히스토리</ContentBtn> */}
                </div>
              </div>
              <div
                className={clsx(
                  st.content,
                  st.stockList,
                  HomeVm.stocks.length > 4 ? st.many : '',
                  HomeVm.stocks.length > 8 ? st.overflow : '',
                  HomeVm.state.stocksOpenYn ? st.open : '',
                )}
              >
                {HomeVm.stocks.map((item, idx) => {
                  return (
                    <ListCard
                      color={item.name === '보통주' || item.name === '보통주식' ? 'important' : null}
                      key={idx}
                      className={st.listCard}
                      onClick={() => HomeVm.stockContent(idx, item.name)}
                    >
                      <span>{item.name}</span>
                      <span>{addCommas(item.number)}주</span>
                    </ListCard>
                  );
                })}
              </div>
              {HomeVm.stocks.length > 8 && (
                <>
                  {HomeVm.state.stocksOpenYn === false && (
                    <button type="button" className={st.moreView} onClick={() => HomeVm.stocksListOpenState()}>
                      더보기
                    </button>
                  )}
                  {HomeVm.state.stocksOpenYn === true && (
                    <button
                      type="button"
                      className={clsx(st.moreView, st.open)}
                      onClick={() => HomeVm.stocksListOpenState()}
                    >
                      접기
                    </button>
                  )}
                </>
              )}
            </ContentBox>
          </div>
          <div className={st.row}>
            <ContentBox style={{ width: '100%' }} title="주주명부" titleStyle={{ paddingLeft: '20px' }}>
              <div className={st.top}>
                <div className={st.changeDate}>
                  <DatePicker
                    className={st.datepicker}
                    dateIcon
                    selected={HomeVm.updatedAt}
                    onChange={(e) => HomeVm.setUpdatedAt(e)}
                  />
                  <span>기준</span>
                </div>
                <div className={st.btnarea}>
                  {HomeVm.isDemo ? (
                    <ContentBtn
                      color="important"
                      disabled={!HomeVm.displayShareholders}
                      onClick={() => HomeVm.demoAlert()}
                    >
                      주주명부 다운로드/발송
                    </ContentBtn>
                  ) : (
                    <Link to={`/corp/stock/shareholderList?corp=${HomeVm.corpId}`}>
                      <ContentBtn color="important" disabled={!HomeVm.displayShareholders}>
                        주주명부 다운로드/발송
                      </ContentBtn>
                    </Link>
                  )}
                  {HomeVm.isDemo ? (
                    <ContentBtn
                      color="important"
                      disabled={!HomeVm.displayShareholders}
                      onClick={() => HomeVm.demoAlert()}
                    >
                      주권미발행 확인서 다운로드/발송
                    </ContentBtn>
                  ) : (
                    <Link to={`/corp/stock/unissuedStockCertificate?corp=${HomeVm.corpId}`}>
                      <ContentBtn color="important" disabled={!HomeVm.displayShareholders}>
                        주권미발행 확인서 다운로드/발송
                      </ContentBtn>
                    </Link>
                  )}
                  <Link to={`/corp/agenda/progress?corp=${HomeVm.corpId}`}>
                    <ContentBtn disabled={!HomeVm.displayShareholders}>주주총회 개최</ContentBtn>
                  </Link>
                  <ContentBtn onClick={() => HomeVm.onlineMusterContent()} disabled={!HomeVm.displayShareholders}>
                    소집통지 온라인 수신동의 받기
                  </ContentBtn>
                </div>
              </div>
              <div className={st.shareholderList}>
                <div className={st.title}>
                  <span>주주명</span>
                  <span>유형</span>
                  <span>주식종류</span>
                  <span>주식수</span>
                  <span>지분율</span>
                  <span>
                    소집통지
                    <br />
                    온라인 수신동의
                  </span>
                </div>
                {HomeVm.displayShareholders === true ? (
                  <>
                    <div className={st.list}>
                      {HomeVm.shareholders.map((item, idx) => (
                        <Link to={`/corp/stock/stockholder?corp=${HomeVm.corpId}&shareholderId=${item.id}`} key={idx}>
                          <ListCard className={st.item} color={idx < 3 ? 'important' : null}>
                            <span>
                              <span>{idx + 1}</span>
                              {item.name}
                            </span>
                            <span>{item.shareholderType}</span>
                            <div className={st.stocksList}>
                              {item?.stocks.map((stock, idx2) => (
                                <div className={st.stockItem} key={idx2}>
                                  <span>{stock.name}</span>
                                  <span>{addCommas(stock.number)} 주</span>
                                  <span>{stock.rate}</span>
                                </div>
                              ))}
                            </div>
                            <span>
                              <span data-status={item.onlineMusterNoticeStatus}>
                                {HomeVm.onlineMusterNoticeState(item.onlineMusterNoticeStatus)}
                              </span>
                            </span>
                          </ListCard>
                        </Link>
                      ))}
                    </div>
                    <div className={st.sum}>
                      <span>총 {HomeVm.shareholders.length} 명</span>
                      <span>총 {addCommas(HomeVm.shareholderStocksSum())} 주</span>
                    </div>
                  </>
                ) : (
                  <div className={st.emptyShareholders}>
                    <img src="/images/empty_exclamation_Icon.svg" className={st.exclamationImg} />
                    <div className={st.explain}>아직 등록된 주주가 없습니다.</div>
                  </div>
                )}
              </div>
            </ContentBox>
          </div>
        </>
      )}
    </>
  ));
}
export default Home;
