import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import React, { useEffect } from 'react';

import { useAwsCreditTermQuery } from '../../queries';
import TermsModal from '../TermsModal/TermsModal';
import type { TermInfo } from '../types';

interface TermInfoState {
  termInfo: TermInfo[];
}

export interface TermBundle {
  termInfoState: TermInfoState;
  agreeAll: boolean;
  isRequiredTermsAgreed: boolean;
  setAgreeAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setAgreeYn: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  openTermPopup: (title: string, content: React.JSX.Element) => void;
}

const useTerm = (): TermBundle => {
  const { data, isSuccess } = useAwsCreditTermQuery();

  const termInfoState = useLocalObservable<TermInfoState>(() => ({
    termInfo: [],
  }));

  useEffect(() => {
    if (!isSuccess) return;

    runInAction(() => {
      termInfoState.termInfo = data.map((item) => ({
        ...item,
        agreeYn: false,
      }));
    });
  }, [data, isSuccess]);

  const setAgreeAll: TermBundle['setAgreeAll'] = (e) => {
    runInAction(() => {
      termInfoState.termInfo.forEach((item) => {
        item.agreeYn = e.target.checked;
      });
    });
  };

  const setAgreeYn: TermBundle['setAgreeYn'] = (e, index) => {
    termInfoState.termInfo[index].agreeYn = e.target.checked;
  };

  const openTermPopup: TermBundle['openTermPopup'] = (title, content) => {
    runInAction(() => {
      portalModel.title = title;
      portalModel.content = <TermsModal content={content} />;
    });
  };

  const agreeAll = termInfoState.termInfo.length !== 0 && termInfoState.termInfo.every((term) => term.agreeYn);
  const isRequiredTermsAgreed =
    termInfoState.termInfo.length !== 0 && termInfoState.termInfo.every((term) => !term.required || term.agreeYn);

  return {
    termInfoState,
    agreeAll,
    setAgreeAll,
    setAgreeYn,
    openTermPopup,
    isRequiredTermsAgreed,
  };
};

export default useTerm;
