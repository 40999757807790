import type { Banks } from '@model/Corp/BusinessRegistration/businessRegistrationModel.interface';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@model/Corp/corpModel';
import { runInAction } from 'mobx';
import AddAccount from '@pages/Corp/BusinessRegistration/BusinessRegistrationDocument/AddAccount/AddAccount';
import portalModel from '@model/portalModel';
import { _alert } from '@model/dialogModel';
import st from './CompleteHome.module.scss';
import BesinessRegistrationDownloadSelectModal from '@pages/Corp/BusinessRegistration/BesinessRegistrationDownloadModal/BesinessRegistrationDownloadSelectModal';

class CompleteHomeVm {
  get corpId() {
    return corpModel.id;
  }
  get guides() {
    return businessRegistrationModel.guides;
  }
  load() {
    businessRegistrationService.completeHome();
  }
  get fileDownloadUrl() {
    return businessRegistrationModel.fileDownloadUrl;
  }
  get issuedAt() {
    return businessRegistrationModel.issuedAt;
  }
  get information() {
    return businessRegistrationModel.information;
  }
  get businessTypeAndItemList() {
    return businessRegistrationModel.businessTypeAndItemList;
  }
  async downLoadFile() {
    // await businessRegistrationService.downLoadUrl(url);
    runInAction(() => {
      portalModel.title = '사업자등록증 다운로드';
      portalModel.content = <BesinessRegistrationDownloadSelectModal />;
    });
  }
  get bankbooks() {
    return businessRegistrationModel.bankbooks;
  }
  get companyName() {
    const company = corpModel.company;
    let companyName: string = '';

    if (company.prefixedYn) {
      if (company.corpType === '주식회사') {
        companyName = `(주)${company.companyName}`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `(유)${company.companyName}`;
      }
    } else {
      if (company.corpType === '주식회사') {
        companyName = `${company.companyName}(주)`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `${company.companyName}(유)`;
      }
    }

    return companyName;
  }
  fileDownload(downloadLink: string) {
    businessRegistrationService.fileDownload(downloadLink);
  }
  accountInfoCopyText(bankName: Banks, accountNumber: string) {
    let copyString: string = '';
    copyString = bankName + ' ' + accountNumber + ' ' + this.companyName;
    return copyString;
  }
  sendDocument() {
    window.location.href = `/corp/accounting/businessRegistration/document/send?corp=${corpModel.id}`;
  }
  addAccount() {
    runInAction(() => {
      portalModel.title = '새로운 계좌 추가하기';
      portalModel.content = <AddAccount />;
    });
  }
  addBankbook() {
    if (this.bankbooks && this.bankbooks.length !== 0) {
      window.location.href = `/corp/accounting/businessRegistration/document/bankbook?corp=${corpModel.id}`;
    } else {
      this.addAccount();
    }
  }
  async updateDocument() {
    await _alert(
      '오른쪽 아래 채팅으로 신규 사업자등록증을 전달해주세요!',
      '',
      '확인',
      <ul className={st.alertContent}>
        <li>업데이트는 스탠바이 팀에서 직접 해드리고 있어요.</li>
        <li>오른쪽 아래 채팅상담 버튼을 눌러, 신규 사업자등록증 파일을 업로드 해주세요. </li>
      </ul>,
      'loud',
    );
  }
}

export default new CompleteHomeVm();
