import { post } from '@common/module/httpRequest';
import { _alert } from '@model/dialogModel';
import loadingModel from '@model/loadingModel';

class CancelRegService {
  async cancel(param: { name: string; reason: string; sendAlimtalkYn: boolean | string }, agendaProgressId: string) {
    loadingModel.showYn = true;
    await post(`/company/agendaProgress/dashboard/regAgency/cancel?agendaProgressId=${agendaProgressId}`, param);
    loadingModel.showYn = false;
    await _alert('등기대행이 취소되었습니다.');
    window.location.reload();
  }
}
export default new CancelRegService();
