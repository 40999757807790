import { Button, TextButton, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';

import st from './VerificationPhoneNumber.module.scss';
import vm from './VerificationPhoneNumberVm';

interface VerificationPhoneNumberProps {
  name: string;
  phoneNumber: string;
}

function VerificationPhoneNumber(props: VerificationPhoneNumberProps) {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)} className={st.form}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-18M" className={st.color}>
          문자로 받으신 인증번호
        </Typography>
        <Typography fontType="Title-18M">를 입력해 주세요.</Typography>
      </div>
      <div className={st.info}>
        <Typography fontType="Body-14R">
          혹시 입력하신 전화번호가 정확하나 문자가 오지 않았다면,
          <br />
          스팸 문자함을 확인해 주세요.
        </Typography>
      </div>
      <TextField
        name="phoneNumber"
        label="발송한 전화번호"
        value={props.phoneNumber}
        className={st.inputText}
        disabled
      />
      <TextField
        maxLength={10}
        name="verificationCode"
        label="인증번호"
        className={st.inputText}
        value={vm.state.verificationCode}
        onChange={(e) => vm.setVerificationCode(e)}
        suffix={<Timer />}
        error={
          <ErrorValidation
            name="verificationCode"
            value={vm.state.verificationCode}
            errorCase={{
              required: '인증번호를 입력해 주세요.',
            }}
          />
        }
      />
      <Button type="submit" size="large" color="primary400" className={st.mainBtn}>
        아이디 확인하기
      </Button>
      <div className={st.likeBtnBox}>
        <TextButton type="button" onClick={vm.reload} className={st.likeBtn} underline>
          인증번호 재요청하기
        </TextButton>
      </div>
    </form>
  ));
}

const Timer = () => {
  const MINUTES_IN_MS = 10 * 60 * 1000 - 1;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(MINUTES_IN_MS);

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0');
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    if (timeLeft <= 0) {
      clearInterval(timer);
      console.log('타이머가 종료되었습니다.');
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <div className={st.timer}>
      {minutes === '-1' || second === '-1' ? (
        <>00분 00초</>
      ) : (
        <>
          {minutes}분 {second}초
        </>
      )}
    </div>
  );
};

export default VerificationPhoneNumber;
