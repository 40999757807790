import { observable, makeObservable } from 'mobx';
import type { documentModelInterface, Shareholder, DefaultValue } from './documentModel.interface';

class documentModel implements documentModelInterface {
  constructor() {
    makeObservable(this, {
      previewLink: observable,
      shareholders: observable,
      defaultValue: observable,
    });
  }
  previewLink: string = '';
  shareholders: Shareholder[] = [];
  defaultValue: DefaultValue = {};
}

export default new documentModel();
