import { useObserver } from 'mobx-react';
import vm from './MeetingScheduleVm';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './MeetingSchedule.module.scss';
import clsx from 'clsx';

interface MeetingScheduleProps {
  agendaProgressId: string;
}

function MeetingSchedule(props: MeetingScheduleProps) {
  return useObserver(() => (
    <div className={bst.row}>
      <div className={bst.title}>안건 & 일정</div>
      <div className={bst.content}>
        <div className={st.meetingScheduleContent}>
          {vm.meetingSchedule.map((schedule, idx) => {
            if (idx === 0 || (idx > 0 && vm.state.moreViewYn)) {
              return (
                <div key={idx} className={st.meetingSchedule}>
                  <div className={st.meetingTitle}>
                    {idx + 1}. {schedule.title}
                  </div>
                  {schedule.agendas.length > 0 && (
                    <div className={st.agendaBox}>
                      <div className={st.meetingSubTitle}>안건</div>
                      <ul className={st.agenda}>
                        {schedule.agendas.map((agenda, idx2) => (
                          <li key={idx2}>
                            <span>{idx2 + 1}.</span>
                            {agenda}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {schedule.place && (
                    <div className={st.placeBox}>
                      <div className={st.meetingSubTitle}>장소</div>
                      <div className={st.place}>{schedule.place}</div>
                    </div>
                  )}
                  {schedule.decisionAt && (
                    <div className={st.decisionAtBox}>
                      <div className={st.meetingSubTitle}>일시</div>
                      <div className={st.decisionAt}>{schedule.decisionAt}</div>
                    </div>
                  )}
                  {idx === vm.meetingSchedule.length - 1 && vm.representativeAddressChangeIsHide && (
                    <div className={st.representative}>
                      대표자주소변경 안건도 함께 진행해요.(전입일자 기준으로 처리)
                    </div>
                  )}
                </div>
              );
            }
          })}
          <button
            type="button"
            className={clsx(st.changeBtn, st.arrowBtn)}
            onClick={() => vm.openModal(props.agendaProgressId)}
          >
            <span>장소, 일시 변경하기</span>
          </button>
          {vm.showAgendaPopUpButton === true && (
            <button
              type="button"
              className={clsx(st.agendaViewBtn, st.arrowBtn)}
              onClick={() => vm.agendaViewModal(props.agendaProgressId)}
            >
              <span>입력내용 확인하기</span>
            </button>
          )}
        </div>
        {vm.meetingSchedule.length > 1 && (
          <button type="button" className={bst.moreViewBtn} onClick={() => vm.moreView()}>
            <span className={vm.state.moreViewYn ? bst.open : ''}>{vm.state.moreViewYn ? '접기' : '더보기'}</span>
          </button>
        )}
      </div>
    </div>
  ));
}
export default MeetingSchedule;
