import { _alert, _confirm } from '@model/dialogModel';
import { downloadFileGet } from '@src/common/module/httpRequest';
import fileUpload_gray from '@src/images/fileUploadIcon_gray.svg';
import fileUpload from '@src/images/fileUploadIcon_main500.svg';
import Color from '@standby/common-ui/color.scss';
import deleteBtn from '@standby/common-ui/components/asset/Icon/file_deleteBtn.svg';
import fileUploadNegative from '@standby/common-ui/components/asset/Icon/file_upload_negative.svg';
import fileDownloadPositive from '@standby/common-ui/components/asset/Icon/icon_download_important.svg';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ScrollArea from 'react-custom-scrollbars-2';
import styled from 'styled-components';

const _fileUpload: string = fileUpload as unknown as string;

const _fileUploadGray: string = fileUpload_gray as unknown as string;

const _fileDownloadPositive: string = fileDownloadPositive as unknown as string;

const _fileUploadNegative: string = fileUploadNegative as unknown as string;

const _deleteBtn: string = deleteBtn as unknown as string;

const FileUploadBox = styled.div``;

const File = styled.input`
  display: none;
`;

const FileGuide = styled.label<{ color?: 'gray' }>`
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 10px 12px;
  padding-top: 9px;
  width: 460px;
  height: 59px;
  border: 1px solid;
  border-color: ${(props) => (props.color === 'gray' ? Color.neutral400 : Color.main400)};
  border-radius: 4px;
  background-color: ${(props) => (props.color === 'gray' ? Color.neutral200 : Color.neutral100)};
  text-align: left;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 10px;
    width: 20px;
    height: 20px;
    background-image: url(${(props) => (props.color === 'gray' ? _fileUploadGray : _fileUpload)});
    background-size: cover;
    background-repeat: no-repeat;
  }
  &:hover {
    background-color: ${Color.main000};
  }
  .title {
    color: ${(props) => (props.color === 'gray' ? Color.neutral800 : Color.main500)};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding-left: 24px;
  }
  .explain {
    color: ${(props) => (props.color === 'gray' ? Color.neutral400 : Color.neutral600)};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.24px;
  }
`;

const FileList = styled.div`
  margin-top: 8px;
  width: 460px;
  border: 1px solid ${Color.neutral300};
  border-top: none;
  border-radius: 4px;

  .scrollArea {
    min-height: 44px;
    max-height: 134px;
  }
`;

const Ul = styled.ul`
  li {
    position: relative;
    padding: 14px 26px;
    padding-left: 36px;
    color: ${Color.neutral700};
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    display: none;
    &.attachment {
      border-top: 1px solid ${Color.neutral300};
      display: block;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 14px;
        left: 16px;
        width: 16px;
        height: 16px;
        background-image: url(${_fileDownloadPositive});
        background-size: cover;
        background-repeat: no-repeat;
      }
      a {
        cursor: pointer;
        color: ${Color.main500};
      }
    }
    &.upload {
      display: block;
      border-top: 1px solid ${Color.neutral300};
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 14px;
        left: 16px;
        width: 16px;
        height: 16px;
        background-image: url(${_fileUploadNegative});
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
`;

const DeleteBtn = styled.button`
  position: absolute;
  top: 14px;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(${_deleteBtn});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
`;

interface FileUploadProps {
  attachmentFilesModel?: {
    id: number | string;
    downloadUrl?: string;
    fileName?: string;
  }[];
  multiple?: boolean;
  accept?: string;
  className?: string;
  style?: React.CSSProperties;
  name?: string;
  explain?: string;
  errorText?: string;
  deleteFileName?: string;
  color?: 'gray';
}

const FileUpload: React.FC<FileUploadProps> = React.memo(function FileUpload(props) {
  const [deleteFiles, setDeleteFiles] = useState<(string | number)[]>([]);

  const [uploadFiles, setUploadFiles] = useState<string[]>([]);

  useEffect(() => {
    setDeleteFiles([]);
    setUploadFiles([]);
  }, [props.attachmentFilesModel]);

  const uploadFilesAdd = () => {
    if (props.multiple) {
      setUploadFiles([...uploadFiles, '']);
    } else {
      setUploadFiles(['']);
    }
  };

  const uploadFileDelete = async (idx: number) => {
    if (await _confirm('업로드한 파일을 삭제하시겠습니까?')) {
      const fileTmp = [...uploadFiles];

      fileTmp[idx] = 'removed';
      setUploadFiles(fileTmp);
    }
  };

  const uploadFileChange = async (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (e.target.files) {
      if (props.accept) {
        const acceptList = props.accept.split(',');

        acceptList.forEach((item, idx) => {
          acceptList[idx] = item.replace(/\./g, '').trim().toLowerCase();
        });
        const splitFileExtenstion = e.target.files[0].name.split('.');

        const fileExtenstion = splitFileExtenstion[splitFileExtenstion.length - 1].toLowerCase();

        if (acceptList.includes(fileExtenstion)) {
          if (e.target.files[0].size > 104857600) {
            await _alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
          } else {
            const fileTmp = [...uploadFiles];

            fileTmp[idx] = e.target.files[0].name;
            setUploadFiles(fileTmp);
          }
        } else {
          await _alert(`${acceptFileList()} 파일만 업로드 가능합니다.`);
        }
      } else {
        if (e.target.files[0].size > 104857600) {
          await _alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
        } else {
          const fileTmp = [...uploadFiles];

          fileTmp[idx] = e.target.files[0].name;
          setUploadFiles(fileTmp);
        }
      }
    }
  };

  const attachmentFileDelete = async (idx: number) => {
    if (await _confirm('업로드한 파일을 삭제하시겠습니까?')) {
      if (props.attachmentFilesModel && props.attachmentFilesModel.length !== 0) {
        setDeleteFiles([...deleteFiles, props.attachmentFilesModel[idx].id]);
        props.attachmentFilesModel.splice(idx, 1);
      }
    }
  };

  const checkIdx = (idx: number) => {
    const currentIdx = idx;

    let preRemoveCnt = 0;

    uploadFiles.forEach((uploadFile, index) => {
      if (currentIdx > index) {
        if (uploadFile === 'removed' || uploadFile === '') {
          preRemoveCnt++;
        }
      }
    });

    return currentIdx - preRemoveCnt;
  };

  const uploadCheck = () => {
    let uploadCnt = 0;

    uploadFiles.forEach((uploadFile) => {
      if (uploadFile !== 'removed' && uploadFile !== '') {
        uploadCnt++;
      }
    });

    if (uploadCnt > 0) {
      return true;
    }

    return false;
  };

  const acceptFileList = () => {
    const acceptFile = props.accept;

    let acceptList: string[] = [];

    let acceptFileList = '';

    if (acceptFile) {
      acceptList = acceptFile.split(',');
      acceptList.forEach((item, idx) => {
        acceptList[idx] = item.replace(/\./g, '').trim().toUpperCase();

        if (acceptList[idx] === 'DOCX') {
          acceptList[idx] = '워드';
        } else if (acceptList[idx] === 'HWP') {
          acceptList[idx] = '한글';
        }

        if (idx !== acceptList.length - 1) {
          acceptList[idx] = acceptList[idx].concat(' / ');
        }
      });
    }

    acceptFileList = acceptList.join().replace(/,/g, '');

    return acceptFileList;
  };

  const onClickDownloadURL = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url?: string) => {
    e.preventDefault();

    if (!url) return;

    void downloadFileGet(url);
  };

  return useObserver(() => (
    <FileUploadBox className={props.className} style={props.style}>
      <FileGuide htmlFor={`${props.name}${uploadFiles.length}`} onClick={() => uploadFilesAdd()} color={props.color}>
        <div className="title">파일 업로드</div>
        <div className="explain">{props.explain}</div>
      </FileGuide>
      {props.errorText && <ErrorText>{props.errorText}</ErrorText>}

      {(props.attachmentFilesModel?.length !== 0 || uploadFiles.length !== 0) && (
        <FileList style={{ display: props.attachmentFilesModel?.length !== 0 || uploadCheck() ? 'block' : 'none' }}>
          <ScrollArea
            className="scrollArea"
            renderView={({ style, props }) => (
              <div {...props} style={{ ...style, position: 'none', maxHeight: '134px' }} />
            )}
            renderThumbVertical={(props) => <div {...props} style={{ width: '3px', backgroundColor: '#ADAFB1' }} />}
          >
            <Ul>
              {props.attachmentFilesModel && props.attachmentFilesModel.length !== 0 && (
                <>
                  {props.attachmentFilesModel.map((attachmentFile, idx) => (
                    <li key={idx} className="attachment">
                      <a
                        href={attachmentFile.downloadUrl}
                        target="_blank"
                        onClick={(e) => onClickDownloadURL(e, attachmentFile?.downloadUrl)}
                        rel="noreferrer"
                      >
                        {attachmentFile.fileName}
                      </a>
                      <DeleteBtn type="button" onClick={() => attachmentFileDelete(idx)} />
                    </li>
                  ))}
                </>
              )}
              {uploadFiles && uploadFiles.length !== 0 && (
                <>
                  {uploadFiles.map((uploadFile, idx) =>
                    uploadFile === 'removed' ? (
                      <React.Fragment key={idx}></React.Fragment>
                    ) : (
                      <List
                        uploadFile={uploadFile}
                        key={idx}
                        idx={idx}
                        name={props.name}
                        checkIdx={checkIdx}
                        uploadFileChange={uploadFileChange}
                        uploadFileDelete={uploadFileDelete}
                        multiple={props.multiple}
                      />
                    ),
                  )}
                </>
              )}
            </Ul>
          </ScrollArea>
        </FileList>
      )}
      {deleteFiles.map((deleteFilesId, index) => (
        <input type="hidden" key={index} name={`${props.deleteFileName}[${index}]`} value={deleteFilesId} />
      ))}
    </FileUploadBox>
  ));
});

interface ListProps {
  multiple?: boolean;
  uploadFile: string;
  name?: string;
  idx: number;
  checkIdx(idx: number): number;
  uploadFileChange(e: React.ChangeEvent<HTMLInputElement>, idx: number): void;
  uploadFileDelete(idx: number): void;
  accept?: string;
}

const List = React.memo(function List(props: ListProps) {
  let _name: string | undefined = '';

  if (props.multiple) {
    if (props.uploadFile !== '') {
      _name = `${props.name}[${props.checkIdx(props.idx)}]`;
    } else {
      _name = undefined;
    }
  } else {
    _name = props.name;
  }

  return useObserver(() => (
    <li className={props.uploadFile !== '' ? 'upload' : undefined}>
      <File
        type="file"
        id={`${props.name}${props.idx + 1}`}
        name={_name}
        onChange={(e) => props.uploadFileChange(e, props.idx)}
        accept={props.accept}
      />
      <span>{props.uploadFile}</span>
      <DeleteBtn type="button" onClick={() => props.uploadFileDelete(props.idx)} />
    </li>
  ));
});

FileUpload.defaultProps = {
  attachmentFilesModel: [],
  explain: '고객님의 소중한 정보는 스탠바이가 철저하게 관리하고 있어요!',
  deleteFileName: 'agendaDetail.attachmentFile.deleteFilesIds',
  multiple: true,
  accept: undefined,
};

export default FileUpload;
