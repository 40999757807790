import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect, Fragment } from 'react';
import SendVm from './SendVm';
import st from './Send.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import Loading from '@commonComponents/Loading/Loading';

let vm;

function Send(props) {
  useComponentWillMount(() => {
    vm = new SendVm();
    vm.load(props.sendParams);
  });

  return useObserver(() => (
    <form className={st.unissuedStockCertificate} onSubmit={(e) => vm.onSubmit(e, props.sendParams)}>
      {vm.state.loadingYn && <Loading />}
      {vm.existsEmailYn ? (
        <div className={st.title}>
          {vm.shareholderName}님의 이메일 주소입니다. 이메일 주소를 확인하세요.
          <div className={st.explain}>입력하신 이메일 주소는 주주명부에 저장됩니다.</div>
        </div>
      ) : (
        <div className={st.title}>
          {vm.shareholderName}님의 이메일 주소가 입력되지 않았네요. 이메일 발송을 할 수 있도록 입력하세요.
          <div className={st.explain}>입력하신 이메일 주소는 주주명부에 저장됩니다.</div>
        </div>
      )}
      <div className={st.emailInfo}>
        <div className={st.name}>{vm.shareholderName}</div>
        <div className={st.email}>
          <InputText
            name="email"
            className={st.inputText}
            style={{ width: '300px' }}
            value={vm.email}
            onChange={(e) => vm.setEmail(e, props.sendParams.shareholderId)}
            errorText={
              <Error
                name="email"
                value={vm.email}
                errorCase={{
                  required: '이메일을 입력하세요.',
                  pattern: {
                    value: regExp.email().test(vm.email),
                    message: '올바른 이메일을 입력하세요.',
                  },
                }}
              />
            }
          />
        </div>
      </div>
      <div className={st.btnArea}>
        <SquareBtn type="submit" className={st.sendEmailBtn}>
          주권미발행확인서 발송하기
        </SquareBtn>
      </div>
    </form>
  ));
}

export default Send;
