import { useObserver } from 'mobx-react';
import st from './StockInfoModal.module.scss';
import { addCommas } from '@common/module/replaceNumber';

interface StockInfoModalProps {
  stock: number;
  content: string;
}

function StockInfoModal(props: StockInfoModalProps) {
  return useObserver(() => (
    <div className={st.stockInfo}>
      <div className={st.stock}>
        {addCommas(props.stock)} 주<div className={st.explain}>* 등기부에 등기된 내용입니다.</div>
      </div>
      <div className={st.content}>
        {props.content.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </div>
    </div>
  ));
}
export default StockInfoModal;
