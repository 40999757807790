import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
class TaxScheduleVm {
  get aoaRegistrationYn() {
    return DashboardModel.dashboardData?.aoaRegistrationYn;
  }
  get schedule() {
    return DashboardModel.dashboardData?.schedule;
  }
}
export default new TaxScheduleVm();
