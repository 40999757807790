import { useObserver } from 'mobx-react';
import vm from './RepresentativeAddressChangeVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import type { SelectedAgenda, I대표이사_주소_변경 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import st from './RepresentativeAddressChange.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';

interface RepresentativeAddressChangeProps {
  agenda: SelectedAgenda<I대표이사_주소_변경>;
  agendaIndex: number;
}

function RepresentativeAddressChange(props: RepresentativeAddressChangeProps) {
  return useObserver(() => (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>집주소 변경이 필요한 대표님을 선택하세요.</div>
        <ul className={st.changeAddressList}>
          {props.agenda.agendaDetail.changeAddressExecutives.map((changeAddressExecutive, index) => (
            <li key={index}>
              <CheckBox
                value={changeAddressExecutive.id}
                checked={changeAddressExecutive.checked}
                name="agendaDetail.changeAddressExecutives[]"
                onChange={(e) => vm.changeAddressExecutives(e, props.agendaIndex, index)}
              >
                {changeAddressExecutive.name} 대표님
              </CheckBox>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ));
}
export default RepresentativeAddressChange;
