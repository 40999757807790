import { useObserver } from 'mobx-react';
import WrittenResolutionVm from './WrittenResolutionVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './WrittenResolution.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';

interface WrittenResolutionProps {
  index: number;
}

function WrittenResolution(props: WrittenResolutionProps) {
  return useObserver(() => (
    <div className={qst.questionForm}>
      {WrittenResolutionVm.hasCapitalOverBillionYn && (
        <div className={st.writtenResolutionMethod}>
          <div className={st.title}>
            <span className={st.bold}>자본금 10억 원</span>을 넘기 때문에,{' '}
            <span className={st.bold}>일반 주주총회 방식</span>으로 진행해요.
          </div>
          <div className={st.explain}>주주 전원의 서면결의 방식은 진행이 불가능해요.</div>
        </div>
      )}
      {WrittenResolutionVm.hasCapitalOverBillionYn === false &&
        WrittenResolutionVm.boardOfDirectorsAuthYn(props.index) && (
          <div className={st.writtenResolutionMethod}>
            <div className={st.title}>
              <span className={st.bold}>이사회 공증</span>이 <span className={st.bold}>필요</span>해서,{' '}
              <span className={st.bold}>일반 주주총회 방식</span>으로 진행해요.
            </div>
            <div className={st.explain}>이사회 공증시, 주주총회도 '공증 방식'을 선택해야 해요.</div>
          </div>
        )}
      {WrittenResolutionVm.hasCapitalOverBillionYn === false &&
        WrittenResolutionVm.boardOfDirectorsAuthYn(props.index) === false && (
          <div className={qst.qusetion}>
            <div className={qst.questionTitle}>
              <div style={{ marginBottom: '10px' }}>주주 전원의 서면결의 방식으로 진행하나요?</div>
              <QuestionTooltip title="주주 전원의 서면결의 방식의 장단점">
                <div className={st.tooltipContent}>
                  <div className={st.strength}>장점 : 절차와 등기서류 준비가 간단해요.</div>
                  <ul className={st.strengthList}>
                    <li>절차 : 소집통지 X</li>
                    <li>
                      등기
                      <ul className={st.strengthContentList}>
                        <li>- 전자등기 : 주주 전원이 공동인증서로 서명</li>
                        <li>- 서류등기 : 주주 전원의 인감증명서 준비, 공증 X (공증료 절약)</li>
                      </ul>
                    </li>
                  </ul>
                  <div className={st.weakness}>단점 : 주주가 많으면 오히려 불편해요.</div>
                  <ul className={st.weaknessList}>
                    <li>
                      전자등기 : 모든 주주가 공동인증서 전자서명을 하기가 번거로울 수 있어요.
                      <ul className={st.weaknessContentList}>
                        <li>
                          - 한국법인 주주의 경우, 공동인증서가 아니라 대법원에서 발급하는 등기전용 "전자증명서"로
                          전자서명을 해야 해요.
                        </li>
                        <li>- 외국인, 외국법인은 전자서명이 불가능하고, 서류등기를 해야해요.</li>
                      </ul>
                    </li>
                    <li>
                      서류등기 : 모든 주주가 인감증명서 준비를 하기가 번거로울 수 있어요.
                      <ul className={st.weaknessContentList}>
                        <li>
                          - 일반 주주총회 방식의 경우, 50% 이상 주주의 인감증명서만 있으면 되기 때문에 더 편리할 수
                          있어요.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </QuestionTooltip>
            </div>
            <div style={{ width: '460px' }}>
              <RadioLabel
                className={qst.radio}
                name="주주전원_서면결의Yn"
                value="true"
                checked={WrittenResolutionVm.주주전원_서면결의Yn(props.index) === true}
                onChange={(e) => WrittenResolutionVm.set주주전원_서면결의Yn(e, props.index)}
              >
                <span className={st.radioImportant}>
                  네.{' '}
                  {WrittenResolutionVm.hasRegistrationMattersYn(props.index) && (
                    <span className={st.radioExplain}>(주주 전원의 인감증명서 또는 공동인증서 서명 필요)</span>
                  )}
                </span>
              </RadioLabel>
              <RadioLabel
                className={clsx(qst.radio, st.radio)}
                name="주주전원_서면결의Yn"
                value="false"
                checked={WrittenResolutionVm.주주전원_서면결의Yn(props.index) === false}
                onChange={(e) => WrittenResolutionVm.set주주전원_서면결의Yn(e, props.index)}
              >
                <span className={st.radioImportant}>아니오.</span>{' '}
                <span className={st.radioExplain}>(일반 주주총회 방식으로 진행)</span>
                <div className={st.radioExplainBox}>
                  온라인으로 <span className={st.important}>소집절차 생략 동의 받기</span> 또는{' '}
                  <span className={st.important}>소집통지 발송</span>이 필요하다면 이 보기를 선택하세요!
                </div>
              </RadioLabel>
              <div>
                <Error
                  name="주주전원_서면결의Yn"
                  value={
                    WrittenResolutionVm.주주전원_서면결의Yn(props.index) !== null
                      ? String(WrittenResolutionVm.주주전원_서면결의Yn(props.index))
                      : ''
                  }
                  errorCase={{
                    required: '주주 전원 서면결의 방식 진행 여부를 선택하세요.',
                  }}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  ));
}

export default WrittenResolution;
