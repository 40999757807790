import { post, get, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';

class bookKeepingContractService {
  async loadBookkeepingContract() {
    const res = await get(`/company/${corpModel.id}/bookkeepingContract`);

    bookKeepingContractModel.setBookkeepingContract(res.data);
  }
  async electronicSignature() {
    const res = await get(`/company/${corpModel.id}/bookkeepingContract/electronicSignature`);
    bookKeepingContractModel.setElectronicSignature(res.data);
  }
  async agreeElectronicSignature() {
    const res = await post(`/company/${corpModel.id}/bookkeepingContract/electronicSignature`);
    bookKeepingContractModel.setBookkeepingContract(res.data);
  }
  async fileDownload(downloadLink) {
    await downloadFileGet(downloadLink);
  }
  async cmsDirectDebitApplication(param) {
    const res = await post(
      `/company/${corpModel.id}/bookkeepingContract/cmsAccount/cmsDirectDebitApplication`,
      param,
      {},
    );
    bookKeepingContractModel.setCmsDirectDebitApplication(res.data);
  }
  async cmsElectronicSignatureNew(param) {
    const res = await post(`/company/${corpModel.id}/bookkeepingContract/cmsAccount/electronicSignature`, param, {});
    bookKeepingContractModel.setBookkeepingContract(res.data);
  }
  async cmsElectronicSignature(param) {
    //param으로 bankbookId 넘어옴
    const res = await post(`/company/${corpModel.id}/bookkeepingContract/cmsAccount/electronicSignature/${param}`);
    bookKeepingContractModel.setBookkeepingContract(res.data);
  }
  async apply() {
    const res = await post(`/company/${corpModel.id}/bookkeepingContract/apply`);
  }
  async agree(id) {
    const res = await post(`/company/${corpModel.id}/bookkeepingContract/agree`, { id: id });
    bookKeepingContractModel.setBookkeepingContract(res.data);
  }
  async bankbooks() {
    const res = await get(`/company/${corpModel.id}/bookkeepingContract/bankbooks`);
    bookKeepingContractModel.setBankbooks(res.data);
  }
}

export default new bookKeepingContractService();
