import { Button, CheckBox, TextButton, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import st from './Login.module.scss';
import LoginVm from './LoginVm';

function Login() {
  const location = useLocation();
  const redirectToken = queryString.parse(location.search).redirectToken;

  return useObserver(() => (
    <form onSubmit={(e) => LoginVm.submit(e, redirectToken)} className={st.loginContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-24M">법인관리</Typography> <Typography fontType="Title-24B">스탠바이</Typography>
      </div>
      <TextField
        name="username"
        placeholder="example@logologo.com"
        value={LoginVm.email}
        onChange={LoginVm.setEmail}
        label="이메일"
      />
      <TextField
        type="password"
        name="password"
        className={st.passwordBox}
        placeholder="Password"
        value={LoginVm.password}
        onChange={LoginVm.setPassword}
        label="비밀번호"
      />
      <Button type="submit" size="large" color="primary400" className={st.mainBtn}>
        로그인
      </Button>
      <div className={st.btnWrap}>
        <CheckBox name="autoLogin" value="true" size="small">
          자동 로그인
        </CheckBox>
        <div className={st.btnList}>
          <Link to="/auth/findId">
            <TextButton type="button" className={st.linkedBtn}>
              아이디 찾기
            </TextButton>
          </Link>
          <Link to="/auth/findPassword">
            <TextButton type="button" className={st.linkedBtn}>
              비밀번호 찾기
            </TextButton>
          </Link>
          <Link to="/auth/joinMembership">
            <TextButton type="button" className={st.linkedBtn}>
              회원가입
            </TextButton>
          </Link>
        </div>
      </div>
    </form>
  ));
}

export default Login;
