import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';
import type { SelectedAgenda, I신주발행_무상증자 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import agendaService from '@service/Corp/Agenda/agendaService';
import { runInAction } from 'mobx';
import { _alert } from '@model/dialogModel';
import loadingModel from '@model/loadingModel';

class BonusIssueVm {
  selectedAgenda(index: number) {
    return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I신주발행_무상증자>;
  }
  beforeIncreaseAmount(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.beforeIncreaseAmount = removeCommas(
        onlyNumber(e.target.value).toString(),
      );
      this.selectedAgenda(index).agendaDetail.info = [];
      this.selectedAgenda(index).agendaDetail.increaseAmount = '';
    });
  }
  increaseAmount(e: React.ChangeEvent<HTMLSelectElement>, index: number) {
    this.selectedAgenda(index).agendaDetail.increaseAmount = e.target.value;
  }
  freeIssueOfNewSharesType(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.freeIssueOfNewSharesType = e.target.value;
      this.selectedAgenda(index).agendaDetail.info = [];
      this.selectedAgenda(index).agendaDetail.increaseAmount = '';
    });
  }
  increaseDate(index: number) {
    const year = this.selectedAgenda(index).agendaDetail.increaseDate.year;
    const month = this.selectedAgenda(index).agendaDetail.increaseDate.month;
    const day = this.selectedAgenda(index).agendaDetail.increaseDate.day;

    let increaseDate = null;
    if (year && month && day) {
      increaseDate = year + '-' + month + '-' + day;
    }
    return increaseDate;
  }
  setIncreaseDate(date: Date, index: number) {
    let year: string;
    let month: string;
    let day: string;
    if (date) {
      year = date.getFullYear().toString();
      month = ('0' + (date.getMonth() + 1)).slice(-2);
      day = ('0' + date.getDate()).slice(-2);
    }

    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.increaseDate.year = year;
      this.selectedAgenda(index).agendaDetail.increaseDate.month = month;
      this.selectedAgenda(index).agendaDetail.increaseDate.day = day;
    });
  }
  beforeIncreaseAmountValidate(agendaIndex: number) {
    const freeIssueOfNewSharesType = this.selectedAgenda(agendaIndex).agendaDetail.freeIssueOfNewSharesType;
    const beforeIncreaseAmount = this.selectedAgenda(agendaIndex).agendaDetail.beforeIncreaseAmount;
    let func = true;
    let message = '';
    let errorSize = '0';
    if (freeIssueOfNewSharesType === '주식발행초과금') {
      const parValue = this.selectedAgenda(agendaIndex).agendaDetail.parValue;
      if (Number(removeCommas(beforeIncreaseAmount)) % Number(parValue) !== 0) {
        func = false;
        message = `액면금 단위로 입력하세요. (액면금 : ${addCommas(parValue)}원)`;
        errorSize = '15px';
      }
    } else {
      const capital = this.selectedAgenda(agendaIndex).agendaDetail.capital;
      if (Number(capital) / 2 < Number(removeCommas(beforeIncreaseAmount))) {
        func = false;
        message = `최대 ${addCommas(Number(capital) / 2)}원 까지만  무상증자를 할 수 있어요. (이익준비금으로 무상증자를 하는 경우, 등기부상 자본금의 50%까지만 무상증자 가능)`;
        errorSize = '50px';
      }
    }
    return {
      func: func,
      message: message,
      errorSize: errorSize,
    };
  }
  async getIncreaseAmountData(agendaIndex: number, agendaProgressId: string, corp: string) {
    const beforeIncreaseAmount = this.selectedAgenda(agendaIndex).agendaDetail.beforeIncreaseAmount;
    const increaseDate = this.increaseDate(agendaIndex) as string;
    if (this.beforeIncreaseAmountValidate(agendaIndex).func === true && beforeIncreaseAmount) {
      try {
        runInAction(async () => {
          loadingModel.showYn = true;
          const info = await agendaService.getIncreaseAmountData(
            agendaProgressId,
            Number(removeCommas(beforeIncreaseAmount)),
            corp,
            increaseDate,
          );
          loadingModel.showYn = false;
          this.selectedAgenda(agendaIndex).agendaDetail.info = info;
          if (!this.selectedAgenda(agendaIndex).agendaDetail.increaseAmount) {
            this.selectedAgenda(agendaIndex).agendaDetail.increaseAmount = info[0].increaseAmount;
          }
        });
      } catch (err) {
        runInAction(() => {
          loadingModel.showYn = false;
        });
      }
    }
  }
  pickInfo(agendaIndex: number) {
    const increaseAmount = this.selectedAgenda(agendaIndex).agendaDetail.increaseAmount;
    return this.selectedAgenda(agendaIndex).agendaDetail.info?.find((info) => {
      if (info.increaseAmount.toString() === increaseAmount.toString()) {
        return true;
      }
    });
  }
  async increaseDateValidate(agendaIndex: number, agendaProgressId: string, corp: string) {
    const increaseDate = this.increaseDate(agendaIndex);
    if (increaseDate) {
      const existsShareholderListYn = await agendaService.shareHolderIncreaseDateValidate(
        agendaProgressId,
        increaseDate,
        corp,
      );
      const agendaDetail = this.selectedAgenda(agendaIndex).agendaDetail;
      agendaDetail.existsShareholderListYn = existsShareholderListYn;
      if (existsShareholderListYn === false) {
        runInAction(() => {
          agendaDetail.increaseDate = {
            year: '',
            month: '',
            day: '',
          };
        });
        await _alert(
          '앗! 고객센터로 연락주세요.',
          '해당일자의 주주데이터는 스탠바이에 저장되지 않았어요. <br/>고객센터로 문의주시면 처리를 도와드릴게요.',
        );
      }
    }
  }
}
export default new BonusIssueVm();
