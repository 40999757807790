import { onlyNumber } from '@src/common/module/replaceNumber';
import { useEffect, useState } from 'react';

import { useInternetAssociateStep2Query } from '../../queries';
import type { ConsultInfoInput } from '../types';

export interface ConsultInfoInputBundle {
  consultInfoInput: ConsultInfoInput;
  setConsultInfo(key: keyof ConsultInfoInput, value: string): void;
}

const useConsultInfoInput = ({ corpId }: { corpId: number }): ConsultInfoInputBundle => {
  const { data, isSuccess } = useInternetAssociateStep2Query({ corpId });

  const [consultInfoInput, setConsultInfoInput] = useState<ConsultInfoInput>({
    name: '',
    phoneNumber: '',
    address: '',
    content: '',
    email: '',
  });

  useEffect(() => {
    if (!isSuccess) return;

    setConsultInfoInput({
      name: data.consultInfo.name || '',
      phoneNumber: data.consultInfo.phoneNumber || '',
      address: data.consultInfo.address || '',
      content: '',
      email: data.consultInfo.email || '',
    });
  }, [data, isSuccess]);

  const setConsultInfo = (key: keyof ConsultInfoInput, value: string) => {
    if (key === 'phoneNumber') {
      value = String(onlyNumber(value));
    }

    setConsultInfoInput((prev) => ({ ...prev, [key]: value }));
  };

  return {
    consultInfoInput,
    setConsultInfo,
  };
};

export default useConsultInfoInput;
