import { post, get, patch, downloadFileGet } from '@common/module/httpRequest';
import { pageRouter } from '@model/pageModel';
import { runInAction, toJS } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import stockholderModel from '@model/Corp/Stock/Stockholder/stockholderModel';
import { _alert, _confirm } from '@model/dialogModel';

class stockholderService {
  async loadStockholderInfo(shareholderId) {
    const res = await get(`/company/${corpModel.id}/stockholder/${shareholderId}`);
    const data = res.data;
    stockholderModel.setShareholder(data);
  }
  async updateStockholderInfo(data, shareholderId) {
    const res = await patch(`/company/${corpModel.id}/stockholder/${shareholderId}`, data);
    await _alert('주주정보가 수정 되었어요.');
    if (res?.data?.id) {
      pageRouter.replace(`/corp/stock/stockholder?corp=${corpModel.id}&shareholderId=${res.data.id}`);
    } else {
      pageRouter.replace(`/corp/stock/home?corp=${corpModel.id}`);
    }
  }
  async fileDownload(downladUrl) {
    await downloadFileGet(downladUrl);
  }
}

export default new stockholderService();
