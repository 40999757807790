import { useObserver } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import SendBRAndBankbook from './SendBRAndBankbook/SendBRAndBankbook';
import Bankbook from './Bankbook/Bankbook';

function BusinessRegistrationDocument() {
  return useObserver(() => (
    <Routes>
      {/* 사업자등록증 & 통장사본 보내기 */}
      <Route path="/send" element={<SendBRAndBankbook />}></Route>
      {/* 통장사본 추가/변경하기 */}
      <Route path="/bankbook" element={<Bankbook />}></Route>
    </Routes>
  ));
}
export default BusinessRegistrationDocument;
