import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import InternetAssociateService from '../service/InternetAssociateService';
import { CalcRq, CalcRs, LoadStep2Rs, UserRegistrationTerms } from '../service/InternetAssociateService.interface';

const queryKey = createQueryKeys('internetAssociate', {
  getTermInfo: null,
  getStep2: (corpId) => [corpId],
});

const useInternetAssociateTermQuery = () => {
  return useQuery<UserRegistrationTerms[]>({
    ...queryKey.getTermInfo,
    queryFn: () => InternetAssociateService.getTermInfo(),
    staleTime: 1000,
  });
};

interface Step2Params {
  corpId: number;
}

const useInternetAssociateStep2Query = ({ corpId }: Step2Params) => {
  return useQuery<LoadStep2Rs>({
    ...queryKey.getStep2(corpId),
    queryFn: () => InternetAssociateService.getStep2Data(corpId),
    staleTime: 1000,
  });
};

type OrderSummaryMutationResult = Omit<UseMutationResult<CalcRs, Error, CalcRq>, 'data'> & {
  data: CalcRs;
};

const useOrderSummaryMutation = (): OrderSummaryMutationResult => {
  const lastResult = useRef<CalcRs>({ baseFee: 0, bundleDiscount: 0, cashback: 0 });

  const mutation = useMutation<CalcRs, Error, CalcRq>({
    mutationFn: (param: CalcRq) => {
      return InternetAssociateService.postOrderSummaryCalc(param);
    },
    onSuccess(data) {
      lastResult.current = data;
    },
  });

  return { ...mutation, data: mutation.data ?? lastResult.current };
};

export { queryKey, useInternetAssociateStep2Query, useInternetAssociateTermQuery, useOrderSummaryMutation };
