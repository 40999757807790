import { useObserver } from 'mobx-react';
import st from './AgendaPromotionPopup.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import vm from './AgendaPromotionPopupVm';
import CloseBtn from '@standby/common-ui/components/atoms/Button/CloseBtn/CloseBtn';

interface AgendaPromotionPopupProps {
  agendaPopupType: 'INIT' | 'EXISTING';
  corpId: number | null;
}

function AgendaPromotionPopup(props: AgendaPromotionPopupProps) {
  return useObserver(() => (
    <div className={st.popupContent}>
      {props.agendaPopupType === 'INIT' && <img src="/images/agendaPromotion_case1.png" />}
      {props.agendaPopupType === 'EXISTING' && <img src="/images/agendaPromotion_case2.png" />}
      <div className={st.btnArea}>
        <SquareBtn arrow className={st.linkBtn} onClick={() => vm.linkBtn(props.corpId)}>
          지금 바로 신규 기능 확인하기
        </SquareBtn>
      </div>
      <CloseBtn className={st.closeBtn} onClick={() => vm.closeBtn()} />
    </div>
  ));
}
export default AgendaPromotionPopup;
