import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import vm from './ApprovaOfFinancialStatementsVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import st from './ApprovaOfFinancialStatements.module.scss';
import FileUpload from '@commonComponents/FileUpload/FileUpload';
import type { SelectedAgenda, I재무재표_승인 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import { _alert } from '@src/model/dialogModel';

interface ApprovaOfFinancialStatementsProps {
  agenda: SelectedAgenda<I재무재표_승인>;
  agendaIndex: number;
}

const ApprovaOfFinancialStatements = React.memo(function (props: ApprovaOfFinancialStatementsProps) {
  useEffect(() => {
    if (props.agenda.agendaDetail.autoAdd재무제표승인Yn) {
      _alert(
        '[재무재표 승인]을 자동으로 넣어드렸어요.',
        '감사가 중임/퇴임하는 경우여서 정기주주총회로 진행해야 하고, 재무재표 승인도 필요해요. 회사법을 잘 몰라도 괜찮아요. 상황에 따라 필요한 업무를 시스템이 도와드려요.',
      );
    }
  }, [props.agenda.agendaDetail.autoAdd재무제표승인Yn]);

  return useObserver(() => (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>회계연도를 입력하세요.</div>
        <div>
          <Select
            value={String(props.agenda.agendaDetail.selectedTerm)}
            onChange={(e) => vm.selectedTerm(e, props.agendaIndex)}
            name="agendaDetail.term"
            required
            style={{ width: '460px' }}
          >
            <option value="">-</option>
            {props.agenda.agendaDetail.fiscalYears.map((fiscalYear, index) => (
              <option key={index} value={fiscalYear.term}>
                {fiscalYear.content}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div>재무제표 업로드 (선택)</div>
          <div className={qst.titleSubInfo}>* 회의록 뒤에 재무제표를 별지로 첨부하려면 업로드하세요.</div>
          <QuestionTooltip title="별지 첨부가 필요한 경우 & 예시">
            <div className={st.tooltipContent}>
              <div className={st.exTitle}>별지 첨부가 필요한 경우</div>
              <ul className={st.exList}>
                <li>회의를 통해 승인했다는 증거를 확실히 남기고 싶은 경우 추천해요.</li>
                <li>일반적인 경우라면, 첨부하지 않아도 괜찮아요.</li>
                <li>별지 파일명이 그대로 의사록 등 서류에 기재되니, 파일명을 읽기 좋게 수정하는 편을 추천해요.</li>
              </ul>
              <div className={st.exTitle}>예시</div>
              <div className={st.sampleList}>
                <div className={st.sample}>
                  <img src="/images/proceedings_attachment_ex01.svg" />
                </div>
                <div className={st.sample}>
                  <img src="/images/proceedings_attachment_ex02.svg" />
                </div>
              </div>
            </div>
          </QuestionTooltip>
        </div>
        <FileUpload
          name={`agendaDetail.attachmentFile.uploadFiles`}
          attachmentFilesModel={props.agenda.agendaDetail.attachmentFiles}
          accept=".pdf"
        />
        <div className={st.ex}>PDF 파일만 업로드 가능</div>
      </div>
    </div>
  ));
});
export default ApprovaOfFinancialStatements;
