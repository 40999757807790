import { observable, makeObservable } from 'mobx';
import type {
  ExecutiveModalModelInterface,
  ExecutiveModalMember,
  ExecutiveModalMembers,
} from './ExecutiveModalModel.interface';

class ExecutiveModalModel implements ExecutiveModalModelInterface {
  constructor() {
    makeObservable(this, {
      validation: observable,
      executives: observable,
      modalOpened: observable,
    });
  }

  validation: {
    title: string;
    content: string;
    validated: boolean | null;
  } = {
    title: '',
    content: '',
    validated: null,
  };
  executives: (ExecutiveModalMember | ExecutiveModalMembers)[] = [];
  modalOpened: boolean = false;
  executiveParams: FormData | null = null;
}

export default new ExecutiveModalModel();
