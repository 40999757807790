import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './RouterVm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function Router() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    vm.load(token);
  }, []);

  return useObserver(() => <></>);
}

export default Router;
