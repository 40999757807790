import { get, post } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import { LoadRs } from './MemoWriteService.interface';
import MemoWriteModel from '@model/AgendaDashboard/MemoWrite/MemoWriteModel';
import { _alert } from '@src/model/dialogModel';
import AgendaDashboardService from '../AgendaDashboardService';

class MemoWriteService {
  async load(memo: string, agendaProgressId: string) {
    const res = await get<LoadRs>(
      `/company/agendaProgress/dashboard/memo/template?agendaProgressId=${agendaProgressId}`,
    );
    runInAction(() => {
      MemoWriteModel.memo = memo || '';
      MemoWriteModel.templates = res.data.templates.map((template) => {
        return {
          title: template?.title || '',
          content: template?.content || '',
          isActive: template?.isActive || false,
        };
      });
    });
  }
  async saveMemo(memo: string, agendaProgressId: string) {
    await post(`/company/agendaProgress/dashboard/memo?agendaProgressId=${agendaProgressId}`, { memo: memo });
    await _alert('메모가 입력되었습니다.');
    AgendaDashboardService.loadDetail(agendaProgressId);
  }
}
export default new MemoWriteService();
