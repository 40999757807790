import type { IA신주발행_유상증자 } from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';

class C신주발행_유상증자Vm {
  sum(info: IA신주발행_유상증자) {
    let investment = 0;
    let number = 0;

    info.newSharesAcquirers.forEach((newSharesAcquirer) => {
      newSharesAcquirer.stocks.forEach((stock) => {
        investment += stock.investment;
        number += stock.number;
      });
    });

    return { investment, number };
  }
}
export default new C신주발행_유상증자Vm();
