import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import vm from './ShareholderListVm';
import Cst from './ShareholderList.module.scss';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import clsx from 'clsx';

function ShareholderList() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>구 주주명부</div>
      <div className={clsx(st.content, Cst.content)}>
        {vm.shareholderListFile.downloadUrl ? (
          <a type="button" className={st.downloadBtn} href={vm.shareholderListFile.downloadUrl}>
            {vm.shareholderListFile.name}
          </a>
        ) : (
          '생성중'
        )}
        <CopyBtn
          copyText={`총 ${vm.shareholdersCount} 명 ${vm.shareholders.map((shareholder) => shareholder.type + shareholder.count).join(', ')}`}
          className={clsx(Cst.shareholderList, Cst.copyBtn)}
        >
          <span className={Cst.sum}>
            총 <span className={Cst.count}>{vm.shareholdersCount}</span> 명
          </span>
          <span className={Cst.person}>
            {vm.shareholders.map((shareholder) => shareholder.type + shareholder.count).join(', ')}
          </span>
        </CopyBtn>
        {vm.shareholderListDate && <span className={Cst.warn}>[주의 : {vm.shareholderListDate} 기준]</span>}
      </div>
    </div>
  ));
}
export default ShareholderList;
