import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import portalModel from '@model/portalModel';
import { observable, runInAction } from 'mobx';
import { _alert } from '@model/dialogModel';
import SendStatePopup from '@pages/Corp/Popup/SendStatePopup/SendStatePopup';
import beforeFinalModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel';
import formDataToJson from '@common/module/submit';

import type {
  LoadDocumentParam,
  I소집통지서,
} from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';

interface State {
  representativeId: string;
  fileShowYn: boolean;
  attachmentFiles: File[];
  options: I소집통지서;
}

class DocumentVm {
  state: State = observable({
    representativeId: 'ALL',
    fileShowYn: true,
    attachmentFiles: [],
    options: {
      fontSize: 'MEDIUM',
      lineSpacing: 'MEDIUM',
      의결권_대리행사_위임장Yn: true,
      주주전원_서면의결서Yn: true,
      onlineStampSealYn: true,
      representatives: [],
    },
  });
  async loadPreview(agendaProgressId: string) {
    if (this.state.representativeId === 'ALL') {
      documentModel.defaultValue?.defaultOptions?.representatives.forEach((representative) => {
        if (!this.state.options.representatives?.includes(representative.id)) {
          this.state.options.representatives?.push(representative.id);
        }
      });
    } else {
      this.state.options.representatives = [];
      documentModel.defaultValue?.defaultOptions?.representatives.forEach((representative) => {
        if (representative.id === Number(this.state.representativeId)) {
          if (!this.state.options.representatives?.includes(representative.id)) {
            this.state.options.representatives?.push(representative.id);
          }
        }
      });
    }

    const param = {
      documentType: '소집통지서',
      options: this.state.options,
    } as LoadDocumentParam;

    await documentService.loadDocument(agendaProgressId, param);
  }
  async load(agendaProgressId: string) {
    await documentService.defaultInfo(agendaProgressId, '소집통지서');
    this.loadPreview(agendaProgressId);
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param_formData = new FormData(e.target as HTMLFormElement);

      param_formData.delete('representativeId');
      this.state.options.representatives?.forEach((representative, idx) => {
        param_formData.append(`options.representatives[${idx}]`, String(representative));
      });

      param_formData.delete('attachmentFiles');
      this.state.attachmentFiles?.forEach((file, idx) => {
        param_formData.append(`options.attachmentFiles[${idx}]`, file);
      });

      runInAction(() => {
        portalModel.title = '2. 발송할 주주를 선택하세요.';
        portalModel.content = (
          <SendStatePopup
            agendaProgressId={agendaProgressId}
            documentType="소집통지서"
            statusType="소집통지_온라인"
            formData={param_formData}
          />
        );
      });
    }
  }
  get previewLink() {
    return documentModel.previewLink;
  }
  setFontSize(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.fontSize = e.target.value;
    this.loadPreview(agendaProgressId);
  }
  setLineSpacing(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.lineSpacing = e.target.value;
    this.loadPreview(agendaProgressId);
  }
  set의결권_대리행사_위임장Yn(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.의결권_대리행사_위임장Yn = e.target.value === 'true';
    this.loadPreview(agendaProgressId);
  }
  set주주전원_서면의결서Yn(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.주주전원_서면의결서Yn = e.target.value === 'true';
    this.loadPreview(agendaProgressId);
  }
  setOnlineStampSealYn(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.onlineStampSealYn = e.target.value === 'true';
    this.loadPreview(agendaProgressId);
  }
  get showRepresentativeNameSelectionYn() {
    return documentModel.defaultValue?.defaultOptions?.showRepresentativeNameSelectionYn;
  }
  get representatives() {
    return documentModel.defaultValue?.defaultOptions?.representatives;
  }
  setRepresentativeId(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.representativeId = e.target.value;
    this.loadPreview(agendaProgressId);
  }
  fileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.state.attachmentFiles.push(e.target.files[i]);
      }
    }
  }
  fileDelete(idx: number) {
    this.state.attachmentFiles.splice(idx, 1);
  }
  get isRegular() {
    const isRegular = beforeFinalModel.isRegular;
    if (isRegular) {
      return '';
    } else {
      return '임시 ';
    }
  }
}

export default DocumentVm;
