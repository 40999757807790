import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import st from './ExpiredMobile.module.scss';

function Expired() {
  const viewport = document.querySelector('meta[name=viewport]');

  useEffect(() => {
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=no');

      return () => {
        viewport.setAttribute('content', 'width=1280');
      };
    }
  }, []);

  return useObserver(() => (
    <div className={st.expired}>
      <div className={st.logo}>
        <img src="/images/logo_standby.svg?1" />
      </div>
      <div className={st.content}>
        <div className={st.title}>열람기간이 종료 되었어요.</div>
        <div className={st.explain}>
          정보가 다시 필요하다면,
          <br />
          발송인에게 다시 요청하세요
        </div>
      </div>
    </div>
  ));
}

export default Expired;
