import RepresentativeDecisionService from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/RepresentativeDecision/RepresentativeDecisionService';
import RepresentativeDecisionModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/RepresentativeDecision/RepresentativeDecisionModel';
import formDataToJson from '@common/module/submit';

class RepresentativeDecisionVm {
  load(companyId: string, agendaProgressId: string, type: '서면결의서' | '대표결정서') {
    RepresentativeDecisionService.load(companyId, agendaProgressId, type);
  }
  get executives() {
    return RepresentativeDecisionModel.executives;
  }
  selectedYn(e: React.ChangeEvent<HTMLInputElement>) {
    //참석의장을 춠석임원에 체크함
    RepresentativeDecisionModel.executives.forEach((executive) => {
      if (executive.id === e.target.value) {
        executive.selectedYn = true;
      } else {
        executive.selectedYn = false;
      }
    });
  }
  async submit(
    e: React.FormEvent<HTMLFormElement>,
    companyId: string,
    agendaProgressId: string,
    type: '서면결의서' | '대표결정서',
  ) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        id: string;
      };
      await RepresentativeDecisionService.save(param, companyId, agendaProgressId, type);
    }
  }
}
export default new RepresentativeDecisionVm();
