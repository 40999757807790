import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import st from './Loading.module.scss';

function Loading(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  useEffect(() => {
    startRotation();
    return () => {
      clearInterval(startRotation);
    };
  }, []);

  const _loading = [
    { x: '54.3984', y: '3.59375', width: '6', height: '20', rx: '3', transform: 'rotate(30 54.3984 3.59375)' },
    { x: '69.4062', y: '16.4062', width: '6', height: '20', rx: '3', transform: 'rotate(60 69.4062 16.4062)' },
    { x: '75.9922', y: '35.0078', width: '6', height: '20', rx: '3', transform: 'rotate(90 75.9922 35.0078)' },
    { x: '72.3984', y: '54.4062', width: '6', height: '20', rx: '3', transform: 'rotate(120 72.3984 54.4062)' },
    { x: '59.5859', y: '69.4062', width: '6', height: '20', rx: '3', transform: 'rotate(150 59.5859 69.4062)' },
    { x: '35', y: '56', width: '6', height: '20', rx: '3', transform: '' },
    { x: '26.3984', y: '52.0859', width: '6', height: '20', rx: '3', transform: 'rotate(30 26.3984 52.0859)' },
    { x: '20.9062', y: '44.3984', width: '6', height: '20', rx: '3', transform: 'rotate(60 20.9062 44.3984)' },
    { x: '20', y: '35', width: '6', height: '20', rx: '3', transform: 'rotate(90 20 35)' },
    { x: '23.9141', y: '26.4062', width: '6', height: '20', rx: '3', transform: 'rotate(120 23.9141 26.4062)' },
    { x: '31.6016', y: '20.9219', width: '6', height: '20', rx: '3', transform: 'rotate(150 31.6016 20.9219)' },
    { x: '35', y: '0', width: '6', height: '20', rx: '3', transform: '' },
  ];

  const [opacityList, setOpacityList] = useState([0, 0.07, 0.15, 0.3, 0.45, 0.6, 0.7, 0.8, 0.85, 0.9, 0.95, 1]);

  function rotateArray(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return arr;
    }

    const lastValue = arr.pop();
    arr.unshift(lastValue);

    return arr;
  }

  function startRotation() {
    setInterval(() => {
      setOpacityList((prevOpacityList) => {
        const rotatedList = rotateArray([...prevOpacityList]);
        return rotatedList;
      });
    }, 60);
  }

  return useObserver(() => (
    <div className={st.loadingWrap}>
      <div className={st.loading}>
        <div className={st.loadingContent}>
          <div className={st.loadingImg}>
            <svg
              width="76"
              height="76"
              viewBox="0 0 76 76"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={st.test}
            >
              {_loading.map((item, idx) => {
                return (
                  <rect
                    x={item.x}
                    y={item.y}
                    width={item.width}
                    height={item.height}
                    rx={item.rx}
                    transform={item.transform}
                    fill="#2F2CC1"
                    key={idx}
                    className={st.fillAnimation}
                    style={{ opacity: opacityList[idx] }}
                  />
                );
              })}
            </svg>
          </div>
          <div className={st.loadingText}>{props.loadingText}</div>
        </div>
      </div>
    </div>
  ));
}
export default Loading;
