import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import HeaderSidebar from '@commonComponents/HeaderSidebar/HeaderSidebar';
import vm from './InformationVm';
import st from './Information.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface InformationProps {
  token: string;
  changePage: () => void;
}

function Information(props: InformationProps) {
  useEffect(() => {
    const documentFrame = document.getElementById('documentFrame') as HTMLIFrameElement;
    if (documentFrame && documentFrame.contentWindow) {
      documentFrame.contentWindow.location.replace(vm.documentUrl);
    }
  }, []);

  return useObserver(() => (
    <>
      <HeaderSidebar companyName={vm.companyName} />
      <div className={st.content}>
        <div className={st.title}>{vm.shareholderName} 주주님! 동의를 요청드립니다.</div>
        <div className={st.documentBox}>
          <div className={st.iframe}>
            <iframe id="documentFrame" title="주주총회 및 신주발행 동의서 미리보기" className={st.document} />
          </div>
        </div>
        <div className={st.btnArea}>
          <SquareBtn className={st.agreeBtn} onClick={() => vm.agreeBtn(props.token, props.changePage)}>
            동의하기
          </SquareBtn>
        </div>
      </div>
    </>
  ));
}
export default Information;
