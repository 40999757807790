import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import portalModel from '@model/portalModel';
import { observable, runInAction } from 'mobx';
import { _alert } from '@model/dialogModel';
import SendStatePopup from '@pages/Corp/Popup/SendStatePopup/SendStatePopup';
import formDataToJson from '@common/module/submit';
import AddDate from '../AddDate/AddDate';
import loadingModel from '@model/loadingModel';

import type {
  LoadDocumentParam,
  I신주발행통지,
} from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';

interface State {
  representativeId: string;
  options: I신주발행통지;
}

interface NewSharesAcquirer {
  id: number;
  name: string;
  subscriptionAt: string;
  paymentAt: string;
  stocks: {
    stockName: string;
    stockAmount: number;
    paymentAmount: number;
    issuePrice: number;
  }[];
}

class DocumentVm {
  state: State = observable({
    representativeId: 'ALL',
    options: {
      fontSize: 'MEDIUM',
      lineSpacing: 'MEDIUM',
      attachmentFiles: [],
      onlineStampSealYn: true,
      representatives: [],
      subscriptionAt: '',
      paymentAt: '',
      newSharesAcquirers: [],
      dividedSubscriptionYn: false,
    },
  });
  async loadPreview(agendaProgressId: string) {
    if (this.state.representativeId === 'ALL') {
      documentModel.defaultValue?.defaultOptions?.representatives.forEach((representative) => {
        if (!this.state.options.representatives?.includes(representative.id)) {
          this.state.options.representatives?.push(representative.id);
        }
      });
    } else {
      this.state.options.representatives = [];
      documentModel.defaultValue?.defaultOptions?.representatives.forEach((representative) => {
        if (representative.id === Number(this.state.representativeId)) {
          if (!this.state.options.representatives?.includes(representative.id)) {
            this.state.options.representatives?.push(representative.id);
          }
        }
      });
    }

    const param = {
      documentType: '신주발행통지',
      options: this.state.options,
    } as LoadDocumentParam;
    await documentService.loadDocument(agendaProgressId, param);
  }
  async load(agendaProgressId: string, dividedSubscriptionYn?: boolean, newSharesAcquirers?: NewSharesAcquirer[]) {
    await documentService.defaultInfo(agendaProgressId, '신주발행통지');
    if (documentModel.defaultValue.defaultOptions) {
      const defaultOptions = documentModel.defaultValue.defaultOptions;
      if (defaultOptions?.subscriptionAt) {
        this.state.options.subscriptionAt = defaultOptions.subscriptionAt;
      }
      if (defaultOptions?.paymentAt) {
        this.state.options.paymentAt = defaultOptions.paymentAt;
      }
      if (newSharesAcquirers && newSharesAcquirers.length !== 0) {
        this.state.options.newSharesAcquirers = newSharesAcquirers;
      } else {
        if (defaultOptions?.newSharesAcquirers) {
          this.state.options.newSharesAcquirers = defaultOptions.newSharesAcquirers;
        }
      }
      if (dividedSubscriptionYn !== undefined) {
        this.state.options.dividedSubscriptionYn = dividedSubscriptionYn;
      } else {
        this.state.options.dividedSubscriptionYn = defaultOptions.dividedSubscriptionYn;
      }
    }

    this.loadPreview(agendaProgressId);
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param_formData = new FormData(e.target as HTMLFormElement);

      param_formData.delete('representativeId');
      this.state.options.representatives?.forEach((representative, idx) => {
        param_formData.append(`options.representatives[${idx}]`, String(representative));
      });

      //나누어 청약여부
      param_formData.append('options.dividedSubscriptionYn', String(this.state.options.dividedSubscriptionYn));

      if (this.state.options.dividedSubscriptionYn) {
        this.state.options.newSharesAcquirers?.forEach((newSharesAcquirer, idx) => {
          param_formData.append(`options.newSharesAcquirers[${idx}].id`, String(newSharesAcquirer.id));
          param_formData.append(`options.newSharesAcquirers[${idx}].subscriptionAt`, newSharesAcquirer.subscriptionAt);
          param_formData.append(`options.newSharesAcquirers[${idx}].paymentAt`, newSharesAcquirer.paymentAt);
        });
      }

      const submitter = (e.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      if (submitter?.name === 'next') {
        runInAction(() => {
          portalModel.title = '2. 발송할 주주를 선택하세요.';
          portalModel.content = (
            <SendStatePopup
              agendaProgressId={agendaProgressId}
              documentType="신주발행통지"
              statusType="신주발행통지"
              formData={param_formData}
            />
          );
        });
      } else if (submitter?.name === 'download') {
        runInAction(async () => {
          try {
            loadingModel.showYn = true;
            await documentService.documentDownload(agendaProgressId, param_formData);
            loadingModel.showYn = false;
          } catch (err) {
            loadingModel.showYn = false;
          }
        });
      }
    }
  }
  get previewLink() {
    return documentModel.previewLink;
  }
  setFontSize(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.fontSize = e.target.value;
    this.loadPreview(agendaProgressId);
  }
  setLineSpacing(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.lineSpacing = e.target.value;
    this.loadPreview(agendaProgressId);
  }
  setOnlineStampSealYn(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.onlineStampSealYn = e.target.value === 'true';
    this.loadPreview(agendaProgressId);
  }
  get showRepresentativeNameSelectionYn() {
    return documentModel.defaultValue?.defaultOptions?.showRepresentativeNameSelectionYn;
  }
  get representatives() {
    return documentModel.defaultValue?.defaultOptions?.representatives;
  }
  setRepresentativeId(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.representativeId = e.target.value;
    this.loadPreview(agendaProgressId);
  }
  setSubscriptionAt(e: Date, agendaProgressId: string) {
    let date = '';
    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;

      const defaultSubscriptionAt = new Date(documentModel.defaultValue.defaultOptions?.subscriptionAt || '');
      const subscriptionAt = new Date(date);
      const paymentAt = new Date(this.state.options.paymentAt);
      if (defaultSubscriptionAt.getTime() > subscriptionAt.getTime()) {
        return _alert('통지일로부터 청약일은 14일 간격이 있어야해요. (통지당일은 포함X)');
      }
      if (paymentAt.getTime() < subscriptionAt.getTime()) {
        this.state.options.paymentAt = date;
      }
    }
    this.state.options.subscriptionAt = date;
    this.loadPreview(agendaProgressId);
  }
  setPaymentAt(e: Date, agendaProgressId: string) {
    let date = '';
    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;

      const subscriptionAt = new Date(this.state.options.subscriptionAt);
      const paymentAt = new Date(date);
      if (subscriptionAt.getTime() > paymentAt.getTime()) {
        return _alert('주금납입일은 청약일과 같거나 이후여야 해요.');
      }
    }
    this.state.options.paymentAt = date;
    this.loadPreview(agendaProgressId);
  }
  separateDate(agendaProgressId: string) {
    runInAction(() => {
      portalModel.title = '일자 추가하기';
      portalModel.content = (
        <AddDate
          agendaProgressId={agendaProgressId}
          newSharesAcquirers={this.state.options.newSharesAcquirers}
          defaultSubscriptionAt={documentModel.defaultValue.defaultOptions?.subscriptionAt as string}
        />
      );
    });
  }
}

export default DocumentVm;
