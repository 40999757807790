import './App.scss';
import Auth from '@pages/Auth/Auth';
import Main from '@pages/Main/Main';
import Corp from '@pages/Corp/Corp';
import UserDashboard from '@pages/UserDashboard/UserDashboard';
import CompanyRegistration from '@pages/CompanyRegistration/CompanyRegistration';
import DocumentCertification from '@pages/DocumentCertification/DocumentCertification';
import Document from '@pages/Document/Document';
import AuthBusinessRegistration from '@pages/AuthBusinessRegistration/AuthBusinessRegistration';
import Guest from '@pages/Guest/Guest';
import Terms from '@pages/Terms/Terms';
import Cookies from 'js-cookie';
import { Route, BrowserRouter, Routes, Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import AppVm from './AppVm';
import { useObserver } from 'mobx-react';
import Popup from '@standby/common-ui/components/molecules/Popup/Popup';
import Dialog from '@standby/common-ui/components/molecules/Dialog/Dialog';
import { reaction } from 'mobx';
import ChannelService from '@common/module/ChannelService';
import ChannelTalkOnly from '@pages/ChannelService/ChannelTalkOnly';
import { pageRouter } from '@model/pageModel';
import queryString from 'query-string';
import ReactGA from 'react-ga4';
import AgendaDashboard from '@pages/AgendaDashboard/AgendaDashboard';
import Loading from '@commonComponents/Loading/Loading';

function App() {
  let location = useLocation();
  const openChannel = queryString.parse(location.search).openChannel;
  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === 'userId') {
        if (localStorage.getItem('userId') !== AppVm.user.id) {
          AppVm.socketDisConnect();
          window.location.href = `/auth/login`;
        }
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: '0' });
    async function fetchData() {
      if (
        location.pathname.indexOf('auth') === -1 &&
        location.pathname.indexOf('authBusinessRegistration') === -1 &&
        location.pathname.indexOf('guest') === -1
      ) {
        if (AppVm.isLogined && !AppVm.user.id) {
          await AppVm.loginedStart();
        }
        if (AppVm.user.id) {
          AppVm.launchChannelService(AppVm.user.channelMemberId, location.pathname, openChannel);
        } else {
          ChannelService.shutdown();
        }
      }
    }
    fetchData();
    //마케팅 구글에널리틱스 관련 코드
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGA.set({ page: location.pathname });
    ReactGA.send('pageview');
  }, [location.pathname]);
  return useObserver(() => (
    <div id="app">
      {AppVm.popupContent && (
        <Popup
          title={AppVm.popupTitle || ''}
          content={AppVm.popupContent}
          closeBtn={AppVm.popupCloseBtn}
          radius={AppVm.popupRadius}
          onClose={() => AppVm.popupClose()}
          portalModel={AppVm.portalModel}
          closeBtnDark={AppVm.popupCloseBtnDarkYn}
        />
      )}
      {AppVm.dialogType && (
        <Dialog
          type={AppVm.dialogType}
          title={AppVm.dialogTitle}
          content={AppVm.dialogContent}
          btn={AppVm.dialogBtn}
          alertBtnText={AppVm.dialogAlertBtnText}
          confirmBtnText={AppVm.dialogConfirmBtnText}
          cancelBtnText={AppVm.dialogCancelBtnText}
          contentJSX={AppVm.dialogContentJSX}
          icon={AppVm.dialogIcon}
        />
      )}
      <Routes>
        {/* 로그인없이 접근가능한 url */}
        {/*메인페이지*/}
        <Route path="/" element={AppVm.isLogined ? <UserDashboard /> : <Navigate to="/auth/login" />} exact />
        {/*회원 인증관련 페이지 (로그인,회원가입,비번찾기등)*/}
        <Route path="/auth/*" element={<Auth />} />
        {/* 서류 확인 인증 페이지 */}
        <Route path="/documentCertification" element={<DocumentCertification />} />
        {/* 서류 확인 페이지 */}
        <Route path="/document" element={<Document />} />
        {/* 사업자 등록 회원가입/로그인 페이지 */}
        <Route path="/authBusinessRegistration/*" element={<AuthBusinessRegistration />} />
        {/* 게스트 페이지 */}
        <Route path="/guest/*" element={<Guest />} />
        {/* 약관 페이지 */}
        <Route path="/terms" element={<Terms />} />
        {/* 안건대시보드 */}
        <Route path="/agendaDashboard" element={<AgendaDashboard />} />

        {/* 로그인후 접근가능한 url */}
        {/*법인페이지*/}
        <Route path="/corp/*" element={<Corp />} />
        {/*계정대시보드*/}
        <Route path="/userDashboard" element={<UserDashboard />} />
        {/* 법인등록 */}
        <Route path="/companyRegistration" element={<CompanyRegistration />} />
        {/* 모바일채널톡 진평 */}
        <Route path="/accounting/channelTalkOnly" element={<ChannelTalkOnly isLogined={AppVm.isLogined} />} />
        {/* 모바일채널톡 */}
        <Route path="/channelTalkOnly" element={<ChannelTalkOnly isLogined={AppVm.isLogined} />} />
      </Routes>
      {AppVm.loadingShowYn && <Loading />}
    </div>
  ));
}

export default App;
