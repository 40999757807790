import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {
  Agenda,
  IA임원_보수_결정,
} from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';
import Cst from './C임원_보수_결정.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import { addCommas } from '@common/module/replaceNumber';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA임원_보수_결정>;
}

function C임원_보수_결정(props: Props) {
  return useObserver(() => (
    <div className={st.agenda}>
      <div className={st.title}>
        <div className={st.iconTitle}>안건</div>
        <div className={st.number}>{props.agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>임원 보수 결정</span>
        </div>
        {props.agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      <div className={st.info}>
        <ul className={st.agendaList}>
          <li>
            승인기간 :{' '}
            {props.agenda.info.decisionStartAt.year +
              '-' +
              props.agenda.info.decisionStartAt.month +
              '-' +
              props.agenda.info.decisionStartAt.day +
              ' - ' +
              props.agenda.info.decisionEndAt.year +
              '-' +
              props.agenda.info.decisionEndAt.month +
              '-' +
              props.agenda.info.decisionEndAt.day}
          </li>
          <li>임원별 보수 한도</li>
        </ul>
        <Table className={Cst.table}>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>임원</th>
              <th>퇴직금 포함 총 보수 (1년)</th>
            </tr>
          </thead>
          <tbody>
            {props.agenda.info.salaryOfExecutives.map((salaryOfExecutive, index) => (
              <tr key={index}>
                <td>
                  {salaryOfExecutive.name} {salaryOfExecutive.position}님
                  {salaryOfExecutive.newExecutiveYn === true && <span className={Cst.new}>신규</span>}
                </td>
                <td>{addCommas(salaryOfExecutive.salary)} 원</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  ));
}
export default C임원_보수_결정;
