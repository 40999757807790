import guestModel from '@model/Guest/guestModel';
import guestService from '@service/Guest/guestService';
import type { A온라인_소집통지_수신동의 } from '@model/Guest/guestModel.interface';
import type { AcceptOnlineMeetingNoticeAgreementRs } from '@service/Guest/guestService.interface';

class InformationVm {
  get authInfo() {
    const authInfo = guestModel.authInfo as A온라인_소집통지_수신동의;
    return authInfo;
  }
  get companyName() {
    return this.authInfo.companyName;
  }
  get shareholderName() {
    return this.authInfo.shareholderName;
  }
  get documentUrl() {
    return this.authInfo.documentUrl;
  }
  agreeBtn(token: string, changePage: () => void) {
    try {
      guestService.accept<AcceptOnlineMeetingNoticeAgreementRs>(token);
      changePage();
    } catch (err) {}
  }
}

export default new InformationVm();
