import userDashboardService from '@service/userDashboardService';
import userDashboardModel from '@model/userDashboardModel';

class BusinessRegistrationModalVm {
  get name() {
    return userDashboardModel.name;
  }
  get acceptWaitingCompanies() {
    return userDashboardModel.acceptWaitingCompanies;
  }
  async businessRegistration() {
    userDashboardService.businessRegistration();
  }
}
export default new BusinessRegistrationModalVm();
