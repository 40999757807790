import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './PopupTable.module.scss';
import clsx from 'clsx';

function PopupTable(props) {
  return useObserver(() => (
    <table className={clsx(props.className, st.table)} style={props.style}>
      {props.children}
    </table>
  ));
}
export default PopupTable;
