import { observable } from 'mobx';

const companyRegistrationModel = observable({
  //회사 이름 검색 관련 데이터
  광역자치단체s: [],
  irosStatusType: '', //인터넷 등기소 상태
  searchResults: [], //회사 이름 검색했을 때, 받아온 회사 리스트,
  isRegistered: null,
  set광역자치단체s(data) {
    if (data.광역자치단체s && data.광역자치단체s.length !== 0) {
      this.광역자치단체s = data.광역자치단체s.map((item, idx) => {
        return {
          name: item.name || '',
          시군구s: item.시군구s || [],
        };
      });
    } else {
      this.광역자치단체s = [];
    }
  },
  setSearchResults(data) {
    this.irosStatusType = data?.irosStatusType || '';
    if (data.searchResults && data.searchResults.length !== 0) {
      this.searchResults = data.searchResults.map((item, idx) => {
        return {
          companyType: item.companyType || '',
          companyName: item.companyName || '',
          address: item.address || '',
          office: item.office || '',
          registerNum: item.registerNum || '',
        };
      });
    } else {
      this.searchResults = [];
    }
  },
  setIsRegistered(data) {
    this.isRegistered = data ?? null;
  },
});

export default companyRegistrationModel;
