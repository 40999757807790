import beforeFinalService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService';
import beforeFinalModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel';
import portalModel from '@model/portalModel';
import { _alert } from '@model/dialogModel';
import { runInAction } from 'mobx';
import formDataToJson from '@common/module/submit';
import ValidationModal from '../ValidationModal/ValidationModal';
import dateListModel from '@model/Corp/Agenda/Step/dateListModel';

import type { DateInfo } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService.interface';

class ChangeMeetingScheduleVm {
  load(agendaProgressId: string) {
    beforeFinalService.loadSchedules(agendaProgressId);
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string) {
    e.preventDefault();
    if (dateListModel.meetingScheduleChangeLockYn) {
      await _alert(
        '날짜, 장소 변경이 필요하다면, 채팅문의를 남겨주세요.',
        '등기대행 진행 중이어서, 곧바로 변경이 제한되어요.',
      );
      return;
    }
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        decisionDate?: string;
        decisionTime?: string;
        schedules: DateInfo[];
      };
      delete param.decisionDate;
      delete param.decisionTime;

      await beforeFinalService.validationSchedules(agendaProgressId, param);

      const validated = beforeFinalModel.changedSchedule.validated;

      if (validated) {
        await beforeFinalService.submitSchedules(agendaProgressId, param);
        await _alert('저장되었어요.', undefined, undefined, undefined, 'loud');
        runInAction(() => {
          portalModel.title = '';
          portalModel.content = null;
        });
        window.location.reload();
      } else {
        runInAction(() => {
          portalModel.title = '';
          portalModel.content = <ValidationModal agendaProgressId={agendaProgressId} param={param} />;
          portalModel.closeBtn = false;
          portalModel.radius = true;
        });
      }
    }
  }
}

export default new ChangeMeetingScheduleVm();
