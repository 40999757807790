import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import { runInAction } from 'mobx';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import type { SelectedAgenda, I임원_보수_결정 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class ExecutiveRemunerationDecisionVm {
  selectedAgenda(index: number) {
    return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I임원_보수_결정>;
  }
  salary(e: React.ChangeEvent<HTMLInputElement>, index: number, index2: number) {
    this.selectedAgenda(index).agendaDetail.salaryOfExecutives[index2].salary = removeCommas(
      onlyNumber(e.target.value).toString(),
    );
  }
  get displayQuestionTooltip() {
    if (agendaStepModel.selectedAgendas.some((data) => data.title === '임원 보수 한도 승인')) {
      return false;
    } else {
      return true;
    }
  }
  decisionStart(index: number) {
    const year = this.selectedAgenda(index).agendaDetail.decisionStartAt.year;
    const month = this.selectedAgenda(index).agendaDetail.decisionStartAt.month;
    const day = this.selectedAgenda(index).agendaDetail.decisionStartAt.day;

    let decisionStart = null;
    if (year && month && day) {
      decisionStart = year + '-' + month + '-' + day;
    }

    return decisionStart;
  }
  setDecisionStart(date: Date, index: number) {
    let year: string;
    let month: string;
    let day: string;
    if (date) {
      year = date.getFullYear().toString();
      month = ('0' + (date.getMonth() + 1)).slice(-2);
      day = ('0' + date.getDate()).slice(-2);
    }

    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.decisionStartAt.year = year;
      this.selectedAgenda(index).agendaDetail.decisionStartAt.month = month;
      this.selectedAgenda(index).agendaDetail.decisionStartAt.day = day;
    });
  }
  decisionEnd(index: number) {
    const year = this.selectedAgenda(index).agendaDetail.decisionEndAt.year;
    const month = this.selectedAgenda(index).agendaDetail.decisionEndAt.month;
    const day = this.selectedAgenda(index).agendaDetail.decisionEndAt.day;

    let decisionEnd = null;
    if (year && month && day) {
      decisionEnd = year + '-' + month + '-' + day;
    }

    return decisionEnd;
  }
  setDecisionEnd(date: Date, index: number) {
    let year: string;
    let month: string;
    let day: string;
    if (date) {
      year = date.getFullYear().toString();
      month = ('0' + (date.getMonth() + 1)).slice(-2);
      day = ('0' + date.getDate()).slice(-2);
    }

    runInAction(() => {
      this.selectedAgenda(index).agendaDetail.decisionEndAt.year = year;
      this.selectedAgenda(index).agendaDetail.decisionEndAt.month = month;
      this.selectedAgenda(index).agendaDetail.decisionEndAt.day = day;
    });
  }
  download() {
    window.location.href = `${process.env.REACT_APP_S3URL}/agenda/%5B%E1%84%89%E1%85%B3%E1%84%90%E1%85%A2%E1%86%AB%E1%84%87%E1%85%A1%E1%84%8B%E1%85%B5%5D+%E1%84%87%E1%85%A9%E1%84%89%E1%85%AE%2C+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A7%E1%84%80%E1%85%B3%E1%86%B7%2C+%E1%84%90%E1%85%AC%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%80%E1%85%B3%E1%86%B7+%E1%84%80%E1%85%B2%E1%84%8C%E1%85%A5%E1%86%BC+3%E1%84%8C%E1%85%A9%E1%86%BC+%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF.zip`;
  }
}
export default new ExecutiveRemunerationDecisionVm();
