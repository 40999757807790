import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './AdminSettingVm';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import st from './AdminSetting.module.scss';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import clsx from 'clsx';
import ModalEdit from './ModalEdit/ModalEdit';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';

function AdminSetting() {
  useEffect(() => {
    vm.load();
  }, []);
  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>담당자 추가/변경</span>
      </PageTitle>
      <ContentBox
        title="담당자 목록"
        className={st.contentBox}
        titleStyle={{ top: '15px', paddingLeft: '20px', fontSize: '18px' }}
      >
        <Link to={`/corp/adminAdd?corp=${vm.id}`} className={st.inviteLink}>
          <span>담당자 추가/초대하기</span>
        </Link>
        <div className={st.content}>
          <table className={st.adminList}>
            <colgroup>
              <col width="130" />
              <col width="180" />
              <col />
              <col width="240" />
              <col width="60" />
            </colgroup>
            <thead>
              <tr>
                <th>이름</th>
                <th>역할</th>
                <th>메일주소</th>
                <th colSpan={2}>자격</th>
              </tr>
            </thead>
            <tbody>
              {vm.managers.map((manager, index) => (
                <tr key={index}>
                  <td className={clsx(manager.inviting ? st.weak : null)}>{manager.name}</td>
                  <td className={clsx(manager.inviting ? st.weak : null)}>
                    <span className={clsx(st.position, manager.role ? null : st.weak)}>
                      {manager.role ? manager.role : '역할 입력'}
                      <ModalEdit
                        value={manager.role}
                        userId={manager.userId}
                        disabled={manager.inviting ? true : false}
                      />
                    </span>
                  </td>
                  <td className={clsx(manager.inviting ? st.weak : null)}>{manager.email}</td>
                  <td>
                    {manager.inviting ? (
                      <span style={{ fontSize: '14px' }}>
                        초대 수락 대기중 ({manager.authorityType === 'GENERAL_MANAGER' ? '최고관리자' : '담당자'})
                      </span>
                    ) : (
                      <Select
                        value={manager.authorityType}
                        className={st.authorityType}
                        name="authorityType"
                        onChange={(e) => vm.changeAuthorityType(e, index, manager.userId)}
                      >
                        <option value="GENERAL_MANAGER">최고 관리자</option>
                        <option value="MANAGER">담당자</option>
                      </Select>
                    )}
                  </td>
                  <td>
                    {manager.inviting ? (
                      <button
                        type="button"
                        className={st.authorityDelete}
                        onClick={() => vm.invitationDelete(manager.userId)}
                      >
                        삭제
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={st.authorityDelete}
                        onClick={() => vm.authorityDelete(manager.userId)}
                      >
                        삭제
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ContentBox>

      <ContentBox
        title="권한 관리"
        className={st.contentBox}
        titleStyle={{ top: '15px', paddingLeft: '20px', fontSize: '18px' }}
        style={{ marginTop: '24px' }}
      >
        <div className={st.content}>
          <ul className={st.authoritiesMemberList}>
            <li>
              <div className={st.title}>최고관리자</div>
              <ul className={clsx(st.listContent, st.disabled)}>
                <li>
                  <div className={st.selectAll}>
                    <label className={clsx(st.labelTitle, st.active)}>법무 기능</label>
                    <input type="checkbox" disabled checked={true} className={st.radioCheckInput} />
                    <label className={st.radioCheckLabel}></label>
                  </div>
                  <div className={st.checkList}>
                    {vm.managers.length !== 0 &&
                      vm.managers[0].authorities.law.map((authority, index2) => (
                        <CheckBox disabled className={st.checkbox} key={index2} checked={true}>
                          {authority.menuName}
                        </CheckBox>
                      ))}
                  </div>
                </li>
                <li>
                  <div className={st.selectAll}>
                    <label className={clsx(st.labelTitle, st.active)}>회계 기능</label>
                    <input type="checkbox" disabled checked={true} className={st.radioCheckInput} />
                    <label className={st.radioCheckLabel}></label>
                  </div>
                  <div className={st.checkList}>
                    {vm.managers.length !== 0 &&
                      vm.managers[0].authorities.acct.map((authority, index2) => (
                        <CheckBox disabled className={st.checkbox} key={index2} checked={true}>
                          {authority.menuName}
                        </CheckBox>
                      ))}
                  </div>
                </li>
                <li>
                  <div className={st.selectAll}>
                    <label className={clsx(st.labelTitle, st.active)}>제휴 기능</label>
                    <input type="checkbox" disabled checked={true} className={st.radioCheckInput} />
                    <label className={st.radioCheckLabel}></label>
                  </div>
                  <div className={st.checkList}>
                    {vm.managers.length !== 0 &&
                      vm.managers[0].authorities.partner.map((authority, index2) => (
                        <CheckBox disabled className={st.checkbox} key={index2} checked={true}>
                          {authority.menuName}
                        </CheckBox>
                      ))}
                  </div>
                </li>
              </ul>
            </li>
            {vm.managers
              .filter((manager) => manager.authorityType === 'MANAGER')
              .map((manager, index) => (
                <li key={index}>
                  <div className={st.title}>{manager.name}</div>
                  <ul className={st.listContent}>
                    <li>
                      <div className={st.selectAll}>
                        <label
                          className={clsx(
                            st.labelTitle,
                            vm.authorityCheckedAll(manager.userId, 'law') ? st.active : null,
                          )}
                          htmlFor={`law[${manager.userId}]`}
                        >
                          법무 기능
                        </label>
                        <input
                          type="checkbox"
                          checked={vm.authorityCheckedAll(manager.userId, 'law')}
                          onChange={(e) => vm.authorityCheckedAllChange(e, manager.userId, 'law')}
                          id={`law[${manager.userId}]`}
                          className={st.radioCheckInput}
                        />
                        <label htmlFor={`law[${manager.userId}]`} className={st.radioCheckLabel}></label>
                      </div>
                      <div className={st.checkList}>
                        {manager.authorities.law.map((authority, index2) => (
                          <CheckBox
                            className={st.checkbox}
                            key={index2}
                            checked={authority.useYn}
                            onChange={(e) => vm.changeUseYn(e, manager.userId, 'law', index2)}
                          >
                            {authority.menuName}
                          </CheckBox>
                        ))}
                      </div>
                    </li>
                    <li>
                      <div className={st.selectAll}>
                        <label
                          className={clsx(
                            st.labelTitle,
                            vm.authorityCheckedAll(manager.userId, 'acct') ? st.active : null,
                          )}
                          htmlFor={`acct[${manager.userId}]`}
                        >
                          회계 기능
                        </label>
                        <input
                          type="checkbox"
                          checked={vm.authorityCheckedAll(manager.userId, 'acct')}
                          onChange={(e) => vm.authorityCheckedAllChange(e, manager.userId, 'acct')}
                          id={`acct[${manager.userId}]`}
                          className={st.radioCheckInput}
                        />
                        <label htmlFor={`acct[${manager.userId}]`} className={st.radioCheckLabel}></label>
                      </div>
                      <div className={st.checkList}>
                        {manager.authorities.acct.map((authority, index2) => (
                          <CheckBox
                            className={st.checkbox}
                            key={index2}
                            checked={authority.useYn}
                            onChange={(e) => vm.changeUseYn(e, manager.userId, 'acct', index2)}
                          >
                            {authority.menuName}
                          </CheckBox>
                        ))}
                      </div>
                    </li>
                    <li>
                      <div className={st.selectAll}>
                        <label
                          className={clsx(
                            st.labelTitle,
                            vm.authorityCheckedAll(manager.userId, 'partner') ? st.active : null,
                          )}
                          htmlFor={`partner[${manager.userId}]`}
                        >
                          제휴 기능
                        </label>
                        <input
                          type="checkbox"
                          checked={vm.authorityCheckedAll(manager.userId, 'partner')}
                          onChange={(e) => vm.authorityCheckedAllChange(e, manager.userId, 'partner')}
                          id={`partner[${manager.userId}]`}
                          className={st.radioCheckInput}
                        />
                        <label htmlFor={`partner[${manager.userId}]`} className={st.radioCheckLabel}></label>
                      </div>
                      <div className={st.checkList}>
                        {manager.authorities.partner.map((authority, index2) => (
                          <CheckBox
                            className={st.checkbox}
                            key={index2}
                            checked={authority.useYn}
                            onChange={(e) => vm.changeUseYn(e, manager.userId, 'partner', index2)}
                          >
                            {authority.menuName}
                          </CheckBox>
                        ))}
                      </div>
                    </li>
                  </ul>
                </li>
              ))}
          </ul>
          <div className={st.btnArea}>
            <button type="button" className={st.submitAll} onClick={() => vm.save()}>
              수정사항 저장하기
            </button>
          </div>
        </div>
      </ContentBox>
    </>
  ));
}
export default AdminSetting;
