import { useObserver } from 'mobx-react';
import React from 'react';
import st from '../../Meetings/Meetings.module.scss';
import { I신주공고통지 } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import File from '../File/File';
import clsx from 'clsx';

interface C신주공고통지Props {
  meetingSchedule: I신주공고통지;
  meetingScheduleIndex: number;
  opener: 'final' | 'agendaDashboard';
}

const C신주공고통지 = React.memo(function (props: C신주공고통지Props) {
  return useObserver(() => (
    <div className={st.meeting}>
      <div className={st.sideTitle}>
        {props.meetingScheduleIndex + 1}. {props.meetingSchedule.title}
      </div>
      <div className={st.meetingInfo}>
        <div className={st.detailTitle}>{props.meetingSchedule.detailTitle}</div>
        <ul className={st.detailList}>
          <li>
            <div className={clsx(st.title, st.notice)}>공고문 양식</div>
            <div className={st.content}>
              <File
                opener={props.opener}
                file={props.meetingSchedule.files.공고문양식}
                id={props.meetingSchedule.id}
                type="공고문양식"
              />
            </div>
          </li>
          <li>
            <div className={clsx(st.title, st.notice)}>공고스캔</div>
            <div className={st.content}>
              <File
                opener={props.opener}
                file={props.meetingSchedule.files.공고스캔}
                id={props.meetingSchedule.id}
                type="공고스캔"
              />
            </div>
          </li>
          <li>
            <div className={clsx(st.title, st.date)}>일시</div>
            <div className={st.content}>{props.meetingSchedule.decisionAt}</div>
          </li>
        </ul>
      </div>
    </div>
  ));
});

export default C신주공고통지;
