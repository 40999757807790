import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import OnlineMusterPopupVm from './OnlineMusterPopupVm';
import st from './OnlineMusterPopup.module.scss';

function OnlineMusterPopup(props) {
  return useObserver(() => (
    <div className={st.onlineMusterPopup}>
      <img src="/images/online_muster_info.svg" />
      <div className={st.onlineMuster}>
        <div className={st.direct}>
          <div className={st.title}>직접 정보 입력하기</div>
          <div className={st.explain}>이미 동의를 받으신 경우 정보 입력</div>
          <Link
            to={`/corp/stock/onlineMuster/direct?corp=${OnlineMusterPopupVm.id}`}
            onClick={() => OnlineMusterPopupVm.closePopup()}
            className={st.btn}
          >
            사전동의 직접 입력하기
          </Link>
        </div>
        <div className={st.beforehand}>
          <div className={st.title}>스탠바이를 통해 소집통지 온라인 수신동의 받기</div>
          <div className={st.explain}>
            아직 동의 안 받은 경우, 동의를 예전에 받았지만
            <br />
            증빙을 만들고 싶은 경우
          </div>
          <Link
            to={`/corp/stock/onlineMuster/beforehand?corp=${OnlineMusterPopupVm.id}`}
            onClick={() => OnlineMusterPopupVm.closePopup()}
            className={st.btn}
          >
            소집통지 온라인 수신동의 받기
          </Link>
        </div>
      </div>
    </div>
  ));
}
export default OnlineMusterPopup;
