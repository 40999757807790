import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import formDataToJson from '@common/module/submit';
import { observable, runInAction } from 'mobx';
import regExp from '@common/module/regExp';
import { _alert } from '@model/dialogModel';

import type { SendDocumentType } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';
import portalModel from '@src/model/portalModel';

enum 수신동의 {
  발송전 = '발송전',
  발송중 = '발송중',
  발송완료 = '발송완료\n(대기중)',
  동의완료 = '동의완료',
  발송실패 = '발송실패',
}

enum 주주동의_주주총회 {
  발송전 = '발송전',
  발송중 = '발송중',
  발송완료 = '발송완료\n(대기중)',
  동의완료 = '동의완료',
  발송실패 = '발송실패',
  발송실패_알림톡 = '발송실패\n(알림톡)',
  발송실패_메일 = '발송실패\n(메일)',
}

enum 주주동의_주주총회_신주발행 {
  발송전 = '발송전',
  발송중 = '발송중',
  발송완료 = '발송완료\n(대기중)',
  동의완료 = '동의완료',
  발송실패 = '발송실패',
  발송실패_알림톡 = '발송실패\n(알림톡)',
  발송실패_메일 = '발송실패\n(메일)',
}

enum 기간단축_동의서 {
  발송전 = '발송전',
  발송중 = '발송중',
  발송완료 = '발송완료\n(대기중)',
  동의완료 = '동의완료',
  발송실패 = '발송실패',
  발송실패_알림톡 = '발송실패\n(알림톡)',
  발송실패_메일 = '발송실패\n(메일)',
}

interface State {
  checkEmail: number[];
  checkAlimTalk: number[];
  errorYn: boolean;
  disabledYn: boolean;
}

class AgreeStatePopupVm {
  state: State = observable({
    checkEmail: [],
    checkAlimTalk: [],
    errorYn: false,
    disabledYn: false,
  });
  load(agendaProgressId: string, documentType: SendDocumentType, page?: string) {
    console.log(page);
    if (page === 'stock') {
      documentService.loadStockShareholders();
    } else {
      documentService.loadShareholders(agendaProgressId, documentType);
    }
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string, formData: FormData, page?: string) {
    e.preventDefault();
    if (
      formDataToJson(e.target).error ||
      (this.state.checkEmail.length === 0 && this.state.checkAlimTalk.length === 0)
    ) {
      if (this.state.checkEmail.length === 0 && this.state.checkAlimTalk.length === 0) {
        this.state.errorYn = true;
      }
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        shareholders: {
          id: number;
          email: string;
          phoneNumber: string;
        }[];
      };
      param.shareholders = param.shareholders.filter((elem) => elem.id !== undefined);

      let deleteKeys = [];
      for (let [key] of formData) {
        if (key.includes('shareholders')) {
          deleteKeys.push(key);
        }
      }
      if (deleteKeys) {
        deleteKeys.forEach((key) => formData.delete(key));
      }

      param.shareholders.forEach((shareholder, idx) => {
        formData.append(`shareholders[${idx}].id`, String(shareholder.id));
        if (shareholder.phoneNumber) {
          formData.append(`shareholders[${idx}].phoneNumber`, shareholder.phoneNumber);
        }
        if (shareholder.email) {
          formData.append(`shareholders[${idx}].email`, shareholder.email);
        }
      });

      try {
        this.state.disabledYn = true;
        if (page === 'stock') {
          const _param = {
            shareholders: param.shareholders,
          };
          await documentService.sendStockDocument(_param);
        } else {
          await documentService.sendDocument(agendaProgressId, formData);
        }
        this.state.disabledYn = false;
        await _alert('발송을 시작했어요.', '- 발송성공 여부는 확인을 누른 후 보이는 화면을 통해 확인하세요.');
        runInAction(() => {
          portalModel.title = '';
          portalModel.content = null;
        });
        window.location.reload();
      } catch {
        this.state.disabledYn = false;
      }
    }
  }
  get shareholders() {
    return documentModel.shareholders;
  }
  get emailAll() {
    const agreeCnt = documentModel.shareholders.filter((elem) => elem.status !== '동의완료').length;
    if (agreeCnt === this.state.checkEmail.length) {
      return true;
    } else {
      return false;
    }
  }
  setEmailAll(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    if (value) {
      documentModel.shareholders.forEach((item) => {
        if (item.status !== '동의완료') {
          this.state.checkEmail.push(item.id);
        }
      });
      this.state.errorYn = false;
    } else {
      this.state.checkEmail = [];
    }
  }
  get alimTalkAll() {
    const agreeCnt = documentModel.shareholders.filter((elem) => elem.status !== '동의완료').length;
    if (agreeCnt === this.state.checkAlimTalk.length) {
      return true;
    } else {
      return false;
    }
  }
  setAlimTalkAll(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    if (value) {
      documentModel.shareholders.forEach((item) => {
        if (item.status !== '동의완료') {
          this.state.checkAlimTalk.push(item.id);
        }
      });
      this.state.errorYn = false;
    } else {
      this.state.checkAlimTalk = [];
    }
  }
  email(id: number) {
    if (this.state.checkEmail.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  setEmail(e: React.ChangeEvent<HTMLInputElement>, id: number) {
    if (e.target.checked) {
      if (!this.state.checkEmail.includes(id)) {
        this.state.checkEmail.push(id);
        this.state.errorYn = false;
      }
    } else {
      this.state.checkEmail = this.state.checkEmail.filter((item) => item !== id);
    }
  }
  alimTalk(id: number) {
    if (this.state.checkAlimTalk.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  setAlimTalk(e: React.ChangeEvent<HTMLInputElement>, id: number) {
    if (e.target.checked) {
      if (!this.state.checkAlimTalk.includes(id)) {
        this.state.checkAlimTalk.push(id);
        this.state.errorYn = false;
      }
    } else {
      this.state.checkAlimTalk = this.state.checkAlimTalk.filter((item) => item !== id);
    }
  }
  status(type: string | null, _status: string) {
    switch (type) {
      case '수신동의':
        return 수신동의[_status as keyof typeof 수신동의];
      case '주주동의_주주총회':
        return 주주동의_주주총회[_status as keyof typeof 주주동의_주주총회];
      case '주주동의_주주총회_신주발행':
        return 주주동의_주주총회_신주발행[_status as keyof typeof 주주동의_주주총회_신주발행];
      case '기간단축_동의서':
      case '신주식_인수포기_및_기간_단축_동의서':
        return 기간단축_동의서[_status as keyof typeof 기간단축_동의서];
    }
  }
  get stockTotal() {
    let cnt = 0;
    documentModel.shareholders.forEach((shareholder) => {
      shareholder.stocks.forEach((stock) => {
        cnt = cnt + stock.number;
      });
    });
    return cnt;
  }
  setShareholderEmail(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      documentModel.shareholders[idx].email = regExp.deleteSpace(e.target.value);
    });
  }
  setShareholderPhoneNumber(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      documentModel.shareholders[idx].phoneNumber = e.target.value;
    });
  }
}

export default AgreeStatePopupVm;
