import type { Banks } from '@model/Corp/BusinessRegistration/businessRegistrationModel.interface';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@src/service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@src/model/Corp/corpModel';

class BankBookVm {
  get bankbooks() {
    return businessRegistrationModel.bankbooks;
  }
  get companyName() {
    const company = corpModel.company;
    let companyName: string = '';

    if (company.prefixedYn) {
      if (company.corpType === '주식회사') {
        companyName = `(주)${company.companyName}`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `(유)${company.companyName}`;
      }
    } else {
      if (company.corpType === '주식회사') {
        companyName = `${company.companyName}(주)`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `${company.companyName}(유)`;
      }
    }

    return companyName;
  }
  fileDownload(downloadLink: string) {
    businessRegistrationService.fileDownload(downloadLink);
  }
  moveBankbook() {
    window.location.href = `/corp/accounting/businessRegistration/document/bankbook?corp=${corpModel.id}`;
  }
  accountInfoCopyText(bankName: Banks, accountNumber: string) {
    let copyString: string = '';
    copyString = bankName + ' ' + accountNumber + ' ' + this.companyName;
    return copyString;
  }
}

export default new BankBookVm();
