import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import DocumentVm from './DocumentVm';
import st from './Document.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import useComponentWillMount from '@common/hook/useComponentWillMount';

let vm: DocumentVm;

interface DocumentProps {
  agendaProgressId: string;
  newStockAcquirerInfo: {
    assignmentType: '제3자' | '주주' | null;
    isExistsAbandonedStock: boolean;
  };
}

function Document(props: DocumentProps) {
  useComponentWillMount(() => {
    vm = new DocumentVm();
    vm.load(props.agendaProgressId, props.newStockAcquirerInfo);
  });

  useEffect(() => {
    reaction(
      () => vm.previewLink,
      (previewLink: string) => {
        if (previewLink) {
          const previewFrame = document.getElementById('previewFrame') as HTMLIFrameElement;
          if (previewFrame && previewFrame.contentWindow) {
            previewFrame.contentWindow.location.replace(previewLink);
          }
        }
      },
    );
  }, []);

  return useObserver(() => (
    <div className={st.document}>
      <div className={st.title}>{vm.title(props.newStockAcquirerInfo)}</div>
      <div className={st.contentBox}>
        <div className={st.documentBox}>
          <div className={st.content}>
            <iframe id="previewFrame" title="기간단축 동의서 미리보기" className={st.iframeDocument} />
          </div>
        </div>
        <form
          className={st.documentOption}
          onSubmit={(e) => vm.next(e, props.agendaProgressId, props.newStockAcquirerInfo)}
        >
          <div className={st.optionSeparate}>
            <div className={st.optionBox}>
              <div className={st.optionTitle}>글자크기</div>
              <div className={st.option}>
                <Radio
                  className={st.radio}
                  name="options.fontSize"
                  value="SMALL"
                  checked={vm.state.options.fontSize === 'SMALL'}
                  onChange={(e) => vm.setFontSize(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  작게
                </Radio>
                <Radio
                  className={st.radio}
                  name="options.fontSize"
                  value="MEDIUM"
                  checked={vm.state.options.fontSize === 'MEDIUM'}
                  onChange={(e) => vm.setFontSize(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  중간
                </Radio>
                <Radio
                  className={st.radio}
                  name="options.fontSize"
                  value="LARGE"
                  checked={vm.state.options.fontSize === 'LARGE'}
                  onChange={(e) => vm.setFontSize(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  크게
                </Radio>
              </div>
            </div>
            <div className={st.optionBox}>
              <div className={st.optionTitle}>내용</div>
              <div className={st.option}>
                <Radio
                  className={st.radio}
                  name="options.contentDetailYn"
                  value="false"
                  checked={vm.state.options.contentDetailYn === false}
                  onChange={(e) => vm.setContentDetailYn(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  간단하게
                </Radio>
                <Radio
                  className={st.radio}
                  name="options.contentDetailYn"
                  value="true"
                  checked={vm.state.options.contentDetailYn === true}
                  onChange={(e) => vm.setContentDetailYn(e, props.agendaProgressId, props.newStockAcquirerInfo)}
                >
                  자세히
                </Radio>
              </div>
              <div className={st.explain}>
                등기시 주식종류 명칭 등이 변경될 수 있다면, [간단하게]를 선택하고, 주주에게 자세히 알려주고 싶다면
                [자세히]를 선택하세요.
              </div>
              <div className={st.btnArea}>
                <SquareBtn className={st.nextBtn} type="submit">
                  다음 페이지
                </SquareBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  ));
}
export default Document;
