import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import clsx from 'clsx';

import type { SelectedProducts, SelectedProductType } from '../../types';
import st from './style.module.scss';

interface Props {
  selectedProducts: SelectedProducts;
  onSelectProduct(productType: SelectedProductType): void;
}

function ProductSelectionContainer(props: Props) {
  const { selectedProducts, onSelectProduct } = props;

  return (
    <ul className={st.mainCategory}>
      <li>
        <div className={st.title} data-type="통신사">
          통신사
        </div>
        <ul className={st.checks}>
          <li className={st.selectedKt}>
            <img src="/images/kt_logo_select.svg" />
          </li>
        </ul>
      </li>
      <li>
        <div className={st.title} data-type="상품선택">
          상품선택
        </div>
        <ul className={st.checks}>
          <ProductCheckBox
            label="기업용 인터넷"
            value={'INTERNET'}
            checked={selectedProducts.includes('INTERNET')}
            onSelectProduct={onSelectProduct}
          />
          <ProductCheckBox
            label="전화"
            value={'TELEPHONE'}
            checked={selectedProducts.includes('TELEPHONE')}
            onSelectProduct={onSelectProduct}
          />
          <ProductCheckBox
            label="TV"
            value={'TV'}
            checked={selectedProducts.includes('TV')}
            onSelectProduct={onSelectProduct}
          />
        </ul>
      </li>
    </ul>
  );
}

interface ProductCheckBoxProps {
  checked: boolean;
  value: SelectedProductType;
  label: string;
  onSelectProduct(productType: SelectedProductType): void;
}

function ProductCheckBox(props: ProductCheckBoxProps) {
  const { checked, value, label, onSelectProduct } = props;

  return (
    <li>
      <CheckBox
        className={clsx(st.check, checked ? st.checked : null)}
        textClassName={st.text}
        value={value}
        name="selectedProducts"
        checked={checked}
        onChange={(e) => onSelectProduct(e.target.value as SelectedProductType)}
      >
        {label}
      </CheckBox>
    </li>
  );
}

export default ProductSelectionContainer;
