import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type { Agenda, IA정관변경 } from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';
import Cst from './C정관변경.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import { addCommas } from '@common/module/replaceNumber';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA정관변경>;
  moreViewYn: boolean;
}

function C정관변경(props: Props) {
  return useObserver(() => (
    <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
      <div className={st.title}>
        <div className={st.number}>{props.agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>정관 변경</span>
        </div>
        {props.agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      {props.moreViewYn && (
        <div className={st.info}>
          {props.agenda.info.autoAddArticlesOfAssociations.length !== 0 && (
            <div className={Cst.aoa}>
              <div className={Cst.badge}>자동추가된 정관변경 사항</div>
              <Table className={Cst.table}>
                <colgroup>
                  <col width="160" />
                  <col width="285" />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>항목</th>
                    <th>기존</th>
                    <th>신규</th>
                  </tr>
                </thead>
                <tbody>
                  {props.agenda.info.autoAddArticlesOfAssociations.map((autoAddArticlesOfAssociation, index) => (
                    <tr key={index}>
                      <td className={Cst.category}>
                        <span style={{ width: '18px', display: 'inline-block' }}>{index + 1}</span>
                        {autoAddArticlesOfAssociation.latest.category}
                      </td>
                      <td className={Cst.origin}>{autoAddArticlesOfAssociation.origin || '없음'}</td>
                      <td className={Cst.latest}>
                        {autoAddArticlesOfAssociation.latest.content}
                        {autoAddArticlesOfAssociation.latest.description && (
                          <> ({autoAddArticlesOfAssociation.latest.description})</>
                        )}
                        {autoAddArticlesOfAssociation.latest.issueLimitYn === true && (
                          <div className={Cst.subText}>
                            <div className={Cst.row}>
                              <span>
                                보통주 발행한도 :{' '}
                                {addCommas(autoAddArticlesOfAssociation.latest.보통주_issueLimit || '')}억 원
                              </span>
                            </div>
                            <div className={Cst.row}>
                              <span>
                                우선주 발행한도 :{' '}
                                {addCommas(autoAddArticlesOfAssociation.latest.우선주_issueLimit || '')}억 원
                              </span>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {props.agenda.info.directAddArticlesOfAssociations.length !== 0 && (
            <div className={Cst.aoa}>
              <div className={Cst.badge}>직접 입력한 정관변경 사항</div>
              <ul className={Cst.aoaList}>
                {props.agenda.info.directAddArticlesOfAssociations.map((directAddArticlesOfAssociation, index) => (
                  <li key={index}>
                    <div className={Cst.number}>{index + 1}. </div>
                    <div className={Cst.regulationLocation}>{directAddArticlesOfAssociation.regulationLocation}</div>조
                    <div className={Cst.content}>{directAddArticlesOfAssociation.content}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  ));
}
export default C정관변경;
