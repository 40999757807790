import { useObserver } from 'mobx-react';
import vm from './CustomerVm';
import st from '../AgendaDashboard.module.scss';
import Cst from './Customer.module.scss';
import clsx from 'clsx';

function Customer() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>등기대행 요청사항</div>
      <div className={clsx(st.content, Cst.content)}>
        <div>
          <div className={st.importantText}>견적발송 후 전화상담 요청 {vm.telephoneCounselingYn ? 'O' : 'X'}</div>
          {vm.requirements && <div className={Cst.requirements}>{vm.requirements}</div>}
        </div>
      </div>
    </div>
  ));
}
export default Customer;
