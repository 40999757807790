import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import vm from './ExecutivesVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import st from './Executives.module.scss';
import clsx from 'clsx';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import Icon from '@standby/common-ui/components/atoms/Icon/Icon';
import CloseBtn from '@standby/common-ui/components/atoms/Button/CloseBtn/CloseBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import type { SelectedAgenda, I임원_취임_중임_사임_퇴임 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import { addCommas } from '@common/module/replaceNumber';

interface ExecutivesProps {
  agenda: SelectedAgenda<I임원_취임_중임_사임_퇴임>;
  agendaIndex: number;
  openFirst: () => void;
}

const Executives = React.memo(function (props: ExecutivesProps) {
  useEffect(() => {
    if (vm.modalOpened === true) {
      vm.openModal(props.openFirst);
    }
  }, [vm.modalOpened]);
  return useObserver(() => (
    <div className={qst.questionForm} key={vm.modalOpened.toString()}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>현 등기부상 임원의 변경사항을 입력하세요.</div>
        <div className={st.executiveHeader}>
          <span style={{ marginLeft: '8px' }}>임원</span>
          <span style={{ marginLeft: '208px' }}>임기</span>
          <span style={{ marginLeft: '383px' }}>변경내역</span>
        </div>
        <div className={st.executivesList}>
          {props.agenda.agendaDetail.originExecutives.map((originExecutive, index) => (
            <div className={st.executiveRow} key={index}>
              <div className={st.personInfo}>
                <input
                  type="hidden"
                  name={`agendaDetail.originExecutives[${index}].name`}
                  value={originExecutive.name}
                />
                <div
                  className={st.name}
                  style={{ fontSize: originExecutive.name.length > 5 ? '14px' : '16px' }}
                  data-position={vm.positionIcon(originExecutive.info)}
                >
                  {originExecutive.name}
                </div>
              </div>
              <div style={{ flex: '1' }}>
                {originExecutive.info.map((info, index2) => (
                  <div key={index2} className={st.infoRow}>
                    <input
                      type="hidden"
                      name={`agendaDetail.originExecutives[${index}].info[${index2}].id`}
                      value={info.id}
                    />
                    <div className={st.gauge}>
                      <div key={index2} className={st.position}>
                        {info.position}
                      </div>
                      <div className={st.date}>{info.termOfOfficeAt}</div>
                      <div className={st.gaugeWrap}>
                        <div className={st.gauge} data-tag={info.tag}>
                          <div
                            className={st.fill}
                            style={{ width: 126 - 126 / (info.fullTermOfOffice / info.remainingTermOfOffice) + 'px' }}
                          >
                            <div className={st.text}>
                              {info.tag === '임기만료' ? '임기만료' : info.remainingTermOfOffice + '일 남음'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={st.newPosition}>
                      {(index2 !== 1 || originExecutive.separateInputYn) && (
                        <>
                          {info.registrationRequiredYn && (
                            <Icon type="exclamationNegative" className={st.registrationRequired} />
                          )}
                          <Select
                            size="small"
                            style={{ width: '140px' }}
                            value={info.changeType || '변경없음'}
                            onChange={(e) => vm.changeType(e, props.agendaIndex, index, index2)}
                            name={`agendaDetail.originExecutives[${index}].info[${index2}].changeType`}
                          >
                            <option value="변경없음">-</option>
                            <option value="임기연장">임기연장</option>
                            <option value="사임_퇴임">사임/퇴임</option>
                            {index2 !== 1 && <option value="직급변경">직급변경</option>}
                          </Select>
                          {info.changeType === '직급변경' && (
                            <span style={{ marginLeft: '8px' }}>
                              <Select
                                size="small"
                                style={{ width: '140px' }}
                                value={info.newPosition || undefined}
                                onChange={(e) => vm.newPosition(e, props.agendaIndex, index, index2)}
                                name={`agendaDetail.originExecutives[${index}].info[${index2}].newPosition`}
                                errorText={
                                  <Error
                                    name={`agendaDetail.originExecutives[${index}].info[${index2}].newPosition`}
                                    value={info.newPosition || undefined}
                                    errorCase={{
                                      required: '변경직급을 입력하세요.',
                                    }}
                                  />
                                }
                              >
                                <option value="">-</option>
                                {vm.changePositionList(info).map((position, index3) => (
                                  <option key={index3} value={position.value}>
                                    {position.text}
                                  </option>
                                ))}
                              </Select>
                            </span>
                          )}
                        </>
                      )}
                      {originExecutive.info.length > 1 && index2 === 1 && (
                        <>
                          <input
                            type="hidden"
                            name={`agendaDetail.originExecutives[${index}].separateInputYn`}
                            value={originExecutive.separateInputYn.toString()}
                          />
                          {originExecutive.separateInputYn ? (
                            <div>
                              <button
                                type="button"
                                className={clsx(st.separateInput, st.true)}
                                onClick={() => vm.separateInputYn(props.agendaIndex, index)}
                              >
                                분리입력 취소
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                type="button"
                                className={clsx(st.separateInput, st.false)}
                                onClick={() => vm.separateInputYn(props.agendaIndex, index)}
                              >
                                분리입력
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {vm.allRegistrationRequiredYn(props.agenda.agendaDetail.originExecutives) && (
          <div className={st.endNotify}>
            <Icon type="exclamationNegative" />
            <span className={st.iconText}>등기추천</span>
            <span>[임기연장]이 자동선택되었어요. 그만둔다면, 사임/퇴임으로 변경하세요.</span>
          </div>
        )}
      </div>
      {props.agenda.agendaDetail.changeAddressExecutives.length !== 0 && (
        <div className={qst.qusetion}>
          <div className={qst.questionTitle}>집주소 변경이 필요한 대표님을 선택하세요.</div>
          <ul className={st.changeAddressList}>
            {props.agenda.agendaDetail.changeAddressExecutives.map((changeAddressExecutive, index) => (
              <li key={index}>
                <CheckBox
                  value={changeAddressExecutive.id}
                  checked={changeAddressExecutive.checked}
                  name="agendaDetail.changeAddressExecutives[]"
                  onChange={(e) => vm.changeAddressExecutives(e, props.agendaIndex, index)}
                >
                  {changeAddressExecutive.name} 대표님
                </CheckBox>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>신규 임원이 있나요?</div>
        <div style={{ width: '460px' }}>
          <RadioLabel
            className={clsx(qst.radio, st.radio)}
            name="agendaDetail.newExecutivesExistsYn"
            value="true"
            checked={props.agenda.agendaDetail.newExecutivesExistsYn === true}
            onChange={(e) => vm.changeNewExecutivesExistsYn(e, props.agendaIndex)}
          >
            네.
          </RadioLabel>
          <RadioLabel
            className={qst.radio}
            name="agendaDetail.newExecutivesExistsYn"
            value="false"
            checked={props.agenda.agendaDetail.newExecutivesExistsYn === false}
            onChange={(e) => vm.changeNewExecutivesExistsYn(e, props.agendaIndex)}
          >
            아니오.
          </RadioLabel>
          <div>
            <Error
              name="agendaDetail.newExecutivesExistsYn"
              value={(props.agenda.agendaDetail.newExecutivesExistsYn ?? '').toString()}
              errorCase={{
                required: '신규 임원 여부를 선택하세요.',
              }}
            />
          </div>
        </div>
      </div>
      {props.agenda.agendaDetail.newExecutivesExistsYn === true && (
        <div className={qst.qusetion}>
          <div className={qst.questionTitle}>신규 임원을 입력하세요.</div>
          <QuestionTooltip title="단독 VS 공동대표 무엇이 좋을까?" style={{ width: '260px' }}>
            <div className={st.tooltipContent}>
              <div className={st.exTitle}>1. 단독대표는 1명이 대표를 맡는 경우에요.</div>
              <div className={st.exContent}>90%의 회사는 단독대표를 선택해요.</div>
              <div className={st.exTitle}>2. 공동대표는 경영권을 서로 감시하고 싶을 때 선택해요.</div>
              <div className={st.exContent}>
                항상 같이 결정해야 하고, 계약서에도 공동대표의 도장이 모두 들어가야 해요.
              </div>
              <div className={st.exTitle}>3. 각자대표는 편리하게 회사를 운영할 때 선택해요.</div>
              <div className={st.exContent}>
                각 대표가 단독으로 무엇이든 결정 가능해요. 하지만 1명의 대표가 잘못된 결정을 하는 것을 미리 방지할 수는
                없어요.
              </div>
            </div>
          </QuestionTooltip>
          <div className={st.newPerson}>
            {props.agenda.agendaDetail.newExecutives.map((newExecutive, index) => (
              <div key={index} className={st.row}>
                <div className={st.newTitle}>
                  <span>{index + 1} 임원</span>
                  {index !== 0 && (
                    <CloseBtn
                      onClick={() => vm.delete(props.agendaIndex, index)}
                      className={st.rowDelete}
                      type="button"
                    />
                  )}
                </div>
                <div className={st.firstRow}>
                  <div className={qst.subTextInput}>
                    {newExecutive.executiveId && (
                      <input
                        type="hidden"
                        value={newExecutive.executiveId}
                        name={`agendaDetail.newExecutives[${index}].executiveId`}
                      />
                    )}
                    {newExecutive.shareholderId && newExecutive.shareholderId !== '0' && (
                      <input
                        type="hidden"
                        value={
                          props.agenda.agendaDetail.shareholders.find(
                            (shareholder) => shareholder.id.toString() === newExecutive.shareholderId.toString(),
                          )?.name
                        }
                        name={`agendaDetail.newExecutives[${index}].name`}
                      />
                    )}
                    <Select
                      size="small"
                      value={newExecutive.shareholderId}
                      onChange={(e) => vm.shareholderId(e, props.agendaIndex, index)}
                      name={`agendaDetail.newExecutives[${index}].shareholderId`}
                      explain="이름*"
                      style={{ width: '281px' }}
                      errorText={
                        <Error
                          name={`agendaDetail.newExecutives[${index}].shareholderId`}
                          value={newExecutive.shareholderId || undefined}
                          errorCase={{
                            required: '이름을 선택하세요.',
                          }}
                        />
                      }
                    >
                      <option value="">이름 선택</option>
                      <option value="0">직접입력 (주주 아닌 사람)</option>
                      {props.agenda.agendaDetail.shareholders.map((shareholder, index2) => (
                        <option key={index2} value={shareholder.id}>
                          주주 {shareholder.name} ({addCommas(shareholder.stock)}주)
                        </option>
                      ))}
                    </Select>
                    <span className={clsx(qst.inputSubText, qst.hasEx)}>님</span>
                  </div>
                  <div>
                    <Select
                      size="small"
                      value={newExecutive.position || undefined}
                      onChange={(e) => vm.position(e, props.agendaIndex, index)}
                      name={`agendaDetail.newExecutives[${index}].position`}
                      explain="직급*"
                      style={{ width: '220px' }}
                      errorText={
                        <Error
                          name={`agendaDetail.newExecutives[${index}].position`}
                          value={newExecutive.position || undefined}
                          errorCase={{
                            required: '직급을 입력하세요.',
                          }}
                        />
                      }
                    >
                      <option value="">직급 선택</option>
                      {vm.positionList.map((position, index2) => (
                        <option key={index2} value={position.value}>
                          {position.text}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <Select
                      size="small"
                      value={newExecutive.simpleNationalityType || undefined}
                      onChange={(e) => vm.simpleNationalityType(e, props.agendaIndex, index)}
                      name={`agendaDetail.newExecutives[${index}].simpleNationalityType`}
                      explain="국적*"
                      style={{ width: '220px' }}
                      errorText={
                        <Error
                          name={`agendaDetail.newExecutives[${index}].simpleNationalityType`}
                          value={newExecutive.simpleNationalityType || undefined}
                          errorCase={{
                            required: '국적을 입력하세요.',
                          }}
                        />
                      }
                    >
                      <option value="">국적 선택</option>
                      <option value="한국인_성인">한국인 성인</option>
                      <option value="한국인_미성년">한국인 미성년</option>
                      <option value="외국인">외국인</option>
                    </Select>
                  </div>
                </div>
                {(newExecutive.shareholderId === '0' || newExecutive.simpleNationalityType === '외국인') && (
                  <div className={st.seconRow}>
                    {newExecutive.shareholderId === '0' ? (
                      <InputText
                        explain="이름입력*"
                        size="small"
                        style={{ width: '281px' }}
                        placeholder="이름"
                        name={`agendaDetail.newExecutives[${index}].name`}
                        value={newExecutive.name}
                        onChange={(e) => vm.name(e, props.agendaIndex, index)}
                        errorText={
                          <Error
                            name={`agendaDetail.newExecutives[${index}].name`}
                            value={newExecutive.name}
                            errorCase={{
                              required: '이름을 입력하세요.',
                            }}
                          />
                        }
                      />
                    ) : (
                      <span></span>
                    )}
                    {newExecutive.simpleNationalityType === '외국인' && (
                      <Select
                        size="small"
                        value={newExecutive.nationalityType || undefined}
                        onChange={(e) => vm.nationalityType(e, props.agendaIndex, index)}
                        name={`agendaDetail.newExecutives[${index}].nationalityType`}
                        explain="국적선택*"
                        style={{ width: '220px' }}
                        errorText={
                          <Error
                            name={`agendaDetail.newExecutives[${index}].nationalityType`}
                            value={newExecutive.nationalityType || undefined}
                            errorCase={{
                              required: '국적을 선택하세요.',
                            }}
                          />
                        }
                      >
                        <option value="">국적 선택</option>
                        {vm.nationalityTypes(props.agendaIndex).map((nationalityType, nationalityTypeIndex) => (
                          <option key={nationalityTypeIndex} value={nationalityType.value}>
                            {nationalityType.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </div>
                )}
                {newExecutive.simpleNationalityType === '외국인' && newExecutive.nationalityType === '기타' && (
                  <div className={st.seconRow}>
                    <span></span>
                    <InputText
                      explain="기타 직접입력*"
                      size="small"
                      style={{ width: '220px' }}
                      placeholder="기타 직접입력"
                      name={`agendaDetail.newExecutives[${index}].nationalityOthers`}
                      value={newExecutive.nationalityOthers}
                      onChange={(e) => vm.nationalityOthers(e, props.agendaIndex, index)}
                      errorText={
                        <Error
                          name={`agendaDetail.newExecutives[${index}].nationalityOthers`}
                          value={newExecutive.nationalityOthers}
                          errorCase={{
                            required: '국적을 입력하세요.',
                          }}
                        />
                      }
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={st.btnArea}>
            <AddBtn className={st.newRow} onClick={() => vm.add(props.agendaIndex)}>
              신규임원 추가하기
            </AddBtn>
          </div>
        </div>
      )}
    </div>
  ));
});
export default Executives;
