import { SelectedProducts, SelectedProductsDetail } from './types';

export const convertSelectedProductsToRequestParam = (
  selectedProductsDetail: SelectedProductsDetail,
  selectedProducts: SelectedProducts,
) => {
  const param: {
    internet?: SelectedProductsDetail['INTERNET'];
    telephone?: SelectedProductsDetail['TELEPHONE'];
    telephoneNumber?: SelectedProductsDetail['TELEPHONE_NUMBER'];
    tv?: SelectedProductsDetail['TV'];
  } = {};

  if (selectedProducts.includes('INTERNET')) {
    param.internet = selectedProductsDetail.INTERNET;
  }
  if (selectedProducts.includes('TELEPHONE')) {
    param.telephone = selectedProductsDetail.TELEPHONE;
    param.telephoneNumber = selectedProductsDetail.TELEPHONE_NUMBER;
  }
  if (selectedProducts.includes('TV')) {
    param.tv = selectedProductsDetail.TV;
  }

  return param;
};
