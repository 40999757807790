import { useObserver } from 'mobx-react';
import vm from './ExecutivesVm';
import st from './Executives.module.scss';
import clsx from 'clsx';

interface PositionListProps {
  executiveMember: {
    name: string;
    info: {
      position: string;
      termOfOfficeAt: string;
      remainingTermOfOffice: number;
      fullTermOfOffice: number;
      tag: string;
    }[];
  };
}

function PositionList(props: PositionListProps) {
  return useObserver(() => (
    <li>
      <div
        className={st.name}
        style={{ fontSize: props.executiveMember.name.length > 5 ? '14px' : '16px' }}
        data-position={vm.positionIcon(props.executiveMember.info)}
      >
        {props.executiveMember.name}
      </div>
      <ul className={st.positionList}>
        {props.executiveMember.info.map((info, index2) => (
          <li key={index2}>
            <div className={st.position}>{info.position}</div>
            <div className={st.date}>{info.termOfOfficeAt}</div>
            <div className={st.gaugeWrap}>
              <div className={st.gauge} data-tag={info.tag}>
                <div
                  className={st.fill}
                  style={{ width: 124 - 124 / (info.fullTermOfOffice / info.remainingTermOfOffice) + 'px' }}
                >
                  <div className={st.text}>
                    {info.tag === '임기만료' ? '임기만료' : info.remainingTermOfOffice + '일 남음'}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  ));
}

function Executives() {
  return useObserver(() => (
    <div className={st.wrap}>
      <div className={st.title}>
        <span>임원</span>
        <div className={st.content}>총 {vm.dashboardData?.executiveMembers.length}명</div>
      </div>
      <div className={st.legend}>
        <span>남은 임기</span>
      </div>
      <button type="button" className={st.openModalBtn} onClick={vm.agendaLinkModal}>
        변경하기
      </button>
      <ul className={st.person}>
        {(vm.dashboardData && vm.dashboardData.executiveMembers.length < 5) || vm.state.moreviewYn === true
          ? vm.dashboardData?.executiveMembers.map((executiveMember, index) => (
              <PositionList executiveMember={executiveMember} key={index} />
            ))
          : vm.dashboardData?.executiveMembers
              .slice(0, 4)
              .map((executiveMember, index) => <PositionList executiveMember={executiveMember} key={index} />)}
      </ul>
      {vm.dashboardData && vm.dashboardData.executiveMembers.length > 4 && (
        <button
          type="button"
          className={clsx(st.moreView, vm.state.moreviewYn ? st.open : null)}
          onClick={() => vm.moreview()}
        >
          더보기
        </button>
      )}
    </div>
  ));
}
export default Executives;
