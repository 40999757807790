import { runInAction } from 'mobx';
import UserSetting from '@pages/UserSetting/UserSetting';
import portalModel from '@model/portalModel';

class AllianceVm {
  openUserSetting() {
    runInAction(() => {
      portalModel.content = <UserSetting activeTab="약관동의" />;
      portalModel.title = '나의 계정';
    });
  }
}
export default new AllianceVm();
