import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import { _alert, _confirm } from '@model/dialogModel';
import corpModel from '@model/Corp/corpModel';

class ProgressStatusVm {
  load() {
    switch (this.statusType) {
      case '세무서_접수':
        businessRegistrationService.taxOfficeRegistration();
        break;
      case '사업자등록_완료':
        businessRegistrationService.businessRegistrationCompleted();
        break;
    }
  }
  get businessRegistrationStatuses() {
    return businessRegistrationModel.businessRegistrationStatuses;
  }
  get statusType() {
    let statusType = '';
    statusType =
      businessRegistrationModel.businessRegistrationStatuses[
        businessRegistrationModel.businessRegistrationStatuses.length - 1
      ]?.status;
    return statusType;
  }
  get filingReceiptThumbnailFile() {
    //세무서 접수증 썸네일 링크
    return businessRegistrationModel.filingReceiptThumbnailFile;
  }
  taxOfficeFileDownload() {
    const fileLink = businessRegistrationModel.filingReceiptFile;
    businessRegistrationService.fileDownload(fileLink);
  }
  get businessRegistrationThumbnailFile() {
    //사업자 등록증 썸네일 링크
    return businessRegistrationModel.businessRegistrationThumbnailFile;
  }
  get businessRegistrationNumber() {
    return businessRegistrationModel.businessRegistrationNumber;
  }
  businessRegistrationFileDownload() {
    const fileLink = businessRegistrationModel.businessRegistrationFile;
    businessRegistrationService.fileDownload(fileLink);
  }
  sendDocument() {
    window.location.href = `/corp/accounting/businessRegistration/document/send?corp=${corpModel.id}`;
  }
}

export default new ProgressStatusVm();
