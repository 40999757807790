import { observable, makeObservable } from 'mobx';
import type { onlineAcceptancePopupInterface, Direct } from './onlineAcceptancePopupModel.interface';

class onlineAcceptancePopupModel implements onlineAcceptancePopupInterface {
  constructor() {
    makeObservable(this, {
      direct: observable,
      nowStep: observable,
      nowPage: observable,
    });
  }
  direct: Direct = {
    shareholders: [],
  };
  nowStep: '1' | '2' | null = null;
  nowPage: 'Direct' | 'Document' | null = null;
}

export default new onlineAcceptancePopupModel();
