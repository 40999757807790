import { makeObservable, observable } from 'mobx';
import type {
  AgendaViewModelInterface,
  ShareholdersMeetingType,
  NewStockType,
  Agenda,
  StockholderInfos,
} from './AgendaViewModel.interface';

class agendaViewModel implements AgendaViewModelInterface {
  constructor() {
    makeObservable(this, {
      shareholdersMeetingType: observable,
      newStockType: observable,
      agendas: observable,
      stockholderInfos: observable,
    });
  }
  shareholdersMeetingType: ShareholdersMeetingType = {
    meetingType: '',
    musterNoticeType: '',
  };
  newStockType: NewStockType = {
    배정_Type: '',
    발행절차_Type: '',
  };
  agendas: Agenda[] = [];
  stockholderInfos: StockholderInfos = {
    criteriaDate: '',
    addedShareholder: [],
  };
}

export default new agendaViewModel();
