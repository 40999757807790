import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';

class ReceiptPopupVm {
  check() {
    runInAction(() => {
      portalModel.content = null;
    });
  }
  get acctFirmCode() {
    return corpModel.acctFirmCode;
  }
}

export default new ReceiptPopupVm();
