import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import vm from './BonusIssueVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './BonusIssue.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import { addCommas } from '@common/module/replaceNumber';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Icon from '@standby/common-ui/components/atoms/Icon/Icon';
import type { SelectedAgenda, I신주발행_무상증자 } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import clsx from 'clsx';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';

interface BonusIssueProps {
  agenda: SelectedAgenda<I신주발행_무상증자>;
  agendaIndex: number;
}

const BonusIssue = React.memo(function (props: BonusIssueProps) {
  let location = useLocation();
  const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;
  const corp = queryString.parse(location.search).corp as string;

  useEffect(() => {
    if (props.agenda.agendaDetail.beforeIncreaseAmount && props.agenda.agendaDetail.increaseAmount) {
      vm.getIncreaseAmountData(props.agendaIndex, agendaProgressId, corp);
    }
  }, []);

  return useObserver(() => (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div style={{ marginBottom: '10px' }}>무상증자 방식을 선택하세요.</div>
          <QuestionTooltip title="주식발행초과금 vs 이익준비금">
            <div className={st.tooltipContent}>
              <div className={st.qna}>
                <div className={st.question}>
                  <Icon type="question" className={st.icon} />
                  주식발행초과금이란?
                </div>
                <div className={st.answer}>
                  <Icon type="answer" className={st.icon} />
                  주식발행초과금이란 액면금보다 높은 금액으로 신주 발행한 경우(= 할증발행),{' '}
                  <span className={st.bold}>액면금을 제외한 나머지 신주인수대금</span>을 말해요. 주식발행 초과금은 전액
                  무상증자가 가능해요.
                </div>
              </div>
              <div className={st.qna}>
                <div className={st.question}>
                  <Icon type="question" className={st.icon} />
                  이익준비금이란?
                </div>
                <div className={st.answer}>
                  <Icon type="answer" className={st.icon} />
                  이익준비금은 매년 회사에 생긴 이익 중{' '}
                  <span className={st.bold}>배당하고 남은 금액 중 준비금으로 쌓아둔 돈</span>을 말해요. 이익준비금의
                  경우, <span className={st.bold}>자본금의 50%에 해당하는 금액까지만</span> 무상증자가 가능해요.
                </div>
              </div>
              <div className={st.qna}>
                <div className={st.question}>
                  <Icon type="question" className={st.icon} />
                  주식발행초과금이나 이익준비금이 없으면 무상증자를 못하나요?
                </div>
                <div className={st.answer}>
                  <Icon type="answer" className={st.icon} />
                  네. 주식발행초과금이나 이익준비금이 없으면 무상증자가 불가능해요. 이 경우 자본금을 늘리고 싶다면
                  유상증자를 해야 해요.
                </div>
              </div>
            </div>
          </QuestionTooltip>
        </div>
        <div style={{ width: '460px' }}>
          <RadioLabel
            className={qst.radio}
            name="agendaDetail.freeIssueOfNewSharesType"
            value="주식발행초과금"
            checked={props.agenda.agendaDetail.freeIssueOfNewSharesType === '주식발행초과금'}
            onChange={(e) => vm.freeIssueOfNewSharesType(e, props.agendaIndex)}
          >
            주식발행초과금을 무상증자하기
            <div className={qst.radioExplain}>할증발행한 적이 있는 경우</div>
          </RadioLabel>
          <RadioLabel
            className={qst.radio}
            name="agendaDetail.freeIssueOfNewSharesType"
            value="이익준비금"
            checked={props.agenda.agendaDetail.freeIssueOfNewSharesType === '이익준비금'}
            onChange={(e) => vm.freeIssueOfNewSharesType(e, props.agendaIndex)}
          >
            이익준비금을 무상증자하기
            <div className={qst.radioExplain}>영업이익이 있는 경우</div>
          </RadioLabel>
          <div>
            <Error
              name="agendaDetail.freeIssueOfNewSharesType"
              value={props.agenda.agendaDetail.freeIssueOfNewSharesType}
              errorCase={{
                required: '방식을 선택하세요.',
              }}
            />
          </div>
        </div>
      </div>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div style={{ marginBottom: '10px' }}>무상증자할 날짜를 입력하세요.</div>
        </div>
        <div>
          <DatePicker
            name="agendaDetail.increaseDate"
            selected={vm.increaseDate(props.agendaIndex)}
            onChange={(e) => vm.setIncreaseDate(e, props.agendaIndex)}
          />
          <button
            type="button"
            className={st.solidBtn}
            onClick={() => vm.increaseDateValidate(props.agendaIndex, agendaProgressId, corp)}
            style={{ marginLeft: '8px' }}
          >
            적용하기
          </button>
        </div>
        <div>
          <Error
            name="agendaDetail.increaseDate"
            value={vm.increaseDate(props.agendaIndex) || ''}
            errorCase={{
              required: '날짜를 입력하세요.',
            }}
          />
        </div>
      </div>
      {props.agenda.agendaDetail.freeIssueOfNewSharesType && props.agenda.agendaDetail.existsShareholderListYn && (
        <div className={clsx(qst.qusetion, st.inputs)}>
          <div className={qst.questionTitle}>
            <div style={{ marginBottom: '10px' }}>
              무상증자할 자본금을 입력하세요. <span style={{ fontWeight: '400' }}>(액면금 단위)</span>
            </div>
            <QuestionTooltip title="무상증자 최대한도액은?">
              <div className={st.tooltipContent}>
                <div className={st.exTitle}>주식발행초과금이 있는 경우, 그 전액을 무상증자할 수 있어요.</div>
                <div className={st.exContent}>주식발행초과금 액수는 담당 세무사에게 확인해 보시면 되어요.</div>
                <div className={st.exTitle}>이익준비금이 있는 경우, 자본금의 50%까지만 무상증자할 수 있어요.</div>
                <div className={st.exContent}>
                  만약 현재 자본금이 1,000만 원이라면, 500만 원만 무상증자할 수 있어요.
                </div>
              </div>
            </QuestionTooltip>
          </div>
          <div style={{ paddingBottom: vm.beforeIncreaseAmountValidate(props.agendaIndex).errorSize }}>
            <span className={st.inputTitle}>조정 전</span>
            <InputText
              name="agendaDetail.beforeIncreaseAmount"
              textAlign="right"
              placeholder="0"
              value={addCommas(props.agenda.agendaDetail.beforeIncreaseAmount)}
              onChange={(e) => vm.beforeIncreaseAmount(e, props.agendaIndex)}
              style={{ width: '220px' }}
              errorText={
                <Error
                  style={{ lineHeight: '130%', letterSpacing: '-1.1px' }}
                  name="agendaDetail.beforeIncreaseAmount"
                  value={addCommas(props.agenda.agendaDetail.beforeIncreaseAmount) || ''}
                  errorCase={{
                    required: '자본금을 입력하세요.',
                    validate: vm.beforeIncreaseAmountValidate(props.agendaIndex),
                  }}
                />
              }
            />
            <span className={st.inputSubText}>원</span>
            <button
              type="button"
              className={st.solidBtn}
              onClick={() => vm.getIncreaseAmountData(props.agendaIndex, agendaProgressId, corp)}
            >
              단주 조정하기
            </button>
          </div>
          {props.agenda.agendaDetail.info && props.agenda.agendaDetail.info.length !== 0 && (
            <div style={{ marginTop: '16px' }}>
              <span className={st.inputTitle}>조정 후</span>
              <Select
                name="agendaDetail.increaseAmount"
                value={props.agenda.agendaDetail.increaseAmount}
                onChange={(e) => vm.increaseAmount(e, props.agendaIndex)}
                style={{ width: '220px' }}
                errorText={
                  <Error
                    style={{ lineHeight: '130%', letterSpacing: '-1.1px' }}
                    name="agendaDetail.increaseAmount"
                    value={props.agenda.agendaDetail.increaseAmount || ''}
                    errorCase={{
                      required: '자본금을 선택하세요.',
                    }}
                  />
                }
              >
                <option value=""> - </option>
                {props.agenda.agendaDetail.info.map((info, index) => (
                  <option key={index} value={info.increaseAmount}>
                    {addCommas(info.increaseAmount)}
                  </option>
                ))}
              </Select>
              <span className={st.inputSubText}>원</span>
            </div>
          )}
        </div>
      )}
      {vm.pickInfo(props.agendaIndex) !== undefined && (
        <>
          <div className={qst.qusetion}>
            <div className={qst.questionTitle}>
              무상증자주식 배정표
              <div className={qst.titleSubInfo}>* 주식수는 소수점이 불가</div>
            </div>
            <table className={st.shareholdersTable}>
              <colgroup>
                <col width="190" />
                <col width="119" />
                <col width="154" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>주주명</th>
                  <th>지분율(=배당률)</th>
                  <th>신주식 수</th>
                  <th>무상증자할 주식수</th>
                </tr>
              </thead>
              <tbody>
                {vm.pickInfo(props.agendaIndex)?.shareholders.map((shareholder, index) => (
                  <tr key={index}>
                    <td>
                      <div className={st.indexWrap}>
                        <span className={st.index}>{index + 1}</span> {shareholder.name}
                      </div>
                    </td>
                    <td>{shareholder.rate} %</td>
                    {index === 0 && (
                      <td rowSpan={vm.pickInfo(props.agendaIndex)?.shareholders.length}>
                        {addCommas(vm.pickInfo(props.agendaIndex)?.numberOfNewStocks || '')}
                      </td>
                    )}
                    <td>{addCommas(shareholder.numberOfStocks)} 주</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={st.shareholdersSum}>
              <div className={st.rateSum}>
                100 <span style={{ fontWeight: '400' }}>%</span>
              </div>
              <div className={st.numberOfStocksSum}>
                <span style={{ fontWeight: '400' }}>총</span>{' '}
                {addCommas(vm.pickInfo(props.agendaIndex)?.numberOfNewStocks || '')}{' '}
                <span style={{ fontWeight: '400' }}>주</span>
              </div>
            </div>
          </div>
          <div className={qst.qusetion}>
            <div className={qst.questionTitle}>등기부상 자본금 변동</div>
            <ul className={st.sumList}>
              <li>
                <div className={st.title}>기존</div>
                <div className={st.num}>{addCommas(props.agenda.agendaDetail.capital)} 원</div>
              </li>
              <li>
                <div className={st.title}>무상증자</div>
                <div className={st.num}>+ {addCommas(Number(props.agenda.agendaDetail.increaseAmount))} 원</div>
              </li>
              <li>
                <div className={st.title}>신규</div>
                <div className={st.num}>
                  {addCommas(
                    Number(props.agenda.agendaDetail.capital) + Number(props.agenda.agendaDetail.increaseAmount),
                  )}{' '}
                  원
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  ));
});
export default BonusIssue;
