import FinalModel from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel';
import FinalService from '@service/Corp/Agenda/Step/FinalCheck/Final/FinalService';
import corpModel from '@model/Corp/corpModel';

class RegistrationAgencyVm {
  get stepsCompletedDate() {
    return FinalModel.stepsCompletedDate;
  }
  get documents() {
    return FinalModel.documents;
  }
  fileDown(url: string) {
    FinalService.fileDown(url);
  }
  get completed() {
    return FinalModel.completed;
  }
  get corpId() {
    return corpModel.id;
  }
  get registrationAgencyType() {
    return FinalModel.registrationAgencyType;
  }
}
export default new RegistrationAgencyVm();
