import { observable } from 'mobx';

const shareholderListModel = observable({
  representatives: [],
  shareholders: [],
  showRepresentativeNameSelectionYn: null,
  previewLink: '',
  minDate: '',
  setLoadSelectionInfo(data) {
    if (data.representatives && data.representatives.length !== 0) {
      this.representatives = data.representatives.map((item, idx) => {
        return {
          id: item.id || '',
          position: item.position || '',
          name: item.name || '',
        };
      });
    } else {
      this.representatives = [];
    }
    if (data.shareholders && data.shareholders.length !== 0) {
      this.shareholders = data.shareholders.map((item, idx) => {
        return {
          id: item.id || '',
          name: item.name || '',
          shareholderType: item.shareholderType || '',
          existsUniqueInfoYn: item.existsUniqueInfoYn ?? false,
          existsAddressYn: item.existsAddressYn ?? false,
          existsEmailYn: item.existsEmailYn ?? false,
          existBusinessRegistrationNumberYn: item.existBusinessRegistrationNumberYn ?? false,
          stocks: item.stocks || [],
          tag: item.tag || '',
          uniqueNumber: '',
          address: '',
          email: '',
        };
      });
    } else {
      this.shareholders = [];
    }
    this.showRepresentativeNameSelectionYn = data.showRepresentativeNameSelectionYn ?? null;
    this.minDate = data.minDate ?? null;
  },
  setPreviewLink(data) {
    this.previewLink = data?.previewLink || '';
  },
});

export default shareholderListModel;
