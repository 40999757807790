import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import formDataToJson from '@common/module/submit';
import { observable, runInAction } from 'mobx';
import regExp from '@common/module/regExp';
import { _alert } from '@model/dialogModel';

import type { SendDocumentType } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';
import portalModel from '@src/model/portalModel';

enum 소집통지_온라인 {
  발송전 = '발송전',
  발송중 = '발송중',
  메일발송 = '메일 발송 O',
  SMS발송 = '알림톡 발송 O',
  발송완료 = '메일&알림톡\n발송 O',
  발송실패 = '발송실패',
  발송실패_알림톡 = '발송실패\n(알림톡)',
  발송실패_메일 = '발송실패\n(메일)',
}

enum 신주발행통지 {
  발송전 = '발송전',
  발송중 = '발송중',
  메일발송 = '메일 발송 O',
  SMS발송 = '알림톡 발송 O',
  발송완료 = '메일&알림톡\n발송 O',
  발송실패 = '발송실패',
  발송실패_알림톡 = '발송실패\n(알림톡)',
  발송실패_메일 = '발송실패\n(메일)',
}

interface State {
  checkEmail: number[];
  checkAlimTalk: number[];
  errorYn: boolean;
  disabledYn: boolean;
}

class SendStatePopupVm {
  state: State = observable({
    checkEmail: [],
    checkAlimTalk: [],
    errorYn: false,
    disabledYn: false,
  });
  load(agendaProgressId: string, documentType: SendDocumentType) {
    documentService.loadShareholders(agendaProgressId, documentType);
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string, formData: FormData) {
    e.preventDefault();
    if (
      formDataToJson(e.target).error ||
      (this.state.checkEmail.length === 0 && this.state.checkAlimTalk.length === 0)
    ) {
      if (this.state.checkEmail.length === 0 && this.state.checkAlimTalk.length === 0) {
        this.state.errorYn = true;
      }
      return;
    } else {
      let param = formDataToJson(e.target).data as {
        shareholders: {
          id: number;
          email: string;
          phoneNumber: string;
        }[];
      };
      param.shareholders = param.shareholders.filter((elem) => elem.id !== undefined);

      let deleteKeys = [];
      for (let [key] of formData) {
        if (key.includes('shareholders')) {
          deleteKeys.push(key);
        }
      }
      if (deleteKeys) {
        deleteKeys.forEach((key) => formData.delete(key));
      }

      param.shareholders.forEach((shareholder, idx) => {
        formData.append(`shareholders[${idx}].id`, String(shareholder.id));
        if (shareholder.phoneNumber) {
          formData.append(`shareholders[${idx}].phoneNumber`, shareholder.phoneNumber);
        }
        if (shareholder.email) {
          formData.append(`shareholders[${idx}].email`, shareholder.email);
        }
      });

      try {
        this.state.disabledYn = true;
        await documentService.sendDocument(agendaProgressId, formData);
        this.state.disabledYn = false;
        await _alert('발송을 시작했어요.', '- 발송성공 여부는 확인을 누른 후 보이는 화면을 통해 확인하세요.');
        runInAction(() => {
          portalModel.title = '';
          portalModel.content = null;
        });
        window.location.reload();
      } catch {
        this.state.disabledYn = false;
      }
    }
  }
  get shareholders() {
    return documentModel.shareholders;
  }
  get emailAll() {
    const agreeCnt = documentModel.shareholders.length;
    if (agreeCnt === this.state.checkEmail.length) {
      return true;
    } else {
      return false;
    }
  }
  setEmailAll(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    if (value) {
      documentModel.shareholders.forEach((item) => {
        this.state.checkEmail.push(item.id);
      });
      this.state.errorYn = false;
    } else {
      this.state.checkEmail = [];
    }
  }
  get alimTalkAll() {
    const agreeCnt = documentModel.shareholders.length;
    if (agreeCnt === this.state.checkAlimTalk.length) {
      return true;
    } else {
      return false;
    }
  }
  setAlimTalkAll(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked;
    if (value) {
      documentModel.shareholders.forEach((item) => {
        this.state.checkAlimTalk.push(item.id);
      });
      this.state.errorYn = false;
    } else {
      this.state.checkAlimTalk = [];
    }
  }
  email(id: number) {
    if (this.state.checkEmail.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  setEmail(e: React.ChangeEvent<HTMLInputElement>, id: number) {
    if (e.target.checked) {
      if (!this.state.checkEmail.includes(id)) {
        this.state.checkEmail.push(id);
        this.state.errorYn = false;
      }
    } else {
      this.state.checkEmail = this.state.checkEmail.filter((item) => item !== id);
    }
  }
  alimTalk(id: number) {
    if (this.state.checkAlimTalk.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  setAlimTalk(e: React.ChangeEvent<HTMLInputElement>, id: number) {
    if (e.target.checked) {
      if (!this.state.checkAlimTalk.includes(id)) {
        this.state.checkAlimTalk.push(id);
        this.state.errorYn = false;
      }
    } else {
      this.state.checkAlimTalk = this.state.checkAlimTalk.filter((item) => item !== id);
    }
  }
  status(type: string, _status: string) {
    switch (type) {
      case '소집통지_온라인':
        return 소집통지_온라인[_status as keyof typeof 소집통지_온라인];
      case '신주발행통지':
        return 신주발행통지[_status as keyof typeof 신주발행통지];
    }
  }
  get stockTotal() {
    let cnt = 0;
    documentModel.shareholders.forEach((shareholder) => {
      shareholder.stocks.forEach((stock) => {
        cnt = cnt + stock.number;
      });
    });
    return cnt;
  }
  setShareholderEmail(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      documentModel.shareholders[idx].email = regExp.deleteSpace(e.target.value);
    });
  }
  setShareholderPhoneNumber(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      documentModel.shareholders[idx].phoneNumber = e.target.value;
    });
  }
}

export default SendStatePopupVm;
