import { makeObservable, observable } from 'mobx';
import type {
  agendaDashboardModelInterface,
  Company,
  agendaProgressSelect,
  AccessRoute,
  Files,
  AgendaProgress,
  RNumber,
  TransmissionStatus,
  Agenda,
  RegistrationAgencyType,
} from './AgendaDashboardModel.interface';

class agendaDashboardModel implements agendaDashboardModelInterface {
  constructor() {
    makeObservable(this, {
      accessRoute: observable,
      agendaProgressSelectList: observable,
      company: observable,
      selectedAgendaProgressId: observable,
      rNumbers: observable,
      files: observable,
      agendaProgress: observable,
      memo: observable,
      transmissionStatus: observable,
      agendas: observable,
      meetingScheduleChangeLockYn: observable,
      registrationAgencyType: observable,
    });
  }
  accessRoute: AccessRoute = null;
  agendaProgressSelectList: agendaProgressSelect[] = [];
  company: Company = {
    name: '',
    cssn: '',
    pastNames: [],
    copyOfRegister: {
      name: '',
      downloadUrl: '',
    },
  };
  selectedAgendaProgressId: string = '';
  rNumbers: RNumber[] = [];
  files: Files = {
    aoa: {
      downloadUrl: '',
    },
    shareholderList: {
      name: '',
      downloadUrl: '',
    },
    shareholderListDate: null,
  };
  agendaProgress: AgendaProgress = {
    company: {
      capital: 0,
      additionalCapital: 0,
      parValue: 0,
      shareholders: [],
    },
    registrationAgency: {
      jointCertificateUseYn: null,
      digitalCertificate: {
        company: {
          status: '',
          date: '',
        },
        shareholders: [],
      },
      requirements: '',
      regAgencyCancelInfo: '',
    },
    personInCharge: {
      name: '',
      phoneNum: '',
      email: '',
      telephoneCounselingYn: false,
    },
    boardOfDirectors: {
      before: false,
      after: false,
    },
    decisionBody: [],
  };
  memo: string = '';
  transmissionStatus: TransmissionStatus = {
    totalCount: 0,
    shareholderMeeting: {
      title: '',
      type: '',
      count: 0,
    },
    newStockAcquire: {
      title: '',
      type: '',
      count: 0,
    },
  };
  agendas: Agenda[] = [];
  meetingScheduleChangeLockYn: boolean = false;
  registrationAgencyType: RegistrationAgencyType = null;
}

export default new agendaDashboardModel();
