import { useState } from 'react';

import { useInternetAssociateStep2Query } from '../../queries';
import type {
  Products,
  SelectedProducts,
  SelectedProductType,
} from '../types';

export interface ProductsBundle {
  products: Products;
  selectedProducts: SelectedProducts;
  onSelectProduct(productType: SelectedProductType): void;
}

const useProducts = ({ corpId }: { corpId: number }): ProductsBundle => {
  const { data, isSuccess } = useInternetAssociateStep2Query({ corpId });

  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>([]);

  const onSelectProduct = (productType: SelectedProductType) =>
    setSelectedProducts((prev) => {
      const copy = [...prev];
      const index = copy.indexOf(productType);

      if (index === -1) {
        copy.push(productType);
      } else {
        copy.splice(index, 1);
      }

      return copy;
    });

  const products = isSuccess
    ? data.products
    : {
        INTERNET: [],
        TELEPHONE: [],
        TELEPHONE_NUMBER: [],
        TV: [],
      };

  return {
    products,
    selectedProducts,
    onSelectProduct,
  };
};

export default useProducts;
