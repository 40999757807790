import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import { useObserver } from 'mobx-react';

import st from './DelegationPopup.module.scss';
import vm from './DelegationPopupVm';

function DelegationPopup() {
  return useObserver(() => (
    <div className={st.delegationPopup}>
      {vm.acctFirmCode === 'JINPYUNG' && (
        <div className={st.advertisement}>
          <div className={st.title}>진평회계법인</div>
          <div className={st.jinpyeongBox}>
            <img className={st.img} src="/images/진평_example.png" />
            <div className={st.jinpyeong}>
              <div className={st.jinpyeongTitle}>
                서울대 경영대 출신의 회계사들이 만든 <span className={st.bold}>스타트업 전문 회계법인</span>
              </div>
              <div className={st.jinpyeongInfo}>
                <span className={st.boldColor}>서울대 경영대</span> 출신 경력 14년 회계사가 직접 관리
              </div>
              <div className={st.jinpyeongInfo}>
                절세를 고려한 <span className={st.boldColor}>최적의 비용처리 및 세액감면/공제 적용</span>
              </div>
              <div className={st.jinpyeongInfo}>
                <span className={st.boldColor}>투자유치, 상장</span>을 대비한 지분구조 및 회계처리
              </div>
              <img src="/images/logo_진평회계법인.svg" className={st.jinpyeongLogoImg} />
            </div>
          </div>
        </div>
      )}
      {vm.acctFirmCode === 'BARUN' && (
        <div className={st.advertisement}>
          <div className={st.title}>세무회계 바른</div>
          <div className={st.jinpyeongBox}>
            <img className={st.img} src="/images/바른_example.png?1" />
            <div className={st.jinpyeong}>
              <div className={st.jinpyeongTitle}>
                삼일회계법인 출신 스타트업 전문 회계사들이 만든, <span className={st.bold}>세무회계 바른</span>
              </div>
              <div className={st.jinpyeongInfo}>
                스타트업 생애주기별 <span className={st.boldColor}>맞춤형 기장대리 서비스 제공</span>
              </div>
              <div className={st.jinpyeongInfo}>
                누적 3,000여 고객사와의 풍부한 경험을 바탕으로{' '}
                <span className={st.boldColor}>고품질 세무컨설팅 제공</span>
              </div>
              <div className={st.jinpyeongInfo}>
                절세를 고려한 <span className={st.boldColor}>최적의 경비처리 및 세액감면/공제 적용</span>
              </div>
              <img src="/images/logo_바른회계.svg" className={st.barunLogoImg} />
            </div>
          </div>
        </div>
      )}
      <div className={st.agreeInfo}>
        {vm.acctFirmCode === 'JINPYUNG' && (
          <div className={st.question}>
            진평회계법인에서 기장계약 이용등록 요청을 했어요.
            <br />
            등록을 하면, 앞으로 기장서비스는 스탠바이 시스템을 통해 제공돼요.
          </div>
        )}
        {vm.acctFirmCode === 'BARUN' && (
          <div className={st.question}>
            세무회계 바른에서 기장계약 이용등록 요청을 했어요.
            <br />
            등록을 진행할까요?
          </div>
        )}
        <Check className={st.checkBox} name="agreeYn" value={vm.state.agreeYn} onChange={(e) => vm.setAgreeYn(e)}>
          {vm.acctFirmCode === 'JINPYUNG' && '진평회계법인에 대한 '}
          {vm.acctFirmCode === 'BARUN' && '세무회계 바른에 대한 '}
          <button type="button" className={st.agreeModal} onClick={() => vm.agreeModal(vm.acctFirmCode)}>
            정보제공 동의
          </button>
        </Check>
      </div>
      <div className={st.btnArea}>
        <SquareBtn className={st.registerBtn} onClick={() => vm.register()}>
          등록 진행하기
        </SquareBtn>
      </div>
    </div>
  ));
}

export default DelegationPopup;
