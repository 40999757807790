import Form from './components/Form';
import ProductSelectionContainer from './components/ProductSelectionContainer';
import st from './style.module.scss';
import useStep2 from './useStep2';

const Step2 = () => {
  const { termBundle, consultInfoInputBundle, productsBundle, productsDetailBundle, orderSummaryBundle, formBundle } =
    useStep2();

  return (
    <>
      <div className={st.mainTitle}>
        필요하신 <span className={st.bold}>인터넷 · 전화 상품</span>을 <span className={st.bold}>선택</span>하세요.
      </div>
      <div className={st.mainContent}>
        <ProductSelectionContainer {...productsBundle} />
        <Form
          termBundle={termBundle}
          consultInfoInputBundle={consultInfoInputBundle}
          productsBundle={productsBundle}
          productsDetailBundle={productsDetailBundle}
          orderSummaryBundle={orderSummaryBundle}
          formBundle={formBundle}
        />
      </div>
    </>
  );
};

export default Step2;
