import regExp from '@common/module/regExp';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';

import type { ConsultInfoInputBundle, TermBundle } from '../../../../useStep2';
import st from '../../style.module.scss';

type Props = TermBundle & ConsultInfoInputBundle & { submissionRejected: boolean };

function ConsultInfoForm(props: Props) {
  return (
    <div className={clsx(st.consultInfo, st.box)}>
      <div className={st.itemTitle} data-type="상담정보">
        상담정보
      </div>
      <div className={st.inputRows}>
        <div>
          <label className={clsx(st.inputTitle, st.required)} htmlFor="consultInfo.name">
            이름
          </label>
        </div>
        <InputText
          style={{ width: '452px' }}
          placeholder="홍길동"
          name="consultInfo.name"
          id="consultInfo.name"
          value={props.consultInfoInput.name}
          onChange={(e) => props.setConsultInfo('name', e.target.value)}
          errorText={
            <Error
              name="consultInfo.name"
              value={props.consultInfoInput.name}
              errorCase={{
                required: '이름을 입력해 주세요.',
              }}
            />
          }
        />
      </div>
      <div className={st.inputRows}>
        <div>
          <label className={clsx(st.inputTitle, st.required)} htmlFor="consultInfo.phoneNumber">
            전화번호
          </label>
        </div>
        <InputText
          style={{ width: '452px' }}
          placeholder="01012341234"
          name="consultInfo.phoneNumber"
          id="consultInfo.phoneNumber"
          value={props.consultInfoInput.phoneNumber}
          onChange={(e) => props.setConsultInfo('phoneNumber', e.target.value)}
          errorText={
            <Error
              name="consultInfo.phoneNumber"
              value={props.consultInfoInput.phoneNumber}
              errorCase={{
                required: '전화번호를 입력해 주세요.',
                pattern: {
                  value: regExp.phoneNumber().test(props.consultInfoInput.phoneNumber),
                  message: '올바른 전화번호를 입력해 주세요.',
                },
              }}
            />
          }
        />
      </div>
      <div className={st.inputRows}>
        <div>
          <label className={clsx(st.inputTitle, st.required)} htmlFor="consultInfo.email">
            이메일
          </label>
        </div>
        <InputText
          style={{ width: '452px' }}
          name="consultInfo.email"
          id="consultInfo.email"
          value={props.consultInfoInput.email}
          onChange={(e) => props.setConsultInfo('email', e.target.value)}
          errorText={
            <Error
              name="consultInfo.email"
              value={props.consultInfoInput.email}
              errorCase={{
                required: '이메일을 입력하세요.',
                pattern: {
                  value: regExp.email().test(props.consultInfoInput.email),
                  message: '올바른 이메일 형식으로 입력해 주세요.',
                },
              }}
            />
          }
        />
      </div>
      <div className={st.inputRows}>
        <div>
          <label className={st.inputTitle} htmlFor="consultInfo.address">
            설치주소
          </label>
        </div>
        <InputText
          fullSize
          name="consultInfo.address"
          id="consultInfo.address"
          value={props.consultInfoInput.address}
          onChange={(e) => props.setConsultInfo('address', e.target.value)}
        />
      </div>
      <div className={st.inputRows}>
        <div>
          <label className={st.inputTitle} htmlFor="consultInfo.content">
            문의내용
          </label>
        </div>
        <TextArea
          className={st.textarea}
          fullSize
          placeholder="궁금한 내용을 편하게 입력해 주세요!"
          name="consultInfo.content"
          id="consultInfo.content"
          value={props.consultInfoInput.content}
          onChange={(e) => props.setConsultInfo('content', e.target.value)}
        />
      </div>
      <CheckBox
        checked={props.allAgreed}
        onChange={(e) => props.agreeAllTerms(e.target.checked)}
        fontSize="15px"
        size="large"
      >
        모두 동의합니다.
      </CheckBox>
      <ul className={st.termsList}>
        {props.termInfos.map((item, idx) => {
          return (
            <li key={idx}>
              <input type="hidden" name={`awsApplicationTerms[${idx}].id`} value={item.id} />
              <CheckBox
                name={`awsApplicationTerms[${idx}].agreeYn`}
                value={String(item.agreeYn)}
                checked={item.agreeYn}
                onChange={(e) => props.agreeTermByIndex(e.target.checked, idx)}
                className={st.check}
                checkIconClassName={st.checkIcon}
                textClassName={st.radioText}
              >
                <span className={st.notRequired}>{item.required === true && '(필수) '}</span>
                {item.title}
              </CheckBox>
              {item.content && (
                <TextBtn
                  className={st.textBtn}
                  onClick={() =>
                    props.openTermDetailPopup(
                      item.title,
                      <div
                        className={clsx(st.agreeContent, 'terms')}
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />,
                    )
                  }
                >
                  상세보기
                </TextBtn>
              )}
            </li>
          );
        })}
      </ul>
      {props.submissionRejected && !props.isRequiredTermsAgreed && (
        <ErrorText className={st.errorText}>필수 약관에 동의해주세요.</ErrorText>
      )}
    </div>
  );
}

export default ConsultInfoForm;
