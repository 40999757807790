import { post, get, put, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import articlesOfAssociationModel from '@model/Corp/ArticlesOfAssociation/articlesOfAssociationModel';
import corpModel from '@model/Corp/corpModel';

class articlesOfAssociationService {
  async load() {
    const res = await get(`/company/${corpModel.id}/articlesOfAssociation`);
    articlesOfAssociationModel.setArticlesOfAssociation(res.data);
  }
  async downLoadFile(corpId) {
    await downloadFileGet(`/company/${corpId}/articlesOfAssociation/download`);
  }
  async articlesOfAssociationChange(param) {
    await put(`/company/${corpModel.id}/articlesOfAssociation`, param);
  }
}
export default new articlesOfAssociationService();
