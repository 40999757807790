import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class CompanyNameVm {
  get dashboardData() {
    return DashboardModel.dashboardData;
  }
  agendaLinkModal() {
    runInAction(() => {
      portalModel.title = '상호 변경하기';
      portalModel.content = <AgendaLinkPopup agendaType="상호" />;
    });
  }
}
export default new CompanyNameVm();
