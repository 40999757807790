import { makeObservable, observable } from 'mobx';
import type { MemoWriteModelInterface, Template } from './MemoWriteModel.interface';

class MemoWriteModel implements MemoWriteModelInterface {
  constructor() {
    makeObservable(this, {
      memo: observable,
      templates: observable,
      boldActive: observable,
    });
  }
  memo: string = '';
  templates: Template[] = [];
  boldActive: boolean = false;
}

export default new MemoWriteModel();
