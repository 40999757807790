import { runInAction, observable, toJS } from 'mobx';
import authBusinessRegistrationService from '@service/authBusinessRegistrationService.js';

class RouterVm {
  async load(token) {
    await authBusinessRegistrationService.businessRegistrationRouter(token);
  }
}

export default new RouterVm();
