import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './StepPageVm';
import st from './StepPage.module.scss';
import clsx from 'clsx';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import { addCommas } from '@common/module/replaceNumber';

function StepPage() {
  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <>
      <ContentBox
        title="부가세 신고 현황"
        titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
        className={st.contentBox}
        titleComponent={
          <select className={st.select} value={vm.state.vatId} onChange={(e) => vm.setVatList(e)}>
            {vm.vatList.length === 0 && <option vallue="">-</option>}
            {vm?.vatList.map((item, idx) => (
              <option key={idx} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        }
      >
        <div className={st.content}>
          {vm.steps.length !== 0 && vm.reportingExemptYn === false && (
            <>
              <div className={st.reportingPeriod}>
                신고기간 :{' '}
                <span className={st.date}>
                  {vm.reportingPeriod?.year}년 {vm.reportingPeriod?.month}월 {vm.reportingPeriod?.day}일까지 신고 및
                  납부
                </span>
              </div>
              <div className={st.stepInfoBox}>
                <img src={`/images/${vm.nowStep}.svg`} className={st.stepImg} />
                {vm?.steps.map((step, idx) => {
                  if (step.name !== '부가세_안내문_발송전') {
                    return (
                      <div key={idx} className={st.stepInfo}>
                        {step.name === '부가세_자료제출_대기중' ? (
                          <>
                            <div className={clsx(st.step2, st.collection)}>{step.content} 수집시작</div>
                            <div className={clsx(st.step2, st.waiting)}>{step.content} 제출요청</div>
                          </>
                        ) : (
                          <div
                            className={clsx(
                              step.name === '부가세_신고중' ? st.step3 : null,
                              step.name === '부가세_신고완료' ? st.step4 : null,
                            )}
                          >
                            <span>{step.content}</span>
                            <span className={st.text}>
                              {step.name === '부가세_신고중' && '신고진행'}
                              {step.name === '부가세_신고완료' && '신고완료'}
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
          {vm.steps.length !== 0 &&
            vm.reportingExemptYn && ( //예정신고 면제된 경우
              <>
                <div className={st.reportingExemptPeriod}>
                  납부기한 : {vm.reportingPeriod?.year}년 {vm.reportingPeriod?.month}월 {vm.reportingPeriod?.day}일까지
                  납부
                  <div className={st.reportingExemptExplain}>
                    마지막 6개월 매출이 1.5억 원 미만인 경우 예정신고는 안해도 되어요.
                    <a href="https://standby.kr/blog/article/부가세예정신고면제/" target="_blank">
                      부가세 예정신고 면제란?
                    </a>
                  </div>
                </div>
                <div className={st.reportingExemptyStepInfoBox}>
                  <img src={`/images/예정신고_면제.svg`} className={st.stepImg} />
                  {vm?.steps.map((step, idx) => {
                    if (step.name === '부가세_납부_안내') {
                      return (
                        <div key={idx} className={st.stepInfo}>
                          {step.content} 안내 완료
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            )}
          {vm.steps.length === 0 && (
            <>
              <div className={st.reportingPeriod}>
                신고기간 :{' '}
                <span className={st.date}>
                  {vm.reportingPeriod?.year}년 {vm.reportingPeriod?.month}월 {vm.reportingPeriod?.day}일까지 신고 및
                  납부
                </span>
              </div>
              <div className={st.infoBox}>
                <img src="/images/stepInfo_check_icon.svg" />
                <div className={st.periodInfo}>
                  {vm.displayReportPeriodInfo}
                  <div className={st.explian}>
                    마지막 6개월 매출이 1.5억 원 미만이고, 납부 부가세액이 100만 원 미만인 경우에 해당
                  </div>
                </div>
                <a
                  href="https://standby.kr/blog/article/%EB%B6%80%EA%B0%80%EC%84%B8%EC%98%88%EC%A0%95%EC%8B%A0%EA%B3%A0%EB%A9%B4%EC%A0%9C/"
                  target="_blank"
                  className={st.infoExemptionLink}
                >
                  부가세 예정신고 면세란?
                </a>
              </div>
            </>
          )}
        </div>
      </ContentBox>
      {vm.displayMaterial && (
        <ContentBox
          title={vm.preparationData.title}
          titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          className={st.contentBox}
        >
          <div className={st.content}>
            <div className={st.material}>
              <div className={st.title}>1. 부가세 신고를 위해 아래 자료를 준비하세요!</div>
              <a className={st.materialLink} href={vm.preparationData.returnReadyDocumentUrl} download>
                <span className={st.btnTitle}>부가세 신고 준비자료</span>
                <span className={st.companyName}>{vm.companyName}</span>
              </a>
            </div>
            <div className={st.material}>
              <div className={st.title}>2. 준비된 자료를 보내주세요!</div>
              <div className={st.deadline}>
                제출 기한 : <span className={st.date}>{vm.preparationData?.submissionDeadline}</span>
              </div>
              <div className={st.deadlineExplain}>제출기한 연장이 필요한 경우, 카톡으로 알려주세요. (2일 연장가능)</div>
              <div className={st.reception}>
                제출처 : <span className={st.email}>{vm.preparationData?.email}</span>로 제출
              </div>
            </div>
            <div className={st.vatQuestion}>
              부가세 신고, 궁금한 점이 있으신가요?
              <div>
                <a
                  href="https://standby.kr/blog/article/%EB%B6%80%EA%B0%80%EC%84%B8%EC%9D%98%EB%AA%A8%EB%93%A0%EA%B2%83/"
                  target="_blank"
                >
                  부가세의 모든 것 A-Z
                </a>
              </div>
            </div>
          </div>
        </ContentBox>
      )}
      {vm.vatData && vm.vatData.length !== 0 && (
        <ContentBox
          title="부가세 신고 및 납부자료"
          titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          className={st.contentBox}
        >
          <div className={st.content}>
            {vm.vatData?.map((item, idx) => (
              <div key={idx} className={st.vatTaxesBox}>
                <div className={st.vatTaxesTitle}>
                  {item.reportingPeriod}
                  {item.reportingExemptYn && (
                    <div className={st.vatTaxesTitleExplain}>신고의무 면제, 납부의무만 발생</div>
                  )}
                </div>
                {item.card?.estimatedAmountOfNotice?.amount || item.card?.estimatedAmountOfNotice?.amount === 0 ? (
                  <div className={st.estimatedAmountOfNoticeBox}>
                    <div
                      className={clsx(
                        st.estimatedAmountOfNotice,
                        vm.highlight(idx, '예정고지액') ? st.highlight : null,
                      )}
                    >
                      <div className={st.taxName}>예정고지액</div>
                      <div className={st.price}>
                        {item.card?.estimatedAmountOfNotice?.amount === 0
                          ? 0
                          : addCommas(item.card?.estimatedAmountOfNotice?.amount)}
                        원
                      </div>
                    </div>
                    <div
                      className={clsx(
                        st.periodExplain,
                        item.reportProgressStatus === '예정신고X_예정고지X' ? st.marginTop : null,
                      )}
                    >
                      {item.card?.estimatedAmountOfNotice?.content}
                    </div>
                    {item.reportProgressStatus === '예정신고X_예정고지O' && (
                      <div className={st.homeTax}>
                        예정고지액은 홈택스에서 바로 납부 가능해요!
                        <br />
                        <a
                          className={st.homeTaxLink}
                          href="https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index.xml"
                          target="_blank"
                        >
                          홈택스 바로가기
                        </a>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={st.vatTaxes}>
                    <div>
                      <div className={st.vatDataBox}>
                        <div className={st.vatDataInfo}>
                          <div className={st.vatTax}>
                            <div className={st.taxName}>매출 부가세</div>
                            <div className={st.price}>{addCommas(item.card?.salesTax)}원</div>
                          </div>
                          <div className={st.vatTax}>
                            <div className={st.taxName}>매입 부가세</div>
                            <div className={st.price}>-{addCommas(item.card?.purchaseTax)}원</div>
                          </div>
                          {item.card?.sumOfTheDeductibles && (
                            <div className={st.vatTax}>
                              <div className={st.taxName}>공제액 합계</div>
                              <div className={st.price}>-{addCommas(item.card?.sumOfTheDeductibles)}원</div>
                            </div>
                          )}
                          {item.card?.additionalTaxPayable && (
                            <div className={st.vatTax}>
                              <div className={st.taxName}>추가 납부세액</div>
                              <div className={st.price}>+{addCommas(item.card?.additionalTaxPayable)}원</div>
                            </div>
                          )}
                        </div>
                        <div className={st.totalVatDataInfo}>
                          <div className={clsx(st.totalVatData, vm.highlight(idx, '납부세액') ? st.highlight : null)}>
                            <div className={st.taxName}>납부세액</div>
                            <div className={st.price}>{addCommas(item.card?.amountOfTaxPaid)}원</div>
                          </div>
                          {vm.displayRefundTaxAmount(idx) && (
                            <div className={clsx(st.totalVatData, vm.highlight(idx, '환급세액') ? st.highlight : null)}>
                              <div className={st.taxName}>환급세액</div>
                              <div className={st.price}>{addCommas(item.card?.refundTaxAmount)}원</div>
                            </div>
                          )}
                          {item.card?.content && <div className={st.dateExplain}>{item.card?.content}</div>}
                        </div>
                        {item?.card?.statementOfPaymentUrl && (
                          <FileBtn
                            btnType="important"
                            className={st.fileBtn}
                            onClick={() => vm.fileDownload(item?.card?.statementOfPaymentUrl)}
                          >
                            부가세 납부서
                          </FileBtn>
                        )}
                      </div>
                      {item.reportProgressStatus === '예정신고O' && item.earlyRefoundYn === false && (
                        <div className={st.earlyRefoundExplain}>
                          환급세액 {addCommas(item.card?.refundTaxAmount)}원은{' '}
                          {vm.vatReportPeriodType(item.vatReportPeriodType)} 확정 신고를 할 때 반영되어요.
                        </div>
                      )}
                    </div>
                    {!item.card?.estimatedAmountOfNotice?.amount && (
                      <div className={st.vatFile}>
                        <div
                          className={st.thumbnailFile}
                          style={{ backgroundImage: `url(${item?.card?.statementOfVatReturn?.thumbnailUrl})` }}
                        ></div>
                        <FileBtn
                          btnType="important"
                          className={st.fileBtn}
                          onClick={() => vm.fileDownload(item?.card?.statementOfVatReturn?.downloadUrl)}
                        >
                          부가세 신고서
                        </FileBtn>
                      </div>
                    )}
                    {item.card?.purchaseAndSalesLedgerFile && (
                      <div className={st.vatFile}>
                        <div
                          className={st.thumbnailFile}
                          style={{ backgroundImage: `url(${item?.card?.purchaseAndSalesLedgerFile?.thumbnailUrl})` }}
                        ></div>
                        <FileBtn
                          btnType="important"
                          className={st.fileBtn}
                          onClick={() => vm.fileDownload(item?.card?.purchaseAndSalesLedgerFile?.downloadUrl)}
                        >
                          매입매출장
                        </FileBtn>
                      </div>
                    )}
                    {item.card?.etcFile && (
                      <div className={st.vatFile}>
                        <div
                          className={st.thumbnailFile}
                          style={{ backgroundImage: `url(${item?.card?.etcFile?.thumbnailUrl})` }}
                        ></div>
                        <FileBtn
                          btnType="important"
                          className={st.fileBtn}
                          onClick={() => vm.fileDownload(item?.card?.etcFile?.downloadUrl)}
                        >
                          부가세 관련 기타서류
                        </FileBtn>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </ContentBox>
      )}
    </>
  ));
}
export default StepPage;
