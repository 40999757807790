import { observable } from 'mobx';

const articlesOfAssociationModel = observable({
  guides: [],
  updatedAt: {
    year: '',
    month: '',
    day: '',
  },
  registerStatus: null,
  articlesOfAssociation: [],
  histories: [],
  importantContents: {
    결산기간: '',
    제3자_신주발행규정Yn: null,
    스톡옵션_규정: '',
    우선주_발행_근거규정Yn: null,
    제3자_주식_양도_제한Yn: null,
  },
  setArticlesOfAssociation(data) {
    this.guides = data?.guides.map((guide) => {
      return {
        title: guide?.title || '',
        contents: guide?.contents || '',
        url: guide?.url || '',
      };
    });
    this.updatedAt = {
      year: data?.updatedAt?.year || '',
      month: data?.updatedAt?.month || '',
      day: data?.updatedAt?.day || '',
    };
    this.registerStatus = data?.registerStatus || null;
    this.articlesOfAssociation = data?.articlesOfAssociations.map((articlesOfAssociation) => {
      return {
        category: articlesOfAssociation?.category || '',
        questions: articlesOfAssociation?.questions.map((question) => {
          return {
            title: question?.title || '',
            content: question?.content || '',
          };
        }),
      };
    });
    this.histories = data?.histories.map((history) => {
      return {
        date: history.date || '',
        title: history.title || '',
      };
    });
    this.importantContents.결산기간 = data?.importantContents?.결산기간 || '';
    this.importantContents.제3자_신주발행규정Yn = data?.importantContents?.제3자_신주발행규정Yn ?? null;
    this.importantContents.스톡옵션_규정 = data?.importantContents?.스톡옵션_규정 || '';
    this.importantContents.우선주_발행_근거규정Yn = data?.importantContents?.우선주_발행_근거규정Yn ?? null;
    this.importantContents.제3자_주식_양도_제한Yn = data?.importantContents?.제3자_주식_양도_제한Yn ?? null;
  },
});

export default articlesOfAssociationModel;
