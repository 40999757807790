import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './Registering.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';

function Registering() {
  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>사업자등록</span>
      </PageTitle>
      <div className={st.imgBox}>
        <img src="/images/businessRegistration_example.png" />
        <div className={st.explain}>
          지금 사업자등록 정보를 입력하고 있어요.
          <br />
          입력을 마치고 카톡으로 알림을 드릴게요!
          <div className={st.subExplain}>최대 1영업일</div>
        </div>
      </div>
    </>
  ));
}
export default Registering;
