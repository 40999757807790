import { observable } from 'mobx';

const expiredInvitedMembershipModel = observable({
  invitedDate: '',
  expiredDate: '',
  setDate(data) {
    this.invitedDate = data.invitedDate || '';
    this.expiredDate = data.expiredDate || '';
  },
});

export default expiredInvitedMembershipModel;
