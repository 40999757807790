import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import InternetAssociateService from './service/InternetAssociateService';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function InternetAssociate() {
  const location = useLocation();
  const corpId = queryString.parse(location.search).corp;
  const navigate = useNavigate();

  useEffect(() => {
    const loadStep = async () => {
      const step = await InternetAssociateService.loadStep();

      switch (step) {
        case 'STEP1':
          navigate(`/corp/internetAssociate/promotion?corp=${corpId}`, { replace: true });
          break;
        case 'STEP2':
          navigate(`/corp/internetAssociate/application?corp=${corpId}`, { replace: true });
          break;
        case 'STEP3':
          navigate(`/corp/internetAssociate/result?corp=${corpId}`, { replace: true });
          break;
      }
    };

    if (location.pathname === '/corp/internetAssociate') {
      //처음 진입 했을때만 실행함
      void loadStep();
    }
  }, [location.pathname, corpId, navigate]);

  return useObserver(() => (
    <>
      <Routes>
        <Route path="/promotion" element={<Step1 />} />
        <Route path="/application" element={<Step2 />} />
        <Route path="/result" element={<Step3 />} />
      </Routes>
    </>
  ));
}

export default InternetAssociate;
