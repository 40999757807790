import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './FirstPage.module.scss';

function FirstPage() {
  return useObserver(() => (
    <>
      <img src="/images/vatTax_first_page.png?1" className={st.image} />
    </>
  ));
}
export default FirstPage;
