import corpModel from '@model/Corp/corpModel';

import useConsultInfoInput, { ConsultInfoInputBundle } from './useConsultInfoInput';
import useForm, { FormBundle } from './useForm';
import useOrderSummary, { OrderSummaryBundle } from './useOrderSummary';
import useProducts, { ProductsBundle } from './useProducts';
import useProductDetail, { ProductsDetailBundle } from './useProductsDetail';
import useTerm, { TermBundle } from './useTerm';

interface Step2Bundle {
  termBundle: TermBundle;
  productsBundle: ProductsBundle;
  productsDetailBundle: ProductsDetailBundle;
  consultInfoInputBundle: ConsultInfoInputBundle;
  orderSummaryBundle: OrderSummaryBundle;
  formBundle: FormBundle;
}

const useStep2 = (): Step2Bundle => {
  const corpId = corpModel.id;

  if (!corpId) {
    throw new Error(`corpId is ${corpId}`);
  }

  const termBundle = useTerm();
  const productsBundle = useProducts({ corpId });
  const productsDetailBundle = useProductDetail({ corpId });
  const consultInfoInputBundle = useConsultInfoInput({ corpId });
  const orderSummaryBundle = useOrderSummary({
    products: productsBundle.products,
    selectedProducts: productsBundle.selectedProducts,
    selectedProductsDetail: productsDetailBundle.selectedProductsDetail,
  });

  const { selectedProducts } = productsBundle;
  const { selectedProductsDetail } = productsDetailBundle;
  const { consultInfoInput } = consultInfoInputBundle;
  const { termInfos, isRequiredTermsAgreed } = termBundle;

  const formBundle = useForm({
    selectedProducts,
    selectedProductsDetail,
    termInfos,
    consultInfoInput,
    isRequiredTermsAgreed,
  });

  return {
    termBundle,
    productsBundle,
    productsDetailBundle,
    consultInfoInputBundle,
    orderSummaryBundle,
    formBundle,
  };
};

export type {
  ConsultInfoInputBundle,
  FormBundle,
  OrderSummaryBundle,
  ProductsBundle,
  ProductsDetailBundle,
  Step2Bundle,
  TermBundle,
};
export default useStep2;
