import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import portalModel from '@model/portalModel';
import { runInAction, observable } from 'mobx';
import { _alert } from '@model/dialogModel';
import AgreeStatePopup from '@pages/Corp/Popup/AgreeStatePopup/AgreeStatePopup';
import formDataToJson from '@common/module/submit';

import type {
  LoadDocumentParam,
  I주주동의서,
} from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';

interface NewStockAcquirerInfo {
  assignmentType: '제3자' | '주주' | null;
  isExistsAbandonedStock: boolean;
}

interface State {
  options: I주주동의서;
}

class DocumentVm {
  state: State = observable({
    options: {
      fontSize: 'MEDIUM',
      contentDetailYn: false,
    },
  });
  load(agendaProgressId: string, newStockAcquirerInfo: NewStockAcquirerInfo) {
    const param = {
      documentType: this.documentType(newStockAcquirerInfo),
      options: this.state.options,
    } as LoadDocumentParam;
    documentService.loadDocument(agendaProgressId, param);
  }
  next(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string, newStockAcquirerInfo: NewStockAcquirerInfo) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param_formData = new FormData(e.target as HTMLFormElement);

      param_formData.append('documentType', this.documentType(newStockAcquirerInfo) as string);

      runInAction(() => {
        portalModel.title = '2. 발송할 주주를 선택하세요.';
        portalModel.content = (
          <AgreeStatePopup
            agendaProgressId={agendaProgressId}
            documentType={this.documentType(newStockAcquirerInfo)}
            statusType="주주동의_주주총회_신주발행"
            formData={param_formData}
          />
        );
      });
    }
  }
  get previewLink() {
    return documentModel.previewLink;
  }
  documentType(_newStockAcquirerInfo: NewStockAcquirerInfo) {
    const newStockAcquirerInfo = _newStockAcquirerInfo;
    if (
      newStockAcquirerInfo.assignmentType === '제3자' ||
      (newStockAcquirerInfo.assignmentType === '주주' && newStockAcquirerInfo.isExistsAbandonedStock === false)
    ) {
      return '주주총회생략동의_및_신주발행_동의서';
    } else if (newStockAcquirerInfo.assignmentType === '주주' && newStockAcquirerInfo.isExistsAbandonedStock) {
      return '주주총회생략동의_및_신주식_인수포기_및_기간단축_동의서';
    } else {
      return null;
    }
  }
  setFontSize(
    e: React.ChangeEvent<HTMLInputElement>,
    agendaProgressId: string,
    newStockAcquirerInfo: NewStockAcquirerInfo,
  ) {
    this.state.options.fontSize = e.target.value;
    this.load(agendaProgressId, newStockAcquirerInfo);
  }
  setContentDetailYn(
    e: React.ChangeEvent<HTMLInputElement>,
    agendaProgressId: string,
    newStockAcquirerInfo: NewStockAcquirerInfo,
  ) {
    this.state.options.contentDetailYn = e.target.value === 'true';
    this.load(agendaProgressId, newStockAcquirerInfo);
  }
}

export default DocumentVm;
