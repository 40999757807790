import { makeObservable, observable } from 'mobx';
import type { CancelRegModelInterface } from './CancelRegModel.interface';

class CancelRegModel implements CancelRegModelInterface {
  constructor() {
    makeObservable(this, {
      name: observable,
      reason: observable,
      sendAlimtalkYn: observable,
    });
  }
  name: string = '';
  reason: string = '';
  sendAlimtalkYn: boolean = false;
}

export default new CancelRegModel();
