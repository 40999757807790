import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';
import onlineMusterModel from '@model/Corp/Stock/OnlineMuster/onlineMusterModel';
import onlineMusterService from '@service/Corp/Stock/OnlineMuster/onlineMusterService';

class OnlineMusterPopupVm {
  get id() {
    return corpModel.id;
  }
  closePopup() {
    portalModel.content = null;
  }
}

export default new OnlineMusterPopupVm();
