import { useObserver } from 'mobx-react';
import NotiOfDraftVm from './NotiOfDraftVm';
import clsx from 'clsx';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './NotiOfDraft.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';

interface NotiOFDraftProps {
  index: number;
}

function NotiOfDraft(props: NotiOFDraftProps) {
  return useObserver(() => (
    <div className={qst.questionForm} style={{ marginBottom: '36px' }}>
      <div className={qst.qusetion}>
        {NotiOfDraftVm.hasCapitalOverBillionYn && (
          <div className={st.writtenResolutionMethod}>
            <div className={st.title}>
              <span className={st.bold}>자본금 10억 원</span>을 넘기 때문에,
              <br />
              <span className={st.bold}>우편 소집통지 방식</span>으로 진행해 드릴게요.
            </div>
            <div className={st.explain}>다음 페이지에서 [소집통지문 자동작성]을 할 수 있어요!</div>
          </div>
        )}
        {NotiOfDraftVm.hasCapitalOverBillionYn === false && (
          <>
            <div className={qst.questionTitle}>
              <div style={{ marginBottom: '10px' }}>주주총회 절차를 선택하세요.</div>
              <QuestionTooltip title="소집통지를 생략하는 꿀팁">
                <div className={st.tooltipContent}>
                  <div className={st.qna}>
                    <div className={st.title}>
                      자본금 10억 미만이라면, 모든 주주가 동의시 소집통지를 생략할 수 있어요.
                    </div>
                    <div className={st.content}>
                      <ul>
                        <li>
                          원칙상 정관에 따라 소집통지를 보내고, 10~14일을 기다린 후 주주총회를 열 수 있어요. 하지만 모든
                          주주가 생략하면 바로 오늘이라도 주주총회를 할 수 있어요.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={st.qna}>
                    <div className={st.title}>주주들에게 주주총회 소집통지 생략동의를 클릭 몇 번으로 받아보세요.</div>
                    <div className={st.content}>
                      <ul>
                        <li>
                          스탠바이에서는 클릭 몇 번으로 주주들에게 주주총회 소집통지 생략동의를 받을 수 있어요.
                          주주들에게 동의서에 직접 도장을 받지 않아도 되니 매우 편리해요!
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={st.sample}>
                    <img src="/images/noti_of_draft_ex.svg" />
                  </div>
                </div>
              </QuestionTooltip>
            </div>
            <div style={{ width: '460px' }}>
              <RadioLabel
                className={clsx(qst.radio, st.radio)}
                name="musterNoticeType"
                value="생략"
                checked={NotiOfDraftVm.musterNoticeType(props.index) === '생략'}
                onChange={(e) => NotiOfDraftVm.setMusterNoticeType(e, props.index)}
                disabled={NotiOfDraftVm.hasCapitalOverBillionYn}
              >
                <span className={st.bold}>주주의 동의</span>를 받아, <span className={st.bold}>바로</span> 주주총회를{' '}
                <span className={st.bold}>진행</span>해요.
                <div
                  className={clsx(
                    qst.radioExplain,
                    st.radioExplain,
                    NotiOfDraftVm.hasCapitalOverBillionYn ? st.disabled : null,
                  )}
                  style={{ fontSize: '12px', marginTop: '15px' }}
                >
                  [온라인 동의받기] 기능 제공
                </div>
                <span className={st.tag}>추천</span>
              </RadioLabel>
              <RadioLabel
                className={clsx(qst.radio, st.radio)}
                name="musterNoticeType"
                value="온라인"
                checked={NotiOfDraftVm.musterNoticeType(props.index) === '온라인'}
                onChange={(e) => NotiOfDraftVm.setMusterNoticeType(e, props.index)}
              >
                <span className={st.bold}>온라인 소집통지</span>를 하고, <span className={st.bold}>10~14일 후</span>{' '}
                주주총회를 <span className={st.bold}>진행</span>해요.
                <div
                  className={clsx(qst.radioExplain, st.radioExplain)}
                  style={{ fontSize: '12px', marginTop: '15px' }}
                >
                  [온라인 소집통지] 기능 제공
                </div>
              </RadioLabel>
              <RadioLabel
                className={clsx(qst.radio, st.radio)}
                name="musterNoticeType"
                value="우편"
                checked={NotiOfDraftVm.musterNoticeType(props.index) === '우편'}
                onChange={(e) => NotiOfDraftVm.setMusterNoticeType(e, props.index)}
                disabled={NotiOfDraftVm.hasCapitalOverBillionYn}
              >
                <span className={st.bold}>우편 소집통지</span>를 하고, <span className={st.bold}>10~14일 후</span>{' '}
                주주총회를 <span className={st.bold}>진행</span>해요.
                <div
                  className={clsx(
                    qst.radioExplain,
                    st.radioExplain,
                    NotiOfDraftVm.hasCapitalOverBillionYn ? st.disabled : null,
                  )}
                  style={{ fontSize: '12px', marginTop: '15px' }}
                >
                  [소집통지문 자동작성] 기능 제공
                </div>
              </RadioLabel>
              <div>
                <Error
                  name="musterNoticeType"
                  value={NotiOfDraftVm.musterNoticeType(props.index)}
                  errorCase={{
                    required: '주주총회 소집통지 진행을 선택해 주세요.',
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ));
}
export default NotiOfDraft;
