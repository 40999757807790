import { observable } from 'mobx';

interface State {
  pageType: 'Security' | 'Information';
}

class BusinessRegistrationVm {
  state: State = observable({
    pageType: 'Security',
  });
  changePage() {
    this.state.pageType = 'Information';
  }
}

export default BusinessRegistrationVm;
