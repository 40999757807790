import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import OnlineMusterDirectVm from './OnlineMusterDirectVm';
import { addCommas } from '@common/module/replaceNumber';

function OnlineMusterDirect() {
  useEffect(() => {
    OnlineMusterDirectVm.load();
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => OnlineMusterDirectVm.submit(e)}>
      <div>온라인 소집통지 사전동의 직접입력(13-8)</div>
      <table>
        <thead>
          <tr>
            <th>사전동의</th>
            <th>주주명</th>
            <th>주식종류</th>
            <th>주식수</th>
            <th>지분율</th>
          </tr>
        </thead>
        <tbody>
          {OnlineMusterDirectVm.shareholders.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <input
                    type="checkbox"
                    name="checkedShareholderIds"
                    value={item.id}
                    checked={OnlineMusterDirectVm.checked(item.id)}
                    onChange={(e) => OnlineMusterDirectVm.setChecked(e)}
                  />
                </td>
                <td>{item.name}</td>
                <td>
                  {item?.stocks.map((stock, index) => {
                    return <div key={index}>{stock.name}</div>;
                  })}
                </td>
                <td>
                  {item?.stocks.map((stock, index) => {
                    return <div key={index}>{addCommas(stock.number)}</div>;
                  })}
                </td>
                <td>
                  {item?.stocks.map((stock, index) => {
                    return <div key={index}>{addCommas(stock.rate)}</div>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button type="submit">저장하기</button>
    </form>
  ));
}
export default OnlineMusterDirect;
