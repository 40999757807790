import regExp from '@common/module/regExp';
import { Button, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import st from './ChangeEmail.module.scss';
import ChangeEmailVm from './ChangeEmailVm';

function ChangeEmail() {
  const location = useLocation();
  const token = queryString.parse(location.search).token;

  return useObserver(() => (
    <form onSubmit={(e) => ChangeEmailVm.submit(e, token)} className={st.ChangeEmailContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logoStadnby-Login.svg" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <Typography fontType="Title-18M">계정 ID로 사용할 </Typography>
        <Typography fontType="Title-18B">새로운 이메일을 입력</Typography>
        <Typography fontType="Title-18M">하세요.</Typography>
      </div>
      <TextField
        label="새로운 이메일"
        name="email"
        className={st.inputText}
        value={ChangeEmailVm.state.email}
        onChange={(e) => ChangeEmailVm.setEmail(e)}
        error={
          <ErrorValidation
            name="email"
            value={ChangeEmailVm.state.email}
            errorCase={{
              required: '이메일을 입력해 주세요',
              pattern: {
                value: regExp.email().test(ChangeEmailVm.state.email),
                message: '올바른 이메일 형식으로 입력해 주세요.',
              },
            }}
          />
        }
      />
      <Button
        type="submit"
        size="large"
        color="primary400"
        className={st.mainBtn}
        active={regExp.email().test(ChangeEmailVm.state.email)}
      >
        이메일 변경하기
      </Button>
    </form>
  ));
}

export default ChangeEmail;
