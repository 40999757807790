import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import GuideListVm from './GuideListVm';
import st from './GuideList.module.scss';
import clsx from 'clsx';

function GuideList(props) {
  return useObserver(() => (
    <div className={st.GuideList}>
      <div className={st.inside}>
        <div className={st.slide}>
          {props.items.map((item, idx) => {
            return (
              <a href={item.url} target="_blank" alt="가이드 사이트로 이동" key={idx}>
                <div className={st.title}>{item.title}</div>
                <div
                  className={st.content}
                  dangerouslySetInnerHTML={{ __html: item.contents.replace(/\n/g, '<br/>') }}
                />
                <span className={st.moreView}>더보기</span>
              </a>
            );
          })}
        </div>
      </div>
      <a href={props.fullLink} target="_blank" className={st.fullLink}>
        전체 목록 보기
      </a>
    </div>
  ));
}
export default GuideList;
