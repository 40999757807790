import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import userModel from '@model/userModel';
import userDashboardModel from '@model/userDashboardModel';
import socketService from '@service/socketService';

class presetService {
  async load() {
    const res = await get('/preset');
    //유저세팅
    userModel.setUser(res.data.user);

    //로컬스토리지에 userId넣음 기존창들에 다른 유저아이디가 있다면 갱신해야하기때문
    localStorage.setItem('userId', res.data.user.id);

    //오늘날짜
    userModel.setNowDate(res.data.date);
    //웹소켓관련 처리
    socketService.load(res.data.channelList);
  }
}
export default new presetService();
