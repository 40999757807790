import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

class CustomerVm {
  get telephoneCounselingYn() {
    return AgendaDashboardModel.agendaProgress.personInCharge.telephoneCounselingYn;
  }
  get requirements() {
    return AgendaDashboardModel.agendaProgress.registrationAgency.requirements;
  }
}
export default new CustomerVm();
