import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import RegisterPopupVm from './RegisterPopupVm';
import st from './RegisterPopup.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Loading from '@commonComponents/Loading/Loading';

function RegisterPopup(props) {
  return useObserver(() => (
    <div className={st.registerPopup}>
      {RegisterPopupVm.state.loadingYn && <Loading />}
      <div className={st.title}>아래 법인으로 등록을 진행해드릴게요!</div>
      <div className={st.companyInfoBox}>
        <div className={st.companyInfo}>
          <div className={st.companyTitle}>{props.companyName}</div>
          <div className={st.companyAddress}>{props.companyAddress}</div>
        </div>
      </div>
      <div className={st.btnArea}>
        <SquareBtn className={st.squareBtn} onClick={() => RegisterPopupVm.registerBtn(props.param)}>
          등록 진행하기
        </SquareBtn>
      </div>
    </div>
  ));
}
export default RegisterPopup;
