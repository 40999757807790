import { observable, runInAction } from 'mobx';

const joinMembershipModel = observable({
  email: '',
  userRegistrationTerms: [],
  submittedTermRequests: [],
  setUserRegistrationTerms(data) {
    runInAction(() => {
      if (data.userRegistrationTerms && data.userRegistrationTerms.length !== 0) {
        this.userRegistrationTerms = data.userRegistrationTerms.map((item) => {
          return {
            id: item.id || '',
            title: item.title || '',
            content: item.content || '',
            required: item.required ?? null,
          };
        });
        this.submittedTermRequests = data.userRegistrationTerms.map((item) => {
          return {
            id: item.id || '',
            agreeYn: false,
            required: item.required ?? null,
          };
        });
      } else {
        this.userRegistrationTerms = [];
      }
    });
  },
});

export default joinMembershipModel;
