import { useObserver } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import AgendaQuestionVm from './AgendaQuestionVm';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import st from './AgendaQuestion.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ContentBtn from '@standby/common-ui/components/atoms/Button/ContentBtn/ContentBtn';
import type { SelectedAgenda } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import type { Tab } from '@model/Corp/Agenda/Step/meetingsStepModel.interface';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';

interface AgendaQuestionProps {
  opened: boolean;
  isAllSaved: boolean;
  pageType: string;
  agendaIndex: number;
  inputComponent: React.ReactElement;
  changeOpened: () => void;
  openFirst: () => void;
  agendaProgressId?: string | null;
  selectedAgenda?: SelectedAgenda;
  tab?: Tab;
  step: string;
}

const AgendaQuestion = React.memo(function (props: AgendaQuestionProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.opened === true) {
      if (props.step === 'MEETINGS' && props.agendaIndex === 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        setTimeout(() => {
          if (ref.current) {
            ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
        }, 50);
      }
    } else {
      if (props.isAllSaved) {
        setTimeout(() => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 50);
      }
    }
  }, [props.opened]);

  return useObserver(() =>
    props.opened ? (
      <ContentBox
        title={`${props.pageType === '안건질문' ? `안건 ${props.agendaIndex + 1} :` : props.agendaIndex + 1 + '.'} ${props.selectedAgenda?.title || props.tab?.title}`}
        subTitleBadge={props.selectedAgenda ? AgendaQuestionVm.subTitleBadgeText(props.selectedAgenda) : null}
        className={st.agendaQuestion}
        titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
        style={{ zIndex: 100 - props.agendaIndex }}
        titleComponent={
          AgendaQuestionVm.tooltipText(props.selectedAgenda?.agendaType) ? (
            <ToolTip
              upAndDown="down"
              leftOrRight="left"
              tooltipContentStyle={{ width: '300px' }}
              className={st.tooltip}
              iconType="question"
            >
              {AgendaQuestionVm.tooltipText(props.selectedAgenda?.agendaType)}
            </ToolTip>
          ) : undefined
        }
        titleComponentSyle={{ marginLeft: '1px', top: '2px', position: 'relative' }}
      >
        <form
          onSubmit={(e) => AgendaQuestionVm.submit(e, props.openFirst, props.pageType, props.agendaProgressId)}
          className={st.questionForm}
        >
          <div className={st.focusArea} ref={ref} />
          <div className={st.title}></div>
          <div className={st.content}>
            <div className={st.questions}>{props.inputComponent}</div>
            <div className={st.btnArea}>
              <SquareBtn type="submit" className={st.contentSubmitBtn} disabled={AgendaQuestionVm.state.submitYn}>
                저장 후 다음
              </SquareBtn>
            </div>
          </div>
        </form>
      </ContentBox>
    ) : (
      <ContentBox
        title={`${props.pageType === '안건질문' ? `안건 ${props.agendaIndex + 1} :` : props.agendaIndex + 1 + '.'} ${props.selectedAgenda?.title || props.tab?.title}`}
        subTitleBadge={props.selectedAgenda ? AgendaQuestionVm.subTitleBadgeText(props.selectedAgenda) : null}
        className={st.agendaQuestion}
        titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
        style={{ zIndex: 100 - props.agendaIndex }}
        titleComponent={
          AgendaQuestionVm.tooltipText(props.selectedAgenda?.agendaType) ? (
            <ToolTip
              upAndDown="down"
              leftOrRight="left"
              tooltipContentStyle={{ width: '300px' }}
              className={st.tooltip}
              iconType="question"
            >
              {AgendaQuestionVm.tooltipText(props.selectedAgenda?.agendaType)}
            </ToolTip>
          ) : undefined
        }
        titleComponentSyle={{ marginLeft: '1px', top: '2px', position: 'relative' }}
      >
        <div className={st.title}>
          <ContentBtn className={st.editBtn} onClick={props.changeOpened} color="important">
            수정
          </ContentBtn>
        </div>
      </ContentBox>
    ),
  );
});
export default AgendaQuestion;
