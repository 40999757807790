import { useObserver } from 'mobx-react';
import st from './RnumberEditModal.module.scss';
import vm from './RnumberEditModal.Vm';

interface Props {
  rNumberId: string;
  rNumber: string;
}

function RnumberEditModal(props: Props) {
  return useObserver(() => (
    <div className={st.rNumberModal}>
      <div className={st.title}>
        R 번호를 변경하면서, 기존 R 번호에 스탠바이가 전달한 정보(주주명부, 정관, 사업자등록증 등)를 삭제할까요?
      </div>
      <ul className={st.confirmList}>
        <li>
          <span className={st.main}>네</span>
          <span className={st.arrow}>→</span>
          <span className={st.sub}>R 번호 변경 + 기존 R번호에 저장된 정보 삭제</span>
        </li>
        <li>
          <span className={st.main}>아니오</span>
          <span className={st.arrow}>→</span>
          <span className={st.sub}>R 번호만 삭제하고, 정보는 삭제 X</span>
        </li>
      </ul>
      <div className={st.btnArea}>
        <button type="button" onClick={() => vm.editRnumber(props.rNumberId, true, props.rNumber)}>
          네
        </button>
        <button type="button" onClick={() => vm.editRnumber(props.rNumberId, false, props.rNumber)}>
          아니오
        </button>
      </div>
    </div>
  ));
}
export default RnumberEditModal;
