import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect } from 'react';
import vm from './SecurityCheckVm';
import BusinessRegistration from '@pages/Guest/BusinessRegistration/BusinessRegistration';
import ShareholderMeetingWaiverConsent from '@pages/Guest/ShareholderMeetingWaiverConsent/ShareholderMeetingWaiverConsent';
import OnlineMeetingNoticeAgreement from '@pages/Guest/OnlineMeetingNoticeAgreement/OnlineMeetingNoticeAgreement';
import ShortenPeriodAgreement from '@pages/Guest/ShortenPeriodAgreement/ShortenPeriodAgreement';
import ShareholderMeetingsWaiverConsentAndNewStockIssue from '@pages/Guest/ShareholderMeetingsWaiverConsentAndNewStockIssue/ShareholderMeetingsWaiverConsentAndNewStockIssue';
import NewStockIssuanceNotice from '@pages/Guest/NewStockIssuanceNotice/NewStockIssuanceNotice';
import ShareholdersMeetingNotice from '@pages/Guest/ShareholdersMeetingNotice/ShareholdersMeetingNotice';
import Expired from '@pages/Guest/Expired/Expired';
import ExpiredMobile from '@pages/Guest/Expired/ExpiredMobile';
import { isMobile } from 'react-device-detect';

function SecurityCheck() {
  let location = useLocation();
  const token = queryString.parse(location.search).token as string;

  useEffect(() => {
    vm.load(token);
  }, []);

  return useObserver(() => (
    <>
      {vm.pageType === 'BUSINESS_REGISTRATION' && <BusinessRegistration token={token} />}
      {/* 주주총회 소집절차 생략동의 */}
      {vm.pageType === 'SHAREHOLDER_MEETING_WAIVER_CONSENT' && <ShareholderMeetingWaiverConsent token={token} />}
      {/* 온라인 소집통지 수신동의 */}
      {vm.pageType === 'ONLINE_MEETING_NOTICE_AGREEMENT' && <OnlineMeetingNoticeAgreement token={token} />}
      {/* 기간 단축 동의서 or 신주식 인수포기 및 기간 단축 동의서 */}
      {(vm.pageType === 'SHORTEN_PERIOD_AGREEMENT' ||
        vm.pageType === 'RENUNCIATION_NEWSHARE_SUBSCRIPTION_AND_SHORTEN_PERIOD_AGREEMENT') && (
        <ShortenPeriodAgreement token={token} />
      )}
      {/* 주주총회생략동의 및 신주발행 동의서 */}
      {(vm.pageType === 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_SHORTEN_PERIOD_AGREEMENT' ||
        vm.pageType === 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_ABANDONED_AND_SHORTEN_PERIOD_AGREEMENT') && (
        <ShareholderMeetingsWaiverConsentAndNewStockIssue token={token} />
      )}
      {/* 신주발행통지 */}
      {vm.pageType === 'NEW_STOCK_ISSUANCE_NOTICE' && <NewStockIssuanceNotice token={token} />}
      {/* 소집통지 */}
      {vm.pageType === 'SHAREHOLDERS_MEETING_NOTICE' && <ShareholdersMeetingNotice token={token} />}
      {vm.status === 'EXPIRED' && <> {isMobile ? <ExpiredMobile /> : <Expired />} </>}
    </>
  ));
}
export default SecurityCheck;
